import React, { useEffect, useState, useMemo } from 'react'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import WorkflowSourceFiles from './workflows/SourceFiles/WorkflowSourceFiles'
import WorkflowReconcileSourceData from './workflows/ReconcileSourceData'
import WorkflowMyCollections from './workflows/MyCollections'
import WorkflowDevUtils from './workflows/DevUtils'
import WorkflowBillingEntities from './workflows/BillingEntities'
import WorkflowProblematics from './workflows/Problematics'
import perms from '../../../utils/perms'
import styled from 'styled-components'

const StyledWorkflowsWrapper = styled.div`
  .sublayout {
    display: flex;
    width: 100%;

    .left {
      min-width: 240px;
      align-self: flex-start;
      position: sticky;
      top: calc(60px + 1rem);
      padding-right: 1.5rem;
      z-index: 11;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;

      .std-table {
        .header-items {
          margin-top: -0.5rem;
        }
      }
    }
  }
`

export default function PageWorkflowsView(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.workflows.tabs',
  })
  const [tab, setTab] = useState<string>(queryData['tab'] || 'bill_ents')
  const calcPerms = useMemo(
    () => ({
      editor: perms.hasAny(
        perms.RoleTokens.PermZClaimAdjudicator,
        perms.RoleTokens.PermZClaimDataLinker,
        perms.RoleTokens.PermZClaimSupervisor,
        perms.RoleTokens.PermEngineering
      ),
      supervisor: perms.hasAny(
        perms.RoleTokens.PermZClaimSupervisor,
        perms.RoleTokens.PermEngineering
      ),
      engineering: perms.hasAny(perms.RoleTokens.PermEngineering),
    }),
    []
  )

  useEffect(() => {
    setQueryData({ tab })
  }, [tab])

  return (
    <StyledWorkflowsWrapper>
      <DesignSuite2023.StyledTabs
        variant="fullWidth"
        value={tab}
        style={{ margin: '0 0 1rem', borderRadius: '8px' }}>
        {calcPerms.editor && (
          <DesignSuite2023.StyledTab
            value={'bill_ents'}
            label="Billing Entities"
            onClick={() => setTab('bill_ents')}
          />
        )}
        <DesignSuite2023.StyledTab
          value={'my_collections'}
          label="My Collections"
          onClick={() => setTab('my_collections')}
        />
        {calcPerms.engineering && (
          <DesignSuite2023.StyledTab
            value={'rsd'}
            label="Reconcile Source Data"
            onClick={() => setTab('rsd')}
          />
        )}
        {calcPerms.engineering && (
          <DesignSuite2023.StyledTab
            value={'csf'}
            label="Claim Source/Files"
            onClick={() => setTab('csf')}
          />
        )}
        <DesignSuite2023.StyledTab
          value={'problematics'}
          label="Trash/Problematic Claims"
          onClick={() => setTab('problematics')}
        />
        {calcPerms.engineering && (
          <DesignSuite2023.StyledTab
            value={'dev_utils'}
            label="Dev Utils"
            onClick={() => setTab('dev_utils')}
          />
        )}
      </DesignSuite2023.StyledTabs>

      {tab === 'rsd' && calcPerms.engineering && (
        <WorkflowReconcileSourceData />
      )}
      {tab === 'csf' && calcPerms.engineering && <WorkflowSourceFiles />}
      {tab === 'bill_ents' && calcPerms.editor && <WorkflowBillingEntities />}
      {tab === 'my_collections' && <WorkflowMyCollections />}
      {tab === 'problematics' && <WorkflowProblematics />}
      {tab === 'dev_utils' && calcPerms.engineering && <WorkflowDevUtils />}
    </StyledWorkflowsWrapper>
  )
}
