import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import * as EmployerDivisionTable from './EmployerDivisionTable'
import { ButtonAddEmployerDivision } from './EmployerDivisionTable'
import * as ObservedEmployerDivisionTable from './ObservedEmployerDivisionTable'
import {
  EmployerDivision,
  ObservedEmployerDivision,
} from '../../../models/EmployerDivisions'

export interface DivisionManagerProps {
  employerID: number
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const SideBar = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 200px;
`

const Content = styled.div`
  flex: 1;
`

const tabs = ['Employer Divisions', 'Observed Divisions']

export default function TabManageDivisions({
  employerID,
}: DivisionManagerProps): React.ReactElement {
  const [tab, setTab] = useState<number>(0)

  if (employerID === 0) {
    return <div>Employer must be saved before managing divisions</div>
  }

  return (
    <Container>
      <SideBar>
        <div>
          {tabs.map((tabName, index) => {
            return (
              <Typography
                key={index}
                component="div"
                variant="body1"
                onClick={() => setTab(index)}
                style={{
                  background: index === tab ? `#efefef` : 'inherit',
                  padding: '0.5rem',
                }}>
                {tabName}
              </Typography>
            )
          })}
        </div>
      </SideBar>
      <Content>
        {tab === 0 && (
          <EmployerDivisionTable.Table
            customColumns={{
              _Edit: {
                Name: ``,
                details: {
                  dataFormat(_: any, row: EmployerDivision): any {
                    return (
                      <EmployerDivisionTable.InlineEditEmployerDivision
                        data={row}
                      />
                    )
                  },
                },
              },
            }}
            LeftHeaderItems={
              <Typography variant="h6">Employer Divisions</Typography>
            }
            RightHeaderItems={
              <ButtonAddEmployerDivision employerID={employerID} />
            }
            passFilters={{ IsLatest: true, IsRetired: false }}
            employerID={employerID}
          />
        )}
        {tab === 1 && (
          <ObservedEmployerDivisionTable.Table
            customColumns={{
              _Edit: {
                Name: ``,
                details: {
                  dataFormat(_: any, row: ObservedEmployerDivision): any {
                    return (
                      <ObservedEmployerDivisionTable.InlineLinkEmployerDivision
                        data={row}
                      />
                    )
                  },
                },
              },
            }}
            LeftHeaderItems={
              <Typography variant="h6">Observed Divisions</Typography>
            }
            employerID={employerID}
            passFilters={{ IsLatest: true }}
          />
        )}
      </Content>
    </Container>
  )
}
