import React, { useEffect, useState } from 'react'
import {
  ShapeBundleDefinition,
  ShapeUBCode,
  ShapeDRGCode,
  ShapeCPTModifierCode,
  ShapeCPTCode,
  ShapeICD10CMCode,
  ShapePOSCode,
} from '../types'
import { getExplainCodes } from '../../../actions/MasterDataActions'
import { Typography, Chip } from '@material-ui/core'
import styled from 'styled-components'

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 0.65rem;
  grid-row-gap: 0.5rem;

  .fuller {
    grid-column: 1 / -1;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 0 0.25rem;
    font-weight: 500;
    font-size: 95%;

    &:first-of-type {
      padding-top: 0;
    }
  }
`

type shapeResponse = {
  cpts?: {
    [k: string]: ShapeCPTCode
  }
  cptModifiers?: {
    [k: string]: ShapeCPTModifierCode
  }
  drgs?: {
    [k: string]: ShapeDRGCode
  }
  ubs?: {
    [k: string]: ShapeUBCode
  }
  icd10cms?: {
    [k: string]: ShapeICD10CMCode
  }
  pos?: {
    [k: string]: ShapePOSCode
  }
}

interface props {
  bundleDefinition?: ShapeBundleDefinition | null
  cpts?: string[]
  cptModifiers?: string[]
  drgs?: string[]
  ubs?: string[]
  icd10cms?: string[]
  pos?: string[]
}

export type shapeExplainCodes = {
  cpts: string[]
  cptModifiers: string[]
  drgs: string[]
  ubs: string[]
  icd10cms: string[]
  pos: string[]
}

export default function ExplainCodes({
  bundleDefinition,
  cpts,
  cptModifiers,
  drgs,
  ubs,
  icd10cms,
  pos,
}: props): React.ReactElement | null {
  const [data, setData] = useState<shapeResponse | null>(null)

  const inspectable = React.useMemo((): shapeExplainCodes => {
    const obj: shapeExplainCodes = {
      cpts: [...(cpts || [])],
      cptModifiers: [...(cptModifiers || [])],
      drgs: [...(drgs || [])],
      ubs: [...(ubs || [])],
      icd10cms: [...(icd10cms || [])],
      pos: [...(pos || [])],
    }

    if (bundleDefinition) {
      bundleDefinition.primCode?.cpt &&
        obj.cpts.push(bundleDefinition.primCode.cpt)
      bundleDefinition.primCode?.mod &&
        obj.cptModifiers.push(bundleDefinition.primCode.mod)
      bundleDefinition.drg && obj.drgs.push(bundleDefinition.drg)
      bundleDefinition.pos?.length && obj.pos.push(...bundleDefinition.pos)

      bundleDefinition.auxCodes?.forEach((anc) => {
        if (!anc.cpt) return
        obj.cpts.push(anc.cpt)
        anc.mod && obj.cptModifiers.push(anc.mod)
      })
    }

    // .filter elinates nulls or ''; new Set dedupes
    obj.cpts = [...new Set(obj.cpts.filter((cpt) => !!cpt))]
    obj.cptModifiers = [...new Set(obj.cptModifiers.filter((mod) => !!mod))]
    obj.drgs = [...new Set(obj.drgs.filter((drg) => !!drg))]
    obj.ubs = [...new Set(obj.ubs.filter((ub) => !!ub))]
    obj.icd10cms = [...new Set(obj.icd10cms.filter((icd) => !!icd))]
    obj.pos = [...new Set(obj.pos.filter((p) => !!p))]

    return obj
  }, [bundleDefinition, cpts])

  useEffect(() => {
    if (!inspectable) return
    getExplainCodes(inspectable)
      .then((res: any) => {
        if (res.error) throw res
        setData(res.Data)
      })
      .catch((err: any) => {
        console.error('caught it', err)
      })
  }, [inspectable])

  if (!data) return null

  return (
    <>
      <StyledGrid>
        <RenderCodes
          list={inspectable.cpts}
          label="CPT Codes"
          Display={(code: string) => (
            <div>
              <Typography component="div" variant="body2">
                {data?.cpts?.[code]?.Descr || '(No description available)'}
              </Typography>
              <Typography component="div" variant="caption">
                Category: {data?.cpts?.[code]?.Category || '?'}
              </Typography>
            </div>
          )}
        />

        <RenderCodes
          list={inspectable.cptModifiers}
          label="CPT Modifiers"
          Display={(mod: string) => (
            <Typography component="div" variant="body2">
              {data?.cptModifiers?.[mod]?.Descr || '(No description available)'}
            </Typography>
          )}
        />

        <RenderCodes
          list={inspectable.drgs}
          label="DRG Codes"
          Display={(drg: string) => (
            <div>
              <Typography component="div" variant="body2">
                {data?.drgs?.[drg]?.Descr || '(No description available)'}
              </Typography>
              <Typography component="div" variant="caption">
                Type: {data?.drgs?.[drg]?.TypeHandle || '?'}
              </Typography>
            </div>
          )}
        />

        <RenderCodes
          list={inspectable.ubs}
          label="UB Codes"
          Display={(ub: string) => (
            <div>
              <Typography component="div" variant="body2">
                {data?.ubs?.[ub]?.Descr || '(No description available)'}
              </Typography>
              <Typography component="div" variant="caption">
                Group: {data?.ubs?.[ub]?.Group || '?'}
              </Typography>
            </div>
          )}
        />

        <RenderCodes
          list={inspectable.icd10cms}
          label="ICD10-CM Codes"
          Display={(icd: string) => (
            <Typography component="div" variant="body2">
              {data?.icd10cms?.[icd]?.Descr || '(No description available)'}
            </Typography>
          )}
          getChipLabel={(code: string) =>
            data?.icd10cms?.[code]?.Formatted || code
          }
        />

        <RenderCodes
          list={inspectable.pos}
          label="POS Codes"
          Display={(code: string) => (
            <div>
              <Typography component="div" variant="body2">
                {data?.pos?.[code]?.Name || '(empty)'}
              </Typography>
              <Typography component="div" variant="caption">
                <strong>Description:</strong> {data?.pos?.[code]?.Descr || '?'}
              </Typography>
            </div>
          )}
        />
      </StyledGrid>
    </>
  )
}

function RenderCodes({
  list,
  label,
  Display,
  getChipLabel,
}: {
  list: string[]
  label: string
  Display(code: string): React.ReactElement
  getChipLabel?(code: string): string
}): React.ReactElement | null {
  if (!list?.length) return null

  return (
    <>
      <Typography component="div" variant="body1" className="fuller">
        {label}
      </Typography>
      {list.map((code: string) => (
        <React.Fragment key={`${label}-${code}`}>
          <div>
            {!!getChipLabel ? (
              <Chip label={getChipLabel(code)} size="small" />
            ) : (
              <Chip label={code} size="small" />
            )}
          </div>
          {Display(code)}
        </React.Fragment>
      ))}
    </>
  )
}
