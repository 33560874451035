import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import {
  renderTextField as RenderTextField,
  // renderNotesField as RenderNotesField,
  // renderSwitchField as RenderSwitchField,
  SetterArgs,
} from '../../ViewHelpers'
import { Button } from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { ShapeCostKeyBundleContentTemplate } from '../types'

interface props {
  onCreated?(v: ShapeCostKeyBundleContentTemplate): void
}

export default function NewContentTemplate({
  onCreated,
}: props): React.ReactElement | null {
  const [name, setName] = useState<string>('')
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setName('')
  }, [])

  function doCreateNewTemplate() {
    apiPostTemplate({ name })
      .then((res) => {
        if (res?.error) throw res
        onCreated?.(res?.Data)
        showSnackbar('New template created', SnackbarTypeSuccess)
        setName('')
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating new cost key template',
        })
      )
  }

  return (
    <RenderTextField
      name="name"
      label="New Template Name"
      value={name}
      setter={(args: SetterArgs) => {
        setName(args.value)
      }}
      use2023Styles
      opts={{
        margin: 'none',
        InputProps: {
          endAdornment: (
            <div>
              <Button
                onClick={doCreateNewTemplate}
                disabled={!name}
                size="small"
                color="primary"
                variant="contained"
                style={{ whiteSpace: 'nowrap' }}>
                Add
              </Button>
            </div>
          ),
        },
      }}
    />
  )
}

function apiPostTemplate(payload: {
  name: string
}): Promise<{ error?: boolean; Data: ShapeCostKeyBundleContentTemplate }> {
  return api.post('/cost_key_bundle_content_template', payload)
}
