import React, { useState, useEffect, useRef } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'
import * as BillingEntityTable from '../../../BillingEntities/BillingEntityTable2'
import DisplayKeyValueData from '../DisplayKeyValueData'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  getZClaimAssignmentProvider,
  putZClaimAssignBillingEntity,
  scopedRoutes,
} from '../../../../actions/ZClaimActions'
import dateTime from '../../../../utils/dateTime'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import BtnCreateUncontractedBillingEntity from '../BtnCreateUncontractedBillingEntity'
import {
  ShapeBillingEntity,
  BillingEntityCreationSource,
} from '../../../BillingEntities/types'
import BtnCreateNewBillingEntity from '../../../BillingEntities/BtnCreateNew'

const StyledContent = styled(DialogContent)`
  padding: 1rem !important;

  .MuiAlert-root {
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .force-switch-label-font-size {
    font-size: inherit;

    .MuiFormControlLabel-label {
      font-size: inherit;
    }
  }

  .std-table .header-items {
    margin-top: 0;
  }
`

const StyledCurrentlyAssigned = styled.div`
  margin-bottom: 0.5rem;
  padding: 1rem;
  background: #e3efe4;
  font-size: 95%;
  border-radius: 5px;
  color: #4e6950;
`

interface props {
  zClaimID: number
  onComplete?(): void
}

export default function AssignerBillingEntity({
  zClaimID,
  onComplete,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [currentInfo, setCurrentInfo] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refTable = useRef<any>(null)

  useEffect(() => {
    if (!isOpen) {
      setCurrentInfo(null)
      return
    }
    loadInfo()
  }, [isOpen])

  function loadInfo() {
    if (!zClaimID) return
    getZClaimAssignmentProvider(zClaimID)
      .then(({ Data }: any) => {
        setCurrentInfo(Data)
      })
      .catch(catchAPIError({}))
  }

  function onClickRow(row: any) {
    if (row.ID === currentInfo?.Current?.BillingEntityInfo?.ID) {
      showSnackbar(
        'Selected Billing Entity is already assigned to this claim, nothing to do.',
        'warning'
      )
      return
    }
    ConfirmDialog({
      title: 'Assign Billing Entity',
      content: (
        <>
          <Typography component="div" variant="body2">
            <RenderBillingEntityInfo be={row} />
          </Typography>
          <DesignSuite2023.Divider />
          <Typography variant="body1" color="textSecondary">
            By clicking confirm, the Billing Entity on the claim will be
            updated. If this Billing Entity causes the claim to be associated
            with a new organization,{' '}
            <i>
              the Practice Facility and Fee Schedule will be cleared
              automatically.
            </i>
          </Typography>
        </>
      ),
      onConfirm() {
        putZClaimAssignBillingEntity(zClaimID, {
          billingEntityID: row.ID,
          // makeDefaulter,
        })
          .then((res: any) => {
            if (res.error) throw res
            setIsOpen(false)
            showSnackbar('Billing Entity assigned OK.', 'success')
            onComplete?.()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning Billing Entity',
            })
          )
      },
    })
  }

  function onNewBillingEntityCreated(be: ShapeBillingEntity) {
    refTable.current?.setFilterImmediate({
      q: be.TIN,
      organizationIDs: [],
      contractedStatus: null,
      creationSource: null,
    })
    alert(
      'New Billing Entity created OK. We attempted to filter the table to show the new Billing Entity for you, but if it does not apepar, you may need to toggle filters (like Source or Uncontracted to "Any").'
    )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Assign Billing Entity (Organization gets set automatically as a result)">
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
          style={{
            padding: '0.3rem',
            background: 'rgba(0,0,0,0.1)',
            fontSize: '110%',
          }}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth={false} onClose={() => setIsOpen(false)}>
        <DialogTitle
          disableTypography
          style={{
            background: '#f1f1f1',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="h6">Assign Billing Entity</Typography>
          <div>
            <IconButton
              size="medium"
              onClick={() => setIsOpen(false)}
              style={{ padding: 5 }}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <StyledContent>
          {!!currentInfo && (
            <Grid className="grid-cols" container spacing={2}>
              <Grid
                item
                xs="auto"
                style={{
                  position: 'sticky',
                  top: 0,
                  // width: 'min-content',
                  // maxWidth: 500,
                  width: 460,
                }}>
                <DesignSuite2023.Section style={{ position: 'sticky', top: 0 }}>
                  {!!currentInfo.Current?.BillingEntityInfo?.ID && (
                    <>
                      <StyledCurrentlyAssigned>
                        <strong>
                          Billing Entity Currently Assigned To Claim
                        </strong>
                        <DesignSuite2023.Divider />
                        <RenderBillingEntityInfo
                          be={currentInfo.Current.BillingEntityInfo}
                        />
                      </StyledCurrentlyAssigned>
                      <DesignSuite2023.Divider />
                    </>
                  )}

                  <Typography variant="subtitle1" style={{ marginTop: 0 }}>
                    Billing Provider Info Received On Claim
                  </Typography>
                  <DisplayKeyValueData
                    style={{ marginBottom: '0.35rem' }}
                    data={currentInfo.SrcBillingProvider}
                    skipFields={[
                      'ID',
                      'CreatedAt',
                      'BillingEntityDefaulterID',
                      'BillingEntityDefaulterBillingEntityID',
                      'Hash',
                    ]}
                    customRender={{
                      TIN(v: any) {
                        return (
                          <DesignSuite2023.Tooltip title="Click to search billing entities by this TIN">
                            <span
                              style={{
                                fontWeight: 'bold',
                                color: 'lightblue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                // 'q' is the standard name for search property in all tables
                                refTable.current?.setFilterImmediate({ q: v })
                              }}>
                              {JSON.stringify(v)}
                            </span>
                          </DesignSuite2023.Tooltip>
                        )
                      },
                      NPI(v: any) {
                        return (
                          <DesignSuite2023.Tooltip title="Click to search billing entities by this NPI">
                            <span
                              style={{
                                fontWeight: 'bold',
                                color: 'lightblue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                // 'q' is the standard name for search property in all tables
                                refTable.current?.setFilterImmediate({ q: v })
                              }}>
                              {JSON.stringify(v)}
                            </span>
                          </DesignSuite2023.Tooltip>
                        )
                      },
                    }}
                  />
                  <DesignSuite2023.GridLR
                    left={
                      <BtnCreateUncontractedBillingEntity
                        srcRecID={currentInfo.SrcBillingProvider?.ID}
                        onConfirm={() => refTable.current?.refresh()}
                      />
                    }
                    right={
                      !!currentInfo.SrcBillingProvider?.ID ? (
                        <BtnCreateNewBillingEntity
                          onSave={onNewBillingEntityCreated}
                          creationSource={
                            BillingEntityCreationSource.ReviewedEDI
                          }
                          tooltip="Create a Billing Entity from this info. You should only do this if you have already searched for, and could not locate, an existing Billing Entity to assign."
                          initValues={{
                            Name: currentInfo.SrcBillingProvider.Name,
                            TIN: currentInfo.SrcBillingProvider.TIN,
                            NPI: currentInfo.SrcBillingProvider.NPI,
                            DateStart: dateTime
                              .now()
                              .format(dateTime.formats.ISODate),
                            Notes: `Created from observed billing provider hash: ${currentInfo.SrcBillingProvider.Hash}`,
                            OriginatingSrcHash:
                              currentInfo.SrcBillingProvider.Hash,
                            RemittanceAddress: {
                              ID: 0,
                              Address1: currentInfo.SrcBillingProvider.Address1,
                              Address2: currentInfo.SrcBillingProvider.Address2,
                              City: currentInfo.SrcBillingProvider.City,
                              State: currentInfo.SrcBillingProvider.State,
                              Zip: currentInfo.SrcBillingProvider.Zip?.slice(
                                0,
                                5
                              ),
                            },
                          }}
                        />
                      ) : null
                    }
                  />

                  <DesignSuite2023.Divider />

                  <Typography variant="subtitle1">
                    Service Facility Info Received On Claim
                  </Typography>
                  <DisplayKeyValueData
                    data={currentInfo.SrcServiceFacility}
                    skipFields={[
                      'ID',
                      'CreatedAt',
                      'PracticeFacilityDefaulterID',
                      'PracticeFacilityDefaulterPracticeFacilityID',
                      'Hash',
                    ]}
                  />
                </DesignSuite2023.Section>
              </Grid>
              <Grid
                item
                xs="auto"
                style={{
                  flex: 1,
                  width: 1100,
                  overflow: 'hidden',
                  overflowX: 'scroll',
                }}>
                <BillingEntityTable.Table
                  noWrapWhitespace
                  apiEndpoint={scopedRoutes.findBillingEntities}
                  ref={refTable}
                  onRowClick={onClickRow}
                  DataTableProps={{ density: 'small', stickyHeader: true }}
                  passFilters={{ contractedStatus: 'yes', isRetired: 'no' }}
                  customColumns={BillingEntityTable.densifiedColumns}
                  LeftHeaderItems={
                    <Typography>Choose A Billing Entity</Typography>
                  }
                  RightHeaderItems={
                    <>
                      <BillingEntityTable.FilterCreationSource />
                      &nbsp;&nbsp;
                      <BillingEntityTable.FilterContractedStatus />
                      &nbsp;&nbsp;
                      <BillingEntityTable.StandardFilterSearch />
                      &nbsp;&nbsp;
                      <BillingEntityTable.FilterOrganization
                        preloadAll
                        apiEndpointSearch={scopedRoutes.findOrganizations}
                        apiEndpointGetByID={scopedRoutes.getOrganizationByID}
                      />
                    </>
                  }
                />
              </Grid>
            </Grid>
          )}
        </StyledContent>
      </Dialog>
    </>
  )
}

function RenderBillingEntityInfo({ be }: any): React.ReactElement {
  const ds = dateTime.parse(be.DateStart)
  const de = dateTime.parse(be.DateEnd)
  const ca = dateTime.parse(be.CreatedAt)

  let pastEndDate = false
  if (de.isValid() && de.isBefore(dateTime.now())) {
    pastEndDate = true
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        rowGap: '0.35rem',
        columnGap: '1rem',
        alignItems: 'center',
      }}>
      <strong>ID</strong>
      <span>{be.ID}</span>
      <strong>Name</strong>
      <span>{be.Name}</span>
      <strong>Organization:</strong>
      <span>{be.OrganizationName || '(?)'}</span>
      <strong>TIN:</strong>
      <span>{be.TIN || '?'}</span>
      <strong>NPI:</strong>
      <span>{be.NPI || '?'}</span>
      <strong>Address:</strong>
      <span>{be.RemittanceAddress?.DisplayAddress || '?'}</span>
      <strong>Start Date:</strong>
      <span>{ds.format(dateTime.formats.AmericanDate, 'N/A')}</span>
      <strong style={{ display: 'inline-flex', alignItems: 'center' }}>
        <DesignSuite2023.CommonIcons.IconArrowRight fontSize="small" />
        <span>End Date:</span>
      </strong>
      {pastEndDate ? (
        <span>
          <strong style={{ color: 'red' }}>
            {de.format(dateTime.formats.AmericanDate, 'N/A')}
          </strong>{' '}
          (*Attention*)
        </span>
      ) : (
        <span>{de.format(dateTime.formats.AmericanDate, 'N/A')}</span>
      )}
      <strong>Is Contracted:</strong>{' '}
      <span>
        {be.IsContracted ? (
          'Yes'
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>NO</span>
        )}
      </span>
      <strong>Creation Source:</strong> <span>{be.CreationSource}</span>
      <strong>Last Modified On:</strong>{' '}
      <span>{ca.format(dateTime.formats.PrettyVerbose)}</span>
      <strong>Notes</strong>
      <span>{be.Notes || '(none)'}</span>
      {be.IsRetired && (
        <>
          <DesignSuite2023.Divider style={{ gridColumn: '1/-1' }} />
          <strong style={{ color: 'red', gridColumn: '1/-1' }}>
            This Billing Entity is retired, indicating it should NEVER be
            selected unless there are extenuating circumstances. If you believe
            this is still the correct Billing Entity, please review and confirm
            with a supervisor.
          </strong>
        </>
      )}
      {!be.IsLatest && (
        <>
          <DesignSuite2023.Divider style={{ gridColumn: '1/-1' }} />
          <strong style={{ opacity: '0.8', gridColumn: '1/-1' }}>
            There is a newer version of this Billing Entity info available. If
            you see this for a claim that has not been processed yet, you should
            update the Billing Entity.
          </strong>
        </>
      )}
    </div>
  )
}
