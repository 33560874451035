import React, { createContext, useContext, useEffect, useState } from 'react'
import { getCostKeys } from '../../actions/SavingsAnalysisV2'

interface ShapeContextSA {
  allCostKeys: any[]
}

const contextSA = createContext({} as ShapeContextSA)
export const useSAContext = () => useContext<ShapeContextSA>(contextSA)

export default function SAContextProvider({
  children,
}: any): React.ReactElement {
  const [allCostKeys, setAllCostKeys] = useState<any[]>([])

  useEffect(() => {
    loadAllCostKeys()
  }, [])

  function loadAllCostKeys() {
    getCostKeys()
      .then((res: any) => {
        if (res.error) throw res
        setAllCostKeys(res.Data)
      })
      .catch((err: any) => {
        console.error('Failed loading all CCs', err)
      })
  }

  return (
    <contextSA.Provider value={{ allCostKeys }}>{children}</contextSA.Provider>
  )
}
