import React, { useEffect, useImperativeHandle, useState } from 'react'
import { EmployerDivision } from '../../../models/EmployerDivisions'
import {
  getEmployerDivision,
  postEmployerDivision,
  putEmployerDivision,
} from '../../../actions/EmployerDivisionActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { renderTextField as RenderTextField } from '../../../components/Inputs/standard'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'

interface DialogEditDivisionProps {
  employerID: number
  employerDivisionUUID?: string
  onClose?(): void
}

const emptyRecord: EmployerDivision = {
  ID: 0,
  UUID: '',
  Name: '',
  Handle: '',
  EmployerID: 0,
  IsRetired: false,
}

export default React.forwardRef(function DialogEditDivision(
  { employerID, employerDivisionUUID, onClose }: DialogEditDivisionProps,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)

  const [division, setDivision] = useState<EmployerDivision | null>({
    ...emptyRecord,
    EmployerID: employerID,
  })
  const { showDurationShort } = useSnackbar()

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!isOpen || !employerDivisionUUID) return
    getEmployerDivision(employerID, employerDivisionUUID)
      .then((res: any) => {
        setDivision(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed fetching division' }))
  }, [isOpen])

  function handleClose() {
    onClose?.()
    setIsOpen(false)
  }

  function doSave() {
    if (!division || isWorking) return

    setIsWorking(true)
    const endPoint = division?.UUID ? putEmployerDivision : postEmployerDivision

    endPoint(employerID, division)
      .then((res: any) => {
        showDurationShort('Division saved', SnackbarTypeSuccess)
        handleClose()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving division' }))
      .finally(() => setIsWorking(false))
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth={'xl'}
      onClose={() => {
        // Only allow close via save or cancel
      }}>
      <DialogTitle>Edit Division</DialogTitle>
      <DialogContent>
        <div style={{ width: '600px' }}>
          <RenderTextField
            name={'Name'}
            label={'Name'}
            value={division?.Name}
            setter={({ name, value }) => {
              let newDivision = { ...division, Name: value } as EmployerDivision
              setDivision(newDivision)
            }}
            use2023Styles
          />
        </div>
        <DesignSuite2023.GridLR
          left={
            <Button color="secondary" variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          }
          right={
            <Button color="primary" variant="contained" onClick={doSave}>
              Save
              {isWorking && (
                <>
                  &nbsp;
                  <DesignSuite2023.LoadingSpinner size={20} show />
                </>
              )}
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  )
})
