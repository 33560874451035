import React, { forwardRef, useMemo, useState } from 'react'
import CostKeyDetail from '../CostKeyDetail'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'

type props = DrawerProps & {
  readOnly?: boolean
  costKeyID?: number | null
  Trigger?: React.FC<any> | null
}

export default forwardRef<props, any>(function (
  {
    readOnly,
    costKeyID,
    disableEscapeKeyDown = true,
    onClose: propOnClose,
    Trigger,
    ...spread
  }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)
  const internalRef = React.useRef<any>(null)
  const whichRef = useMemo(() => ref || internalRef, [ref, internalRef])

  function onOpen() {
    setComponent(
      <div style={{ margin: '0 auto', paddingTop: '2rem' }}>
        {/* @todo: read only */}
        <CostKeyDetail id={costKeyID!} readOnly={readOnly} />
      </div>
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <>
      <DesignSuite2023.Drawer
        {...spread}
        ref={whichRef}
        width="80vw"
        disableEscapeKeyDown={disableEscapeKeyDown}
        onOpen={onOpen}
        onClose={onClose}>
        {component}
      </DesignSuite2023.Drawer>
      {Trigger && (
        <Trigger
          doOpen={() => {
            whichRef?.current?.open()
          }}
        />
      )}
    </>
  )
})
