import utils from '../utils'
import perms from '../utils/perms'

interface TzUsers {
  columns: cols
  permissionColumns: permCols
  editFields: any
  hasAccess: any
}

interface cols {
  FirstName: col
  LastName: col
  Email: col
  CanManageOtherUsers: col
}

interface col {
  name: string
  details?: any
}

interface permCols {
  ViewReportPaymentLag: col
  ViewReportInvoice: col
  ViewReportPaidClaims: col
  ViewReportPaidClaimsPHI: col
  ViewReportSavings: col
  ViewInvoice: col
  ViewInvoiceDetail: col
  ViewAndSubmitEligibility: col
  UpdateEligibility: col
}

/*
 * transform function is to be used to take data from the server and make it fit a frontend format for editing.
 */
const teamZeroUsers: TzUsers = {
  columns: {
    FirstName: {
      name: 'First Name',
      details: { dataFormat: getFromUserInfo('FirstName') },
    },
    LastName: {
      name: 'Last Name',
      details: { dataFormat: getFromUserInfo('LastName') },
    },
    Email: { name: 'Email', details: { dataFormat: getFromUserInfo('Email') } },
    CanManageOtherUsers: {
      name: 'Is Admin',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
  },
  permissionColumns: {
    ViewReportPaymentLag: {
      name: 'Payment Lag',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewReportInvoice: {
      name: 'Invoice Summary',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewReportPaidClaims: {
      name: 'Paid Claims',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewReportPaidClaimsPHI: {
      name: 'Paid Claims (PHI)',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewReportSavings: {
      name: 'Savings',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewAndSubmitEligibility: {
      name: 'View and Submit Eligibility',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    UpdateEligibility: {
      name: 'Update Eligibility',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewInvoice: {
      name: 'View Invoice',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    ViewInvoiceDetail: {
      name: 'View Invoice Detail (PHI)',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
  },
  editFields: {},
  hasAccess: () => perms.has(perms.RoleTokens.PermReadTeamZeroUser),
}

function getFromUserInfo(colName: string): any {
  return (cell: any, row: any) => {
    return row.UserInfo[colName]
  }
}

export default teamZeroUsers
