/*
@todo: update this to use the useStandardTableSetup hook; and allow injecting
dependencies such that things like Employers/Organizations etc don't need to
create their own 'FilesTable' components - just use this.

See views/Loas/FilesTable for an example of what could go in place here.
*/
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { DataTable } from '../../components/DataTable'
import { debounce } from 'lodash'
import models from '../../models'
import { getFiles } from '../../actions/FileActions'
import { FileTypeData } from './types'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { Grid, InputAdornment, TextField, Tooltip } from '@material-ui/core'
import { InfoOutlined, Search as IconSearch } from '@material-ui/icons'
import FileTypeFilter from './FileTypeFilter'
import useRefresh from '../../hooks/useRefresh'
import styled from 'styled-components'

const TableContainer = styled(Grid)`
  margin-top: 10px;
`

const LeftHeaderContainer = styled(Grid)`
  display: flex;
`

const ToolTipIcon = styled(InfoOutlined)`
  margin-left: 2px;
  color: #a2a2a2;
  height: 16px;
  width: auto;
  cursor: pointer;
`

interface contextData {
  filter: any
  setFilter(v: any): void
}

const sharedContext = React.createContext({} as contextData)

interface props {
  passFilters?: any
  customColumns?: any
  onRowClick?(fileID: number, cmdClicked: boolean): void
  onCheckHandler?(selected: any[]): void
  isRowCheckable?(file: any): boolean
  DataTableProps?: any
  RightHeaderItems?: React.ReactNode
  LeftHeaderItems?: React.ReactNode
  apiEndpoint(payload: any): Promise<any>
  editRowHandler?: ((files: any[]) => void) | null
}

export const Table = forwardRef(function FileTableList(
  {
    passFilters,
    customColumns = models.File.columns,
    onRowClick,
    isRowCheckable = (file: any) => !!file.UIPermissions?.CanDelete,
    onCheckHandler,
    DataTableProps = {},
    RightHeaderItems,
    LeftHeaderItems,
    apiEndpoint = apiEndpointDefault,
    editRowHandler,
  }: props & Partial<any>,
  ref: any
): React.ReactElement {
  const [results, setResults] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ q: '', ...passFilters })
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [sortable, setSortable] = useState({
    col: 'effective_date',
    dir: 'desc',
  })
  const { catchAPIError } = useErrorHandlers()
  const { refresh, refreshToken } = useRefresh()

  const dataTableProps = {
    ...DataTableProps,
    ...(onCheckHandler ? { checkHandler: onCheckHandler } : {}),
  }

  /*
    Exposes a public API for this component; parents can call .refresh() on the
    tracked ref in order to trigger an update of the table
  */
  useImperativeHandle(ref, () => ({ refresh }), [])

  useEffect(() => {
    if (editRowHandler && results) editRowHandler(results)
  }, [results])

  const loadFiles = useCallback(
    debounce((payload: any) => {
      setLoading(true)
      apiEndpoint(payload)
        .then((res: any) => {
          setResults(res.Data)
          setResultCount(res.Meta?.Total || res.Data.length)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed to load files; please contact Engineering',
          })
        )
        .finally(() => setLoading(false))
    }, 400),
    [setLoading, setResults, setResultCount]
  )

  useEffect(() => {
    setLoading(true)
    // compose payload
    const payload = {
      filter: { ...filter },
      sort: [sortable.col, sortable.dir],
      page: pagination.page,
      pageSize: pagination.pageSize,
    }
    if (payload.filter.q && payload.filter.q.length < 3) {
      // @ts-ignore
      payload.filter.q = null
    }
    loadFiles(payload)
  }, [filter, pagination, sortable, loadFiles, refreshToken])

  const onRowClickHandler = (_: any, row: any, cmdClicked: boolean): void => {
    if (!onRowClick) return
    onRowClick(row, cmdClicked)
  }

  return (
    <sharedContext.Provider value={{ filter, setFilter }}>
      <TableContainer container spacing={2} justify="space-between">
        <Grid item xs={12} md="auto">
          <Grid container spacing={2} alignItems="center">
            {!!LeftHeaderItems && (
              <LeftHeaderContainer item xs="auto">
                {LeftHeaderItems}
              </LeftHeaderContainer>
            )}
          </Grid>
        </Grid>
        {!!RightHeaderItems && (
          <Grid item xs={12} md="auto">
            {RightHeaderItems}
          </Grid>
        )}
      </TableContainer>

      <DataTable
        loading={loading}
        keyProp="ID"
        data={results}
        columns={customColumns}
        initPage={pagination.page * 1}
        initPageSize={pagination.pageSize * 1}
        count={resultCount * 1}
        onRowClick={onRowClickHandler}
        onChangePage={setPagination}
        onChangeRowsPerPage={setPagination}
        sortHandler={setSortable}
        sortable={sortable}
        isRowCheckable={isRowCheckable}
        {...dataTableProps}
      />
    </sharedContext.Provider>
  )
})

interface TableFilterProps {
  entityType?: any[]
}

export function FileTableFilter({
  entityType,
}: TableFilterProps): React.ReactElement {
  const { filter, setFilter } = useContext(sharedContext)

  const handleFileChange = (ft: FileTypeData | null) => {
    if (!ft) return setFilter({ ...filter, fileTypeID: null })
    setFilter({ ...filter, fileTypeID: ft.ID })
  }

  return <FileTypeFilter entityTypes={entityType} onChange={handleFileChange} />
}

export const FileTableSearcher = () => {
  const { filter, setFilter } = useContext(sharedContext)

  return (
    <Grid>
      <TextField
        placeholder="Start typing to search"
        size="small"
        variant="outlined"
        value={filter.q}
        onChange={(ev: any) => {
          const q = ev.target?.value
          setFilter((c: any) => {
            return { ...c, q }
          })
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          ),
          endAdornment: (
            <Tooltip title="Files can be searched by ID, Name, Notes, or Description">
              <ToolTipIcon />
            </Tooltip>
          ),
        }}
      />
    </Grid>
  )
}

const apiEndpointDefault = (payload: any): Promise<any> => {
  return getFiles(payload)
}
