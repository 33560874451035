import React, { useState, useEffect, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import Models from '../../models'
import * as model from '../../models/PracticeFacility'
import * as practiceFacilityActions from '../../actions/PracticeFacilityActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useDiffChangedFields from '../../hooks/useDiffChangedFields'
import {
  getPracticeFacilityTermData,
  terminatePracticeFacility,
} from '../../actions/PracticeFacilityActions'
import { TermModal } from '../../components/TermModal/TermModal'
import MapPreview from '../../components/MapPreview'
import DialogRemoveFromFeeSchedules from './DialogRemoveFromFeeSchedules'
import NetworksAutocomplete, { Network } from '../Networks/NetworksAutocomplete'
import {
  SpecializationsAutocomplete,
  Spec,
} from '../../components/SpecializationsAutocomplete'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import { Grid, Button, Typography } from '@material-ui/core'
import {
  renderTextField,
  renderPhoneField,
  renderEmailField,
  renderNotesField,
  SetterArgs,
  renderNumericField,
} from '../ViewHelpers'
import DesignSuite2023 from '../../components/DesignSuite2023'
import * as PracticeFacilityFeeSchedules from './PracticeFacilityFeeSchedules'
import { UnterminateButton } from './UnterminateButton'

const defaultPracticeFacility: model.PracticeFacilityRecordShape = {
  ID: null,
  CreatedAt: null,
  UpdatedAt: null,
  CreatedByUserID: null,
  ModifiedByUserID: null,
  ExternalId: null,
  AddressId: null,
  Address: null,
  Name: null,
  Notes: null,
  Phone: null,
  Fax: null,
  Email: null,
  PosCode: null,
  Npi: null,
  Fastpass: false,
  LaunchDate: null,
  TerminationDate: null,
  ConfigurableNetworks: [],
  Specializations: [],
  ConfigurableNetworkIDs: [],
}

export default function PracticeFacilityDetail({
  match,
  history,
  location,
}: RouteComponentProps<{
  id: string
  orgId: string
}>): React.ReactElement | null {
  const id = match.params.id ? Number(match.params.id) : null
  const orgID = match.params.orgId ? Number(match.params.orgId) : null
  const [record, setRecord] = useState<model.PracticeFacilityRecordShape>({
    ...defaultPracticeFacility,
    OrganizationID: orgID,
  })
  const [notFound, setNotFound] = useState(false)
  const [tabValue, setTabValue] = useState(1)
  const [selectedFeeSchedules, setSelectedFeeSchedules] = useState([])
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const changeTracker = useDiffChangedFields(record, model.diffChangedFields)
  const refPFFSTable = useRef(null)

  useEffect(() => {
    if (!id) return
    loadPracticeFacility(id)
  }, [id])

  function nestedSetter(nested: string) {
    return ({ name, value }: SetterArgs) => {
      setRecord((curr: model.PracticeFacilityRecordShape) => ({
        ...curr,
        [nested]: {
          ...curr[nested],
          [name]: value,
        },
      }))
    }
  }

  function setter({ name, value }: SetterArgs) {
    setRecord((curr: model.PracticeFacilityRecordShape) => ({
      ...curr,
      [name]: value,
    }))
  }

  function loadPracticeFacility(id: number) {
    practiceFacilityActions
      .getPracticeFacility(id)
      .then((res) => {
        if (res.error) throw res
        setRecord(res.Data)
        changeTracker.setInitialForDiff(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading or refreshing Practice Facility',
          withError: (err: any) => {
            if (err?.Error?.StatusCode === 404) {
              setNotFound(true)
            }
          },
        })
      )
  }

  function handleSave() {
    if (record && record.ID) {
      // the endpoint just wants IDs, so massage it a bit before saving
      if (
        record.ConfigurableNetworks &&
        record.ConfigurableNetworks.length > 0
      ) {
        const networkIDs = record.ConfigurableNetworks.map((net: Network) => {
          return net.ID
        })
        record.ConfigurableNetworkIDs = networkIDs
      }
      return practiceFacilityActions
        .putPracticeFacility(record.ID, record)
        .then((res) => {
          if (res.error) throw res
          setRecord(res.Data)
          changeTracker.setInitialForDiff(res.Data)
          showSnackbar('Facility updated!', SnackbarTypeSuccess)
        })
        .catch(catchAPIError({ defaultMessage: 'Failed saving Partner' }))
    }
    return practiceFacilityActions
      .postPracticeFacility({ orgID }, record)
      .then((res) => {
        if (res.error) throw res
        history.push(`/organization/${orgID}/practice_facility/${res.Data.ID}`)
        showSnackbar('Facility created!', SnackbarTypeSuccess)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving new Facility' }))
  }

  if (notFound) {
    return (
      <DesignSuite2023.AlertError>
        Practice Facility could not be found
      </DesignSuite2023.AlertError>
    )
  }

  const renderTermOptions = () => {
    const { ID, TerminationDate, Name } = record
    return (
      <>
        {ID && ID > 0 && !TerminationDate && (
          <TermModal
            name={`${Name}`}
            entityType="practice facility"
            getTermData={() => {
              return getPracticeFacilityTermData(ID)
            }}
            terminateEntity={(payload) => {
              return terminatePracticeFacility(ID, payload)
            }}
            onTerminated={({ Destroyed }) => {
              if (Destroyed) {
                history.push(`/organization/${orgID}`)
                return
              }
              loadPracticeFacility(ID)
            }}
          />
        )}
        {ID && ID > 0 && TerminationDate && (
          <UnterminateButton
            practiceFacilityID={ID}
            onComplete={() => {
              loadPracticeFacility(ID)
            }}
          />
        )}
      </>
    )
  }

  const renderTabs = () => {
    return (
      !!record?.ID && (
        <>
          <DesignSuite2023.Section padTop0>
            <DesignSuite2023.StyledTabs value={tabValue}>
              <DesignSuite2023.StyledTab
                value={1}
                label="Fee Schedules"
                onClick={() => setTabValue(1)}
              />
            </DesignSuite2023.StyledTabs>
            {tabValue === 1 ? (
              <PracticeFacilityFeeSchedules.Table
                ref={refPFFSTable}
                practiceFacilityID={record.ID}
                onCheckHandler={(selected: any) => {
                  setSelectedFeeSchedules(selected || [])
                }}
                LeftHeaderItems={
                  <>
                    <PracticeFacilityFeeSchedules.FilterSearch autoFocus />
                    &nbsp;&nbsp;
                    <PracticeFacilityFeeSchedules.FilterStatus />
                    &nbsp;&nbsp;
                  </>
                }
                DataTableProps={{
                  rowOptsApplier(row: any) {
                    return (
                      !!row.FeeScheduleRemovedOn && { className: 'tr-warning' }
                    )
                  },
                  LeftFooterItems: (
                    <>
                      <DialogRemoveFromFeeSchedules
                        practiceFacilityID={record.ID}
                        feeSchedules={selectedFeeSchedules}
                        onChange={() => {
                          setSelectedFeeSchedules([])
                          // @ts-ignore
                          refPFFSTable.current?.refresh()
                        }}
                      />
                    </>
                  ),
                }}
              />
            ) : null}
          </DesignSuite2023.Section>
        </>
      )
    )
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderTextField({
                name: 'Name',
                label: 'Name',
                setter,
                value: record.Name || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={3}>
              {renderTextField({
                name: 'ExternalId',
                label: 'External ID',
                setter,
                value: record.ExternalId || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={3}>
              {renderEmailField({
                name: 'Email',
                label: 'Email',
                setter,
                value: record.Email || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={3}>
              {renderPhoneField({
                name: 'Phone',
                label: 'Phone',
                setter,
                value: record.Phone || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={3}>
              {renderPhoneField({
                name: 'Fax',
                label: 'Fax',
                setter,
                value: record.Fax || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={3}>
              {renderNumericField({
                name: 'Npi',
                label: 'NPI',
                setter,
                value: record.Npi || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={3}>
              {renderNumericField({
                name: 'PosCode',
                label: 'POS Code',
                setter,
                value: record.PosCode || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={6}>
              {renderNotesField({
                name: 'Notes',
                label: 'Notes',
                setter,
                value: record.Notes || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid
              container
              item
              xs={3}
              direction="row"
              justify="center"
              alignItems="flex-start">
              {/* {renderDateField({name:'LaunchDate', label:'Launch Date', setter, value:record.LaunchDate || '', opts: {
                variant: 'outlined',
                size: 'small',
                margin: 'none'}})} */}
              <ManagedDateInput
                name="LaunchDate"
                label="Launch Date"
                value={record.LaunchDate}
                setter={setter}
                margin="none"
              />
              {record?.TerminationDate !== null ? (
                /*renderDateField({name:'TerminationDate', label:'Termination Date', setter, value:record.TerminationDate || '', opts: {
              disabled: true,
              variant: 'outlined',
              size: 'small',
              margin: 'none'}})*/ <ManagedDateInput
                  disabled
                  name="TerminationDate"
                  label="Termination Date"
                  value={record.TerminationDate}
                  setter={setter}
                  margin="none"
                />
              ) : null}
            </Grid>
            <Grid
              container
              item
              xs={3}
              direction="row"
              alignItems="center"
              justify="center">
              <Typography style={{ color: record?.IsActive ? 'green' : 'red' }}>
                Facility is {record?.IsActive ? 'ACTIVE' : 'NOT ACTIVE'}
              </Typography>
              {renderTermOptions()}
            </Grid>

            <Grid item xs={6}>
              {!!record.ID && Models.ConfigurableNetwork.canUpdate() ? (
                <NetworksAutocomplete
                  currentNetworks={record.ConfigurableNetworks}
                  handleChange={(val: Network[]) =>
                    setter({ name: 'ConfigurableNetworks', value: val })
                  }
                />
              ) : null}
            </Grid>
            <Grid item xs={6}>
              {!!record.ID ? (
                <SpecializationsAutocomplete
                  currentSpecs={record.Specializations}
                  handleChange={(val: Spec[]) =>
                    setter({ name: 'Specializations', value: val })
                  }
                />
              ) : null}
            </Grid>
            <Grid item xs={3}>
              {renderTextField({
                name: 'Address1',
                label: 'Address',
                setter: nestedSetter('Address'),
                value: record.Address?.Address1 || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={3}>
              {renderTextField({
                name: 'Address2',
                label: '',
                setter: nestedSetter('Address'),
                value: record.Address?.Address2 || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={3}>
              {renderTextField({
                name: 'City',
                label: 'City',
                setter: nestedSetter('Address'),
                value: record.Address?.City || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={1}>
              {renderTextField({
                name: 'State',
                label: 'State',
                setter: nestedSetter('Address'),
                value: record.Address?.State || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={2}>
              {renderNumericField({
                name: 'Zip',
                label: 'Zip',
                setter: nestedSetter('Address'),
                value: record.Address?.Zip || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                *Address changes will trigger re-geocoding, which is a delayed
                process*. Geocode status:
                {!!record?.Address?.Geocoded ? (
                  <DesignSuite2023.CommonIcons.IconCheck fontSize="inherit" />
                ) : (
                  <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {record?.AddressId && (
                <div>
                  <MapPreview addressID={record.AddressId} />
                </div>
              )}
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>

        <DesignSuite2023.LayoutSidebar>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}>
            Save
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              history.push(`/organization/${orgID}`)
            }}>
            Back to Organization
          </Button>

          {record.ID && (
            <>
              <DesignSuite2023.Divider />
              <DesignSuite2023.DisplayRecordMeta record={record} />
              <changeTracker.DisplayChangedFields>
                <DesignSuite2023.Divider />
              </changeTracker.DisplayChangedFields>
            </>
          )}
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>
      {renderTabs()}
    </>
  )
}
