import React, { useState, forwardRef, useMemo, useRef } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import * as api from '../../services/thezerocard/api-helper'
import utils from '../../utils'
import dateTime from '../../utils/dateTime'
import styled from 'styled-components'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'

const StyledDialog = styled(Dialog)`
  [role='dialog'],
  .MuiDialogContent-root,
  .std-table {
    height: 100%;
  }

  .MuiDialogContent-root {
    padding: 0 !important;

    .std-table {
      overflow: hidden;
      overflow-y: auto;

      .header-items {
        padding: 0 1rem;
        white-space: nowrap;
        margin-top: 0;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 5;

        .header-item-cell {
          column-gap: 0.5rem;
        }
      }

      .base-table-display {
        margin-top: 0 !important;

        tr > td {
          cursor: auto; -- annoying always having cursor hand
        }

        .data-table-footer {
          position: sticky;
          bottom: 0;
          background: #fff;
        }
      }
    }
  }

  .btn-clear-email {
    background: #d5d5d5;
    display: inline-block;
    padding: 0.1rem 0.3rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 95%;
  }
`

export default function FlaggedEmailManagement(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const refTable = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()

  function onClickClearEmail(email: string) {
    if (
      !window.confirm(
        `This will clear ALL RECORDS for the email ${email}. Are you sure?`
      )
    ) {
      return
    }
    api
      .del(`/notifications/flagged_email`, { email })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Email cleared OK', SnackbarTypeSuccess)
        refTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed clearing email',
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Launch Flagged Emails management dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Flagged Emails
        </Button>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        open={isOpen}
        fullWidth
        maxWidth="lg"
        onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Table
            ref={refTable}
            onClickClearEmail={onClickClearEmail}
            LeftHeaderItems={
              <>
                <Typography variant="subtitle1">Flagged Emails</Typography>
                <stdTableSetup.StandardFilterSearch />
              </>
            }
          />
        </DialogContent>
      </StyledDialog>
    </>
  )
}

const Table = forwardRef(function TableFlaggedEmails(
  {
    onClickClearEmail,
    apiEndpoint = getFlaggedEmails,
    DataTableProps: passDataTableProps = {},
    initPageSize = 50,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    return {
      ID: { name: 'ID' },
      CreatedAt: {
        name: 'CreatedAt',
        details: {
          dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
        },
      },
      Email: {
        name: 'Email',
        details: {
          dataFormat(_: any, row: any) {
            return (
              <div>
                <span style={{ whiteSpace: 'nowrap' }}>{row?.Email}</span>
                &nbsp;
                <span
                  className="btn-clear-email"
                  onClick={() => onClickClearEmail?.(row.Email)}>
                  Clear
                </span>
              </div>
            )
          },
        },
      },
      UserID: { name: 'UserID' },
      DidUnsubscribe: {
        name: 'DidUnsubscribe',
        details: { dataFormat: utils.boolYesNoFormatter },
      },
      WrongRecipient: {
        name: 'WrongRecipient',
        details: { dataFormat: utils.boolYesNoFormatter },
      },
      SESBounced: {
        name: 'SESBounced',
        details: { dataFormat: utils.boolYesNoFormatter },
      },
      SESComplained: {
        name: 'SESComplained',
        details: { dataFormat: utils.boolYesNoFormatter },
      },
      NotificationSESID: { name: 'NotificationSESID' },
      NotificationSESUntrackedID: { name: 'NotificationSESUntrackedID' },
      BounceDetails: { name: 'BounceDetails' },
      ComplaintDetails: { name: 'ComplaintDetails' },
    }
  }, [])

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      initPageSize,
      DataTableProps: {
        ...passDataTableProps,
        density: 'small',
        rowsPerPage: [25, 50, 100],
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

function getFlaggedEmails(params: any) {
  return api.search('/engineering/flagged_emails', params)
}
