import React, { useRef, useState } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import * as ClaimTable from '../Components/ClaimTable'
import DialogAddToCollection from '../Components/Collections/DialogAddToCollection'
import ButtonDoAdjudicate from '../Components/ButtonDoAdjudicate'
import ButtonMoveToFunding from '../Components/ButtonMoveToFunding'
import ButtonSendToTrash from '../Components/ButtonSendToTrash'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { useFrameHeightLock } from '../../../components/AppFrame'

export default React.forwardRef(function PageClaimsListView(
  props: any,
  ref: any
): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<any>>([])
  const [showAExtendedSearch, setShowExtendedSearch] = useState(false)
  const refCollectionPicker = useRef<any>(null)

  useFrameHeightLock(() => true, [])

  return (
    <ClaimTable.Table
      ref={ref}
      className="full-fixed"
      enableURLReflection="zclaims.claims.list"
      passFilters={{
        HasFundingStatus: false,
        HideClaimsInTrash: true,
      }}
      onCheckHandler={setCheckedItems}
      DataTableProps={{
        stickyHeader: true,
      }}
      BeforeTable={
        <div className="lefty" style={{ padding: '1rem' }}>
          <DesignSuite2023.GridLR
            left={
              <ButtonDoAdjudicate
                disabled={(checkedItems || []).length === 0}
                zClaimIDs={checkedItems.map((v: any) => v.ID)}
                onComplete={() => {
                  ref.current?.refresh()
                }}
              />
            }
            right={
              <IconButton
                size="small"
                onClick={() => {
                  ref.current?.refresh()
                }}>
                <DesignSuite2023.CommonIcons.IconRefresh />
              </IconButton>
            }
          />
          {!!(checkedItems || []).length && (
            <div style={{ paddingTop: '1rem' }}>
              <DialogAddToCollection
                zClaimIDs={checkedItems.map((v: any) => v.ID)}
                onDone={() => {
                  refCollectionPicker.current?.refresh()
                }}
              />

              <div style={{ paddingTop: '0.5rem' }}>
                <ButtonSendToTrash
                  zClaimIDs={checkedItems.map((v: any) => v.ID)}
                  onDone={() => {
                    ref.current?.refresh()
                  }}
                />
              </div>

              <div style={{ paddingTop: '0.5rem' }}>
                <ButtonMoveToFunding
                  zClaimIDs={checkedItems.map((v: any) => v.ID)}
                  onComplete={() => {
                    ref.current?.refresh()
                  }}
                />
              </div>
            </div>
          )}
          <DesignSuite2023.Divider />

          <div style={{ paddingBottom: '1rem' }}>
            {/* <ClaimTable.StandardFilterSearch
              autoFocus
              label="Search"
              InputLabelProps={{ shrink: true }}
            /> */}
            <ClaimTable.FilterZClaimIDs tooltip="Type one or more ZClaimIDs in here (comma-separated) to filter claims" />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterAdjudicationStatus />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterCollections ref={refCollectionPicker} multiple />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterOrganization />
          </div>
          <div style={{ paddingBottom: '0.4rem' }}>
            <ClaimTable.FilterEmployer />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterMemberPicker />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterClaimType />
          </div>
          <div>
            <ClaimTable.FilterSourceType />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ClaimTable.FilterLineCount />
          </div>
          <div>
            <ClaimTable.FilterShowAllClaims />
          </div>
          <div>
            <ClaimTable.FilterClaimsCreatedByMe />
          </div>

          <DesignSuite2023.Divider />

          <DesignSuite2023.GridLR
            left={
              <Typography variant="subtitle2" color="textSecondary">
                <span
                  onClick={() => setShowExtendedSearch(!showAExtendedSearch)}>
                  Extended Search
                </span>
              </Typography>
            }
          />
          {!!showAExtendedSearch && (
            <>
              <div style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
                <ClaimTable.FilterSourceFiles
                  multiple
                  passFilters={{ status: 'extracted' }}
                />
              </div>
              <div style={{ paddingBottom: '1rem' }}>
                <ClaimTable.FilterPracticeFacility />
              </div>
              <div style={{ paddingBottom: '1rem' }}>
                <ClaimTable.FilterWhereCaged />
              </div>
              <div style={{ paddingBottom: '1rem' }}>
                <ClaimTable.FilterSrcHash />
              </div>
              <div style={{ paddingBottom: '1rem' }}>
                <ClaimTable.FilterExternalClaimID />
              </div>
              <div style={{ paddingBottom: '1rem' }}>
                <ClaimTable.FilterClearingHouseClaimNumber />
              </div>

              <div style={{ textAlign: 'center' }}>
                <ClaimTable.FilterHideClaimsInTrash />
              </div>

              <div style={{ paddingBottom: '1rem' }}>
                <Typography variant="subtitle2" color="textSecondary">
                  Filter by presence of linked entities:
                </Typography>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  gridColumnGap: '0.5rem',
                  gridRowGap: '1rem',
                }}>
                <ClaimTable.FilterHasLinkedSubscriber />
                <ClaimTable.FilterHasLinkedPatient />
                <ClaimTable.FilterHasLinkedOrganization />
                <ClaimTable.FilterHasLinkedPracticeFacility />
                <ClaimTable.FilterHasLinkedFeeSchedule />
              </div>
            </>
          )}
        </div>
      }
    />
  )
})
