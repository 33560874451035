import React, { forwardRef, useContext, useState } from 'react'
import { Chip, FormControlLabel, IconButton, Switch } from '@material-ui/core'
import {
  AspectRatio as IconAspectRatio,
  LinearScale as IconLinearScale,
} from '@material-ui/icons'
import { getZClaimInspectSrcRecords } from '../../../../actions/ZClaimActions'
import * as stdTableSetup from '../../../../hooks/useStandardTableSetup'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import ReconcileSrcPatientOrSubscriber from './reconcileSrcPatientOrSubscriber'
import ReconcileSrcBillingEntity from './reconcileSrcBillingEntity'
import ReconcileSrcServiceFacility from './reconcileServiceFacility'
import styled from 'styled-components'
import helpers from '../../helpers'

export * from '../../../../hooks/useStandardTableSetup'

const StyledTableWrap = styled.div`
  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }

  &.src_service_facility {
    .MuiTable-root {
      tbody tr td:nth-child(4) {
        white-space: nowrap;
      }
      tbody tr td:nth-child(6) {
        white-space: nowrap;
      }
    }
  }
`

export type kind =
  | 'src_patient'
  | 'src_subscriber'
  | 'src_billing_provider'
  | 'src_service_facility'

interface customProps {
  kind: kind
  legacyMode?: boolean // used for resolving imported VBA records
  RenderComponentOnClick?: React.FC<{ kind: kind; srcRec: any }>
}

const defaultSort = Object.freeze({ col: 'ID', dir: 'desc' })

const OccurrencesColumnLabel = (
  <DesignSuite2023.Tooltip title="Observations (how many claims have the exact same source record)">
    <DesignSuite2023.CommonIcons.IconPeek fontSize="small" />
  </DesignSuite2023.Tooltip>
)

const HasDefaulterColumnLabel = (
  <DesignSuite2023.Tooltip title="Has Defaulter">
    <IconLinearScale fontSize="small" />
  </DesignSuite2023.Tooltip>
)

export const Table = forwardRef(function TableSrcRecords(
  {
    kind,
    legacyMode = false,
    apiEndpoint = getZClaimInspectSrcRecords,
    DataTableProps = {},
    initPageSize = 100,
    RenderComponentOnClick,
    ...passThrough
  }: stdTableSetup.props & customProps,
  ref: any
): React.ReactElement | null {
  const customColumns = React.useMemo(() => {
    return {
      _: {
        name: '',
        details: {
          dataFormat(_: any, row: any): any {
            if (RenderComponentOnClick) {
              return <RenderComponentOnClick kind={kind} srcRec={row} />
            }
            return <BtnReconcileSrc kind={kind} hash={row?.Hash} />
          },
        },
      },
      ZClaimOccurrences: {
        name: OccurrencesColumnLabel,
        details: {
          sortName: 'ZClaimOccurrences',
          dataFormat(_: any, row: any): any {
            return <Chip label={row.ZClaimOccurrences} size="small" />
          },
        },
      },
      _HasDefaulter: {
        name: HasDefaulterColumnLabel,
        details: {
          dataFormat(_: any, row: any): any {
            switch (kind) {
              case 'src_patient':
                return helpers.truthy(
                  row?.EmployerMemberDefaulterEmployerMemberID
                ) ? (
                  <DesignSuite2023.CommonIcons.IconCheck
                    color="action"
                    fontSize="small"
                  />
                ) : (
                  <DesignSuite2023.CommonIcons.IconDeny
                    color="error"
                    fontSize="small"
                  />
                )
              case 'src_subscriber':
                return helpers.truthy(
                  row?.EmployerMemberDefaulterEmployerMemberID
                ) ? (
                  <DesignSuite2023.CommonIcons.IconCheck
                    color="action"
                    fontSize="small"
                  />
                ) : (
                  <DesignSuite2023.CommonIcons.IconDeny
                    color="error"
                    fontSize="small"
                  />
                )
              case 'src_billing_provider':
                return helpers.truthy(
                  row?.BillingEntityDefaulterBillingEntityID
                ) ? (
                  <DesignSuite2023.CommonIcons.IconCheck
                    color="action"
                    fontSize="small"
                  />
                ) : (
                  <DesignSuite2023.CommonIcons.IconDeny
                    color="error"
                    fontSize="small"
                  />
                )
              case 'src_service_facility':
                return helpers.truthy(
                  row?.PracticeFacilityDefaulterPracticeFacilityID
                ) ? (
                  <DesignSuite2023.CommonIcons.IconCheck
                    color="action"
                    fontSize="small"
                  />
                ) : (
                  <DesignSuite2023.CommonIcons.IconDeny
                    color="error"
                    fontSize="small"
                  />
                )
            }
          },
        },
      },
      ...getCols(kind, legacyMode),
    }
  }, [kind, legacyMode])

  const { TableDisplay /*setFilterImmediate, filter*/ } =
    stdTableSetup.useStandardTableSetup(
      {
        ...passThrough,
        customColumns,
        apiEndpoint,
        defaultSort,
        passFilters: { ...passThrough?.passFilters, kind },
        DataTableProps: {
          ...DataTableProps,
          density: 'small',
          rowsPerPage: [10, 25, 50, 100, 250],
        },
        initPageSize,
      },
      ref
    )

  return <StyledTableWrap className={kind}>{TableDisplay}</StyledTableWrap>
})

export function FilterWhereHasNoDefaulter(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Only show source records with no defaults"
      style={{ margin: 0 }}
      control={
        <Switch
          size="small"
          color="primary"
          checked={!!filter.HasNoDefaulter}
          onChange={(e: any) => {
            setFilterImmediate({ HasNoDefaulter: e.target.checked })
          }}
        />
      }
    />
  )
}

function BtnReconcileSrc({
  kind,
  hash,
}: {
  kind: kind
  hash: string
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onClick(ev: any) {
    ev.stopPropagation()
    switch (kind) {
      case 'src_patient':
        setComponent(
          <ReconcileSrcPatientOrSubscriber kind={kind} hash={hash} />
        )
        return
      case 'src_subscriber':
        setComponent(
          <ReconcileSrcPatientOrSubscriber kind={kind} hash={hash} />
        )
        return
      case 'src_billing_provider':
        setComponent(<ReconcileSrcBillingEntity kind={kind} hash={hash} />)
        return
      case 'src_service_facility':
        setComponent(<ReconcileSrcServiceFacility kind={kind} hash={hash} />)
        return
    }
  }

  function onClose() {
    setComponent(null)
    refresh()
  }

  return (
    <>
      <IconButton size="small" onClick={onClick}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <DesignSuite2023.Drawer
        open={!!component}
        width="80vw"
        disableEscapeKeyDown
        onClose={onClose}>
        {component}
      </DesignSuite2023.Drawer>
    </>
  )
}

function getCols(kind: kind, legacyMode: boolean = false): any {
  let cols: any[] = []
  switch (kind) {
    case 'src_patient':
      cols = [
        // 'ID',
        'PatientID',
        'DOB',
        { name: 'FirstName', details: { sortName: 'firstName' } },
        'MiddleName',
        { name: 'LastName', details: { sortName: 'lastName' } },
        'Gender',
        'SSN',
        'Address1',
        'Address2',
        'City',
        'State',
        'Zip',
        'ControlNumber',
        'AgencyClaimNumber',
        'Relationship',
        'Scope',
      ]
      if (legacyMode) {
        cols = [
          'DOB',
          { name: 'FirstName', details: { sortName: 'firstName' } },
          { name: 'LastName', details: { sortName: 'lastName' } },
          'Gender',
          'SSN',
        ]
      }
      break
    case 'src_subscriber':
      cols = [
        'ID',
        'SubscriberID',
        'SSN',
        'DOB',
        { name: 'FirstName', details: { sortName: 'firstName' } },
        'MiddleName',
        { name: 'LastName', details: { sortName: 'lastName' } },
        'Address1',
        'Address2',
        'City',
        'State',
        'Zip',
        'Gender',
        'Phones',
        'Faxes',
        'Emails',
      ]
      break
    case 'src_billing_provider':
      cols = [
        'ID',
        { name: 'Name', details: { sortName: 'name' } },
        { name: 'NPI', details: { sortName: 'npi' } },
        { name: 'TIN', details: { sortName: 'tin' } },
        'Address1',
        'Address2',
        'City',
        'State',
        'Zip',
        'SSN',
        'SpecialtyInfo',
        'ContactName',
        'Misc',
        // 'Telephones',
        // 'Faxes',
        // 'Emails',
      ]
      break
    case 'src_service_facility':
      cols = [
        'ID',
        'Name',
        'NPI',
        'Address1',
        'Address2',
        'City',
        'State',
        'Zip',
        'StateLicenseNumber',
        'CommercialNumber',
        'LocationNumber',
        'OtherPayerID',
        'Telephones',
        'Faxes',
        'Emails',
      ]
      break
  }

  return cols.reduce((acc, col) => {
    if (typeof col === 'string') {
      acc[col] = { name: col }
    } else {
      acc[col.name] = col
    }

    return acc
  }, {} as any)
}
