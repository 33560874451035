import * as api from '../services/thezerocard/api-helper'

export type APIResponseTags = Promise<Tag[]>
export type APIResponseTagSet = Promise<TagSet>
export type TagSetRoute = 'partner_tagset' | 'cost_key_tagset'
export type TagFamily = 'partner' | 'costkeycode'

interface Params {
  filter: Filter
}

interface Filter {
  family: string
  noLimit?: boolean
}

export interface Tag {
  ID: string | null
  Tag: string
}

export interface TagSet {
  ID: number
  Tags: Tag[]
  CreatedAt: string
}

/**
 * Get Tags for a specific "family", ie: costKeyCode
 * @param params
 */
export const getTagsByFamily = (family: TagFamily): APIResponseTags => {
  const params: Params = {
    filter: {
      family,
      noLimit: true,
    },
  }
  return api.search('/taggable/tags', params).then((res: any) => {
    if (res.error) throw res
    return res.Data as Tag[]
  })
}

export const getTagsByTagSetId = (tagSetId: number): APIResponseTagSet => {
  return api.get(`/taggable/tagset/${tagSetId}`).then((res: any) => {
    if (res.error) throw res
    return res.Data as TagSet
  })
}

/**
 * Create a new tag set. For each model that has tags a different route
 * is used. When this API call returns it sends back a tagset ID. This needs
 * to be assigned to the coresponding instances of the model relected in route.
 *
 * @param route - the route to create the tagset
 * @param tags - the tags to assign to the tagset
 * @return - An object containing the tagset ID and the tags.
 */
export const createNewTagSet = (
  route: TagSetRoute,
  tags: Tag[]
): APIResponseTagSet => {
  return api.post(`/${route}`, { Tags: tags }).then((res: any) => {
    if (res.error) throw res
    return res.Data as TagSet
  })
}
