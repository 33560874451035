import React from 'react'

export enum RoleTokens {
  PermRequestProspectClaim = 'r:prospect_claim',
  PermUpdateProspectClaim = 'u:prospect_claim',
  PermRequestZeroCardClaim = 'r:zero_card_claim',
  PermUpdateZeroCardClaim = 'u:zero_card_claim',
  PermCreateBillingEntity = 'c:billing_entity',
  PermRequestBillingEntity = 'r:billing_entity',
  PermUpdateBillingEntity = 'u:billing_entity',
  PermDeleteBillingEntity = 'd:billing_entity',
  PermCreateLOA = 'c:loa',
  PermReadLOA = 'r:loa',
  PermUpdateLOA = 'u:loa',
  PermDeleteLOA = 'd:loa',
  PermCreateProvider = 'c:provider',
  PermReadProvider = 'r:provider',
  PermUpdateProvider = 'u:provider',
  PermDeleteProvider = 'd:provider',
  PermCreateProviderPrices = 'c:provider_prices',
  PermReadProviderPrices = 'r:provider_prices',
  PermUpdateProviderPrices = 'u:provider_prices',
  PermDeleteProviderPrices = 'd:provider_prices',
  PermUpdateCreateMasterData = 'uc:master_data',
  PermReadMasterData = 'r:master_data',
  PermDeleteMasterData = 'd:master_data',
  PermCreateEmployer = 'c:employer',
  PermReadEmployer = 'r:employer',
  // ,PermUpdateEmployer  = "u:employer"
  PermDeleteEmployer = 'd:employer',
  PermUpdateEmployerCore = 'u:employer_core',
  PermReadEmployerDetail = 'r:employer_detail',
  PermUpdateEmployerDetail = 'u:employer_detail',
  PermUpdateEmployerBilling = 'u:employer_billing',
  PermRequestStopLossContract = 'r:employer_stoploss',
  PermCreateStopLossContract = 'c:employer_stoploss',
  PermUpdateStopLossContract = 'u:employer_stoploss',
  PermCreateContact = 'c:contact',
  PermRequestContact = 'r:contact',
  PermUpdateContact = 'u:contact',
  PermDeleteContact = 'd:contact',
  PermCreateFile = 'c:file',
  PermRequestFile = 'r:file',
  PermUpdateFile = 'u:file',
  PermDeleteFile = 'd:file',
  PermMemberFile = 'a:member_file',
  PermCreateInvoice = 'c:invoice',
  PermRequestInvoice = 'r:invoice',
  PermUpdateInvoice = 'u:invoice',
  PermDeleteInvoice = 'd:invoice',
  PermReadEligibilitySrc = 'r:eligibility_src',
  PermReadEligibility = 'r:eligibility',
  PermCreateEligibility = 'c:eligibility',
  PermReadEligibilityFileConfig = 'r:eligibility_file_config',
  PermUpdateEligibilityFileConfig = 'u:eligibility_file_config',
  PermCreateEligibilityFileConfig = 'c:eligibility_file_config',
  PermDeleteEligibilityFileConfig = 'd:eligibility_file_config',
  PermViewOrganizations = 'search:organizations',
  PermViewPracticeFacilities = 'search:practice_facilities',
  PermChangeMyZeroEmail = 'a:change_myzero_email',
  PermCreateUser = 'c:user',
  PermReadUser = 'r:user',
  PermReadGoZeroUser = 'r:gozero_user',
  PermReadTeamZeroUser = 'r:teamzero_user',
  PermUpdateUser = 'u:user',
  PermSearchUserGroups = 's:user_groups',
  PermMedxoom = 'rwx:medxoom',
  PermCreatePartner = 'c:partner',
  PermReadPartner = 'r:partner',
  PermUpdatePartner = 'u:partner',
  PermReadReferral = 'r:referral',
  PermCreateReferrer = 'c:referrer',
  PermReadReferrer = 'r:referrer',
  PermUpdateReferrer = 'u:referrer',
  PermEngineering = 'a:engineering',
  PermETL = 'a:etl',
  PermMarketingActions = 'a:marketing',
  PermExportEligibility = 'a:export_eligibility',
  PermIngestEligibility = 'a:ingest_eligibility',
  PermImportFacilities = 'a:import_facilities',
  PermViewPHI = 'r:phi',
  PermCreateConfigurableNetwork = 'c:configurable_network',
  PermReadConfigurableNetwork = 'r:configurable_network',
  PermUpdateConfigurableNetwork = 'u:configurable_network',
  PermDeleteConfigurableNetwork = 'd:configurable_network',
  PermZClaimViewer = 'zclaim:viewer',
  PermZClaimAdjudicator = 'zclaim:adjudicator',
  PermZClaimDataLinker = 'zclaim:data_linker',
  PermZClaimSupervisor = 'zclaim:supervisor',
  PermZClaimAcctViewer = 'zclaim:acct_viewer',
  PermZClaimAcctProcessor = 'zclaim:acct_processor',
  PermZClaimAcctSupervisor = 'zclaim:acct_supervisor',
  PermZClaimAnalyticsViewer = 'zclaim:analytics_viewer',
  PermZClaimAnalyticsProcessor = 'zclaim:analytics_processor',
}

export function canExecEngineering(): boolean {
  return has(RoleTokens.PermEngineering)
}

export function has(p: string): boolean {
  if (!localStorage) return false
  const perms = localStorage.getItem('perms') || ''
  return perms.includes(p)
}

export function hasAny(...ps: string[]): boolean {
  return ps.some(has)
}

export function hasAll(...ps: string[]): boolean {
  return ps.every(has)
}

export function Checker({
  boolIf = false,
  has: propHas,
  hasAny: propHasAny,
  hasAll: propHasAll,
  children,
}: {
  boolIf?: boolean
  has?: string
  hasAny?: string[]
  hasAll?: string[]
  children: React.ReactNode
}): React.ReactElement | null {
  let show = boolIf
  if (propHas) {
    show = has(propHas)
  }
  if (propHasAny) {
    show = hasAny(...propHasAny)
  }
  if (propHasAll) {
    show = hasAll(...propHasAll)
  }
  if (!show) return null
  return <>{children}</>
}

export default {
  RoleTokens,
  canExecEngineering,
  has,
  hasAny,
  hasAll,
  Checker,
}
