import React, { forwardRef, useState, useContext } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findCostKeys } from '../../actions/CostKeyActions'
// import Models from '../../models'
import CostKeyCategorySearcher from '../../components/Searchers/CostKeyCategorySearcher'
import {
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import DesignSuite2023 from '../../components/DesignSuite2023'
import StringifyCostKey from './Components/StringifyCostKey'
import { Link } from 'react-router-dom'
import AutoCompleteCPTCodes, {
  CPTCodeAndDescr,
} from './Components/AutoCompleteCPTCodes'
import AutoCompleteCPTModifierCodes from './Components/AutoCompleteCPTModifierCodes'
import AutoCompleteDRGCodes from './Components/AutoCompleteDRGCodes'
import AutoCompleteKeywords from './Components/AutoCompleteKeywords'
import { ShapeDRGCode, ShapeCPTModifierCode, Exclusions } from './types'
import styled from 'styled-components'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

const StyledCostKeyDisplay = styled.div`
  white-space: nowrap;

  .styled-cost-key {
    font-weight: 800;
  }

  .legacy {
    font-size: 90%;
    opacity: 0.8;
  }
`

export const defaultColumns = Object.freeze({
  _ViewCK: {
    name: '',
    details: {
      dataFormat(cell: any, row: any): any {
        return (
          <Link to={`/cost_key/${row.ID}`}>
            <IconButton size="small" style={{ padding: '0.25rem' }}>
              <DesignSuite2023.CommonIcons.IconAspectRatio fontSize="small" />
            </IconButton>
          </Link>
        )
      },
    },
  },
  ID: {
    name: 'ID',
    details: {
      sortName: 'id',
    },
  },
  Deprecated: {
    name: (
      <DesignSuite2023.Tooltip title="Deprecated status (retired / no longer available for use on new fee schedules)">
        <span
          style={{ fontSize: '110%', display: 'block', textAlign: 'center' }}>
          <DesignSuite2023.CommonIcons.IconDeny fontSize="small" />
        </span>
      </DesignSuite2023.Tooltip>
    ),
    details: {
      dataFormat(cell: any, row: any): any {
        if (!row.Deprecated) return null
        return (
          <DesignSuite2023.Tooltip title="This cost key is deprecated.">
            <DesignSuite2023.CommonIcons.IconWarning fontSize="small" />
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  Code: {
    name: 'Cost Key',
    details: {
      sortName: 'code',
      dataFormat(cell: any, row: any): any {
        return (
          <StyledCostKeyDisplay>
            <StringifyCostKey
              bd={row.BundleDefinition}
              highlighters
              showV2Prefix={false}
              invalidText="!BROKEN!😢"
            />
            {row.IsLegacyCode && (
              <div className="legacy">
                <strong>Legacy Code:</strong> {row.Code}
              </div>
            )}
          </StyledCostKeyDisplay>
        )
      },
    },
  },
  Category: {
    name: 'Category',
    details: {
      sortName: 'category',
      dataFormat(cell: any, row: any): any {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {row?.V2Category || row?.Category}
          </span>
        )
      },
    },
  },
  Descr: {
    name: 'Description',
    details: {
      width: '100%',
    },
  },
})

const defaultSort = { col: 'id', dir: 'desc' }

export const Table = forwardRef(function CostKeyTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findCostKeys,
    DataTableProps = {},
    initPageSize = 25,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize,
      DataTableProps: {
        ...DataTableProps,
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250],
        rowOptsApplier: (row: any) => {
          if (row.BundleDefinitionOK === false) {
            return { className: 'tr-error' }
          }
          if (row.Deprecated) return { className: 'tr-warning' }
          return null
        },
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterCategory(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <CostKeyCategorySearcher
      preloadAll
      selectedCostKeyCategoryID={filter.CostKeyCategoryID}
      onChange={(id: number) => {
        if (!id) {
          setFilterImmediate({ CostKeyCategoryID: null })
          return
        }
        setFilterImmediate({ CostKeyCategoryID: id })
      }}
      // AutoCompleteProps={{ disabled: true }}
      TextFieldProps={{
        InputLabelProps: {
          shrink: true,
        },
      }}
    />
  )
}

export function FilterCPTs({
  multiple = true,
}: {
  multiple?: boolean
}): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function onChange(codes: Array<CPTCodeAndDescr>) {
    setFilterImmediate({
      cptCodes: codes.map((c) => c.Code),
    })
  }

  return (
    <AutoCompleteCPTCodes
      multiple={multiple}
      // @ts-ignore
      onChange={onChange}
      value={filter.cptCodes}
    />
  )
}

export function FilterCPTModifiers(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function onChange(codes: Array<ShapeCPTModifierCode>) {
    setFilterImmediate({
      cptMods: codes.map((c) => c.Code),
    })
  }

  return (
    <AutoCompleteCPTModifierCodes
      // @ts-ignore
      onChange={onChange}
      value={filter.cptMods}
    />
  )
}

export function FilterDRG(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function onChange(v: Array<ShapeDRGCode> | null) {
    if (!v) {
      setFilterImmediate({ drgCode: null })
      return
    }
    setFilterImmediate({
      drgCode: v?.[0]?.Code,
    })
  }

  return <AutoCompleteDRGCodes onChange={onChange} value={filter.drgCode} />
}

export function FilterKeywords(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function onChange(v: any) {
    if (!v) {
      setFilterImmediate({ keywords: null })
      return
    }
    setFilterImmediate({ keywords: v?.map((k: any) => k?.Keyword) })
  }

  return (
    <AutoCompleteKeywords
      currentlySelected={
        filter.keywords?.map((k: string) => ({ Keyword: k })) || null
      }
      onChange={onChange}
    />
  )
}

export function FilterExclusions(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'none', label: '-----' },
    ...Object.keys(Exclusions).map((e) => ({ value: e, label: Exclusions[e] })),
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'none') {
      return setFilterImmediate({ hasExclusion: null })
    }
    setFilterImmediate({ hasExclusion: sel.props.value })
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Exclusions
      </InputLabel>
      <Select
        labelId={internalID}
        value={filter.hasExclusion || 'none'}
        onChange={onChange}
        label="Exclusions"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
