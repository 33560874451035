import * as api from '../services/thezerocard/api-helper'
import * as types from '../views/SavingsAnalysisV2/types'

export function postSavingsAnalysisGenerateBundles(): Promise<{
  error: boolean
  Data: null
}> {
  return api.post('/sa/generate_bundles')
}

export function getSavingsAnalysisBundles(
  params: any = {}
): Promise<{ error?: boolean; Data: types.ShapeListSavingsBundle[] | null }> {
  return api.get(`/sa/bundles`, null, params)
}

export function findSavingsAnalysisBundles(params: any) {
  return api.search('/sa/bundles', params)
}

export function getSavingsAnalysisBundleByUUID(
  uuid: string,
  params: any = {}
): Promise<{ error?: boolean; Data: types.ShapeSavingsBundleFull }> {
  return api.get(`/sa/bundle/${uuid}`, null, params)
}

export function putSAIgnoreLines(params: {
  bundleUUID: string
  zClaimLineIDs: number[]
}): Promise<{ error?: boolean; Data?: any }> {
  return api.put(`/sa/bundle/${params.bundleUUID}/ignore_lines`, params)
}

export function putSABundleLineAction(params: {
  zClaimLineIDs: number[]
  [k: string]: any
}): Promise<{ error?: boolean; Data?: any }> {
  return api.put(`/sa/bundle/line_action`, params)
}

export function putSABundleReasonCode(params: {
  bundleUUID: string
  addReasonCodeHandles?: string[]
  clearReasonCodeHandles?: string[]
}): Promise<{ error?: boolean; Data?: any }> {
  return api.put(`/sa/bundle/${params.bundleUUID}/reason_codes`, params)
}

export function putSABundleCostKeyID(
  bundleUUID: string,
  costKeyID: number
): Promise<{ error?: boolean; Data?: any }> {
  return api.put(`/sa/bundle/${bundleUUID}/cost_key/${costKeyID}`)
}

export function putSABundleNotes(
  bundleUUID: string,
  notes: string
): Promise<{ error?: boolean; Data?: any }> {
  return api.put(`/sa/bundle/${bundleUUID}/notes`, { notes })
}

export function postSABundleRecalc(
  bundleUUID: string
): Promise<{ error?: boolean; Data?: any }> {
  return api.post(`/sa/bundle/${bundleUUID}/recalc`)
}

export function putSAManualPPO(
  bundleUUID: string,
  manualPPOPrice: string | null
): Promise<{ error?: boolean; Data?: any }> {
  let body = { PPOManual: manualPPOPrice }
  return api.put(`/sa/bundle/${bundleUUID}/manual_ppo`, body)
}

export function putSAManualLOA(
  bundleUUID: string,
  loaID: number | null
): Promise<{ error?: boolean; Data?: any }> {
  let body = { LoaID: loaID }
  return api.put(`/sa/bundle/${bundleUUID}/manual_loa`, body)
}

export function getCostKeys(): Promise<{
  error?: boolean
  Data?: any
}> {
  return api.get(`/sa/cost_keys`)
}

export function getReasonCodes(): Promise<{
  error?: boolean
  Data?: any
}> {
  return api.get(`/sa/reason_codes`)
}

// export function getZClaimHistoryByID(id: number) {
//   return api.get(`/zclaims/claim/${id}/history`)
// }

// export function findZClaims(params: any) {
//   return api.search('/zclaims/claim', params)
// }

// export function findZClaimSourceFiles(params: any) {
//   return api.search('/zclaims/source_file', params)
// }

// export function findUnprocessedZClaimSrcGoZero(params: any) {
//   params.filter = { ...params.filter, processed: false }
//   return api.search('/zclaims/src_go_zero', { ...params }).then((res: any) => {
//     if (res.error) {
//       throw res
//     }
//     return res
//   })
// }

// export function postZClaimSourceFileIngest(params: any) {
//   return api.post('/zclaims/source_file/ingest', params)
// }
