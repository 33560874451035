import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putSendToTrash } from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core'
import { renderNotesField as RenderNotesField } from '../../../components/Inputs/standard'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .custom-title {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content,
  .MuiDialogContent-root {
    padding: 0.5rem 1rem 1rem;
    min-width: 500px;
  }
`

interface props {
  zClaimIDs: Array<number>
  onDone?: () => void
}

export default function ButtonSendToTrash({
  zClaimIDs,
  onDone,
}: props): React.ReactElement | null {
  const [isWorking, setIsWorking] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [note, setNote] = useState('')
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) return
    setNote('')
    setIsWorking(false)
  }, [isOpen])

  function doConfirm() {
    if (isWorking) return

    setIsWorking(true)
    putSendToTrash({ zClaimIDs, note })
      .then(() => {
        showSnackbar('Claims sent to trash', SnackbarTypeSuccess)
        onDone?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed sending claims to trash',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Send selected claims to trash">
        <div>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setIsOpen(true)}
            disabled={zClaimIDs?.length === 0}>
            Send To Trash
          </Button>
        </div>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        maxWidth="sm"
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <DialogTitle disableTypography className="custom-title">
          <Typography variant="h5">
            Add {zClaimIDs?.length || '0'} Claims To Trash
          </Typography>
          <div>
            <IconButton size="small" onClick={() => setIsOpen(false)}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent className="content">
          <Typography variant="body1">
            Are you sure you want to send{' '}
            <strong>{zClaimIDs?.length || 0} claim(s)</strong> to the trash?
            This action removes claims from standard processing flow. Claims are
            NOT immediately deleted, and remain recoverable for up to six
            months.
          </Typography>
          <DesignSuite2023.Divider />
          <RenderNotesField
            name="Notes"
            label="Notes"
            value={note || ''}
            setter={({ name, value }) => {
              setNote(value)
            }}
            use2023Styles
            opts={{
              margin: 'none',
              InputLabelProps: {
                shrink: true,
              },
              helperText: '**Required**',
            }}
          />
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button
                disabled={isWorking}
                color="primary"
                variant="contained"
                onClick={doConfirm}>
                Continue
              </Button>
            }
          />
        </DialogActions>
      </StyledDialog>
    </>
  )
}
