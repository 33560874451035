import React, { forwardRef, useContext, useMemo, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findInvoiceItems } from '../../../actions/ZClaimFundingActions'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

//@ts-ignore
import styled from 'styled-components'
import {
  InvoiceItemType,
  getInvoiceItemTypeDisplay,
} from './CreateInvoiceItemDialog'
import { IconButton } from '@material-ui/core'
import EditInvoiceItem from './EditInvoiceItem'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import EditInvoiceableHold from './EditInvoiceableHold'

const TableData = styled.div`
  &.short {
    width: 100px;
  }

  &.long {
    width: 400px;
  }
`

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.invoice-item-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export interface invoiceableInvoiceItemTableRecord {
  InvoiceItemID: number
  Date: string
  Type: InvoiceItemType
  Amount: string
  DisplayNotes: string
  Notes: string
  OnHold: boolean
  HoldReleaseOn: string
  HoldNotes: string
}

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = (
  onEditInvoiceItem: () => void,
  onEditHold: () => void,
  allowEdit: boolean
) =>
  Object.freeze({
    _EditInvoiceItem: {
      name: '',
      details: {
        hidden: !allowEdit,
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return (
            <InlineEditInvoiceItem
              data={row}
              onEditInvoiceItem={onEditInvoiceItem}
            />
          )
        },
      },
    },
    InvoiceItemID: {
      name: 'Item ID',
      details: {
        sortName: 'InvoiceItemID',
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return <TableData>{row.InvoiceItemID}</TableData>
        },
      },
    },
    Date: {
      name: 'Date',
      details: {
        sortName: 'Date',
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return <TableData> {row.Date}</TableData>
        },
      },
    },
    Type: {
      name: 'Type',
      details: {
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return <TableData> {getInvoiceItemTypeDisplay(row.Type)}</TableData>
        },
      },
    },
    Notes: {
      name: 'Internal Notes',
      details: {
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return <TableData>{row.Notes}</TableData>
        },
      },
    },
    DisplayNotes: {
      name: 'Invoice display notes',
      details: {
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          return <TableData className="long">{row.DisplayNotes}</TableData>
        },
      },
    },
    HoldAmount: {
      name: 'On Hold',
      details: {
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          const amount = row.OnHold
            ? parseFloat(row.Amount.replace(/[^0-9.-]+/g, ''))
            : null
          const style = amount !== null && amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {amount !== null ? (
                <>
                  {formatAmount(row.Amount)}
                  <InlineEditInvoiceItemHold
                    data={row}
                    onEditHold={onEditHold}
                  />
                </>
              ) : null}
            </TableData>
          )
        },
      },
    },
    Amount: {
      name: 'Current',
      details: {
        sortName: 'Amount',
        dataFormat(_: any, row: invoiceableInvoiceItemTableRecord): any {
          const amount = !row.OnHold
            ? parseFloat(row.Amount.replace(/[^0-9.-]+/g, ''))
            : null
          const style = amount !== null && amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {amount !== null ? formatAmount(row.Amount) : null}
            </TableData>
          )
        },
      },
    },
  })

const defaultSort = { col: 'Date', dir: 'asc' }

export const Table = forwardRef(function Table(
  {
    apiEndpoint = findInvoiceItems,
    dataTableProps,
    onEditInvoiceItem,
    onEditHold,
    allowEdit,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    return defaultColumns(onEditInvoiceItem, onEditHold, allowEdit)
  }, [onEditInvoiceItem, onEditHold, allowEdit])

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        // isRowCheckable: (row: invoiceableInvoiceItemTableRecord) => {
        //   return !row.OnHold
        // },
        ...dataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`invoice-item-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function InlineEditInvoiceItem({
  data,
  onEditInvoiceItem,
}: {
  data: invoiceableInvoiceItemTableRecord
  onEditInvoiceItem?(): void
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DesignSuite2023.Tooltip title="Edit invoice item">
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <EditInvoiceItem
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        invoiceItemID={data.InvoiceItemID}
        onComplete={() => {
          refresh()
          onEditInvoiceItem?.()
        }}
      />
    </>
  )
}

function InlineEditInvoiceItemHold({
  data,
  onEditHold,
}: {
  data: invoiceableInvoiceItemTableRecord
  onEditHold?(): void
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DesignSuite2023.Tooltip title="Edit hold">
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <EditInvoiceableHold
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        invoiceItemID={data.InvoiceItemID}
        onComplete={() => {
          refresh()
          onEditHold?.()
        }}
      />
    </>
  )
}
