import * as api from '../services/thezerocard/api-helper'

export function findZelisBatches(params: any) {
  return api.search('/zelis/batches', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getZelisBatchDetail(batchID: number) {
  return api.get(`/zelis/batch/${batchID}`).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function findZelisBatchPayments(params: any) {
  return api.search('/zelis/batch_payments', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function reprintZelisBatchPayments(paymentIDs: number[]) {
  return api
    .post('/zelis/reprint_batch_payments', { PaymentIDs: paymentIDs })
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function reprintZelisBatch(batchID: number) {
  return api.post(`/zelis/reprint_batch/${batchID}`).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}
