import React, { forwardRef, useCallback } from 'react'
import * as FeeScheduleTable from '../FeeSchedules/FeeScheduleTable2'
import { ComponentFeeScheduleActions } from '../../actions'
import utils from '../../utils'
import dateTime from '../../utils/dateTime'

const FilterOrganization = FeeScheduleTable.FilterOrganization
const FilterSearch = FeeScheduleTable.StandardFilterSearch
const FilterStatus = FeeScheduleTable.FilterStatus

interface props extends FeeScheduleTable.props {
  // add as necessary
}

const columns = Object.freeze({
  ID: { name: 'ID', details: {} },
  Name: { name: 'Name', details: { sortName: 'name' } },
  StartDate: {
    name: 'Start',
    details: { dataFormat: dateTime.cellFormatter(), sortName: 'date_start' },
  },
  EndDate: {
    name: 'End',
    details: { dataFormat: dateTime.cellFormatter(), sortName: 'date_end' },
  },
  Status: {
    name: 'Production',
    details: { dataFormat: utils.zeroOneCheckFormatter('Status') },
  },
  StatusModifiedByUsername: { name: 'Productionized by', details: {} },
  Notes: { name: 'Notes', details: {} },
  Type: { name: 'Type', details: {} },
})

const Table = forwardRef(function ComponentFeeScheduleTable(
  { ...allProps }: props & Partial<any>,
  ref: any
): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()

  const apiEndpoint = useCallback(
    (payload: any): Promise<any> => {
      return ComponentFeeScheduleActions.findComponentFeeSchedules(payload)
        .payload
    },
    [internalID]
  )

  return (
    <FeeScheduleTable.Table
      {...allProps}
      ref={ref}
      customColumns={columns}
      apiEndpoint={apiEndpoint}
      fnLinkOnRowClick={(row: any) => `/component_fee_schedule/${row.ID}`}
    />
  )
})

export default Table
export { Table, FilterOrganization, FilterSearch, FilterStatus }
