import React, { useState, forwardRef, useRef } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogContent,
  Chip,
  Typography,
} from '@material-ui/core'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import * as api from '../../services/thezerocard/api-helper'
import dateTime from '../../utils/dateTime'
import utils from '../../utils'
import styled from 'styled-components'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'

const StyledDialog = styled(Dialog)`
  [role='dialog'],
  .MuiDialogContent-root,
  .std-table {
    height: 100%;
  }

  .MuiDialogContent-root {
    padding: 0 !important;

    .std-table {
      overflow: hidden;
      overflow-y: auto;

      .header-items {
        padding: 0 1rem;
        white-space: nowrap;
        margin-top: 0;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 5;

        .header-item-cell {
          column-gap: 0.5rem;
        }
      }

      .base-table-display {
        margin-top: 0 !important;

        .data-table-footer {
          position: sticky;
          bottom: 0;
          background: #fff;
        }
      }
    }
  }

  .info-display {
    span {
      display: block;
    }
  }
`

export default function InboxUploadManagement(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const refTable = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()

  function onClickMarkHandled() {
    if (!selected?.length) return
    const ids = selected.map((row: any) => row.ID)
    api
      .put('/engineering/inbox_upload/mark_handled', { ids })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Inbox Upload records updated OK', SnackbarTypeSuccess)
        refTable.current?.refresh?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed marking handled',
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Launch inbox upload management dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Inbox Uploads
        </Button>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        open={isOpen}
        fullWidth
        maxWidth="xl"
        onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Table
            ref={refTable}
            onCheckHandler={setSelected}
            LeftHeaderItems={
              <>
                <Typography variant="subtitle1">Inbox Uploads</Typography>
              </>
            }
            RightHeaderItems={
              <>
                <Button
                  disabled={selected?.length === 0}
                  size="small"
                  variant="contained"
                  onClick={onClickMarkHandled}>
                  Mark Handled
                </Button>
              </>
            }
          />
        </DialogContent>
      </StyledDialog>
    </>
  )
}

const Table = forwardRef(function TableInboxUpload(
  {
    customColumns = defaultColumns,
    apiEndpoint = apiEndpointGetInboxUpload,
    DataTableProps: passDataTableProps = {},
    initPageSize = 50,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      initPageSize,
      DataTableProps: {
        ...passDataTableProps,
        density: 'small',
        rowsPerPage: [25, 50, 100],
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

function apiEndpointGetInboxUpload(params: any) {
  return api.search('/engineering/inbox_upload', params)
}

const defaultColumns = Object.freeze({
  ID: { name: 'ID' },
  CreatedAt: {
    name: 'Created At',
    details: {
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
  Type: { name: 'Type' },
  Combined: {
    name: 'Info',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <div className="info-display">
            <DesignSuite2023.Tooltip title={`Storage location: ${row?.S3Key}`}>
              <Chip label={row?.FileName} size="small" />
            </DesignSuite2023.Tooltip>
            <span>
              <strong>Notes:</strong>&nbsp;{row?.Notes}
            </span>
          </div>
        )
      },
    },
  },
  FromAndTo: {
    name: 'From/To',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <span>
            <strong>From:</strong>&nbsp;{row?.FromFirstName}&nbsp;
            {row?.FromLastName}&nbsp;({row?.Email})
            <br />
            &rarr;&nbsp;<strong>To:</strong>&nbsp;{row?.Recipient}
            <br />
            <strong>Employer:</strong> {row?.FromEmployerName}
          </span>
        )
      },
    },
  },
  Handled: {
    name: 'Handled',
    details: { dataFormat: utils.boolYesNoFormatter },
  },
  SenderNotified: {
    name: 'Sender Notified',
    details: { dataFormat: utils.boolYesNoFormatter },
  },
})
