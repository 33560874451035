import React, { useState, useEffect } from 'react'
import { TextField, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getNetworks } from '../../actions/NetworkActions'
import { Network } from './types'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface AutocompleteProps {
  disabled?: boolean
  currentNetworks: Array<Network>
  handleChange: (networks: Array<Network>) => void
  passFilters?: any
  getOptionLabel?: (option: Network) => string
  getOptionDisabled?: (option: Network) => boolean
  TextFieldProps?: any
}

export type { Network }

export default function NetworksAutocomplete({
  handleChange,
  currentNetworks = [],
  passFilters = {},
  getOptionLabel = (option: Network) => option.Name,
  getOptionDisabled = (option: Network) => false,
  disabled = false,
  TextFieldProps,
}: AutocompleteProps) {
  const [allNetworks, setAllNetworks] = useState<Array<Network>>([])
  const [selectedNetworks, setSelectedNetworks] =
    useState<Array<Network>>(currentNetworks)
  const [availableNetworks, setAvailableNetworks] = useState<Array<Network>>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getNetworks({
      filter: { ...passFilters, disableLimit: true },
      page: 1,
      pageSize: 10,
    })
      .then((res: { Data: Array<Network> }) => {
        setAllNetworks(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Networks; please contact Engineering',
        })
      )
  }, [])

  useEffect(() => {
    if (JSON.stringify(currentNetworks) === JSON.stringify(selectedNetworks)) {
      return
    }
    setSelectedNetworks(currentNetworks)
  }, [currentNetworks, selectedNetworks])

  useEffect(() => {
    setAvailableNetworks(
      allNetworks.filter((n: Network) => {
        return !selectedNetworks.find((x: any) => x.ID === n.ID)
      })
    )
  }, [allNetworks, selectedNetworks, setAvailableNetworks])

  const handleSelected = (e: any, value: Array<Network>) => {
    setSelectedNetworks(value)
    handleChange(value)
  }

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      options={availableNetworks}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      value={selectedNetworks}
      onChange={handleSelected}
      renderInput={(params: any) => (
        <TextField
          {...TextFieldProps}
          {...params}
          size={TextFieldProps?.size || 'small'}
          variant={TextFieldProps?.variant || 'outlined'}
          label={TextFieldProps?.label || 'Configurable Networks'}
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderTags={(value: any, getProps: any) =>
        value.map((option: any, index: number) => (
          <Chip
            variant="outlined"
            size={TextFieldProps?.size || 'small'}
            label={option.Name}
            {...getProps({ index })}
          />
        ))
      }
    />
  )
}
