import helpers from '../views/ZClaims/helpers'

export interface EmployerDivision {
  ID: number
  UUID: string
  EmployerID: number
  Name: string
  Handle: string
  IsRetired: boolean
}

export interface ObservedEmployerDivision {
  ID: number
  UUID: string
  EmployerID: number
  ReferenceID: string
  Name: string
  EmployerDivisionUUID: string
  IsLatest: boolean
}

export const EmployerDivisionColumns = {
  ID: {
    name: 'ID',
    details: {
      hidden: true,
    },
  },
  UUID: {
    name: 'UUID',
    details: {
      dataFormat: (cell: any, row: EmployerDivision) => {
        return helpers.UUIDShorty(row.UUID)
      },
    },
  },
  Name: {
    name: 'Name',
  },
  Handle: {
    name: 'Handle',
  },
  IsRetired: {
    name: 'Retired',
    details: {
      dataFormat: (cell: any, row: EmployerDivision) => {
        return row.IsRetired ? 'Yes' : 'No'
      },
    },
  },
}

export const ObservedEmployerDivisionColumns = {
  ID: {
    name: 'ID',
    details: {
      hidden: true,
    },
  },
  UUID: {
    name: 'UUID',
    details: {
      dataFormat: (_: any, row: EmployerDivision) => {
        return helpers.UUIDShorty(row.UUID)
      },
    },
  },
  ReferenceID: {
    name: 'Reference ID',
  },
  Name: {
    name: 'Linked Division Name',
    details: {
      dataFormat: (_: any, row: ObservedEmployerDivision) => {
        return `${row.Name}`
      },
    },
  },
}
