import React from 'react'
import { Route, Router, Switch, useHistory } from 'react-router-dom'

// redux imports
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import ReduxPromise from 'redux-promise'
import reducers from './reducers'

import 'typeface-roboto'
import EligibilityConsole2 from './views/Eligibility/EligibilityConsole2'
import EligibilityDetail from './views/Eligibility/EligibilityDetail'
import SavingsReview from './views/SavingsReview'
import OpportunityAnalysis from './views/OpportunityAnalysis/OpportunityAnalysis'
import Home from './views/Main/Home/Home'
import NoMatch from './components/NoMatch/NoMatch'
import CostKeys from './views/CostKeys'
import CostKeyDetail from './views/CostKeys/CostKeyDetail'
import Employers from './views/Employers/Employers'
import EmployerDetail from './views/Employers/EmployerDetail'
import Partners from './views/Partners/Partners'
import PartnerDetail from './views/Partners/PartnerDetail'
import Organizations from './views/Organizations'
import Networks from './views/Networks/Networks'
import NetworkDetail from './views/Networks/NetworkDetail'
import OrganizationDetail from './views/Organizations/Detail'
import PracticeFacilityDetail from './views/PracticeFacilities/PracticeFacilityDetail'
import Invoices from './views/Invoices/Invoices'
import ProviderSearch2 from './views/ProviderSearch/ProviderSearch2'
import Loas from './views/Loas'
import LoaDetail from './views/Loas/Detail'
import MemberDetail from './views/Members'
import Users from './views/Users/Users'
import UserDetail from './views/Users/UserDetail'
import Engineering from './views/Engineering'
import ETL from './views/ETL'
import EngineeringUserImport from './views/Engineering/UserImport'
import EngineeringUserDiff from './views/Engineering/UserDiff'
import EngineeringSFTPaas from './views/Engineering/sftpaas'
import EngineeringPGPKeys from './views/Engineering/PGPKeys'
import EngineeringFaxOCRSandbox from './views/Engineering/FaxOCRSandbox'
import InboundSftp from './views/ETL/InboundSftp'
import FeeScheduleImport from './views/FeeSchedules/FeeScheduleImport/PriceImport'
import { BillingPlanRoutable } from './views/BillingPlans/BillingPlan'
import GroupDetail from './views/Groups/GroupDetail'
import ZClaims from './views/ZClaims'
import FeeScheduleDetail from './views/FeeSchedules/FeeScheduleDetail'
import ComponentFeeSchedule from './views/FeeSchedules/ComponentFeeSchedule'
import FeeSchedules from './views/FeeSchedules/FeeSchedules'
import Schmipit from './views/Schmipit/Schmipit'
import StopLoss from './views/StopLoss'
import StopLossDetail from './views/StopLoss/StopLossDetail'
import Referrers from './views/Referrers'
import ReferrerDetail from './views/Referrers/Detail'
import AppFrame from './components/AppFrame'
import history from './utils/history'
import './utils/logger'
import 'postcss'
import Marketing from './views/Marketing'

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore)
const store = createStoreWithMiddleware(reducers)

export default function App() {
  // under test, history can be injected by a custom <Router> provider
  const injectedHistory = useHistory()

  function hasId(props: any): boolean {
    if (props.match && props.match.params) {
      return props.match.params.id !== undefined
    }
    return false
  }

  function renderFrame(child: any, title: string): React.ReactElement {
    const resourceIdentifier =
      child &&
      child.props &&
      child.props.match &&
      child.props.match.params &&
      child.props.match.params.id
    let fullTitle = `Admin - ${title}`
    if (resourceIdentifier) fullTitle = `${fullTitle} - ${resourceIdentifier}`

    document.title = fullTitle

    return <AppFrame content={child} contentKey={title} title={title} />
  }

  return (
    <Provider store={store}>
      <Router history={injectedHistory || history}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props: any) => renderFrame(<Home {...props} />, 'Home')}
          />
          <Route
            path="/home"
            render={(props: any) => renderFrame(<Home {...props} />, 'Home')}
          />
          <Route
            path="/savings_review"
            render={(props: any) =>
              renderFrame(<SavingsReview {...props} />, 'Savings Review')
            }
          />
          <Route
            path="/eligibility"
            render={(props: any) =>
              renderFrame(
                <EligibilityDetail {...props} />,
                'Eligibility Detail'
              )
            }
          />
          <Route
            path="/eligibility_console"
            render={(props: any) =>
              renderFrame(
                <EligibilityConsole2 {...props} />,
                'Eligibility Console'
              )
            }
          />
          <Route
            path="/opportunity_analysis"
            render={(props: any) =>
              renderFrame(
                <OpportunityAnalysis {...props} />,
                'Opportunity Analysis'
              )
            }
          />
          <Route
            path="/cost_keys"
            render={(props: any) =>
              renderFrame(<CostKeys {...props} />, 'Cost Keys')
            }
          />
          <Route
            path="/cost_key/:id?"
            render={(props: any) =>
              renderFrame(<CostKeyDetail {...props} />, 'Cost Key Detail')
            }
          />
          <Route
            path="/employers"
            render={(props: any) =>
              renderFrame(<Employers {...props} />, 'Employers')
            }
          />
          <Route
            path="/employer/:id?"
            render={(props: any) =>
              renderFrame(<EmployerDetail {...props} />, 'Employer Detail')
            }
          />
          <Route
            path="/partners"
            exact
            render={(props: any) =>
              renderFrame(<Partners {...props} />, 'Partners')
            }
          />
          <Route
            path="/partner/:id?"
            render={(props: any) =>
              renderFrame(<PartnerDetail {...props} />, 'Partner Detail')
            }
          />
          <Route
            path="/stop_loss_contracts"
            render={(props: any) =>
              renderFrame(<StopLoss {...props} />, 'Stop Loss')
            }
          />
          <Route
            path="/stop_loss_contract/:id?"
            exact
            render={(props: any) =>
              renderFrame(
                <StopLossDetail {...props} />,
                hasId(props)
                  ? 'Stop Loss Contract Details'
                  : 'Add New Stop Loss Contract'
              )
            }
          />
          <Route
            path="/invoices"
            render={(props: any) =>
              renderFrame(<Invoices {...props} />, 'Invoices')
            }
          />
          <Route
            path="/organizations"
            render={(props: any) =>
              renderFrame(<Organizations {...props} />, 'Organizations')
            }
          />
          <Route
            path="/organization/:id?"
            exact
            render={(props: any) =>
              renderFrame(
                <OrganizationDetail {...props} />,
                'Organization Detail'
              )
            }
          />
          <Route
            path="/organization/:id/price_import"
            exact
            render={(props: any) =>
              renderFrame(<FeeScheduleImport {...props} />, 'Price Import')
            }
          />
          <Route
            path="/organization/:orgId/practice_facility/:id?"
            exact
            render={(props: any) =>
              renderFrame(
                <PracticeFacilityDetail {...props} />,
                'Edit Practice Facility'
              )
            }
          />
          <Route
            path="/networks"
            render={(props: any) =>
              renderFrame(<Networks {...props} />, 'Networks')
            }
          />
          <Route
            path="/network/:id?"
            exact
            render={(props: any) =>
              renderFrame(<NetworkDetail {...props} />, 'Network Detail')
            }
          />
          <Route
            path="/search"
            exact
            render={(props: any) =>
              renderFrame(<ProviderSearch2 {...props} />, 'Provider Search')
            }
          />
          <Route
            path="/loas"
            render={(props: any) => renderFrame(<Loas {...props} />, 'LOAs')}
          />
          <Route
            path="/loa/:id?"
            exact
            render={(props: any) =>
              renderFrame(<LoaDetail {...props} />, 'LOA Detail')
            }
          />
          <Route
            path="/member/:id"
            exact
            render={(props: any) =>
              renderFrame(<MemberDetail {...props} />, 'Member Detail')
            }
          />
          <Route
            path="/users"
            render={(props: any) => renderFrame(<Users {...props} />, 'Users')}
          />
          <Route
            path="/user/:id?"
            exact
            render={(props: any) =>
              renderFrame(<UserDetail {...props} />, 'User Detail')
            }
          />
          <Route
            path="/group/:id?"
            exact
            render={(props: any) =>
              renderFrame(<GroupDetail {...props} />, 'Group Detail')
            }
          />
          <Route
            path="/fee_schedule_price/:fsp_id/billing_plan/:id?"
            exact
            render={(props: any) =>
              renderFrame(<BillingPlanRoutable {...props} />, 'Billing Plan')
            }
          />
          <Route
            path="/fee_schedule/:id?"
            exact
            render={(props: any) =>
              renderFrame(
                <FeeScheduleDetail {...props} />,
                'Fee Schedule Detail'
              )
            }
          />
          <Route
            path="/component_fee_schedule/:id?"
            exact
            render={(props: any) =>
              renderFrame(
                <ComponentFeeSchedule {...props} />,
                'Component Fee Schedule'
              )
            }
          />
          <Route
            path="/fee_schedules"
            exact
            render={(props: any) =>
              renderFrame(<FeeSchedules {...props} />, 'Fee Schedules')
            }
          />
          <Route
            path="/referrers"
            exact
            render={(props: any) =>
              renderFrame(<Referrers {...props} />, 'Referrers')
            }
          />
          <Route
            path="/referrer/:id?"
            exact
            render={(props: any) =>
              renderFrame(<ReferrerDetail {...props} />, 'Referrer Detail')
            }
          />
          <Route
            path="/etl"
            exact
            render={(props: any) => renderFrame(<ETL {...props} />, 'ETL')}
          />
          <Route
            path="/marketing"
            exact
            render={(props: any) =>
              renderFrame(<Marketing {...props} />, 'Marketing')
            }
          />
          <Route
            path="/engineering"
            exact
            render={(props: any) =>
              renderFrame(<Engineering {...props} />, 'Engineering')
            }
          />
          <Route
            path="/engineering/user_import"
            exact
            render={(props: any) =>
              renderFrame(<EngineeringUserImport {...props} />, 'User Import')
            }
          />
          <Route
            path="/engineering/user_diff"
            exact
            render={(props: any) =>
              renderFrame(
                <EngineeringUserDiff {...props} />,
                'Zerocard/Auth0 User Diff'
              )
            }
          />
          <Route
            path="/engineering/sftpaas"
            exact
            render={(props: any) =>
              renderFrame(<EngineeringSFTPaas {...props} />, 'SFTPaas')
            }
          />
          <Route
            path="/engineering/pgp_keys_public"
            exact
            render={(props: any) =>
              renderFrame(<EngineeringPGPKeys {...props} />, 'PGP Keys')
            }
          />
          <Route
            path="/engineering/fax_ocr_sandbox"
            exact
            render={(props: any) =>
              renderFrame(
                <EngineeringFaxOCRSandbox {...props} />,
                'faxocrsandbox'
              )
            }
          />
          <Route
            path="/zclaims"
            exact
            render={(props: any) =>
              renderFrame(<ZClaims {...props} />, 'ZClaims')
            }
          />
          <Route
            path="/etl/inbound_sftp/:id?"
            exact
            render={(props: any) =>
              renderFrame(<InboundSftp {...props} />, 'Inbound SFTP Configs')
            }
          />
          <Route
            path="/schmipit/:id"
            render={(props: any) =>
              renderFrame(<Schmipit {...props} />, 'Schmipit')
            }
          />

          <Route
            path="/test-router"
            exact
            render={(props: any) => <div>Works</div>}
          />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    </Provider>
  )
}
