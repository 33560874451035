import React, { forwardRef, useCallback, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import {
  EmployerDivision,
  EmployerDivisionColumns,
} from '../../../models/EmployerDivisions'
import { findEmployerDivisions } from '../../../actions/EmployerDivisionActions'
import { Button, IconButton } from '@material-ui/core'
import DialogEditDivision from './DialogEditDivision'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import { renderSwitchField as RenderSwitchField } from '../../../components/Inputs/standard'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = EmployerDivisionColumns

export const Table = forwardRef(function EmployerDivisionTable(
  {
    employerID,
    customColumns,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const apiEndpoint = useCallback(
    (params: any) => findEmployerDivisions(employerID, params),
    [employerID]
  )

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,

      customColumns: { ...customColumns, ...defaultColumns },
      initPage: 1,
      initPageSize: 10,
      apiEndpoint,
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function ButtonAddEmployerDivision({
  employerID,
}: {
  employerID: number
}): React.ReactElement {
  const ref = React.useRef<any>(null)
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => ref?.current?.open()}>
        Add Division
      </Button>
      <DialogEditDivision employerID={employerID} ref={ref} onClose={refresh} />
    </>
  )
}

export function InlineEditEmployerDivision({
  data,
}: {
  data: EmployerDivision
}): React.ReactElement {
  const ref = React.useRef<any>(null)
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <DialogEditDivision
        employerID={data.EmployerID}
        employerDivisionUUID={data.UUID}
        ref={ref}
        onClose={refresh}
      />
    </>
  )
}

export function FilterEmployerDivisionIsRetired(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <RenderSwitchField
      name={'ShowRetired'}
      label={'Show Retired'}
      value={filter.IsRetired === null}
      setter={({ value }) => {
        setFilterImmediate({ IsRetired: value ? null : false })
      }}
    />
  )
}
