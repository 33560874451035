import React, { useRef, useState } from 'react'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import * as InvoicedTable from '../Components/InvoicedTable'
import { BillingMethod, invoicedRecord } from '../Components/InvoicedTable'
import ButtonRecordAutoDebitACH from '../Components/ButtonRecordAutoDebitACH'
import ButtonSendInvoiceCreatedNotification from '../Components/ButtonSendInvoiceCreatedNotification'
import ButtonSendInvoiceReminderNotification from '../Components/ButtonSendInvoiceReminderNotification'

export default function PageInvoiced(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<invoicedRecord>>([])
  const refInvoicedTable = useRef<any>(null)

  const refreshInvoicedTable = () => {
    refInvoicedTable.current.refresh()
  }

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refInvoicedTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonRecordAutoDebitACH
          invoicedRecords={checkedItems}
          disabled={
            checkedItems.length === 0 ||
            checkedItems.some(
              (item) =>
                item.BillingMethod !== BillingMethod.DebitAuto ||
                item.InvoiceBalance === '0' ||
                item.IsRefund
            )
          }
          onComplete={refreshInvoicedTable}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonSendInvoiceCreatedNotification
          invoicedRecords={checkedItems}
          disabled={
            checkedItems.length === 0 ||
            checkedItems.some((item) => !!item.LastNotificationDate)
          }
          onComplete={refreshInvoicedTable}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonSendInvoiceReminderNotification
          invoicedRecords={checkedItems}
          disabled={
            checkedItems.length === 0 ||
            checkedItems.some(
              (item) => !item.LastNotificationDate || item.IsRefund
            )
          }
          onComplete={refreshInvoicedTable}
        />
      </div>
      <DesignSuite2023.Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterInvoiceDate />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterEmployer />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterHasBalance />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterBillingMethod />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterHasInvoiceNotification />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoicedTable.FilterHasInvoiceReminder />
      </div>
    </div>
  )

  return (
    <InvoicedTable.Table
      ref={refInvoicedTable}
      className="full-fixed"
      enableURLReflection="zclaims.invoiced.list"
      onCheckHandler={setCheckedItems}
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
