import React from 'react'
import {
  CheckCircleOutline as IconPayable,
  PauseCircleFilled as IconPended,
  RadioButtonUnchecked as IconDenied,
  Toll as IconPartiallyDenied,
} from '@material-ui/icons'
import styled from 'styled-components'

export enum Statuses {
  accepted = 'accepted',
  ignored = 'ignored',
  needs_review = 'needs_review',
  pended = 'pended',
}

export const StatusColors = {
  [Statuses.accepted]: 'green',
  [Statuses.ignored]: '#9f952e',
  [Statuses.needs_review]: '#9f952e',
  [Statuses.pended]: '#6a6a89',
}

const StyledStatus = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  small {
    display: inline-block;
    padding-left: 0.15rem;
    font-weight: 600;

    .fs {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`

export default function DisplayStatus({
  status,
  colorized = true,
}: {
  status: Statuses
  isSuspended?: boolean
  colorized?: boolean
}): React.ReactElement | null {
  let dispAdjStatus: React.ReactElement | null = null

  switch (status) {
    case 'accepted':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[status] : 'inherit' }}>
          <IconPayable />
          <small>Accepted</small>
        </StyledStatus>
      )
      break

    case 'ignored':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[status] : 'inherit' }}>
          <IconDenied />
          <small>Ignored</small>
        </StyledStatus>
      )
      break

    case 'needs_review':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[status] : 'inherit' }}>
          <IconPartiallyDenied />
          <small>Needs Review</small>
        </StyledStatus>
      )
      break

    case 'pended':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[status] : 'inherit' }}>
          <IconPended />
          <small>Pended</small>
        </StyledStatus>
      )
      break
  }

  return dispAdjStatus
}
