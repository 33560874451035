import * as api from '../services/thezerocard/api-helper'

export function findOrgs(params) {
  return api.search('/organization', params)
}

export function terminateAllFeeSchedules(orgID, params) {
  return api.put(`/organization/${orgID}/terminate_all_fee_schedules`, params)
}

export function backfillReferralInvoices({ orgID }) {
  return api.post(`/generate_referral_invoices/${orgID}`)
}

export function saveNewOrganization(body) {
  return api.post('/organization', body)
}

export function saveNewOrgContact({ orgId, contact }) {
  return api.post(`/organization/${orgId}/contact`, contact)
}

export function saveOrganization(id, body) {
  return api.put(`/organization/${id}`, body)
}

export function getOrgContactTypes() {
  return api.get('/contact_type/organization')
}

export function updateOrgEmployers(params) {
  const { orgID, payload } = params
  return api.put(`/organization/${orgID}/employers`, payload)
}

export function getOrgEmployers(orgID) {
  return api.get(`/organization/${orgID}/employers`)
}

export function getOrganizationLogos({ id }) {
  return api
    .get(`/organization/${id}/logos`)
    .then((res) => {
      if (res.error) {
        throw res
      }
      return res.Data.Logos
    })
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed loading organization logos; please contact Engineering`
    })
}

export function putActiveLogo({ id, fileID }) {
  return api
    .put(`/organization/${id}/active_logo_file/${fileID}`)
    .then(() => {
      return true
    })
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed updating active logo; please contact Engineering`
    })
}

export function postLogoFile({ id }, body) {
  const payload = Object.assign({}, body, { MakeActive: true })
  return api
    .post(`/organization/${id}/logo_file`, payload)
    .then((res) => {
      return res.Data
    })
    .catch((err) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed saving logo file; please contact Engineering`
    })
}

export function getOrgTermData(id) {
  return api.get(`/organization_termination/${id}`).then((res) => res.Data)
}

export function terminateOrg(id, body) {
  return api
    .put(`/organization_termination/${id}`, body)
    .then((res) => res.Data)
}

export function postPracticeFacilityImport(id, formData) {
  return api.postFormData(
    `/organization/${id}/import_practice_facilities`,
    formData
  )
}

export function getOrganization(id) {
  return api.get(`/organization/${id}`)
}

export function getOrgLaunch(id) {
  return api.get(`/organization/${id}/launch`).then((res) => res.Data)
}

export function putOrgLaunch(id, body) {
  return api.put(`/organization/${id}/launch`, body).then((res) => res.Data)
}

export function getOrgContractingTypes() {
  return api.get('/organization_contracting_types')
}
