import React, { useLayoutEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import {
  IconButton,
  Button,
  Typography,
  // Fade as TransitionComponent,
  Checkbox,
} from '@material-ui/core'
import {
  ExclusionConsts,
  Exclusions,
  ShapeBundleDefinition,
  ShapeBundleDefinitionCell,
  ShapeDRGCode,
} from '../types'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ExplainCodes from './ExplainCodes'
import { get as _get, set as _set } from 'lodash'
import {
  // renderTextField as RenderTextField,
  // renderNotesField as RenderNotesField,
  renderSwitchField as RenderSwitchField,
  // RenderPriceField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import AutoCompletePOSCodes from './AutoCompletePOSCodes'
import AutoCompleteDRGCodes from './AutoCompleteDRGCodes'
import AutoCompleteCPTCodes, { CPTCodeAndDescr } from './AutoCompleteCPTCodes'
import { ShapePOSCode } from '../types'
import StringifyCostKey from './StringifyCostKey'

const StyledCostKeyBuilder = styled.div`
  max-width: 100%;
  min-width: 500px;

  .wrapper {
    border-radius: 7px;
    border: 3px solid #ddd;
    padding: 1rem 1.25rem 0.9rem;
    line-height: 1;
    background: #f5f5f5;
    width: 100%;
    overflow: hidden;

    .assembled {
      background: linear-gradient(to right, #5fb383, #5f956b);
      margin: -1rem -1.25rem 1rem;
      text-align: center;
      padding: 1rem;

      .assembled-label {
        display: block;
        font-weight: 500;
        font-size: 90%;
        opacity: 0.65;
        user-select: none;
      }

      .styled-cost-key {
        font-size: 145%;
        color: #fff;
        padding: 0.25rem 0 0;
        text-shadow: 0 1px 1px BLACK;
      }

      .legacy-code-display {
        opacity: 0.64;
        font-size: 85%;
        position: relative;
        bottom: -0.15rem;
      }
    }
  }
`

export default function CostKeyBuilder({
  legacyCodeStr,
  bundleDefinition,
  setBundleDefinition,
}: {
  legacyCodeStr?: string | null
  bundleDefinition: ShapeBundleDefinition | null
  setBundleDefinition: (bt: ShapeBundleDefinition) => void
}): React.ReactElement {
  const [showExplainCodes, setShowExplainCodes] = useState(false)
  const [autoFocusLastAncillary, setAutoFocusLastAncillary] = useState(false)
  const refWrapper = React.useRef<any>(null)

  useLayoutEffect(() => {
    if (!autoFocusLastAncillary) return
    refWrapper?.current
      ?.querySelector('.cpts-mods aside:last-of-type input.code')
      ?.focus()
    setAutoFocusLastAncillary(false)
  }, [autoFocusLastAncillary])

  useLayoutEffect(() => {
    if (!bundleDefinition) return
    if (!bundleDefinition.auxCodes?.length) return
    let setSynths = false
    bundleDefinition?.auxCodes?.forEach((_: any, idx: number) => {
      if (bundleDefinition.auxCodes![idx]._syntheticID) return
      bundleDefinition.auxCodes![idx]._syntheticID =
        `${Math.random() * 1000000000000}`
      setSynths = true
    })
    if (!setSynths) return
    setBundleDefinition({ ...bundleDefinition })
  }, [bundleDefinition])

  const showLegacyCode = useMemo(() => {
    if (legacyCodeStr?.startsWith('[V2]')) return ''
    return legacyCodeStr
  }, [legacyCodeStr])

  function makeSetter(
    target: string
  ): (
    field: string
  ) => (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void {
    return (field) => (ev) => {
      const t = _get(bundleDefinition, target)
      const v = _set(t, field, ev.target.value)
      const r = _set({ ...bundleDefinition }, target, v) as any
      setBundleDefinition(r)
    }
  }

  function makeAddPayableWithModSlot(target: string): (ev: any) => void {
    return (ev: any) => {
      const t = _get(bundleDefinition, target)
      const v = _set(t, 'payableWithMods', [...(t?.payableWithMods || []), ''])
      const r = _set({ ...bundleDefinition }, target, v) as any
      setBundleDefinition(r)
    }
  }

  function makeRmPayableWithModSlot(target: string) {
    return (idx: number) => (ev: any) => {
      const t = _get(bundleDefinition, target)
      t.payableWithMods?.splice(idx, 1)
      const r = _set({ ...bundleDefinition }, target, t) as any
      setBundleDefinition(r)
    }
  }

  function makeRemoveAux(idx: number) {
    return (ev: any) => {
      const t = { ...bundleDefinition } as ShapeBundleDefinition
      t.auxCodes?.splice(idx, 1)
      setBundleDefinition(t)
    }
  }

  function addAux() {
    // @ts-ignore
    setBundleDefinition({
      ...bundleDefinition,
      auxCodes: [
        ...(bundleDefinition?.auxCodes || []),
        {
          cpt: null,
          mod: null,
          payableWithMods: [],
          requireUnits: false,
        },
      ],
    })
    setAutoFocusLastAncillary(true)
  }

  function onChangeExclusionCheckBox(ev: any) {
    const t = { ...bundleDefinition } as ShapeBundleDefinition
    if (!t.exclusions) t.exclusions = {}
    const value = ev.target.value as ExclusionConsts
    if (ev.target.checked) {
      t.exclusions[value] = true
    } else {
      delete t.exclusions[value]
    }
    if (!Object.keys(t.exclusions).length) t.exclusions = null
    setBundleDefinition(t)
  }

  function onChangePOSCodes(v: Array<ShapePOSCode>) {
    const t = { ...bundleDefinition } as ShapeBundleDefinition
    t.pos = v?.map((c) => c.Code) || []
    setBundleDefinition(t)
  }

  function onChangeDRGCode(v: Array<ShapeDRGCode> | null) {
    const t = { ...bundleDefinition } as ShapeBundleDefinition
    t.drg = v?.[0]?.Code || null
    setBundleDefinition(t)
  }

  function onChangeCaptureCPTs(v: Array<CPTCodeAndDescr> | null) {
    const t = { ...bundleDefinition } as ShapeBundleDefinition
    t.captureCPTs = v?.map((c) => c.Code) || []
    setBundleDefinition(t)
  }

  function onChangeDeclineCPTs(v: Array<CPTCodeAndDescr> | null) {
    const t = { ...bundleDefinition } as ShapeBundleDefinition
    t.declineCPTs = v?.map((c) => c.Code) || []
    setBundleDefinition(t)
  }

  return (
    <StyledCostKeyBuilder>
      <div ref={refWrapper} className="wrapper">
        <div className="assembled">
          <Typography
            component="span"
            variant="body2"
            className="assembled-label">
            Assembled Cost Key:
          </Typography>
          <StringifyCostKey highlighters bd={bundleDefinition} />

          {!!showLegacyCode && (
            <span className="legacy-code-display">
              LEGACY Code:&nbsp;
              <strong>{showLegacyCode}</strong>
            </span>
          )}
        </div>

        {!bundleDefinition?.primCode?.cpt && (
          <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
            This cost key configuration is incomplete. A{' '}
            <strong>Primary CPT</strong> must be specified at the bare minimum.
          </DesignSuite2023.AlertWarning>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '1rem',
            marginBottom: '1rem',
          }}>
          <div style={{ flex: 1 }}>
            <RenderCell
              cell={bundleDefinition?.primCode!}
              setter={makeSetter('primCode')}
              addPayableWithModSlot={makeAddPayableWithModSlot('primCode')}
              rmPayableWithModSlot={makeRmPayableWithModSlot('primCode')}
              isPrimary
            />

            {bundleDefinition?.auxCodes?.map((cell, idx) => (
              <RenderCell
                key={cell._syntheticID || Math.random() * 100000000}
                cell={cell}
                setter={makeSetter(`auxCodes[${idx}]`)}
                addPayableWithModSlot={makeAddPayableWithModSlot(
                  `auxCodes[${idx}]`
                )}
                rmPayableWithModSlot={makeRmPayableWithModSlot(
                  `auxCodes[${idx}]`
                )}
                removeCell={makeRemoveAux(idx)}
              />
            ))}

            <div style={{ textAlign: 'center' }}>
              <DesignSuite2023.Tooltip title="Add ancillary CPT/Modifier">
                <Button
                  startIcon={<DesignSuite2023.CommonIcons.IconAdd />}
                  color="primary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={addAux}>
                  Add Ancillary Component
                </Button>
              </DesignSuite2023.Tooltip>
            </div>
          </div>

          <div style={{ minWidth: 180, maxWidth: 240, paddingTop: '0.5rem' }}>
            <div style={{ paddingBottom: '1rem' }}>
              {/* <RenderTextField
                use2023Styles
                name="drg"
                label="DRG"
                value={bundleDefinition?.drg || ''}
                setter={({ name, value }: SetterArgs) => {
                  const t = { ...bundleDefinition } as ShapeBundleDefinition
                  t.drg = value
                  setBundleDefinition(t)
                }}
                opts={{
                  margin: 'none',
                  InputLabelProps: {
                    shrink: true,
                  },
                  inputProps: {
                    maxLength: 3,
                  },
                }}
              /> */}
              <AutoCompleteDRGCodes
                // multiple
                value={bundleDefinition?.drg}
                onChange={onChangeDRGCode}
              />
            </div>

            <AutoCompletePOSCodes
              value={bundleDefinition?.pos}
              onChange={onChangePOSCodes}
            />

            <Typography
              variant="body1"
              style={{ margin: '1rem 0 0.25rem', fontWeight: 'bold' }}>
              Excludes
            </Typography>

            {Object.keys(Exclusions).map((excl) => (
              <label
                key={`excl-${excl}`}
                style={{ display: 'block', fontWeight: 400 }}>
                <StyledCheckbox
                  value={excl}
                  checked={
                    !!bundleDefinition?.exclusions?.[
                      excl as ExclusionConsts
                    ] === true
                  }
                  onChange={onChangeExclusionCheckBox}
                />{' '}
                {Exclusions[excl]}
              </label>
            ))}
          </div>
        </div>

        <div style={{ paddingBottom: '1rem' }}>
          <AutoCompleteCPTCodes
            value={bundleDefinition?.captureCPTs}
            onChange={onChangeCaptureCPTs}
            TextFieldProps={{
              label: 'Capture Alt CPTs',
              placeholder: 'Type to search...',
            }}
          />
        </div>

        <div style={{ paddingBottom: '1rem' }}>
          <AutoCompleteCPTCodes
            value={bundleDefinition?.declineCPTs}
            onChange={onChangeDeclineCPTs}
            TextFieldProps={{
              label: 'Decline Alt CPTs',
              placeholder: 'Type to search...',
            }}
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <RenderSwitchField
            name="showExplainCodes"
            label="Show Code Hints"
            value={showExplainCodes}
            setter={({ name, value }: SetterArgs) => {
              setShowExplainCodes(value)
            }}
            opts={{ size: 'small', margin: 'none' }}
          />
        </div>
        {showExplainCodes && (
          <ExplainCodes bundleDefinition={bundleDefinition} />
        )}
      </div>
    </StyledCostKeyBuilder>
  )
}

const StyledCell = styled.div`
  margin-bottom: 0.5rem;
  position: relative;

  fieldset {
    width: 100%;
    border: none;
    background: #e9e9e9;
    border: 2px solid #ddd;
    padding: 1rem 1rem 0.75rem;
    border-radius: 5px;
    line-height: 1;
    display: inline-flex;
    align-items: center;

    legend {
      font-size: 84%;
      font-weight: bold;
      background: #999;
      color: #fff;
      margin: 0 0 0 -0.5rem;
      padding: 0.25rem 0.35rem;
      border: 0;
      line-height: inherit;
      border-radius: 5px;
      display: inline-block;
      width: auto;
    }

    .parts {
      display: flex;
      // column-gap: 1rem;
      align-items: flex-start;

      .part {
        font-weight: 500;
        font-size: 110%;
        padding: 0 1rem;
        border-right: 2px solid #ddd;

        small {
          display: block;
          font-weight: inherit;
          padding-bottom: 0.35rem;
          white-space: nowrap;
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .part.cpt {
  }
  .part.mdf {
  }
  .part.excl {
  }
  .part.pwm {
  }

  .pwm-list {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
  }

  input {
    width: auto;
    height: auto;
    line-height: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: 400;
    field-sizing: content;
    box-sizing: content-box;
    border: 0;
    display: inline-block;
    background: rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0.65rem;
    margin: 0;
    border-radius: 5px;
    outline: none;
  }

  .selector {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: inherit;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: '';
      right: 6px;
      pointer-events: none;
    }

    &::before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
      top: 30%;
    }

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      top: 55%;
    }

    select {
      // A reset of styles, including removing the default dropdown arrow
      appearance: none;
      // Additional resets for further consistency
      background-color: transparent;
      box-sizing: border-box;
      border: none;
      padding: 0.5rem 1.25rem 0.5rem 0.65rem;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      font-weight: 400;
      cursor: inherit;
      line-height: 1.1;
      outline: none;

      option:first-child {
        color: #ccc;
      }
    }
  }

  button.remover {
    position: absolute;
    bottom: 2px;
    right: 2px;
    z-index: 2;
    font-size: 10px;
    line-height: 1;
    opacity: 0.6;
    z-index: 2;

    :hover {
      opacity: 1;
    }
  }
`

function RenderCell({
  cell,
  setter,
  addPayableWithModSlot,
  rmPayableWithModSlot,
  removeCell,
  isPrimary,
}: {
  cell: ShapeBundleDefinitionCell
  setter(
    field: string
  ): (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  addPayableWithModSlot(ev: any): void
  rmPayableWithModSlot(idx: number): (ev: any) => void
  removeCell?(ev: any): void
  isPrimary?: boolean
}): React.ReactElement | null {
  return (
    <StyledCell>
      <fieldset>
        <legend>
          {isPrimary ? 'PRIMARY Component' : 'Ancillary Component'}
        </legend>
        <div className="parts">
          <label className="part cpt">
            <small>CPT</small>
            <input
              type="text"
              className="code"
              placeholder="CPT"
              maxLength={5}
              value={cell?.cpt || ''}
              onChange={setter('cpt')}
            />
          </label>
          <label className="part mdf">
            <small>Modifier</small>
            <input
              type="text"
              className="modifier"
              placeholder="M"
              maxLength={2}
              value={cell?.mod || ''}
              onChange={setter('mod')}
            />
          </label>
          <div className="part pwm">
            <small>Pays With Modifier(s)</small>
            <div className="pwm-list">
              {cell?.payableWithMods?.map((_, pmIDX) => {
                return (
                  <div
                    key={`${cell?._syntheticID}.${pmIDX}`}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      columnGap: '0.25rem',
                    }}>
                    <input
                      type="text"
                      maxLength={2}
                      value={cell?.payableWithMods?.[pmIDX] || ''}
                      onChange={setter(`payableWithMods[${pmIDX}]`)}
                    />
                    <DesignSuite2023.Tooltip title="Remove modifier">
                      <IconButton
                        size="small"
                        style={{ background: '#e8c1c1' }}
                        onClick={rmPayableWithModSlot(pmIDX)}>
                        <DesignSuite2023.CommonIcons.IconDelete fontSize="inherit" />
                      </IconButton>
                    </DesignSuite2023.Tooltip>
                  </div>
                )
              })}
              <div>
                <DesignSuite2023.Tooltip title="Add ancillary CPT/Modifier">
                  <IconButton
                    size="small"
                    style={{ background: '#e9e9e9', marginTop: '2px' }}
                    onClick={addPayableWithModSlot}>
                    <DesignSuite2023.CommonIcons.IconAdd fontSize="inherit" />
                  </IconButton>
                </DesignSuite2023.Tooltip>
              </div>
            </div>
          </div>
          <div className="part units">
            <small>Require Units</small>
            <label style={{ paddingTop: '0.25rem' }}>
              <StyledCheckbox
                checked={cell?.requireUnits || false}
                onChange={(ev: any) => {
                  setter('requireUnits')({
                    target: { value: ev.target.checked },
                  } as any)
                }}
              />
            </label>
          </div>
        </div>
      </fieldset>
      {removeCell && (
        <Button
          onClick={removeCell}
          className="remover"
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<DesignSuite2023.CommonIcons.IconDeny />}>
          Remove
        </Button>
      )}
    </StyledCell>
  )
}

// const StyledCostKey = styled.em`
//   font-size: 145%;
//   display: block;
//   font-style: normal;
//   line-height: 1;
//   user-select: all;
//   text-transform: uppercase;
//   font-family: monospace;
//   font-weight: 400;
//   letter-spacing: 0.01em;

//   span {
//     display: inline-block;
//     padding: 3px;
//     border-radius: 6px;

//     span {
//       padding: 3px 0;
//     }

//     &.sep {
//       padding: 3px;
//     }

//     &.primary {
//       text-decoration: underline;
//     }

//     &:hover {
//       background: rgba(116, 71, 170, 0.45);
//     }
//   }
// `

// function BundleDefinitionToCostKey({
//   highlighters = false,
//   bd,
// }: {
//   highlighters?: boolean
//   bd: ShapeBundleDefinition | null
// }): any {
//   const invalid = (
//     <StyledCostKey className="styled-cost-key invalid">
//       invalid_cost_key
//     </StyledCostKey>
//   )
//   if (!bd) return invalid

//   // @ts-ignore
//   bd.primCode._isPrim = true

//   let ordered = (
//     [bd.primCode, ...(bd.auxCodes || [])] as ShapeBundleDefinitionCell[]
//   ).sort((a: any, b: any) => {
//     if (a.cpt < b.cpt) return -1
//     if (a.cpt > b.cpt) return 1
//     return 0
//   })

//   if (!highlighters) {
//     let asStr = ordered
//       .map((c: ShapeBundleDefinitionCell): string | null => {
//         if (!c?.cpt) return null
//         let s = c.cpt
//         if (c.mod) s += `[&${c.mod}]`
//         if (c.payableWithMods && c.payableWithMods?.length > 0) {
//           s += `[?${[...c.payableWithMods].sort().join('|')}]`
//         }
//         return s
//       })
//       .filter((v) => !!v)
//       .join('~')

//     if (!asStr) return invalid

//     if (bd.pos) asStr += `~#P:${bd.pos}`
//     if (bd.drg) asStr += `~#D:${bd.drg}`

//     return <StyledCostKey className="styled-cost-key">{asStr}</StyledCostKey>
//   }

//   let fancied = ordered
//     .map((c: ShapeBundleDefinitionCell): any => {
//       if (!c?.cpt) return null
//       const k = `${btoa(JSON.stringify(c))}`
//       return (
//         <React.Fragment key={k}>
//           <DesignSuite2023.Tooltip
//             key={k}
//             TransitionProps={{ timeout: 0 }}
//             TransitionComponent={TransitionComponent}
//             title={`Component${c._isPrim ? ' (PRIMARY)' : ''}`}>
//             <span key={k} className={`component`}>
//               <DesignSuite2023.Tooltip
//                 TransitionProps={{ timeout: 0 }}
//                 TransitionComponent={TransitionComponent}
//                 title={`CPT: ${c.cpt}`}
//                 placement="bottom">
//                 <span className={`cpt ${c._isPrim && 'primary'}`}>{c.cpt}</span>
//               </DesignSuite2023.Tooltip>
//               {c.mod && (
//                 <DesignSuite2023.Tooltip
//                   TransitionProps={{ timeout: 0 }}
//                   TransitionComponent={TransitionComponent}
//                   title={`Modifier (required)`}
//                   placement="bottom">
//                   <span>-{c.mod}</span>
//                 </DesignSuite2023.Tooltip>
//               )}
//               {c.payableWithMods && (c.payableWithMods || []).length > 0 && (
//                 <DesignSuite2023.Tooltip
//                   TransitionProps={{ timeout: 0 }}
//                   TransitionComponent={TransitionComponent}
//                   title={`"Payable with" any of modifiers: ${[...c.payableWithMods].sort().join(', ')}`}
//                   placement="bottom">
//                   <span>?{[...c.payableWithMods].sort().join('|')}</span>
//                 </DesignSuite2023.Tooltip>
//               )}
//             </span>
//           </DesignSuite2023.Tooltip>
//         </React.Fragment>
//       )
//     })
//     .filter((v) => !!v)

//   if (!fancied.length) return invalid

//   if (bd.pos) {
//     fancied.push(
//       <DesignSuite2023.Tooltip
//         TransitionProps={{ timeout: 0 }}
//         TransitionComponent={TransitionComponent}
//         title={`Place of Service (required)`}
//         placement="bottom"
//         key={`pos-${bd.pos}`}>
//         <span key="pos">POS:{bd.pos}</span>
//       </DesignSuite2023.Tooltip>
//     )
//   }

//   if (bd.drg) {
//     fancied.push(
//       <DesignSuite2023.Tooltip
//         TransitionProps={{ timeout: 0 }}
//         TransitionComponent={TransitionComponent}
//         title={`DRG (required)`}
//         placement="bottom"
//         key={`drg-${bd.drg}`}>
//         <span key="drg">DRG:{bd.drg}</span>
//       </DesignSuite2023.Tooltip>
//     )
//   }

//   if (bd.exclusions) {
//     Object.keys(bd.exclusions).forEach((excl) => {
//       fancied.push(
//         <DesignSuite2023.Tooltip
//           TransitionProps={{ timeout: 0 }}
//           TransitionComponent={TransitionComponent}
//           title={`Exclusion ${excl}`}
//           placement="top"
//           key={`excl-${excl}`}>
//           <span key="excl">!{excl.substring(0, 2)}</span>
//         </DesignSuite2023.Tooltip>
//       )
//     })
//   }

//   const echo = []
//   for (let i = 0; i < fancied.length; i++) {
//     echo.push(fancied[i])
//     if (i < fancied.length - 1)
//       echo.push(
//         <span className="sep" key={`sep${i}`}>
//           ~
//         </span>
//       )
//   }

//   return <StyledCostKey className="styled-cost-key">{echo}</StyledCostKey>
// }

function StyledCheckbox(props: any) {
  return (
    <Checkbox
      {...props}
      disableRipple
      color="primary"
      size="small"
      style={{ background: 'transparent', padding: 0 }}
    />
  )
}
