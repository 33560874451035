import React, { useEffect, useRef, useState } from 'react'
import {
  invoiceableClaimTableRecord,
  StandardFilterSearch,
  Table as InvoiceableClaimTable,
} from './InvoiceableClaimTable'
import {
  invoiceableInvoiceItemTableRecord,
  Table as InvoiceableInvoiceItemTable,
} from './InvoiceableInvoiceItemTable'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import ButtonDoNotInvoiceZClaims from './ButtonDoNotInvoiceZClaims'
import ButtonCreateInvoiceableHolds from './ButtonCreateInvoiceableHolds'
import { invoiceableRecord, initialInvoiceableRecord } from './InvoiceableTable'
import { findInvoiceables } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import CreateInvoiceItemDialog from './CreateInvoiceItemDialog'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ButtonReleaseInvoiceableHolds from './ButtonReleaseInvoiceableHolds'

type props = {
  employerID: number
}

export default function ViewInvoiceableDetail({
  employerID,
}: props): React.ReactElement {
  const [data, setData] = useState<invoiceableRecord>(initialInvoiceableRecord)

  const [checkedClaims, setCheckedClaims] = useState<
    Array<invoiceableClaimTableRecord>
  >([])

  const [checkedHeldClaims, setCheckedHeldClaims] = useState<
    Array<invoiceableClaimTableRecord>
  >([])

  const [checkedNonHeldClaims, setCheckedNonHeldClaims] = useState<
    Array<invoiceableClaimTableRecord>
  >([])

  const [checkedHeldInvoiceItems, setCheckedHeldInvoiceItems] = useState<
    Array<invoiceableInvoiceItemTableRecord>
  >([])

  const [checkedNonHeldInvoiceItems, setCheckedNonHeldInvoiceItems] = useState<
    Array<invoiceableInvoiceItemTableRecord>
  >([])

  const refInvoiceableInvoiceItemTable = useRef<any>(null)
  const refInvoiceableClaimTable = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getInvoiceableData()
  }, [employerID])

  function getInvoiceableData() {
    return findInvoiceables({ filter: { EmployerIDs: [employerID] } })
      .then((res: any) => {
        if (res.Data && res.Data.length > 0) {
          setData(res.Data[0])
        } else {
          setData({
            ...initialInvoiceableRecord,
            EmployerLegalName: data.EmployerLegalName,
            EmployerQuickbooksHandle: data.EmployerQuickbooksHandle,
            EmployerName: data.EmployerName,
          })
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get zClaimInvoiceable data',
        })
      )
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader
            title={
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={9}>
                  <div>
                    <div>{data.EmployerLegalName}</div>
                    {data.EmployerLegalName !==
                      data.EmployerQuickbooksHandle && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerQuickbooksHandle}
                      </div>
                    )}
                    {data.EmployerLegalName !== data.EmployerName && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerName}
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item xs={'auto'}>
                  {data.InvoiceItemCount > 0 && (
                    <Typography>
                      {`Invoice Items[${data.InvoiceItemCount}]:`}{' '}
                    </Typography>
                  )}
                  {data.ClaimCount > 0 && (
                    <>
                      <Typography>{`Claims[${data.ClaimCount}]:`} </Typography>
                      <Typography>{`Fees:`}</Typography>
                    </>
                  )}
                  <Typography>{`Total:`}</Typography>
                  {data.OnHoldCount > 0 && (
                    <>
                      <Typography>{`Holds[${data.OnHoldCount}]:`} </Typography>
                      <Typography>{`Current:`}</Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={'auto'} style={{ textAlign: 'right' }}>
                  {data.InvoiceItemCount > 0 && (
                    <Typography>{`${formatAmount(data.InvoiceItemTotal)}`}</Typography>
                  )}
                  {data.ClaimCount > 0 && (
                    <>
                      <Typography>{`${formatAmount(data.ClaimTotal)}`}</Typography>
                      <Typography>{`${formatAmount(data.FeeTotal)}`}</Typography>
                    </>
                  )}
                  <Typography>
                    {`${formatAmount(data.InvoiceTotal)}`}
                  </Typography>
                  {data.OnHoldCount > 0 && (
                    <>
                      <Typography>
                        {`${formatAmount(data.OnHoldAmount)}`}
                      </Typography>
                      <Typography>
                        {`${formatAmount(data.InvoiceCurrent)}`}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            }></CardHeader>
        </Card>
      </Container>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={'Invoice Items'}></CardHeader>
          <CardContent>
            <InvoiceableInvoiceItemTable
              ref={refInvoiceableInvoiceItemTable}
              passFilters={{
                WhereInvoiceableForEmployerID: employerID,
                EmployerIDs: [employerID],
              }}
              onEditInvoiceItem={() => {
                getInvoiceableData()
                refInvoiceableInvoiceItemTable.current?.refresh()
              }}
              onEditHold={() => {
                getInvoiceableData()
                refInvoiceableInvoiceItemTable.current?.refresh()
              }}
              allowEdit={true}
              onCheckHandler={(
                items: Array<invoiceableInvoiceItemTableRecord>
              ) => {
                const heldItems = items.filter((item) => item.OnHold)
                const nonHeldItems = items.filter((item) => !item.OnHold)
                setCheckedHeldInvoiceItems(heldItems)
                setCheckedNonHeldInvoiceItems(nonHeldItems)
              }}
              dataTableProps={{
                keyProp: 'InvoiceItemID',
              }}
              LeftHeaderItems={
                <CreateInvoiceItemButton
                  disabled={
                    checkedHeldInvoiceItems.length > 0 ||
                    checkedNonHeldInvoiceItems.length > 0
                  }
                  employerID={employerID}
                  onComplete={() => {
                    getInvoiceableData()
                    refInvoiceableInvoiceItemTable.current?.refresh()
                  }}
                />
              }
              RightHeaderItems={
                <>
                  <ButtonCreateInvoiceableHolds
                    disabled={
                      checkedHeldInvoiceItems.length > 0 ||
                      checkedNonHeldInvoiceItems.length === 0
                    }
                    style={{ marginRight: '10px' }}
                    zClaimIDs={[]}
                    invoiceItemIDs={checkedNonHeldInvoiceItems
                      .map(
                        (v: invoiceableInvoiceItemTableRecord) =>
                          v.InvoiceItemID
                      )
                      .flat()}
                    onComplete={() => {
                      getInvoiceableData()
                      refInvoiceableInvoiceItemTable.current?.refresh()
                    }}
                    totalAmount={checkedNonHeldInvoiceItems
                      .reduce((sum, item) => {
                        const amount = parseFloat(
                          item.Amount.replace(/[^0-9.-]+/g, '')
                        )
                        return sum + amount
                      }, 0)
                      .toFixed(2)}
                  />
                  <ButtonReleaseInvoiceableHolds
                    disabled={
                      checkedHeldInvoiceItems.length === 0 ||
                      checkedNonHeldInvoiceItems.length > 0
                    }
                    zClaimIDs={[]}
                    invoiceItemIDs={checkedHeldInvoiceItems
                      .map(
                        (v: invoiceableInvoiceItemTableRecord) =>
                          v.InvoiceItemID
                      )
                      .flat()}
                    onComplete={() => {
                      getInvoiceableData()
                      refInvoiceableInvoiceItemTable.current?.refresh()
                    }}
                    totalAmount={checkedHeldInvoiceItems
                      .reduce((sum, item) => {
                        const amount = parseFloat(
                          item.Amount.replace(/[^0-9.-]+/g, '')
                        )
                        return sum + amount
                      }, 0)
                      .toFixed(2)}
                  />
                </>
              }
            />
          </CardContent>
        </Card>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={'Claims'}></CardHeader>
          <CardContent>
            <InvoiceableClaimTable
              ref={refInvoiceableClaimTable}
              passFilters={{
                WhereInvoiceableForEmployerID: employerID,
                DoNotInvoice: false,
              }}
              onEditHold={() => {
                getInvoiceableData()
                refInvoiceableClaimTable.current?.refresh()
              }}
              onCheckHandler={(items: Array<invoiceableClaimTableRecord>) => {
                setCheckedClaims(items)
                const heldItems = items.filter((item) => item.OnHold)
                const nonHeldItems = items.filter((item) => !item.OnHold)
                setCheckedHeldClaims(heldItems)
                setCheckedNonHeldClaims(nonHeldItems)
              }}
              dataTableProps={{
                keyProp: 'ZClaimID',
              }}
              LeftHeaderItems={
                <StandardFilterSearch
                  autoFocus
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                />
              }
              RightHeaderItems={
                <>
                  <ButtonCreateInvoiceableHolds
                    disabled={
                      checkedHeldClaims.length > 0 ||
                      checkedNonHeldClaims.length === 0
                    }
                    style={{ marginRight: '10px' }}
                    invoiceItemIDs={[]}
                    zClaimIDs={checkedNonHeldClaims
                      .map((v: invoiceableClaimTableRecord) => v.ZClaimID)
                      .flat()}
                    onComplete={() => {
                      getInvoiceableData()
                      refInvoiceableClaimTable.current?.refresh()
                    }}
                    totalAmount={checkedNonHeldClaims
                      .reduce((sum, item) => {
                        const amount = parseFloat(
                          item.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, '')
                        )
                        return sum + amount
                      }, 0)
                      .toFixed(2)}
                  />

                  <ButtonReleaseInvoiceableHolds
                    disabled={
                      checkedHeldClaims.length === 0 ||
                      checkedNonHeldClaims.length > 0
                    }
                    style={{ marginRight: '10px' }}
                    zClaimIDs={checkedHeldClaims
                      .map((v: invoiceableClaimTableRecord) => v.ZClaimID)
                      .flat()}
                    invoiceItemIDs={[]}
                    onComplete={() => {
                      getInvoiceableData()
                      refInvoiceableClaimTable.current?.refresh()
                    }}
                    totalAmount={checkedHeldClaims
                      .reduce((sum, item) => {
                        const amount = parseFloat(
                          item.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, '')
                        )
                        return sum + amount
                      }, 0)
                      .toFixed(2)}
                  />
                  <ButtonDoNotInvoiceZClaims
                    disabled={checkedClaims.length === 0}
                    zClaimIDs={checkedClaims
                      .map((v: invoiceableClaimTableRecord) => v.ZClaimID)
                      .flat()}
                    onComplete={() => {
                      getInvoiceableData()
                      refInvoiceableClaimTable.current?.refresh()
                    }}
                    totalAmount={checkedClaims
                      .reduce((sum, item) => {
                        const amount = parseFloat(
                          item.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, '')
                        )
                        return sum + amount
                      }, 0)
                      .toFixed(2)}
                  />
                </>
              }
            />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function CreateInvoiceItemButton({
  disabled,
  employerID,
  onComplete,
}: {
  disabled: boolean
  employerID: number
  onComplete?(): void
}): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        size="small"
        endIcon={<DesignSuite2023.CommonIcons.IconAdd />}
        onClick={() => {
          setIsOpen(true)
        }}>
        {`Create Invoice Item`}
      </Button>

      <CreateInvoiceItemDialog
        employerID={employerID}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onComplete={onComplete}
      />
    </>
  )
}
