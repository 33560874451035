import filesize from 'filesize'
import { fileLinkFormatter } from '../utils/ReactFormatters'
import dateTime from '../utils/dateTime'
import perms from '../utils/perms'

const file = {
  columns: {
    KeyColumn: 'ID',
    ID: { name: 'ID' },
    EffectiveDate: {
      name: 'Effective Date',
      details: { dataFormat: dateTime.cellFormatter() },
    },
    S3Key: { name: 'Filename', details: { dataFormat: fileLinkFormatter } },
    Size: { name: 'Size', details: { dataFormat: sizeFormatter } },
    FileType: { name: 'Type', details: { dataFormat: typeFormatter } },
    FileTypeDescr: { name: 'FileTypeDescr', details: { hidden: true } }, // index to search on
    Notes: {
      name: 'Notes',
      details: {
        dataFormat(cell, row) {
          return cell !== null ? cell : ''
        },
      },
    },
    CreatedBy: {
      name: 'Created By',
      details: { dataFormat: createdByFormatter },
    },
  },
  editFields: {
    S3Key: '',
    Notes: '',
    EmployerId: null,
    OrganizationId: null,
    EmployerMemberId: null,
    PartnerId: null,
    ReferrerId: null,
    FileTypeId: 0,
    EffectiveDate: dateTime.now().format(), // @todo: this will be buggy if an app stays open across days; should be a callback
  },
  hasAccess: () => {
    return perms.has(perms.RoleTokens.PermRequestFile)
  },
  canCreate: () => {
    return perms.has(perms.RoleTokens.PermCreateFile)
  },
  hasMemberFileAccess: () => {
    return perms.has(perms.RoleTokens.PermMemberFile)
  },
}

function createdByFormatter(cell, row) {
  return row.CreatedBy ? row.CreatedBy : 'System Generated'
}

function sizeFormatter(cell, row) {
  return filesize(cell, { round: 0 })
}

function typeFormatter(cell, row) {
  return cell.Description
}

export default file
