import React, { useState, useEffect } from 'react'
import { GetReviewStates } from './typed'
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface props {
  onChange: (value: any) => void
  disabled?: boolean
  value: number | null
  variant?: 'standard' | 'filled' | 'outlined' | undefined
}
export const ReviewStateSelector = ({
  onChange,
  disabled,
  value,
  variant = 'standard',
}: props) => {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const reviewStates = GetReviewStates()
  return (
    <FormControl
      variant={variant}
      margin="none"
      size="small"
      style={{ minWidth: 160 }}>
      <InputLabel htmlFor={internalID} id={internalID}>
        Review State
      </InputLabel>
      <Select
        autoWidth
        value={value}
        onChange={(ev: any, sel: any) => {
          onChange(sel.props.value)
        }}
        inputProps={{
          variant: variant,
        }}
        labelId={internalID}
        disabled={disabled}>
        <MenuItem key={'empty'} value="">
          Clear
        </MenuItem>
        {reviewStates
          ? reviewStates.map((item: any) => {
              return (
                <MenuItem key={item.label} value={item.value}>
                  {item.value}: {item.label}
                </MenuItem>
              )
            })
          : null}
      </Select>
    </FormControl>
  )
}

export const ReviewStateAutoSuggest = ({
  onChange,
  disabled,
  value,
  variant = 'standard',
}: props) => {
  const [selectedState, setSelectedState] = useState<any>(null)
  const reviewStates = GetReviewStates()

  useEffect(() => {
    setSelectedState(
      reviewStates.find((item: any) => item.value === value) || null
    )
  }, [value])

  return (
    <Autocomplete
      options={reviewStates}
      getOptionLabel={(opt: any): string => {
        return `${opt.value}: ${opt.label}`
      }}
      getOptionSelected={(v: any): boolean => v.value === value}
      value={selectedState}
      defaultValue={null}
      onChange={(_: any, val: any): void => {
        if (!val) {
          onChange(null)
          return
        }
        onChange(val.value)
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant={variant}
          label="Review State"
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      )}
      style={{ minWidth: 250 }}
    />
  )
}
