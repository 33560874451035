import * as api from '../services/thezerocard/api-helper'
import { shapeExplainCodes } from '../views/CostKeys/Components/ExplainCodes'

export function getExplainCodes(params: shapeExplainCodes) {
  return api.get(`/explain_codes`, null, params)
}

// no filtering/searching option: always returns the full set (which is known to be very small)
export function getListAllCodesByAcronym(
  acronym: 'pos' | 'drg' | 'cpt' | 'cpt_mod',
  params?: any
) {
  return api.get(`/list_all_codes/${acronym}`, null, params)
}
