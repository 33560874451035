import React, { useEffect, useRef, useState } from 'react'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import ExCodesDisplay from '../ExCodesDisplay'
// import EmployerMemberDefaulterReconciler from '../Advanced/EmployerMemberDefaulterReconciler'
// import OrganizationDefaulterReconciler from '../Advanced/OrganizationDefaulterReconciler'
// import PracticeFacilityDefaulterReconciler from '../Advanced/PracticeFacilityDefaulterReconciler'
import AssignPatientOrSubscriber from './AssignerPatientOrSubscriber'
import AssignBillingEntity from './AssignerBillingEntity'
import AssignPracticeFacility from './AssignerPracticeFacility'

import ButtonDoAdjudicate from '../ButtonDoAdjudicate'
import ClaimTimeline from './ClaimTimeline'
import ClaimLinesDisplay from './ClaimLineTable'
import DisplayCodesList, { displayCodeListActions } from './DisplayCodesList'
import DisplayOtherDetailFields from './ClaimDetailOtherFields'
import FindAndLinkFeeSchedule from '../FindAndLinkFeeSchedule'
import LimitsInfo from './LimitsInfo'
import ButtonMoveToFunding from '../../Components/ButtonMoveToFunding'
import ButtonDoSendBackToAdjudication from '../ButtonDoSendBackToAdjudication'
import ButtonDoProcess from '../ButtonDoProcess'
import { Button, Chip, CircularProgress, Typography } from '@material-ui/core'
import { SubdirectoryArrowRight } from '@material-ui/icons'
import {
  getZClaimByID,
  postAddClaimExCode,
  postClearClaimExCode,
  putZClaimClearSuspense,
} from '../../../../actions/ZClaimActions'
import ViewSource from './ViewSource'
import DisplayStatus, { AdjStatuses, FundStatuses } from '../Status'
import dateTime from '../../../../utils/dateTime'
import styled from 'styled-components'
import FastView, { FastViewType } from './FastViews'
import helpers, { SourceTypes } from '../../helpers'
import LogViewer from '../LogViewer'
import { ShapeExCodeOpt } from '../../types'
import DisplayAddress from '../../../../components/Address'
import DisplayRelatedClaims from './RelatedClaims'
import ClaimReversalDialog from '../ClaimReversalDialog'
import useHotKeys from './hookUseHotkeys'
import useSnackbar, { SnackbarTypeSuccess } from '../../../../hooks/useSnackbar'
import DisplayClaimID from '../DisplayClaimID'
import LinkReferrals from './LinkReferrals'
import perms from '../../../../utils/perms'

const StyledComponent = styled.div`
  .ds23-layout-sidebar {
    padding-top: 1rem;

    .sidebar-actions {
      padding: 0 1rem;

      .MuiButton-root {
        margin-bottom: 0.5rem;

        &.no-margin {
          margin-bottom: 0;
        }
      }
    }
  }

  .ds23-layout-primary {
    align-self: flex-start;
    position: sticky;
    top: 0;
  }

  .top-alert {
    margin-bottom: 0.5rem;
  }

  .headerish {
    // @todo: target the "reconcile" buttons better
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSizeSmall.MuiButton-sizeSmall {
      padding: 0 0.25rem;
    }
  }
`

const StyledFieldset = styled.fieldset`
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  margin: 2rem 0 1.5rem;
  position: relative;

  > legend {
    display: inline-block;
    margin: 0;
    background: #fff;
    line-height: 1;
    padding: 0.5rem 0.65rem;
    font-size: 90%;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-weight: bold;
    width: auto;
    position: absolute;
    top: 0;
    left: 1rem;
    transform: translateY(-50%);
  }

  // reconsider these styles
  .block-bold {
    display: block;
    font-size: 85%;
    font-weight: 500;
    opacity: 0.8;
  }
  h5 {
    margin: 0;
    font-size: 110%;
  }

  .mr-grid {
    display: flex;
    align-items: stretch;
    flex-flow: wrap;

    .col {
      flex: 1;
      padding: 2rem;
      flex-basis: 50%;

      &.bordered {
        border-left: 2px solid #ccc;
      }

      &:nth-child(3) {
        flex-basis: 100%;
        border-top: 2px solid #ccc;
        border-left: none;
      }
    }

    .subgrid {
      display: grid;
      grid-column-gap: 1rem;
      align-items: flex-start;

      &.c2 {
        grid-template-columns: repeat(2, auto);
      }
      &.c3 {
        grid-template-columns: repeat(3, auto);
      }

      .fullwidth {
        grid-column: 1/-1;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .mr-grid {
      flex-flow: row;

      .col,
      .col:nth-child(3) {
        max-width: fit-content;
        flex-basis: 33.33%;
        border-top: none;
        border-left: 2px solid #ccc;

        &:first-of-type {
          border-left: none;
        }
      }
    }
  }
`

interface props {
  zClaimID?: number | null
  fullWidth?: boolean
  doClose?(): void
  readOnly?: boolean
}

interface claimDetailContext {
  doReload(): void
  launchFastView(t: FastViewType | null, optProps?: any): void
  toggleLineTargeted(ZClaimLineID: number | null): void
}

export const ClaimDetailContext = React.createContext<claimDetailContext>(
  {} as claimDetailContext
)

export default function ZClaimDetail({
  zClaimID = null,
  fullWidth = true,
  doClose,
  readOnly = false,
}: props): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [clm, setClm] = useState<any>(null)
  const [changed, setChanged] = useState<any>({})
  const { showForDuration: showSnackbar } = useSnackbar()

  const [dirtyProcCodes, setDirtyProcCodes] = useState(false)
  const [dirtyDiagCodes, setDirtyDiagCodes] = useState(false)
  const [dirtyOtherFields, setDirtyOtherFields] = useState(false)
  const [dirtyLines, setDirtyLines] = useState(false)

  // refs
  const refTimeline = useRef(null) as any
  const refClaimLines = useRef(null) as any
  const refBtnAdjudicate = useRef(null) as any
  const refBtnAddClaimExCode = useRef(null) as any
  const refClaimDetailOtherFields = useRef(null) as any
  const refDiagnosisCodes = useRef(null) as any
  const refProcedureCodes = useRef(null) as any
  const refRelatedClaims = useRef(null) as any
  const refFastView = useRef(null) as any

  const isReadOnly = React.useMemo(() => {
    let ro = readOnly
    if (!!clm?.Claim?.ZClaimFundingStatus) {
      ro = true
    }
    if (clm?.Claim?.Detail?.IsReversal) {
      ro = true
    }
    return ro
  }, [clm, readOnly])

  useEffect(() => {
    if (!zClaimID) {
      setClm(null)
      return
    }
    loadClaim(zClaimID)
  }, [zClaimID])

  useHotKeys(
    { metaKey: true, key: '0' },
    (ev: any) => {
      if (isReadOnly) return
      refClaimLines?.current?.autoSetDefaultsWhereNull?.({
        useLineDateStart: clm?.Claim?.Detail?.StatementDateStart,
        useLineDateEnd: clm?.Claim?.Detail?.StatementDateEnd,
      })
    },
    [clm, isReadOnly]
  )

  useHotKeys(
    { metaKey: true, key: 's' },
    (ev: any) => {
      if (isReadOnly) return
      refBtnAdjudicate?.current?.click()
    },
    [clm, isReadOnly]
  )

  useHotKeys(
    { metaKey: true, key: 'e' },
    (ev: any) => {
      if (isReadOnly) return
      refBtnAddClaimExCode?.current?.click()
    },
    [clm, isReadOnly]
  )

  useHotKeys({ metaKey: true, key: 'Escape' }, (ev: any) => {
    doClose?.()
  })

  function loadClaim(zClaimID: number | null) {
    if (!zClaimID) return
    return getZClaimByID(zClaimID)
      .then(({ Data }: any) => {
        setClm(Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading ZClaim',
          withError(err: any) {
            setClm(null)
          },
        })
      )
  }

  // @todo: wrap in promise chain and return promise results; as well
  // as make all called functions return their promises
  function doReload() {
    loadClaim(zClaimID)?.then(() => {
      refTimeline?.current?.refresh()
      refClaimLines?.current?.refresh()
      refRelatedClaims?.current?.refresh()
    })
  }

  function onAssignClaimExCode(eco: ShapeExCodeOpt) {
    if (!zClaimID) return
    postAddClaimExCode(zClaimID, { ExCodeOptID: eco.ID })
      .then(doReload)
      .catch(
        catchAPIError({
          defaultMessage: 'Failed assigning claim ex code',
        })
      )
  }

  function handleClearExCode(eco: ShapeExCodeOpt) {
    if (!zClaimID) return
    postClearClaimExCode(eco.ID)
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar(`ExCode cleared OK`, SnackbarTypeSuccess)
      })
      .then(doReload)
      .catch(
        catchAPIError({
          defaultMessage: 'Failed clearing claim excode',
        })
      )
  }

  function makeOnClickClearSuspense(suspendedID: number): (ev: any) => void {
    return (ev: any) => {
      if (!zClaimID) return
      putZClaimClearSuspense(zClaimID, suspendedID)
        .then(doReload)
        .catch(
          catchAPIError({
            defaultMessage: 'Failed clearing this claim from suspense queue',
          })
        )
    }
  }

  function launchFastView(t: FastViewType, optProps?: any): void {
    refFastView?.current?.toggle(t, optProps)
  }

  function toggleLineTargeted(ZClaimLineID: number | null): void {
    refClaimLines?.current?.doToggleLineTargeted(ZClaimLineID)
  }

  function displaySourceType(): React.ReactElement {
    return (
      <span style={{ textTransform: 'uppercase', fontSize: '90%' }}>
        {clm?.Claim?.SourceType}
        {clm?.Claim?.SourceType === SourceTypes.SourceTypeVBA && (
          <span>&nbsp;&nbsp;| claim #: {clm?.Claim?.Detail?.ExtClaimID}</span>
        )}
      </span>
    )
  }

  function doPreAdjudicate(): Promise<any> {
    const pre = []
    if (dirtyDiagCodes) {
      pre.push(refDiagnosisCodes?.current?.doSave())
    }
    if (dirtyProcCodes) {
      pre.push(refProcedureCodes?.current?.doSave())
    }
    if (dirtyOtherFields) {
      pre.push(refClaimDetailOtherFields?.current?.doSave())
    }
    if (dirtyLines) {
      pre.push(refClaimLines?.current?.doSave())
    }
    return Promise.all(pre)
  }

  function renderDetails(): React.ReactElement {
    return (
      <div>
        <DesignSuite2023.GridLR
          left={
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <strong>Status:</strong>&nbsp;
              <DisplayStatus
                adjStatus={clm.Claim.ZClaimStatus}
                fundStatus={clm.Claim.ZClaimFundingStatus}
              />
            </span>
          }
          right={
            <>
              <div>
                <DesignSuite2023.Tooltip
                  title={<div>UUID: {clm.Claim.ZClaimUUID}</div>}>
                  <Chip label="Identifier" size="small" />
                </DesignSuite2023.Tooltip>
                &nbsp;&nbsp;
                {/* <strong>{clm.Claim.ID}</strong> */}
                <DisplayClaimID claimObj={clm.Claim} />
                &nbsp;&nbsp;&nbsp;
                <Chip label="Type" size="small" />
                &nbsp;&nbsp;
                <helpers.ClaimTypeDisplay claimType={clm.Claim.ClaimType} />
                &nbsp;&nbsp;&nbsp;
                <Chip label="Source" size="small" />
                &nbsp;&nbsp;
                {displaySourceType()}
                &nbsp;&nbsp;&nbsp;
                <Chip label="ExCodes" size="small" />{' '}
                <ExCodesDisplay
                  ref={refBtnAddClaimExCode}
                  list={clm.ClaimExCodes}
                  handleClearExCode={handleClearExCode}
                  onExCodeAssigned={onAssignClaimExCode}
                  readOnly={isReadOnly && !clm?.Claim?.Detail?.IsReversal}
                />
                &nbsp;&nbsp;&nbsp;
                <LogViewer uuid={clm.Claim.ZClaimUUID} />
              </div>
            </>
          }
        />

        <DesignSuite2023.Divider />

        <StyledFieldset>
          <legend>Claim Details</legend>

          <div className="mr-grid">
            <div className="col">
              <div className="subgrid c3">
                <helpers.FlexAlign
                  spaced
                  className="fullwidth"
                  style={{ marginBottom: '0.5rem' }}>
                  <h5>Member</h5>
                  {!isReadOnly && (
                    <div>
                      <AssignPatientOrSubscriber
                        zClaimID={clm?.Claim?.ID}
                        mode="patient"
                        onComplete={doReload}
                      />
                    </div>
                  )}
                </helpers.FlexAlign>

                {!!clm?.Claim?.PatientInfo?.EmployerMemberID ? (
                  <>
                    <div>
                      <strong className="block-bold">Patient</strong>
                      <DisplayLinked
                        text={clm?.Claim?.PatientInfo?.Name}
                        href={`/member/${clm?.Claim?.PatientInfo?.MemberID}?employerMemberID=${clm?.Claim?.PatientInfo?.EmployerMemberID}`}
                        onDoFastView={() =>
                          launchFastView(FastViewType.PATIENT)
                        }
                      />
                    </div>
                    <div>
                      <strong className="block-bold">DOB</strong>
                      {dateTime.parse(clm.Claim.PatientInfo?.DOB).format()}
                    </div>
                    <div>
                      <strong className="block-bold">
                        Activation/Deactivation
                      </strong>
                      <DateStartEnd
                        start={clm.Claim.PatientInfo.ActivationDate}
                        end={clm.Claim.PatientInfo.DeactivationDate}
                      />
                    </div>
                  </>
                ) : (
                  <div className="fullwidth">
                    <strong className="block-bold">Patient</strong>
                    NO LINKED PATIENT
                  </div>
                )}

                <DesignSuite2023.Divider className="fullwidth" />

                {!!(
                  clm.Claim?.PatientInfo.EmployerMemberID ===
                  clm.Claim?.SubscriberInfo?.EmployerMemberID
                ) ? (
                  <div className="fullwidth">
                    <strong className="block-bold">Subscriber</strong>
                    Same as Patient
                  </div>
                ) : (
                  <>
                    <div>
                      <strong className="block-bold">Subscriber</strong>
                      <DisplayLinked
                        text={clm?.Claim?.SubscriberInfo?.Name}
                        href={`/member/${clm?.Claim?.SubscriberInfo?.MemberID}?employerMemberID=${clm?.Claim?.SubscriberInfo?.EmployerMemberID}`}
                        onDoFastView={() =>
                          launchFastView(FastViewType.SUBSCRIBER)
                        }
                      />
                    </div>
                    <div>
                      <strong className="block-bold">DOB</strong>
                      {dateTime.parse(clm.Claim.SubscriberInfo?.DOB).format()}
                    </div>
                    <div>
                      <strong className="block-bold">
                        Activation/Deactivation
                      </strong>
                      <DateStartEnd
                        start={clm.Claim.SubscriberInfo.ActivationDate}
                        end={clm.Claim.SubscriberInfo.DeactivationDate}
                      />
                    </div>
                  </>
                )}

                <div className="fullwidth">
                  <helpers.FlexAlign>
                    <div style={{ paddingRight: '0.25rem' }}>
                      <SubdirectoryArrowRight />
                    </div>
                    <div style={{ flex: 1 }}>
                      <DesignSuite2023.Divider
                        style={{ margin: '0.5rem 0 0.5rem' }}
                      />
                      {!!clm?.Employer?.ID ? (
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto',
                            gridColumnGap: '1rem',
                            justifyContent: 'start',
                          }}>
                          <div>
                            <strong className="block-bold">Employer</strong>
                            <DisplayLinked
                              text={clm?.Employer?.Name}
                              href={`/employer/${clm?.Employer?.ID}`}
                              onDoFastView={() =>
                                launchFastView(FastViewType.EMPLOYER)
                              }
                            />
                          </div>
                          <div>
                            <strong className="block-bold">Launch/Term</strong>
                            <DateStartEnd
                              start={clm?.Employer.LaunchDate}
                              end={clm?.Employer.TerminationDate}
                            />
                          </div>
                          <div style={{ gridColumn: '1/-1' }}>
                            <Typography color="textSecondary" variant="caption">
                              (employer derives from subscriber)
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <strong className="block-bold">Employer</strong>
                          Unassigned (depends on Subscriber)
                        </div>
                      )}
                    </div>
                  </helpers.FlexAlign>
                </div>
              </div>
            </div>

            <div className="col bordered">
              <div className="subgrid c2">
                <helpers.FlexAlign
                  spaced
                  className="fullwidth"
                  style={{ marginBottom: '0.5rem' }}>
                  <h5>Provider ("Organization")</h5>
                  {!isReadOnly && (
                    <div>
                      <AssignBillingEntity
                        zClaimID={clm?.Claim?.ID}
                        onComplete={doReload}
                      />
                    </div>
                  )}
                </helpers.FlexAlign>

                {!!clm?.Claim?.BillingEntityInfo?.ID ? (
                  <>
                    <div style={{ paddingBottom: '0.35rem' }}>
                      <strong className="block-bold">Billing Entity</strong>
                      <div>
                        <DisplayLinked
                          text={clm?.Claim?.BillingEntityInfo?.Name}
                          // href={`/organization/${clm?.Claim?.OrganizationInfo?.ID}/billing_entity/${clm?.Claim?.BillingEntityInfo?.ID}`}
                          onDoFastView={() =>
                            launchFastView(FastViewType.BILLING_ENTITY)
                          }
                        />
                      </div>
                    </div>
                    <div style={{ paddingBottom: '0.35rem' }}>
                      <strong className="block-bold">Launch/Term</strong>
                      <DateStartEnd
                        start={clm.Claim.BillingEntityInfo.DateStart}
                        end={clm.Claim.BillingEntityInfo.DateEnd}
                      />
                    </div>
                    <div
                      className="fullwidth"
                      style={{
                        padding: '0 0 0.35rem 0.5rem',
                        fontSize: '12px',
                      }}>
                      <strong>TIN:</strong> {clm.Claim.BillingEntityInfo.TIN}{' '}
                      |&nbsp;
                      <strong>NPI:</strong> {clm.Claim.BillingEntityInfo.NPI}
                    </div>
                    <div
                      className="fullwidth"
                      style={{
                        padding: '0 0 0.35rem 0.5rem',
                        fontSize: '12px',
                      }}>
                      <DisplayAddress
                        id={clm?.Claim?.BillingEntityInfo?.RemittanceAddressID}
                        asLink={false}
                        style={{ whiteSpace: 'nowrap' }}
                      />
                    </div>
                    {clm?.Claim?.BillingEntityInfo?.IsRetired === true ||
                    clm?.Claim?.BillingEntityInfo?.IsLatest !== true ? (
                      <div className="fullwidth">
                        <Typography color="error" variant="caption">
                          Current billing entity is outdated or retired.
                        </Typography>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div
                    className="fullwidth"
                    style={{ paddingBottom: '0.35rem' }}>
                    <strong className="block-bold">Billing Entity</strong>
                    NO LINKED BILLING ENTITY
                  </div>
                )}

                {!!clm?.Claim?.OrganizationInfo?.ID ? (
                  <>
                    <div>
                      <strong className="block-bold">Organization</strong>
                      <DisplayLinked
                        text={clm?.Claim?.OrganizationInfo?.Name}
                        href={`/organization/${clm?.Claim?.OrganizationInfo?.ID}`}
                        onDoFastView={() =>
                          launchFastView(FastViewType.ORGANIZATION)
                        }
                      />
                    </div>
                    <div>
                      <strong className="block-bold">Launch/Term</strong>
                      <DateStartEnd
                        start={clm.Claim.OrganizationInfo.LaunchDate}
                        end={clm.Claim.OrganizationInfo.TerminationDate}
                      />
                    </div>
                  </>
                ) : !!clm?.Claim?.BillingEntityInfo?.ID &&
                  clm?.Claim?.BillingEntityInfo?.IsContracted === false ? (
                  <div className="fullwidth">
                    <strong className="block-bold">Organization</strong>
                    <span style={{ color: 'red' }}>
                      None (Uncontracted Billing Entity)
                    </span>
                  </div>
                ) : (
                  <div className="fullwidth">
                    <strong className="block-bold">Organization</strong>
                    (DETERMINED BY BILLING ENTITY)
                  </div>
                )}

                <DesignSuite2023.Divider className="fullwidth" />

                <helpers.FlexAlign spaced className="fullwidth">
                  <h5>Facility</h5>
                  {!isReadOnly && (
                    <div>
                      <AssignPracticeFacility
                        zClaimID={clm?.Claim?.ID}
                        buttonDisabled={helpers.falsy(
                          clm?.Claim?.BillingEntityInfo?.IsContracted
                        )}
                        onComplete={doReload}
                      />
                    </div>
                  )}
                </helpers.FlexAlign>

                {!!clm?.Claim?.PracticeFacilityInfo?.ID ? (
                  <>
                    <div>
                      {/* <strong className='block-bold'>Facility</strong> */}
                      <DisplayLinked
                        text={clm?.Claim?.PracticeFacilityInfo?.Name}
                        href={`/organization/${clm?.Claim?.OrganizationInfo?.ID}/practice_facility/${clm?.Claim?.PracticeFacilityInfo?.ID}`}
                        onDoFastView={() =>
                          launchFastView(FastViewType.PRACTICE_FACILITY)
                        }
                      />
                    </div>
                    <div>
                      <strong className="block-bold">Launch/Term</strong>
                      <DateStartEnd
                        start={clm.Claim.PracticeFacilityInfo.LaunchDate}
                        end={clm.Claim.PracticeFacilityInfo.TerminationDate}
                      />
                    </div>
                  </>
                ) : (
                  <div className="fullwidth">
                    {/* <strong className='block-bold'>Facility</strong> */}
                    No Linked Facility
                  </div>
                )}

                <div className="fullwidth">
                  <helpers.FlexAlign>
                    <div style={{ paddingRight: '0.25rem' }}>
                      <SubdirectoryArrowRight />
                    </div>
                    <div style={{ flex: 1 }}>
                      <DesignSuite2023.Divider
                        style={{ margin: '0.5rem 0 0.5rem' }}
                      />
                      {!!clm?.FeeSchedule?.ID ? (
                        <>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto auto',
                              gridColumnGap: '1rem',
                              justifyContent: 'start',
                            }}>
                            <div>
                              <strong className="block-bold">
                                Fee Schedule
                              </strong>
                              <DisplayLinked
                                text={clm?.FeeSchedule?.Name}
                                href={`/fee_schedule/${clm?.FeeSchedule?.ID}`}
                                onDoFastView={() =>
                                  launchFastView(FastViewType.FEE_SCHEDULE)
                                }
                              />
                            </div>
                            <div>
                              <strong className="block-bold">
                                Activation/Deactivation
                              </strong>
                              <DateStartEnd
                                start={clm?.FeeSchedule?.DateStart}
                                end={clm?.FeeSchedule?.DateEnd}
                              />
                            </div>
                            {!isReadOnly && (
                              <div>
                                <FindAndLinkFeeSchedule
                                  zClaimID={clm?.Claim?.ID}
                                  organizationID={
                                    clm?.Claim?.OrganizationInfo?.ID
                                  }
                                  onSelected={doReload}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <div>
                            <strong className="block-bold">Fee Schedule</strong>
                            <Typography color="textSecondary" variant="caption">
                              Unassigned (depends on Provider)
                            </Typography>
                          </div>
                          {!isReadOnly && (
                            <div>
                              <FindAndLinkFeeSchedule
                                zClaimID={clm?.Claim?.ID}
                                organizationID={
                                  clm?.Claim?.OrganizationInfo?.ID
                                }
                                practiceFacilityID={
                                  clm?.Claim?.PracticeFacilityInfo?.ID
                                }
                                onSelected={doReload}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </helpers.FlexAlign>
                </div>
              </div>
            </div>

            <div className="col bordered">
              <DisplayOtherDetailFields
                detail={clm?.Claim?.Detail}
                zClaimID={clm?.Claim?.ID}
                claimType={clm?.Claim?.ClaimType}
                sourceType={clm?.Claim?.SourceType}
                ref={refClaimDetailOtherFields}
                title={<span>Claim Info</span>}
                readOnly={isReadOnly}
                markDirty={setDirtyOtherFields}
              />
              <DesignSuite2023.Divider style={{ margin: '0.5rem 0' }} />

              {clm?.Claim.SourceType !== SourceTypes.SourceTypeRxngo && (
                <>
                  <DisplayCodesList
                    readOnly={isReadOnly}
                    action={displayCodeListActions.putZClaimDiagnosisCodes}
                    markDirty={setDirtyDiagCodes}
                    zClaimID={zClaimID}
                    ref={refDiagnosisCodes}
                    codes={clm.ClaimDetailCodes?.DiagnosisCodes}
                    title={
                      <>
                        <span>Diagnosis Codes</span>&nbsp;
                        <small>
                          (A) is the{' '}
                          <i>
                            <strong>principal</strong> diagnosis code
                          </i>
                        </small>
                      </>
                    }
                    onCodeChange={(codes: string[]) => {
                      setChanged((curr: any) => {
                        const x = { ...curr }
                        if (
                          (
                            clm.ClaimDetailCodes?.DiagnosisCodes || []
                          ).toString() === codes.toString()
                        ) {
                          delete x['ClaimDetailCodes.DiagnosisCodes']
                          return x
                        }
                        x['ClaimDetailCodes.DiagnosisCodes'] = codes
                        return x
                      })
                    }}
                  />
                  {clm.ClaimDetailCodes?.ProcedureCodes && (
                    <>
                      <DesignSuite2023.Divider style={{ margin: '0.5rem 0' }} />
                      <DisplayCodesList
                        readOnly={isReadOnly}
                        action={displayCodeListActions.putZClaimProcedureCodes}
                        markDirty={setDirtyProcCodes}
                        zClaimID={zClaimID}
                        ref={refProcedureCodes}
                        codes={clm.ClaimDetailCodes?.ProcedureCodes}
                        title={
                          <>
                            <span>Procedure Codes</span>&nbsp;
                            <small>
                              (A) is the{' '}
                              <i>
                                <strong>principal</strong> procedure code
                              </i>
                            </small>
                          </>
                        }
                        onCodeChange={(codes: string[]) => {
                          setChanged((curr: any) => {
                            const x = { ...curr }
                            if (
                              (
                                clm.ClaimDetailCodes?.ProcedureCodes || []
                              ).toString() === codes.toString()
                            ) {
                              delete x['ClaimDetailCodes.ProcedureCodes']
                              return x
                            }
                            x['ClaimDetailCodes.ProcedureCodes'] = codes
                            return x
                          })
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </StyledFieldset>

        <ClaimLinesDisplay
          ref={refClaimLines}
          zClaimID={clm?.Claim?.ID}
          claimType={clm?.Claim?.ClaimType}
          diagnosisCodes={
            changed?.['ClaimDetailCodes.DiagnosisCodes'] ||
            clm?.ClaimDetailCodes?.DiagnosisCodes
          }
          isReversal={clm?.Claim?.Detail?.IsReversal}
          readOnly={isReadOnly}
          markDirty={setDirtyLines}
          claimStatus={clm?.Claim?.ZClaimStatus}
          summary={{ SumPayable: clm?.Claim?.ComputedSummary?.SumPayable }}
          sourceType={clm?.Claim?.SourceType}
          reloadEntireClaim={doReload}
        />

        <LimitsInfo zClaimID={clm?.Claim?.ID} />

        <DisplayRelatedClaims
          zClaimID={clm?.Claim?.ID}
          ref={refRelatedClaims}
        />

        {/* <pre>{JSON.stringify(savePreAdj, null, '  ')}</pre> */}
        {/* <pre>{JSON.stringify(changed, null, '  ')}</pre> */}
        {/* <pre>{JSON.stringify(clm, null, '  ')}</pre> */}
      </div>
    )
  }

  function renderSidebar(): React.ReactElement {
    return (
      <DesignSuite2023.LayoutSidebar width="240px" stickyTop={false}>
        <div className="sidebar-actions">
          <RenderActionButtons
            clm={clm}
            doReload={doReload}
            doPreAdjudicate={doPreAdjudicate}
            refBtnAdjudicate={refBtnAdjudicate}
            hasChanges={
              dirtyProcCodes || dirtyDiagCodes || dirtyOtherFields || dirtyLines
            }
          />
        </div>
        <DesignSuite2023.Divider />
        <div className="sidebar-actions">
          <LinkReferrals
            zClaimID={zClaimID}
            employerMemberID={clm.Claim?.PatientInfo.EmployerMemberID}
          />
          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={() => launchFastView(FastViewType.CLAIM_ETC)}>
            View Ancillary Info
          </Button>
          <ViewSource
            zClaimID={zClaimID}
            Trigger={(props: any) => (
              <Button
                {...props}
                className="no-margin"
                variant="outlined"
                size="small"
                fullWidth>
                View Source
              </Button>
            )}
          />
        </div>
        <DesignSuite2023.Divider />
        <div style={{ padding: '0 1rem' }}>
          <div
            style={{
              padding: '0.5rem',
              fontSize: 12,
              background: '#f1f1f1',
              color: '#888',
              borderRadius: '5px',
              marginTop: '1rem',
            }}>
            <strong>Keyboard Shortcuts:</strong>
            <div>
              <code>CMD+S</code>: Save &amp; adjudicate claim
            </div>
            <div>
              <code>CMD+E</code>: Add claim-level ExCode
            </div>
            <div>
              <code>CMD+0</code>: Zero-out allowed amounts where not repriced by
              fee schedule &amp; auto-set line service dates from claim
              statement dates (if applicable)
            </div>
            <div>
              <code>CMD+ESC</code>: Close claim
            </div>
          </div>
        </div>
        <DesignSuite2023.Divider />
        <div style={{ paddingLeft: '1rem' }}>
          <ClaimTimeline
            zClaimID={clm.Claim.ID}
            currentStatus={clm.Claim.ZClaimStatus}
            ref={refTimeline}
          />
        </div>
      </DesignSuite2023.LayoutSidebar>
    )
  }

  if (!clm) {
    return (
      <StyledComponent>
        <CircularProgress />
      </StyledComponent>
    )
  }

  // these would normally be computed inline; either way they're evaluated every time
  // const employerIsCurrentlyActive = dateTime.validationFns.isActive(clm?.Employer?.LaunchDate, clm?.Employer?.TerminationDate)
  // const feeScheduleIsCurrentlyActive = dateTime.validationFns.isActive(clm?.FeeSchedule?.DateStart, clm?.FeeSchedule?.DateEnd)

  return (
    <ClaimDetailContext.Provider
      value={{
        doReload,
        launchFastView,
        toggleLineTargeted,
      }}>
      <StyledComponent>
        <DesignSuite2023.LayoutContainer
          style={{
            margin: '0 auto',
            width: fullWidth ? '100%' : 'fit-content',
          }}>
          {renderSidebar()}

          <DesignSuite2023.LayoutPrimary
            style={{
              flex: 1,
              padding: '1rem 2rem',
              margin: '0',
              ...(fullWidth
                ? { width: '100%', maxWidth: '100%' }
                : { width: 960, maxWidth: 960 }),
            }}>
            {/* {renderSuspenseAlert()} */}
            <RenderAlerts
              clm={clm}
              isReadOnly={isReadOnly}
              makeOnClickClearSuspense={makeOnClickClearSuspense}
            />
            {renderDetails()}
          </DesignSuite2023.LayoutPrimary>
        </DesignSuite2023.LayoutContainer>

        {/* <DesignSuite2023.Drawer
        variant="persistent"
        anchor="left"
        open={!!fastViewComponent}
        onClose={() => setFastViewComponent(null)}
        width="20vw"
        paperTransparent>
        <FastView
          kind={fastViewComponent}
          zClaimID={zClaimID}
          claim={clm}
          doClose={() => setFastViewComponent(null)}
        />
      </DesignSuite2023.Drawer> */}
        <FastView ref={refFastView} zClaimID={zClaimID} claim={clm} />
      </StyledComponent>
    </ClaimDetailContext.Provider>
  )
}

function DisplayLinked({
  text,
  href,
  onDoFastView,
}: any): React.ReactElement | null {
  if (!text) return null
  return (
    <>
      <DesignSuite2023.Tooltip title="Open in fast-view sidebar">
        <span
          onClick={onDoFastView}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}>
          {text}
        </span>
      </DesignSuite2023.Tooltip>
      {!!href && (
        <>
          &nbsp;
          <DesignSuite2023.Tooltip title="Open in new window">
            <a target="_blank" href={href} rel="noopener noreferrer">
              <DesignSuite2023.CommonIcons.IconOpenInNew fontSize="inherit" />
            </a>
          </DesignSuite2023.Tooltip>
        </>
      )}
    </>
  )
}

function DateStartEnd({ start, end }: any): React.ReactElement {
  return (
    <helpers.FlexAlign>
      {!!start ? dateTime.parse(start).format() : '-'}
      &nbsp;
      <DesignSuite2023.CommonIcons.IconArrowRight fontSize="small" />
      &nbsp;
      {!!end ? dateTime.parse(end).format() : '-'}
    </helpers.FlexAlign>
  )
}

function RenderAlerts({
  clm,
  isReadOnly,
  makeOnClickClearSuspense,
}: {
  clm: any
  isReadOnly: boolean
  makeOnClickClearSuspense: (suspendedID: number) => (ev: any) => void
}): React.ReactElement | null {
  const alerts = []
  let showIsReversal = clm?.Claim?.Detail?.IsReversal === true
  let showSuspensions = clm?.Claim?.IsSuspended === true
  let showReadOnly = isReadOnly
  const [canClearSuspense] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )

  if (showIsReversal || showSuspensions) {
    showReadOnly = false
  }

  if (showReadOnly) {
    alerts.push(
      <DesignSuite2023.AlertInfo
        key="read-only"
        style={{ marginBottom: '0.5rem' }}>
        <span>
          This claim is in read-only mode (editing of any kind is disabled).
        </span>
      </DesignSuite2023.AlertInfo>
    )
  }

  if (clm?.Claim?.IsInTrash) {
    alerts.push(
      <DesignSuite2023.AlertError
        key="in-trash"
        style={{ marginBottom: '0.5rem' }}>
        <span>
          This claim is marked as trash; the info displayed here should not be
          trusted. If this is a legacy/VBA claim, it may have been copied to a
          new claim and reprocessed.
        </span>
      </DesignSuite2023.AlertError>
    )
  }

  if (showIsReversal) {
    alerts.push(
      <DesignSuite2023.AlertWarning
        key="reversal-notice"
        style={{ marginBottom: '0.5rem' }}>
        <span>
          <strong>Notice:</strong> This is a <strong>reversal claim</strong>;
          meaning it is intended to undo (in full) the charges of a previous
          claim. <i>No edits can be made to this claim.</i>
        </span>
      </DesignSuite2023.AlertWarning>
    )
  }

  if (showSuspensions) {
    Object.keys(clm?.Suspensions || {}).forEach((reason: string) => {
      const record = clm?.Suspensions[reason]
      if (!record) return
      if (record.IsResolved) {
        alerts.push(
          <DesignSuite2023.AlertSuccess
            key={`in-suspense-cleared-${reason}`}
            className="top-alert">
            <span>
              Suspense code: <code>{record.Reason}</code> was cleared{' '}
              <strong>
                {dateTime
                  .parse(record.CreatedAt)
                  .format(dateTime.formats.PrettyVerbose)}
              </strong>{' '}
              by {record.CreatedByUserEmail}.
            </span>
          </DesignSuite2023.AlertSuccess>
        )
        return
      }
      alerts.push(
        <DesignSuite2023.AlertWarning
          key={`in-suspense-open-${reason}`}
          className="top-alert"
          action={
            <Button
              disabled={!canClearSuspense}
              size="small"
              variant="outlined"
              onClick={makeOnClickClearSuspense(record.ID)}>
              Approve
            </Button>
          }>
          <span>
            <strong>This claim is currently suspended</strong> (reason code:{' '}
            <code>{record.Reason}</code>). You can either{' '}
            <strong>Approve</strong> to clear this suspense code, or revert to
            adjudication and edit the claim.
          </span>
        </DesignSuite2023.AlertWarning>
      )
    })
  }

  return <>{alerts}</>
}

function RenderActionButtons({
  clm,
  doReload,
  doPreAdjudicate,
  refBtnAdjudicate,
  hasChanges,
}: {
  clm: any
  doReload: any
  doPreAdjudicate: any
  refBtnAdjudicate: any
  hasChanges: boolean
}): React.ReactElement | null {
  if (!clm?.Claim) return null
  const adjStatus = clm.Claim.ZClaimStatus
  const fundStatus = clm.Claim.ZClaimFundingStatus
  const isClone = clm.Claim.SourceType === SourceTypes.SourceTypeClone
  // const isReversal = clm.Claim.Detail?.IsReversal

  // can show the "adjudicate" button if claim is not in any funding status
  let showAdjudicate = !fundStatus
  // can show the "process claim" button if funding status is empty
  let showMoveToFunding = !fundStatus
  // can show "issue claim adjustment" button if claim already in funding with status past "in_process",
  // and the claim is not a cloned one (ie. already a refund/adjustment claim)
  let showAdjust =
    !!fundStatus &&
    fundStatus !== FundStatuses.in_process &&
    !isClone &&
    !clm?.Claim?.IsInTrash
  // can show "do process" button if claim is in "in_process" funding status
  let showDoProcess = fundStatus === FundStatuses.in_process
  // the only time a claim can be "sent back" to adjudication is when it has funding status 'in_process
  let showSendBackToAdjudication = fundStatus === FundStatuses.in_process

  if (adjStatus === AdjStatuses.error || adjStatus === AdjStatuses.pended) {
    showMoveToFunding = false
  }
  if (clm?.Claim?.IsSuspended) {
    showDoProcess = false
  }
  // SD-2267: the ability to process a claim is predicated on having the role, and that's very important...
  // need to come back here and implement role check once available to show/hide the DoProcess button

  const buttons = []

  showAdjudicate &&
    buttons.push({
      k: 'btn-adj',
      c: (
        <ButtonDoAdjudicate
          ref={refBtnAdjudicate}
          zClaimIDs={[clm.Claim.ID]}
          onComplete={doReload}
          before={doPreAdjudicate}
        />
      ),
    })

  showMoveToFunding &&
    buttons.push({
      k: 'btn-move-fund',
      c: (
        <ButtonMoveToFunding
          zClaimIDs={[clm.Claim.ID]}
          onComplete={doReload}
          disabled={hasChanges}
        />
      ),
    })

  showDoProcess &&
    buttons.push({
      k: 'btn-do-proc',
      c: <ButtonDoProcess zClaimIDs={[clm.Claim.ID]} onComplete={doReload} />,
    })

  showSendBackToAdjudication &&
    buttons.push({
      k: 'btn-send-back',
      c: (
        <ButtonDoSendBackToAdjudication
          zClaimIDs={[clm.Claim.ID]}
          onComplete={doReload}
        />
      ),
    })

  showAdjust &&
    buttons.push({
      k: 'btn-reversal',
      c: (
        <ClaimReversalDialog
          // zClaimID={clm.Claim.ID}
          clm={clm}
          onComplete={doReload}
        />
      ),
    })

  if (buttons.length === 0) return <small>No claim actions available</small>

  return (
    <>
      {buttons.map((item: any) => (
        <div key={item.k}>{item.c}</div>
      ))}
    </>
  )
}
