import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
} from '@material-ui/core'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import DesignSuite2023 from '../../components/DesignSuite2023'
import styled from 'styled-components'

const StyledCols = styled.div`
  padding: 1rem 0 0;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  align-items: center;

  &.missing-tables {
    grid-template-columns: min-content 1fr min-content;
  }

  &.missing-cols {
    grid-template-columns: min-content 1fr min-content min-content min-content min-content;
  }

  strong.header {
    position: sticky;
    top: 0;
    background: #f1f1f1;
    padding: 0.5rem;
    z-index: 2;
  }
`

export default function DataDictionary(): any {
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [missingTables, setMissingTables] = useState<any>([])
  const [missingColumns, setMissingColumns] = useState<any>([])
  const [goners, setGoners] = useState<any>({ Tables: [], Columns: [] })
  const [tab, setTab] = useState<string>('tbls')
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (isOpen) return
    setLoading(false)
    setMissingTables([])
    setMissingColumns([])
    setGoners({ Tables: [], Columns: [] })
    setTab('tbls')
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    loadAll()
  }, [isOpen, showSnackbar, setMissingTables, setMissingColumns, setGoners])

  function loadAll() {
    setLoading(true)
    Promise.all([
      api.get('/engineering/data_dictionary/missing'),
      api.get('/engineering/data_dictionary/deprecated_or_removed'),
    ])
      .then(([resA, resB]: [any, any]) => {
        if (resA.error) {
          throw resA
        }
        if (resB.error) {
          throw resB
        }
        if (Array.isArray(resA.Data?.Tables)) {
          setMissingTables(
            resA.Data.Tables.map((t: any) => {
              return { ...t, Description: '' }
            })
          )
        }
        if (Array.isArray(resA.Data?.Columns)) {
          setMissingColumns(
            resA.Data.Columns.map((c: any) => {
              let descr = ''
              switch (c.ColumnName) {
                case `id`:
                  descr = 'unique identifier (primary key)'
                  break
                case `updated_at`:
                  descr = 'timestamp record last updated'
                  break
                case `deleted_at`:
                  descr = 'timestamp record deleted'
                  break
                case `created_at`:
                  descr = 'timestamp record originally created'
                  break
                case `created_by_user_id`:
                  descr = '(FK) reference to user.id that created record'
                  break
                case `modified_by_user_id`:
                  descr = '(FK) reference to user.id that last modified record'
                  break
              }
              return {
                ...c,
                Description: descr,
                IsMedical: false,
                IsPII: false,
                IsEncrypted: false,
              }
            })
          )
        }
        setGoners((n: any) => {
          const next = { ...n }
          next.Tables = resB.Data?.Tables || []
          next.Columns = resB.Data?.Columns || []
          return next
        })
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbar(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbar(
          'Request failed; please contact engineering',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function markDeprecatedRemovedDeleted() {
    api
      .post(
        '/engineering/data_dictionary/mark_deprecated_removed_deleted',
        goners
      )
      .then(() => {
        showSnackbar('OK (refresh required)', SnackbarTypeSuccess)
      })
      .then(() => {
        return loadAll()
      })
  }

  function rmMissingTable(index: number) {
    setMissingTables((n: any) => {
      n.splice(index, 1)
      return [...n]
    })
    showSnackbar('Saved OK', SnackbarTypeSuccess)
  }

  function rmMissingColumn(index: number) {
    setMissingColumns((n: any) => {
      n.splice(index, 1)
      return [...n]
    })
    showSnackbar('Saved OK', SnackbarTypeSuccess)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={setIsOpen.bind(null, true)}>
        Manage Data Dictionary
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        maxWidth={false}>
        <DialogTitle style={{ background: '#e1e1e1' }}>
          <DesignSuite2023.GridLR
            left={<>Manage Data Dictionary</>}
            right={
              <>
                {loading && (
                  <>
                    <DesignSuite2023.LoadingSpinner />
                    &nbsp;&nbsp;
                  </>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={() => setIsOpen(false)}>
                  Close
                </Button>
              </>
            }
          />
        </DialogTitle>
        <DialogContent
          style={{ width: '95vw', maxWidth: '1200px', padding: '1rem' }}>
          <DesignSuite2023.StyledTabs
            value={tab}
            variant="fullWidth"
            marginless>
            <DesignSuite2023.StyledTab
              value="tbls"
              label="Missing Tables"
              onClick={() => setTab('tbls')}
            />
            <DesignSuite2023.StyledTab
              value="cols"
              label="Missing Columns"
              onClick={() => setTab('cols')}
            />
            <DesignSuite2023.StyledTab
              value="deps"
              label="Deprecated or Removed"
              onClick={() => setTab('deps')}
            />
          </DesignSuite2023.StyledTabs>

          {tab === 'tbls' && (
            <>
              <StyledCols className="missing-tables">
                <strong className="header">Table Name</strong>
                <strong className="header">Description</strong>
                <strong className="header">Save</strong>
                {missingTables?.length === 0 && (
                  <div style={{ gridColumn: '1/-1' }}>No missing tables</div>
                )}
                {missingTables?.map((t: any, index: number) => {
                  return (
                    <MissingTable
                      key={t.TableName}
                      rec={t}
                      onDone={() => rmMissingTable(index)}
                    />
                  )
                })}
              </StyledCols>
            </>
          )}

          {tab === 'cols' && (
            <>
              <StyledCols className="missing-cols">
                <small style={{ display: 'block', gridColumn: '1/-1' }}>
                  Some descriptions are pre-populated with suggestions; you must
                  still save these for them to persist.
                </small>
                <strong className="header">Table/Column Name</strong>
                <strong className="header">Description</strong>
                <strong className="header">IsMedical</strong>
                <strong className="header">IsPII</strong>
                <strong className="header">IsEncrypted</strong>
                <strong className="header">Save</strong>
                {missingColumns?.length === 0 && (
                  <div style={{ gridColumn: '1/-1' }}>No missing columns</div>
                )}
                {missingColumns?.map((c: any, index: number) => {
                  return (
                    <MissingColumn
                      key={`${c.TableName}_${c.ColumnName}`}
                      rec={c}
                      onDone={() => rmMissingColumn(index)}
                    />
                  )
                })}
              </StyledCols>
            </>
          )}

          {tab === 'deps' && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                }}>
                <div>
                  <h5 style={{ gridColumn: '1/-1' }}>Tables</h5>
                  {goners?.Tables?.map((t: any) => {
                    return <div key={t.TableName}>{t.TableName}</div>
                  })}

                  <h5 style={{ gridColumn: '1/-1' }}>Columns</h5>
                  {goners?.Columns?.map((c: any) => {
                    return (
                      <div key={`${c.TableName}.${c.ColumnName}`}>
                        {c.TableName}
                        <strong>.{c.ColumnName}</strong>
                      </div>
                    )
                  })}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    position: 'sticky',
                    right: 0,
                    top: 10,
                    marginTop: 10,
                  }}
                  onClick={markDeprecatedRemovedDeleted}>
                  Acknowledge All And Stop Tracking
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

function MissingTable({ rec, onDone }: any) {
  const [data, setData] = useState<any>(rec)

  function doSave() {
    api
      .post('/engineering/data_dictionary/missing', {
        Tables: [data],
      })
      .then(onDone)
  }

  if (rec.TableName?.includes('_temp') || !data) return null

  return (
    <>
      <div>{data.TableName}</div>
      <div>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          margin="none"
          placeholder="Description"
          value={data.Description || ''}
          onChange={(ev: any) => {
            setData((curr: any) => ({ ...curr, Description: ev.target.value }))
          }}
        />
      </div>
      <Button
        disabled={!data.Description}
        color="primary"
        variant="contained"
        onClick={doSave}>
        Save
      </Button>
    </>
  )
}

function MissingColumn({ rec, onDone }: any) {
  const [data, setData] = useState<any>(rec)

  function doSave() {
    api
      .post('/engineering/data_dictionary/missing', {
        Columns: [data],
      })
      .then(onDone)
  }

  if (rec.TableName?.includes('_temp')) return null

  return (
    <>
      <div>
        {data.TableName}
        <strong>.{data.ColumnName}</strong>
      </div>
      <div>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          margin="none"
          placeholder="Description"
          value={data.Description || ''}
          onChange={(ev: any) => {
            setData((curr: any) => ({ ...curr, Description: ev.target.value }))
          }}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Checkbox
          value={data.IsMedical}
          onChange={(ev: any) => {
            setData((curr: any) => ({ ...curr, IsMedical: ev.target.checked }))
          }}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Checkbox
          value={data.IsPII}
          onChange={(ev: any) => {
            setData((curr: any) => ({ ...curr, IsPII: ev.target.checked }))
          }}
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Checkbox
          value={data.IsEncrypted}
          onChange={(ev: any) => {
            setData((curr: any) => ({
              ...curr,
              IsEncrypted: ev.target.checked,
            }))
          }}
        />
      </div>
      <Button
        disabled={!data.Description}
        color="primary"
        variant="contained"
        onClick={doSave}>
        Save
      </Button>
    </>
  )
}
