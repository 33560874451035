import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewPaidDetailOverlay from './ViewPaidDetailOverlay'
//@ts-ignore
import styled from 'styled-components'
import { findPayments } from '../../../actions/ZClaimFundingActions'
import dateTime from '../../../utils/dateTime'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

interface paidTableRecord {
  PaymentID: string
  PaymentType: string
  Date: string | null
  Amount: string
  PayeeName: string
  PayeeType: string
  PayEntityAddress: string
  ClaimCount: number
  InvoiceItemCount: number
  ManualPayType: string
  CheckNumber: number
  IsVoid: boolean
}

export const defaultColumns = Object.freeze({
  ViewPaidDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: paidTableRecord): any {
        return <InlineViewPaidDetail data={row} />
      },
    },
  },
  PaymentID: { name: 'ID' },
  Date: { name: 'Date', details: { dataFormat: dateTime.cellFormatter() } },
  PayeeType: { name: 'Payee Type' },
  PayEntityName: {
    name: 'Payee',
    details: {
      dataFormat(_: any, row: paidTableRecord): any {
        return (
          <div>
            <div>{row.PayeeName}</div>
            <div
              style={{
                fontSize: '0.875em',
                fontStyle: 'italic',
                color: 'secondary',
              }}>
              {row.PayEntityAddress}
            </div>
          </div>
        )
      },
    },
  },
  PaymentType: {
    name: 'Method',
    details: {
      dataFormat(_: any, row: paidTableRecord): any {
        let paymentTypeDisplay = row.PaymentType

        if (row.PaymentType.toLowerCase() === 'manual') {
          paymentTypeDisplay = `${row.PaymentType}${row.ManualPayType.toLowerCase() === 'ach' ? ' ACH' : ''}`
        } else if (row.PaymentType.toLowerCase() === 'zelis_file') {
          paymentTypeDisplay = 'Zelis'
        }

        return (
          <span style={{ color: row.IsVoid ? 'red' : 'inherit' }}>
            {row.IsVoid ? '[Void] ' : ''}
            {paymentTypeDisplay}
            {row.CheckNumber && ` check ${row.CheckNumber}`}
          </span>
        )
      },
    },
  },
  ClaimCount: {
    name: 'Claims',
    details: {
      dataFormat(_: any, row: paidTableRecord): JSX.Element {
        return <>{row.ClaimCount !== 0 && row.ClaimCount}</>
      },
    },
  },
  InvoiceItemCount: {
    name: 'Other',
    details: {
      dataFormat(_: any, row: paidTableRecord): JSX.Element {
        return <>{row.InvoiceItemCount !== 0 && row.InvoiceItemCount}</>
      },
    },
  },

  Amount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: paidTableRecord): string {
        return formatAmount(row.Amount)
      },
    },
  },
})

enum paidPayeeType {
  All = 'all',
  Employer = 'employer',
  BillingEntity = 'billing_entity',
  Partner = 'partner',
}

function getPaidPayeeTypeDisplay(payeeType: paidPayeeType): string {
  switch (payeeType) {
    case paidPayeeType.All:
      return 'all'
    case paidPayeeType.Employer:
      return 'employer'
    case paidPayeeType.BillingEntity:
      return 'billing entity'
    case paidPayeeType.Partner:
      return 'partner'
    default:
      return 'Unknown'
  }
}

enum paymentType {
  All = 'all',
  ZelisFile = 'zelis_file',
  Manual = 'manual',
}

function getPaymentTypeDisplay(payeeType: paymentType): string {
  switch (payeeType) {
    case paymentType.All:
      return 'all'
    case paymentType.ZelisFile:
      return 'Zelis'
    case paymentType.Manual:
      return 'manual'
    default:
      return 'Unknown'
  }
}

const defaultSort = { col: 'PaymentID', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findPayments,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 250,
      DataTableProps: {
        keyProp: 'PaymentID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function InlineViewPaidDetail({
  data,
}: {
  data: paidTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewPaidDetailOverlay
        ref={ref}
        paymentID={data.PaymentID}
        onClose={() => refresh()}
      />
    </>
  )
}

export function FilterPayeeType(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(paidPayeeType).map((value: paidPayeeType) => ({
      value,
      label: getPaidPayeeTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payeeTypes: null })
    }
    setFilterImmediate({ payeeTypes: [sel.props.value] })
  }

  const v = filter.payeeTypes || paidPayeeType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payee Type
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payee Type"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterPaymentType(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(paymentType).map((value: paymentType) => ({
      value,
      label: getPaymentTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ paymentTypes: null })
    }
    setFilterImmediate({ paymentTypes: [sel.props.value] })
  }

  const v = filter.paymentTypes || paymentType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payment Type
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payment Type"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterVoidOnly(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Void Payments"
      control={
        <Switch
          color="primary"
          checked={filter.isVoid}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, isVoid: true })
              return
            }
            setFilterImmediate({ ...filter, isVoid: null })
          }}
        />
      }
    />
  )
}

export function FilterPaymentDateFrom(props: any): React.ReactElement {
  const { setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <ManagedDateInput
      {...props}
      label="Payment Date From"
      setter={({ value }) => {
        if (!value || value === '_invalid_') {
          setFilterImmediate({ paymentDateFrom: null })
          return
        }

        setFilterImmediate({ paymentDateFrom: value })
      }}
    />
  )
}

export function FilterPaymentDateTo(props: any): React.ReactElement {
  const { setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <ManagedDateInput
      {...props}
      label="Payment Date To"
      setter={({ value }) => {
        if (!value || value === '_invalid_') {
          setFilterImmediate({ paymentDateTo: null })
          return
        }

        setFilterImmediate({ paymentDateTo: value })
      }}
    />
  )
}
