export const enum ExclusionConsts {
  pathology = 'path',
  implant = 'impl',
}

export const Exclusions: { [key: string]: string } = {
  [ExclusionConsts.pathology]: 'Pathology',
  [ExclusionConsts.implant]: 'Implant',
}

export type ShapeBundleDefinition = {
  drg: string | null
  pos: string[] | null
  primCode: ShapeBundleDefinitionCell | null
  auxCodes: Array<ShapeBundleDefinitionCell> | null
  exclusions?:
    | {
        [key in ExclusionConsts]?: boolean
      }
    | null
  captureCPTs?: string[] | null
  declineCPTs?: string[] | null
}

export type ShapeBundleDefinitionCell = {
  _syntheticID?: string
  cpt: string | null
  mod: string | null
  payableWithMods: string[] | null
  requireUnits: boolean | null
  [key: string]: any
}

export type ShapeCostKeyData = {
  ID: number | null
  UUID: string | null
  CreatedAt: string | null
  UpdatedAt: string | null
  ModifiedByUserID: number | null
  ModifiedByUsername?: string | null
  Code: string | null
  Category: string | null
  Descr: string | null
  LaymanDescr: string | null
  BundleContents: string | null
  RequireUnits: boolean | null
  GroupByServiceDate: boolean | null
  Deprecated: boolean | null
  TypicalPPOOverride: boolean | null
  ManualTypicalPPOPrice: number | string | null // @todo: want string; currently .Money type on backend (should be nulldecimal)
  PPOOverrideNotes: string | null
  AlwaysOverrideTypicalPPO: boolean | null
  TagSetID: number | null
  DRGCode: string | null
  Notes: string | null
  IsMissingPathology: boolean | null
  IsMissingImplant: boolean | null
  IsMissingXrays: boolean | null
  AliasID: number | null
  AllowTravel: boolean | null
  RequireMedicalReview: boolean | null
  LegacyGroupDescr: string | null
  CostKeyCategoryID: number | null
  BundleDefinition?: ShapeBundleDefinition | null
  ConfigurableNetworks: Array<any> | null
  CostKeyKeywords: Array<any> | null
  BundleContentsV2?: Array<ShapeBundleContentRecord> | null
  EndorsedByUserID?: number | null
  EndorsedByUsername?: string | null
}

export type ShapeBundleContentRecord = {
  ID: number
  Content: string
  CreatedAt: string
  CreatedByUserID: number
  Occurrences?: number
  Hash?: string
}

export type ShapePOSCode = {
  Code: string
  Name: string | null
  Descr: string | null
}

export type ShapeDRGCode = {
  Code: string
  Descr: string | null
  TypeHandle: string | null
}

export type ShapeUBCode = {
  Code: string
  Descr: string | null
  Group: string | null
}

export type ShapeCPTModifierCode = {
  Code: string
  Descr: string | null
}

export type ShapeCPTCode = {
  Code: string
  Category: string | null
  Descr: string | null
  IsValid: boolean | null
  Source: string | null
}

export type ShapeICD10CMCode = {
  Code: string
  Descr: string | null
  Formatted: string | null
}

export type ShapeCostKeyBundleContentTemplate = {
  ID: number
  CreatedAt: string | null
  CreatedByUserID: number | null
  Name: string | null
}
export type ShapeBundleContentTemplateWithContents =
  ShapeCostKeyBundleContentTemplate & {
    Contents: any[] | null
  }
