import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findFeeSchedules } from '../../actions/FeeScheduleActions'
import utils from '../../utils'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import { FormControl, MenuItem, InputLabel, Select } from '@material-ui/core'
import dateTime from '../../utils/dateTime'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: {} },
  Name: { name: 'Name', details: { sortName: 'name' } },
  OrganizationName: {
    name: 'Org Name',
    details: { dataFormat: utils.orgLinkFormatter },
  },
  DateStart: {
    name: 'Start',
    details: { dataFormat: dateTime.cellFormatter(), sortName: 'date_start' },
  },
  DateEnd: {
    name: 'End',
    details: { dataFormat: dateTime.cellFormatter(), sortName: 'date_end' },
  },
  Status: {
    name: 'Production',
    details: { dataFormat: utils.zeroOneCheckFormatter('Status') },
  },
  StatusModifiedByUsername: { name: 'Productionized by', details: {} },
  Notes: { name: 'Notes', details: {} },
})

const defaultSort = { col: 'name', dir: 'asc' }

export const Table = forwardRef(function FeeScheduleTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findFeeSchedules,
    fnLinkOnRowClick = (row: any) => `/fee_schedule/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
      DataTableProps: { ...DataTableProps, rowsPerPage: [5, 10, 25, 100] },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterOrganization(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      selectedOrganizationID={filter.organizationID}
      onChange={(id: number | null) => {
        setFilterImmediate({ organizationID: id })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

// Filter by active/inactive status
// note: material ui's select component *sucks* for using any value={}'s that aren't
// a string... YMMV
export function FilterStatus(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'production', label: 'Production' },
    { value: 'draft', label: 'Draft' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ byStatus: null })
    }
    setFilterImmediate({ byStatus: sel.props.value })
  }

  const v = filter.byStatus || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 120 }}>
      <InputLabel shrink id={internalID}>
        Status
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Status"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
