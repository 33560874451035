import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import queryString from 'query-string'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemIcon,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../ViewHelpers'
import { Check, Done, Note } from '@material-ui/icons'
import { STATUS_DRAFT, STATUS_PRODUCTION } from '../../models/FeeSchedule'
import * as FeeSchedulePracticeFacilities from './FeeSchedulePracticeFacilities'
import PriceTable from '../Prices/PriceTable'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import { FeeScheduleRelationsManager } from './FeeScheduleRelationsManager.js'
import DialogAddPrice from './DialogAddPrice'
import { FeeScheduleActions, OrgActions } from '../../actions'
import { Organization } from '../Organizations/types'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { saveNewFeeSchedule } from '../../actions/FeeScheduleActions'
import DailogRemovePracticeFacilities from './DialogRemovePracticeFacilities'
import { FeeSchedule } from './types'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'

const { cloneFeeSchedule, moveToProduction, saveFeeSchedule, getFeeSchedule } =
  FeeScheduleActions
const { getOrganization } = OrgActions

const CardColumn = styled(Grid)`
  max-width: 316px;
  min-width: 316px;
`
const WideField = styled(TextField)`
  width: 268px;
`
const ProductionIndicatorNote = styled(Note)`
  font-size: 38px;
`
const ProductionIndicatorCheck = styled(Check)`
  font-size: 38px;
`
const CardContentCentered = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionDivider = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const DoneCheck = styled(Done)`
  height: 36px;
  width: 36px;
  margin-right: 8px;
  margin-left: 8px;
`

const ChildContainer = styled(Grid)`
  min-height: 600px;
`

const SelectOrgText = styled(Typography)`
  text-align: right;
  margin-top: 15px;
`

const ChildTableButton = styled(Button)`
  float: right;
  margin: 15px;
`

const OrgLink = styled(Typography)`
  cursor: pointer;
  color: #337ab7;
`

const OrgSearchContainer = styled(Grid)`
  margin-top: 15px;
`

const ErrorText = styled(Typography)`
  color: red;
`

const ActionButton = styled(Button)`
  margin-left: 15px;
`

const ProdExplanation = styled(Typography)`
  margin-top: 5px;
  margin-bottom: 5px;
`

const defaultFeeSchedule = {
  ID: 0,
  Name: '',
  Status: 0,
  DateStart: '',
  DateEnd: null,
  OrganizationID: null,
  OrganizationName: null,
  StatusModifiedByUsername: null,
  StatusModifiedByUserID: null,
  StatusUpdatedAt: null,
  IsComponent: false,
  Notes: null,
  LaunchDate: '',
  TerminationDate: null,
  ModifiedByUserID: null,
  TerminatedByUserID: null,
  TerminationNotes: null,
  UpdatedAt: null,
  CreatedAt: '',
}

const changeFieldsArray = [
  `Name`,
  `DateStart`,
  `DateEnd`,
  `OrganizationID`,
  `OrganizationName`,
  `IsComponent`,
  `Notes`,
  `LaunchDate`,
]

const tabs = {
  pfs: 0,
  prices: 1,
}

type FeeScheduleParams = {
  id: string
}

type FeeScheduleRoutableProps = RouteComponentProps<FeeScheduleParams>

const FeeScheduleDetail: React.FC<FeeScheduleRoutableProps> = ({ match }) => {
  const id = match.params.id ? Number(match.params.id) : null

  const [feeSchedule, setFeeSchedule] =
    useState<FeeSchedule>(defaultFeeSchedule)
  const [initialFeeSchedule, setInitialFeeSchedule] = useState<FeeSchedule>()
  const [changedFields, setChangedFields] = useState<Array<string>>([])
  const [org, setOrg] = useState<Organization>()
  const [updateCounter, setUpdateCounter] = useState<number>(0)
  const [applyToOrgOpen, setApplyToOrgOpen] = useState<boolean>(false)
  const [manageFacilitiesOpen, setManageFacilitiesOpen] =
    useState<boolean>(false)
  const [moveToProductionOpen, setMoveToProductionOpen] =
    useState<boolean>(false)
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<number>(0)
  const [checkedPfs, setCheckedPfs] = useState<Array<any>>([])
  const [errorText, setErrorText] = useState<string>('')
  const [dateEndExists, setDateEndExists] = useState<boolean>(false)
  const [refreshCount, setRefreshCount] = useState<number>(0)
  const { catchAPIError } = useErrorHandlers()
  const history = useHistory()
  const location = useLocation()
  const refPFTable = useRef()

  useEffect(() => {
    if (!id) return
    getFeeSchedule(id)
      .then((res: FeeSchedule) => {
        setDateEndExists(res.Status === STATUS_PRODUCTION && !!res.DateEnd)
        setFeeSchedule(res)
        setInitialFeeSchedule(res)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Fee Schedule; please contact Engineering',
          duration: 5000,
        })
      )
  }, [id, refreshCount])

  useEffect(() => {
    if (!feeSchedule.OrganizationID) return
    getOrganization(feeSchedule.OrganizationID)
      .then((res: any) => {
        if (res.error) throw res
        return setOrg(res.Data as Organization)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading organization',
          duration: 5000,
        })
      )
  }, [feeSchedule.OrganizationID])

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, feeSchedule, { [name]: value })
    setFeeSchedule(newFields)
    setErrorText('')
    if (id) handleSetCheckedFields(name, value)
  }

  const handleSetCheckedFields = (name: string, value: any) => {
    if (changeFieldsArray.includes(name) && !changedFields.includes(name))
      return setChangedFields([...changedFields, name])
    const checkValue = value === '' ? null : value
    if (
      changeFieldsArray.includes(name) &&
      initialFeeSchedule &&
      checkValue === initialFeeSchedule[name as keyof FeeSchedule]
    )
      setChangedFields(changedFields.filter((field) => field !== name))
  }

  const renderCards = () => {
    if (!feeSchedule) return null
    return (
      <Grid container spacing={2}>
        <CardColumn item xs={12} sm={3}>
          <Card>
            <CardHeader
              title="Fee Schedule Details"
              subheader="Details about this specific fee schedule."
            />
            <CardContent>
              {feeSchedule.ID ? (
                <WideField
                  disabled
                  label="ID"
                  value={feeSchedule.ID}
                  autoFocus
                />
              ) : null}
              <RenderTextField
                name="Name"
                label="Name"
                value={feeSchedule.Name}
                setter={setter}
              />
              <RenderSwitchField
                name="IsComponent"
                label="Is Component"
                value={feeSchedule.IsComponent}
                setter={setter}
              />
              <RenderNotesField
                name="Notes"
                label="Notes"
                value={feeSchedule.Notes}
                setter={setter}
              />
            </CardContent>
          </Card>
        </CardColumn>
        <CardColumn item xs={12} sm={3}>
          <Card>
            <CardHeader
              title="Activation Details"
              subheader="When you activate, these properties will lock."
            />
            <CardContent>
              {/* {renderDateField({
                name: 'DateStart',
                label: 'Start Date',
                setter,
                value: typeof(feeSchedule.DateStart) === 'string' ? feeSchedule.DateStart : '',
                opts: {
                  disabled: feeSchedule.Status === STATUS_PRODUCTION,
                }
              })} */}
              <ManagedDateInput
                name="DateStart"
                label="Start Date"
                setter={setter}
                value={feeSchedule.DateStart}
                disabled={feeSchedule.Status === STATUS_PRODUCTION}
              />
              {/* {renderDateField({
                name: 'DateEnd',
                label: 'End Date',
                setter,
                value: typeof(feeSchedule.DateEnd) === 'string' ? feeSchedule.DateEnd : '',
                opts: { disabled: STATUS_PRODUCTION && dateEndExists }
              })} */}
              <ManagedDateInput
                name="DateEnd"
                label="End Date"
                setter={setter}
                value={feeSchedule.DateEnd}
                disabled={STATUS_PRODUCTION && dateEndExists} // @todo: SD-2055: ... what is this const &&
              />
            </CardContent>
          </Card>
        </CardColumn>
        <CardColumn item xs={12} sm={3}>
          <Card>
            <CardHeader
              title="Organization Details"
              subheader="This fee schedule is tied to this organization."
            />
            <CardContent>
              {renderOrgLink()}
              {feeSchedule.Status !== STATUS_PRODUCTION
                ? renderOrgSearch()
                : null}
              {/* {renderDateField({
                name: 'LaunchDate',
                label: 'Launch Date',
                setter,
                value: org?.LaunchDate || '',
                opts: { disabled: true }
              })} */}
              <ManagedDateInput
                name="LaunchDate"
                label="Launch Date"
                setter={setter}
                value={org?.LaunchDate}
                disabled={true}
              />
              {/* {renderDateField({
                name: 'TerminationDate',
                label: 'Termination Date',
                setter,
                value: org?.TerminationDate || '',
                opts: { disabled: true }
              })} */}
              <ManagedDateInput
                name="TerminationDate"
                label="Termination Date"
                setter={setter}
                value={org?.TerminationDate}
                disabled={true}
              />
            </CardContent>
          </Card>
        </CardColumn>
        {feeSchedule.Status === STATUS_DRAFT && (
          <CardColumn item xs={12} sm={3}>
            <Card>
              <CardHeader
                title="Draft Details"
                subheader="Information about this draft fee schedule"
              />
              <CardContentCentered>
                <ProductionIndicatorNote color="primary" />
                <Typography>
                  {' '}
                  This is a <strong>DRAFT</strong> fee schedule.
                </Typography>
                <Typography>
                  You may adjust any information on this fee schedule, add
                  prices, remove prices as needed while it is draft mode.
                </Typography>
                <Typography>
                  When you are ready to put this fee schedule into production,
                  go through this checklist and then hit "Move to Production"
                </Typography>
                <List>
                  <ListItemIcon>
                    <Check />
                    Has a start date.
                  </ListItemIcon>
                  <ListItemIcon>
                    <Check />
                    Does not overlap with any other production fee schedules
                    assigned to any of the same facilities
                  </ListItemIcon>
                  <ListItemIcon>
                    <Check />
                    Has at least 1 facility
                  </ListItemIcon>
                  <ListItemIcon>
                    <Check />
                    Has at least 1 price
                  </ListItemIcon>
                </List>

                {feeSchedule.ID ? (
                  <Button onClick={() => setMoveToProductionOpen(true)}>
                    Move to Production
                  </Button>
                ) : null}
              </CardContentCentered>
            </Card>
          </CardColumn>
        )}
        {feeSchedule.Status === STATUS_PRODUCTION && (
          <CardColumn item xs={12} sm={3}>
            <Card>
              <CardHeader
                title="Production Details"
                subheader="Details on when this fee schedule was moved from a draft into production"
              />
              <CardContentCentered>
                <ProductionIndicatorCheck color="primary" />
                <Typography>This is a production fee schedule.</Typography>
                <ProdExplanation>
                  Activated by:{' '}
                  {feeSchedule.StatusModifiedByUsername || (
                    <Chip color="primary" label="Username Missing" />
                  )}{' '}
                  on{' '}
                  {feeSchedule.StatusUpdatedAt ? (
                    dateTime.parse(feeSchedule.StatusUpdatedAt).local().format()
                  ) : (
                    <Chip color="primary" label="Date Missing" />
                  )}
                </ProdExplanation>
                <Typography>
                  This fee schedule is now locked. If you need to extend the
                  dates on the fee schedule, or create a copy, please use the
                  "Clone fee schedule" option.
                </Typography>
              </CardContentCentered>
            </Card>
          </CardColumn>
        )}
        <Grid container justify="flex-end">
          <Button onClick={backToFeeSchedules}>
            {'< Back to Fee Schedules'}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} container justify="flex-end">
          <Grid>
            {showCheckmark ? <DoneCheck /> : null}
            <Button color="primary" variant="contained" onClick={handleSave}>
              Save
            </Button>
            <ActionButton variant="outlined" color="primary">
              <Link to="/fee_schedule" target="_blank">
                Add Another FeeSchedule
              </Link>
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={handleClone}>
              Clone as Draft
            </ActionButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {changedFields.length && id ? (
            <Typography>
              Unsaved Changes to: {changedFields.join(', ')}
            </Typography>
          ) : null}
          {errorText.length ? <ErrorText>{errorText}</ErrorText> : null}
        </Grid>
        <Grid item xs={12}>
          <SectionDivider />
        </Grid>
        <Grid item xs={12}>
          {renderChildTabs()}
        </Grid>
        {renderConfirmMoveToProductionDialog()}
      </Grid>
    )
  }

  const backToFeeSchedules = () => {
    if (location.state) {
      // @ts-ignore
      const qs = location.state.prevSearch ? location.state.prevSearch : ''
      // @ts-ignore
      if (location.state.originPathname)
        // @ts-ignore
        return history.push(`${location.state.originPathname + qs}`)
    }
    leavePageHandler('/fee_schedules', false)
  }

  const renderChildTabs = () => {
    if (!feeSchedule || !feeSchedule.ID) return null
    return (
      <>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit">
          <Tab value={tabs.pfs} label="Facilities" />
          <Tab value={tabs.prices} label="Prices" />
        </Tabs>
        <ChildContainer>{renderChildTabContent()}</ChildContainer>
      </>
    )
  }

  const renderChildTabContent = () => {
    const hasOrg = !!feeSchedule.OrganizationID
    const isProd = feeSchedule.Status === STATUS_PRODUCTION
    let table

    if (tabValue === tabs.pfs) {
      table = (
        <Grid>
          <FeeSchedulePracticeFacilities.Table
            ref={refPFTable}
            feeScheduleID={id}
            onCheckHandler={(selected: Array<any>) => setCheckedPfs(selected)}
            LeftHeaderItems={
              <>
                <FeeSchedulePracticeFacilities.StandardFilterSearch autoFocus />
                &nbsp;&nbsp;
                <Chip label={`FeeScheduleID: ${id}`} />
                <FeeSchedulePracticeFacilities.FilterShowInactiveFacilities />
              </>
            }
            DataTableProps={{
              rowOptsApplier(row: any) {
                return !!row.FeeScheduleRemovedOn && { className: 'tr-warning' }
              },
              LeftFooterItems: (
                <>
                  <DailogRemovePracticeFacilities
                    feeSchedule={feeSchedule}
                    practiceFacilities={checkedPfs}
                    onChange={() => {
                      setCheckedPfs([])
                      // @ts-ignore
                      refPFTable.current?.refresh()
                    }}
                  />
                </>
              ),
            }}
          />
          {!hasOrg && (
            <SelectOrgText variant="caption">
              Please select an organization and save it before managing
              facilities
            </SelectOrgText>
          )}
          <ChildTableButton
            disabled={!hasOrg}
            onClick={() => setManageFacilitiesOpen(true)}
            color="primary"
            variant="contained">
            Manage Facilities
          </ChildTableButton>
          <ChildTableButton
            disabled={!hasOrg}
            onClick={() => setApplyToOrgOpen(true)}
            color="primary"
            variant="outlined">
            Apply to All Active Facilities
          </ChildTableButton>
        </Grid>
      )
    } else if (tabValue === tabs.prices) {
      table = (
        <Grid>
          <PriceTable
            fsId={id}
            production={isProd}
            canDeactivate={!(isProd && feeSchedule.DateEnd)}
            updateCounter={updateCounter}
          />
          <div style={{ padding: '1rem', textAlign: 'right' }}>
            <DialogAddPrice
              feeScheduleID={id!}
              feeScheduleStatus={feeSchedule.Status}
              onSave={() => {
                // @todo: SD-2458: refactor; bad pattern
                setUpdateCounter(updateCounter + 1)
              }}
            />
          </div>
        </Grid>
      )
    }

    return <Grid>{table}</Grid>
  }

  const handleTabChange = (e: any, value: number) => {
    const existingParams = queryString.parse(history.location.search)
    const stringified = queryString.stringify({
      ...existingParams,
      active_tab: value,
    })
    setTabValue(value)
    history.push(`?${stringified}`, false)
  }

  const handleClone = () => {
    cloneFeeSchedule(id)
      .then((res: FeeSchedule) =>
        leavePageHandler(`/fee_schedule/${res.ID}`, false)
      )
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to clone Fee Schedule; please contact Engineering',
        })
      )
  }

  const feeScheduleInvalid = () => {
    if (!feeSchedule) return 'Cannot save, this Fee Schedule is Invalid!'
    if (!feeSchedule.Name || feeSchedule.Name.length < 1)
      return 'Cannot save, Fee Schedule Name is Invalid!'
    const start = dateTime.parse(feeSchedule.DateStart)
    if (!start.isValid())
      return 'Cannot save, Fee Schedule Start Date is Invalid!'
    const end = dateTime.parse(feeSchedule.DateEnd)
    if (end.isBefore(start, 'day'))
      return 'Cannot save, Fee Schedule Start Date is after End Date!'
    return false
  }

  const handleSave = () => {
    const errorText = feeScheduleInvalid()
    if (errorText) return setErrorText(errorText)
    const fsToSave = Object.assign({}, feeSchedule)

    fsToSave.DateStart = dateTime
      .parse(fsToSave.DateStart)
      .format(dateTime.formats.ISODate)
    fsToSave.DateEnd = dateTime
      .parse(fsToSave.DateEnd)
      .format(dateTime.formats.ISODate)

    if (!id) {
      return saveNewFeeSchedule(fsToSave)
        .then((res: FeeSchedule) => {
          setErrorText('')
          history.push(`/fee_schedule/${res.ID}`)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed to save new Fee Schedule; please contact Engineering',
            duration: 10000,
          })
        )
    }

    saveFeeSchedule(fsToSave.ID, fsToSave)
      .then(() => {
        setShowCheckmark(true)
        setChangedFields([])
        setRefreshCount(refreshCount + 1)
        setTimeout(() => {
          setShowCheckmark(false)
        }, 3000)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update existing Fee Schedule; please contact Engineering',
          duration: 10000,
        })
      )
  }

  const renderOrgLink = () => {
    const orgName = org ? org.Name : feeSchedule.OrganizationName
    const orgId = feeSchedule.OrganizationID

    if (orgName && orgId && id) {
      const href = `${window.location.origin}/organization/${orgId}`
      return (
        <OrgLink variant="body1" onClick={() => leavePageHandler(href, true)}>
          Organization: {orgName}
        </OrgLink>
      )
    }
  }

  const leavePageHandler = (url: string, newTab: boolean) => {
    if (newTab) return window.open(url)
    setFeeSchedule(defaultFeeSchedule)
    history.push(url)
  }

  const renderOrgSearch = () => (
    <OrgSearchContainer>
      <OrganizationSearcherV2
        selectedOrganizationID={feeSchedule.OrganizationID}
        onChange={handleOrgSelected}
      />
    </OrgSearchContainer>
  )

  const handleOrgSelected = (orgID: number, org: any) => {
    if (!org)
      return setFeeSchedule(
        Object.assign({}, feeSchedule, {
          OrganizationID: null,
          OrganizationName: null,
        })
      )
    return setFeeSchedule(
      Object.assign({}, feeSchedule, {
        OrganizationID: orgID,
        OrganizationName: org.Name,
      })
    )
  }

  const renderConfirmMoveToProductionDialog = () => {
    return (
      <div>
        <Dialog
          open={moveToProductionOpen}
          onClose={() => setMoveToProductionOpen(false)}>
          <DialogTitle>Confirm Move from Draft to Production</DialogTitle>
          <DialogContent>
            Once in production, you will no longer be able to alter the dates on
            this schedule, or the dates on the prices associated with it.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMoveToProduction} color="secondary">
              Confirm
            </Button>
            <Button
              onClick={() => setMoveToProductionOpen(false)}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const handleMoveToProduction = () => {
    if (feeSchedule.Status === STATUS_PRODUCTION) return
    const fsToSave = Object.assign({}, feeSchedule)

    fsToSave.DateStart = dateTime
      .parse(fsToSave.DateStart)
      .format(dateTime.formats.ISODate)
    fsToSave.DateEnd = dateTime
      .parse(fsToSave.DateEnd)
      .format(dateTime.formats.ISODate)

    moveToProduction(fsToSave.ID, fsToSave)
      .then(() => setRefreshCount(refreshCount + 1))
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Move to Production; please contact Engineering',
          duration: 10000,
        })
      )
      .finally(() => setMoveToProductionOpen(false))
  }

  const handleResetOpenModals = () => {
    setApplyToOrgOpen(false)
    setManageFacilitiesOpen(false)
  }

  const handleUpdatePracticeFacilities = () => {
    setApplyToOrgOpen(false)
    setManageFacilitiesOpen(false)
    // @ts-ignore
    refPFTable.current?.refresh()
  }

  return (
    <Grid container>
      <Grid container>{renderCards()}</Grid>
      {feeSchedule && (
        <Grid container>
          <FeeScheduleRelationsManager
            fsId={id}
            onConfirm={handleUpdatePracticeFacilities}
            onCancel={handleResetOpenModals}
            facilitiesOpen={manageFacilitiesOpen}
            orgId={feeSchedule.OrganizationID}
            orgName={org?.Name}
            orgOpen={applyToOrgOpen}
            isProd={feeSchedule.Status === STATUS_PRODUCTION}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default FeeScheduleDetail
