import React, { useEffect, useMemo, useState } from 'react'
import { Card, Container, Typography } from '@material-ui/core'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import { renderTextField as RenderTextField } from '../../../components/Inputs/standard'
import DataTable from '../../../components/DataTable/DataTable'
import dateTime from '../../../utils/dateTime'
import VoidPay from './VoidPayment'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { getPayment } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

export interface paidDetailRecordDetail {
  LineNumber: number
  ZClaimID: number | null
  InvoiceItemID: number | null
  Description: string
  Amount: string | null
  FundingInfos: string[]
}

export interface paidDetailRecord {
  PaymentID: string
  PaymentType: string
  Date: string | null
  OrganizationID: number
  PartnerID: number
  PayeeName: string
  PayeeType: string
  PayEntityAddress: string
  CheckingAccount: string
  ManualPayType: string
  CheckNumber: number
  ACHInfo: string
  Notes: string
  IsVoid: boolean
  VoidPaymentNotes: string
  ClaimCount: number
  Amount: string

  Details: paidDetailRecordDetail[]
}

const initialPaidDetailRecord: paidDetailRecord = {
  PaymentID: '',
  PaymentType: '',
  Date: null,
  OrganizationID: 0,
  PartnerID: 0,
  PayeeName: '',
  PayeeType: '',
  PayEntityAddress: '',
  CheckingAccount: '',
  ManualPayType: '',
  CheckNumber: 0,
  ACHInfo: '',
  Notes: '',
  IsVoid: false,
  VoidPaymentNotes: '',
  ClaimCount: 0,
  Amount: '',
  Details: [],
}

interface paidDetailProps {
  paymentID: string
  doClose?(): void
}

const PaymentDetailDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

const claimColumns = {
  LineNumber: {
    name: 'Line',
  },
  Description: {
    name: 'Description',
  },
  ID: {
    name: 'ID',
    details: {
      dataFormat(_: any, row: paidDetailRecordDetail): JSX.Element {
        const id = row.ZClaimID || row.InvoiceItemID
        return <> {id}</>
      },
    },
  },
  FundingInfos: {
    name: 'Funding Information',
    details: {},
  },
  ZClaimAmount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: paidDetailRecordDetail): JSX.Element {
        const amount = row.Amount?.toString() || '0'
        const isNegative = amount.startsWith('-')
        return (
          <span style={{ color: isNegative ? 'red' : 'inherit' }}>
            {formatAmount(amount)}
          </span>
        )
      },
    },
  },
}

export default function PaidDetail({
  paymentID,
  doClose,
}: paidDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null

  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<paidDetailRecord>(initialPaidDetailRecord)

  const sorted = useMemo(() => {
    return data.Details.sort((a, b) => a.LineNumber - b.LineNumber)
  }, [data.Details])

  const [filteredRows, setFilteredRows] = useState<paidDetailRecordDetail[]>([])
  const [search, setSearch] = useState('')
  const [paginatedRows, setPaginatedRows] = useState<paidDetailRecordDetail[]>(
    []
  )
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)
  const [isWorking, setIsWorking] = useState(true)

  useEffect(() => {
    getData()
  }, [paymentID])

  function getData() {
    setIsWorking(true)
    return getPayment(paymentID)
      .then((res: any) => {
        setData(res.Data)
        setPaginatedRows(res.Data.Details)
        setFilteredRows(res.Data.Details)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get payment data',
        })
      )
      .finally(() => setIsWorking(false))
  }

  useEffect(() => {
    paginateFilteredRows()
  }, [page, pageSize, filteredRows])

  useEffect(() => {
    if (!search) {
      setFilteredRows(sorted)
      return
    }
    setFilteredRows(
      sorted.filter((item) => {
        const id = item.ZClaimID || item.InvoiceItemID || 0

        return id.toString().includes(search.toLowerCase())
      })
    )
  }, [search])

  function paginateFilteredRows() {
    setPaginatedRows(
      filteredRows.slice(page * pageSize - pageSize, page * pageSize)
    )
  }
  function handlePageChange(value: any) {
    setPage(value.page)
  }

  function handlePageSizeChange(value: any) {
    setPageSize(value.pageSize)
  }

  return (
    <Container>
      <Typography component="div" variant="h4" style={{ marginTop: '16px' }}>
        Payment Detail
      </Typography>
      <Typography component="div" variant="h5" style={{ marginTop: '16px' }}>
        {data.PayeeName}
      </Typography>
      <Typography>{data.PayEntityAddress}</Typography>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment ID" value={data.PaymentID} />
        <ChipInformationDisplay
          label="Date"
          value={dateTime.parse(data.Date).format()}
        />
        <ChipInformationDisplay
          label="Amount"
          value={formatAmount(data.Amount)}
        />
        <ChipInformationDisplay
          label="Checking Account"
          value={data.CheckingAccount}
        />
      </PaymentDetailDiv>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment Type" value={data.PaymentType} />
        {data.ManualPayType && (
          <ChipInformationDisplay
            label="Manual Payment Type"
            value={data.ManualPayType}
          />
        )}
        {data.ACHInfo && (
          <ChipInformationDisplay label="ACH Info" value={data.ACHInfo} />
        )}
        {data.CheckNumber && (
          <ChipInformationDisplay
            label="Check Number"
            value={data.CheckNumber}
          />
        )}
        {data.Notes && (
          <ChipInformationDisplay label="Notes" value={data.Notes} />
        )}
      </PaymentDetailDiv>

      <Card variant="outlined">
        <DesignSuite2023.GridLR
          left={
            <div style={{ paddingLeft: '16px' }}>
              <RenderTextField
                name="ID Search"
                label="ID Search"
                value={search}
                setter={({ value }) => setSearch(value)}
                opts={{ style: { width: '150px' } }}
                use2023Styles
              />
            </div>
          }
          right={
            <div style={{ paddingRight: '16px' }}>
              {data.IsVoid ? (
                <>
                  <Typography variant="h4" style={{ color: 'red' }}>
                    This payment has been voided
                  </Typography>
                  {data.VoidPaymentNotes && (
                    <Typography variant="h5">
                      {data.VoidPaymentNotes}
                    </Typography>
                  )}
                </>
              ) : (
                <VoidPay
                  data={data}
                  disabled={false}
                  hidden={data.Amount === '0'}
                  onClose={getData}
                />
              )}
            </div>
          }
        />
        {!isWorking && (
          <DataTable
            columns={claimColumns}
            data={paginatedRows}
            initPage={1}
            initPageSize={pageSize}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={(v: any) => handlePageSizeChange(v)}
            count={filteredRows.length}
          />
        )}
      </Card>
      {isWorking && (
        <>
          &nbsp;
          <DesignSuite2023.LoadingSpinner size={100} show={isWorking} />
        </>
      )}
    </Container>
  )
}
