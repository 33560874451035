import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import * as model from '../../models/Partner'
import * as partnerActions from '../../actions/PartnerActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useDiffChangedFields from '../../hooks/useDiffChangedFields'
import { Grid, Button } from '@material-ui/core'
import {
  renderTextField,
  renderSwitchField,
  renderNotesField,
  SetterArgs,
} from '../ViewHelpers'
import { TagAutocomplete } from '../../components/Tags'
import PartnerFileTableContainer from './PartnerFileTableContainer'
import * as ContactTable from '../Contacts/ContactTable'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ManagedDateInput from '../../components/Inputs/managedDateInput'

const defaultPartner: model.PartnerRecordShape = {
  ID: null,
  CreatedAt: null,
  UpdatedAt: null,
  Name: null,
  Notes: null,
  Owner: null,
  Website: null,
  LastReviewDate: null,
  ReviewNotes: null,
  Tags: [],
  TagSetID: null,
  DeletedAt: null,
  Active: true,
  CreatedByUserID: null,
  ModifiedByUserID: null,
}

export default function PartnerDetail({
  match,
  history,
  location,
}: RouteComponentProps<{ id: string }>): React.ReactElement | null {
  const id = match.params.id ? Number(match.params.id) : null
  const [record, setRecord] = useState<model.PartnerRecordShape>({
    ...defaultPartner,
  })
  const [notFound, setNotFound] = useState(false)
  const [tabValue, setTabValue] = useState(1)
  const [contactTypes, setContactTypes] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const changeTracker = useDiffChangedFields(record, model.diffChangedFields)

  useEffect(() => {
    if (!id) return
    loadPartner(id)
  }, [id])

  useEffect(() => {
    partnerActions
      .getPartnerContactTypes()
      .then((res: any) => {
        if (res.error) throw res
        setContactTypes(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading Partner Contact Types',
        })
      )
  }, [])

  function setter({ name, value }: SetterArgs) {
    setRecord((curr: model.PartnerRecordShape) => ({ ...curr, [name]: value }))
  }

  function loadPartner(id: number) {
    partnerActions
      .getPartner(id)
      .then((res) => {
        if (res.error) throw res
        setRecord(res.Data)
        changeTracker.setInitialForDiff(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading or refreshing Partner',
          withError: (err: any) => {
            if (err?.Error?.StatusCode === 404) {
              setNotFound(true)
            }
          },
        })
      )
  }

  function handleSave() {
    if (record && record.ID) {
      return partnerActions
        .putUpdatePartner(record)
        .then((res) => {
          if (res.error) throw res
          setRecord(res.Data)
          changeTracker.setInitialForDiff(res.Data)
          showSnackbar('Partner Saved OK', SnackbarTypeSuccess)
        })
        .catch(catchAPIError({ defaultMessage: 'Failed saving Partner' }))
    }
    return partnerActions
      .postCreatePartner(record)
      .then((res) => {
        if (res.error) throw res
        history.push(`/partner/${res.Data.ID}`)
        showSnackbar('New Partner Saved', SnackbarTypeSuccess)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving New Partner' }))
  }

  if (notFound) {
    return (
      <DesignSuite2023.AlertError>
        Partner could not be found
      </DesignSuite2023.AlertError>
    )
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary>
          <Grid container spacing={3}>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={10}>
                {renderTextField({
                  name: 'Name',
                  label: 'Name',
                  setter,
                  value: record.Name || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
              <Grid item xs={2}>
                {renderSwitchField({
                  name: 'Active',
                  label: 'Active',
                  setter,
                  value: !!record.Active,
                })}
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                {renderTextField({
                  name: 'Owner',
                  label: 'Owner',
                  setter,
                  value: record.Owner || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
              <Grid item xs={6}>
                {renderTextField({
                  name: 'Website',
                  label: 'Website',
                  setter,
                  value: record.Website || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                {renderNotesField({
                  name: 'Notes',
                  label: 'Notes',
                  setter,
                  value: record.Notes || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                <TagAutocomplete
                  tagFamily="partner"
                  tagSetID={record.TagSetID}
                  onChangeTagSet={(newTagsetID) => {
                    setter({ name: 'TagSetID', value: newTagsetID })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {/* {renderDateField({name:'LastReviewDate', label:'Last Review Date', setter, value:record.LastReviewDate || '', opts: {
                margin: 'none'
              }})} */}
                <ManagedDateInput
                  name="LastReviewDate"
                  label="Last Review Date"
                  value={record.LastReviewDate}
                  setter={setter}
                  margin="none"
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                {renderNotesField({
                  name: 'ReviewNotes',
                  label: 'Review Notes',
                  setter,
                  value: record.ReviewNotes || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>

        <DesignSuite2023.LayoutSidebar>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}>
            Save
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              history.push('/partners')
            }}>
            Back to Partners
          </Button>

          {record.ID && (
            <>
              <DesignSuite2023.Divider />
              <DesignSuite2023.DisplayRecordMeta record={record} />
              <changeTracker.DisplayChangedFields>
                <DesignSuite2023.Divider />
              </changeTracker.DisplayChangedFields>
            </>
          )}
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>

      {!!record?.ID && (
        <>
          <DesignSuite2023.Section padTop0>
            <DesignSuite2023.StyledTabs value={tabValue}>
              <DesignSuite2023.StyledTab
                value={1}
                label="Files"
                onClick={() => setTabValue(1)}
              />
              <DesignSuite2023.StyledTab
                value={2}
                label="Contacts"
                onClick={() => setTabValue(2)}
              />
            </DesignSuite2023.StyledTabs>
            {tabValue === 1 ? (
              <PartnerFileTableContainer id={record.ID} />
            ) : null}
            {tabValue === 2 ? (
              <ContactTable.Table
                passFilters={{ PartnerID: record.ID, IncludeInactive: false }}
                handleSaveNewContact={(contact: any) =>
                  partnerActions.savePartnerContact(record.ID, contact)
                }
                contactTypes={contactTypes}
                LeftHeaderItems={
                  <>
                    <ContactTable.StandardFilterSearch />
                    &nbsp;
                    <ContactTable.ContactTypeTableFilter entityType="partner" />
                    &nbsp;
                    <ContactTable.SwitchInactive />
                    <ContactTable.FilterIsPrimaryContact />
                  </>
                }
              />
            ) : null}
          </DesignSuite2023.Section>
        </>
      )}
    </>
  )
}
