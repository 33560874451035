import React, { ReactElement, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { putSABundleLineAction } from '../../actions/SavingsAnalysisV2'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import BtnChooseCostKey from './BtnChooseCostKey'
import StringifyCostKey from '../CostKeys/Components/StringifyCostKey'
import ViewClaimDetailOverlay from '../ZClaims/Components/ViewClaimDetailOverlay'
import dateTime from '../../utils/dateTime'
import utils from '../../utils'
import { renderNotesField as RenderNotesField } from '../../components/Inputs/standard'

const StyledBundleDetail = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .sa-bundle-detail-overview {
    .overview-container {
      display: flex;
      // grid-column-gap: 2rem;
      background: #ececec;
      border-radius: 8px;
      margin: 1rem auto;
      overflow: hidden;
      width: 100%;

      aside {
        border-left: 2px solid #d3d3d3;
        padding: 1rem;

        > strong {
          display: block;
          margin: -1rem -1rem 1rem;
          background: #ccc;
          padding: 0.5rem 1rem;
        }

        &:first-of-type {
          border-left: none;
        }
      }
    }
  }

  .sa-lines-view {
    background: #f9f9f9;
    // flex: 1;
    display: grid;
    grid-template-columns: repeat(12, min-content) 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-column-gap: 1.5rem;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    max-height: 100%;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    header,
    .sa-line,
    .pinnable {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      border-bottom: 1px solid #e1e1e1;
    }

    .pinnable {
      background: #fff;
    }

    header,
    .sa-line {
      padding: 0.5rem 0.75rem;
    }

    header {
      background: #e9e9e9;
      position: sticky;
      top: 0;

      strong {
        position: relative;
        font-weight: 500;

        &:not(:empty)::before {
          content: '';
          width: 3px;
          background: #ddd;
          height: calc(100% + 1rem + 1px);
          position: absolute;
          left: calc(-0.75rem - 2px);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .sa-line {
      font-family: monospace;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: #f2f2f2;
      }

      &.is-ignored {
        // opacity: 0.4;
        text-decoration: line-through;
        background: #f6f0b3;
        color: #a79627;
      }
    }

    aside {
      grid-column: 1 / -1;
      padding: 0.5rem 0.75rem;
      background: #3f3f3f;
      color: #fff;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      // justify-content: center;
      font-size: 86%;
      line-height: 1;
      z-index: 99;

      .MuiTypography-root {
        line-height: 1;
        font-size: inherit;
      }

      .MuiButton-root {
        font-size: inherit;

        &.Mui-disabled {
          color: inherit;
          border: 1px solid;
          opacity: 0.35;
        }
      }
    }
  }
`

interface props {
  lines: any[]
  onLinesExtracted?(): void
}
export default function BtnExtractLinesNewBundle({
  lines = [],
  onLinesExtracted,
}: props): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [selectedCostKey, setSelectedCostKey] = useState<any>(null)

  const { catchAPIError } = useErrorHandlers()
  const { showDurationShort } = useSnackbar()

  function doExtract() {
    if (isWorking || !selectedCostKey.ID) {
      return
    }
    setIsWorking(true)

    let zClaimLineIDs = lines.map((line) => line.ZClaimLineID)

    putSABundleLineAction({
      zClaimLineIDs,
      ActionNewBundle: {
        CostKeyID: selectedCostKey.ID,
        Note: notes,
      },
    })
      .then(() => {
        showDurationShort('Lines extracted to new bundle', SnackbarTypeSuccess)
        setIsOpen(false)
        onLinesExtracted?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to extract lines to new bundle',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        onClick={() => setIsOpen(true)}>
        Extract
      </Button>
      <Dialog
        maxWidth="xl"
        open={isOpen}
        onClose={() => {
          // Do nothing: Only close on button action
        }}>
        <DialogTitle>Extract Lines to New Bundle</DialogTitle>
        <DialogContent>
          <StyledBundleDetail>
            <div className="sa-bundle-detail-overview">
              <div className="overview-container">
                <aside>
                  <strong>Cost Key</strong>
                  <div style={{ display: 'flex', columnGap: '0.5rem' }}>
                    <div>
                      {selectedCostKey?.ID ? (
                        <StringifyCostKey
                          highlighters
                          bd={selectedCostKey?.BundleDefinition}
                          showV2Prefix={false}
                        />
                      ) : (
                        <span>Select Cost Key</span>
                      )}
                    </div>
                    <div>
                      <BtnChooseCostKey onChange={setSelectedCostKey} />
                    </div>
                  </div>
                </aside>
                <aside style={{ width: '100%' }}>
                  <strong>Notes</strong>
                  <RenderNotesField
                    name="Notes"
                    label=""
                    value={notes}
                    setter={({ value }) => setNotes(value)}
                    disabled={isWorking}
                    opts={{ margin: 'none', fullWidth: true }}
                    use2023Styles
                  />
                </aside>
              </div>
            </div>

            <div style={{ flex: 1, overflow: 'hidden' }}>
              <div className="sa-lines-view">
                <header>
                  <strong>ZClaimID</strong>
                  <strong>ServiceStart</strong>
                  <strong>PaymentDate</strong>
                  <strong>Billed ($)</strong>
                  <strong>Paid ($)</strong>
                  <strong>TypicalPPO ($)</strong>
                  <strong>ProcedureCode</strong>
                  <strong>Modifiers</strong>
                  <strong>UBCode</strong>
                  <strong>Units</strong>
                  <strong>POSCode</strong>
                  <strong>Provider</strong>
                </header>

                <div className="pinnable">
                  {(lines || []).map((l: any) => (
                    <div
                      key={`bl-${l.ID}-${l.ZClaimLineID}`}
                      className={`sa-line`}>
                      <div>
                        <ViewClaimDetailOverlay
                          readOnly
                          zClaimID={l.ZClaimID}
                          Trigger={({ doOpen }: any) => (
                            <span
                              onClick={doOpen}
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}>
                              {l.ZClaimID}
                            </span>
                          )}
                        />
                      </div>
                      {/*
                        Following pieces of information could come from Bundle Lines or Nearby Lines.
                        Fields are different for some of them so use whichever one is present.
                      */}
                      <div>
                        {dateTime
                          .parse(
                            l.ZClaimLine?.ServiceDateStart ?? l.ServiceDateStart
                          )
                          .format()}
                      </div>
                      <div>{dateTime.parse(l.PaymentDate).format()}</div>
                      <div>
                        {utils.V2FormatAsCurrency(
                          l.ZClaimLine?.AmntBilled ?? l.AmntBilled
                        )}
                      </div>
                      <div>
                        {utils.V2FormatAsCurrency(
                          l.PaidAmount ?? l.AmntPayable
                        )}
                      </div>
                      <div style={{ color: '#888' }}>
                        {utils.V2FormatAsCurrency(
                          l.Decorated?.TypicalPPOPrice
                        ) || '?'}
                      </div>
                      <div>
                        {l.ZClaimLine?.ProcedureCode ?? l.ProcedureCode}
                      </div>
                      <div>
                        {l.ZClaimLine
                          ? joinArr(
                              l.ZClaimLine.ModifierCode1,
                              l.ZClaimLine.ModifierCode2,
                              l.ZClaimLine.ModifierCode3,
                              l.ZClaimLine.ModifierCode4
                            )
                          : joinArr(
                              l.ModifierCode1,
                              l.ModifierCode2,
                              l.ModifierCode3,
                              l.ModifierCode4
                            )}
                      </div>
                      <div>
                        {l.ZClaimLine?.UBRevenueCode ?? l.UBRevenueCode}
                      </div>
                      <div>{l.ZClaimLine?.Units ?? l.Units}</div>
                      <div>{l.ZClaimLine?.POSCode ?? l.POSCode}</div>
                      <div>{l.OrganizationName}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </StyledBundleDetail>
          <DesignSuite2023.GridLR
            style={{ marginTop: '1rem' }}
            left={
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button
                disabled={isWorking || !selectedCostKey?.ID}
                variant="contained"
                color="secondary"
                onClick={doExtract}>
                Extract
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

function joinArr(...items: string[]) {
  return items.filter((v) => !!v).join(' | ')
}
