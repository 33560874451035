import React, { useRef, useEffect, useState } from 'react'
import * as InvoiceableTable from '../Components/InvoiceableTable'
import { invoiceableRecord } from '../Components/InvoiceableTable'
import { Button, IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import IssueInvoices from '../Components/IssueInvoices'
import CreateInvoiceItemDialog from '../Components/CreateInvoiceItemDialog'

export default function PageInvoiceable(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<invoiceableRecord>>([])
  const [totalCheckedInvoices, setTotalCheckedInvoices] = useState<number>(0)
  const refInvoiceableTable = useRef<any>(null)

  useEffect(() => {
    const total = checkedItems.reduce(
      (sum, item) => sum + Number(item.InvoiceCurrent),
      0
    )
    setTotalCheckedInvoices(total)
  }, [checkedItems])

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refInvoiceableTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <IssueInvoices
          disabled={(checkedItems || []).length === 0}
          zClaimIDs={checkedItems
            .map((v: invoiceableRecord) => v.ZClaimIDsCurrent)
            .flat()
            .filter((id) => id !== 0 && id !== null)}
          invoiceItemIDs={checkedItems
            .map((v: invoiceableRecord) => v.InvoiceItemIDsCurrent)
            .flat()
            .filter((id) => id !== 0 && id !== null)}
          invoiceCount={checkedItems.length}
          invoiceCurrent={totalCheckedInvoices.toFixed(2)}
          onComplete={() => {
            refInvoiceableTable.current?.refresh()
          }}
        />
      </div>
      <CreateInvoiceItemButton
        onComplete={() => {
          refInvoiceableTable.current?.refresh()
        }}
      />
      <DesignSuite2023.Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <InvoiceableTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <InvoiceableTable.FilterEmployer />
      </div>
    </div>
  )

  return (
    <InvoiceableTable.Table
      ref={refInvoiceableTable}
      className="full-fixed"
      enableURLReflection="zclaims.invoiceable.list"
      onCheckHandler={setCheckedItems}
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}

function CreateInvoiceItemButton({
  onComplete,
}: {
  onComplete?(): void
}): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DesignSuite2023.Tooltip title="Create an invoice item">
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="small"
          endIcon={<DesignSuite2023.CommonIcons.IconAdd />}
          onClick={() => {
            setIsOpen(true)
          }}>
          {`Create Invoice Item`}
        </Button>
      </DesignSuite2023.Tooltip>

      <CreateInvoiceItemDialog
        useEmployerSearcher={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onComplete={onComplete}
      />
    </>
  )
}
