import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findBillingEntities } from '../../actions/BillingEntityActions'
import dateTime from '../../utils/dateTime'
import DisplayAddress from '../../components/Address'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import { pick } from 'lodash'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import DisplayBillingEntity from './DisplayBillingEntity'
import { ShapeBillingEntity, BillingEntityCreationSource } from './types'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  _View: {
    name: '',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return <OpenBillingEntity BillingEntity2ID={row.ID} />
      },
    },
  },
  ID: { name: 'ID' },
  Name: {
    name: 'Name',
    details: {
      sortName: 'BillingEntity.Name',
      dataFormat(_: any, row: ShapeBillingEntity) {
        return (
          <span>
            <strong style={{ display: 'block' }}>{row.Name}</strong>
            <small>
              <a
                target="_blank"
                rel="noreferrer"
                href={`/organization/${row.OrganizationID}`}>
                {row.OrganizationName}
              </a>
              {!row.IsCurrentlyActive && (
                <DesignSuite2023.Tooltip title="Organization has no launch date, thus should not be considered active">
                  <DesignSuite2023.CommonIcons.IconWarning fontSize="inherit" />
                </DesignSuite2023.Tooltip>
              )}
            </small>
          </span>
        )
      },
    },
  },
  TIN: { name: 'TIN', details: { sortName: 'BillingEntity.TIN' } },
  NPI: { name: 'NPI', details: { sortName: 'BillingEntity.NPI' } },
  Notes: {
    name: 'Notes',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return (
          <span
            style={{ display: 'inline-block', fontSize: '90%', maxWidth: 320 }}>
            {row.Notes}
          </span>
        )
      },
    },
  },
  DateStart: {
    name: 'DateStart',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  DateEnd: {
    name: 'DateEnd',
    details: {
      dataFormat: dateTime.cellFormatter(),
    },
  },
  IsContracted: {
    name: 'IsContracted',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return row.IsContracted ? (
          'Yes'
        ) : (
          <span style={{ color: 'red' }}>No</span>
        )
      },
    },
  },
  IsRetired: {
    name: 'IsRetired',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return row.IsRetired ? <span style={{ color: 'red' }}>Yes</span> : 'No'
      },
    },
  },
  CreationSource: {
    name: 'CreationSource',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return row.CreationSource
      },
    },
  },
  DisplayAddress: {
    name: 'Address',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity) {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            <DisplayAddress addressObj={row?.RemittanceAddress} />
          </span>
        )
      },
    },
  },
})

export const densifiedColumns = Object.freeze({
  ...pick(defaultColumns, ['ID']),
  Name: {
    name: 'Name',
    details: {
      width: 300,
      sortName: 'BillingEntity.Name',
      dataFormat(_: any, row: ShapeBillingEntity) {
        return (
          <div>
            <strong style={{ display: 'block' }}>{row?.Name}</strong>
            {row?.OrganizationID && (
              <small
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  fontSize: '95%',
                  opacity: 0.75,
                }}>
                Org:&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/organization/${row.OrganizationID}`}>
                  {row.OrganizationName}
                </a>
                {!row.OrganizationLaunchDate && (
                  <DesignSuite2023.Tooltip title="Organization has no launch date, thus should not be considered active">
                    <DesignSuite2023.CommonIcons.IconWarning fontSize="inherit" />
                  </DesignSuite2023.Tooltip>
                )}
              </small>
            )}
            <small
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                fontSize: '95%',
                opacity: 0.75,
              }}>
              <DisplayAddress
                asLink={false}
                addressObj={row?.RemittanceAddress}
              />
            </small>
          </div>
        )
      },
    },
  },
  ...pick(defaultColumns, ['OrganizationName']),
  Notes: {
    name: 'Notes',
    details: {
      dataFormat(_: any, row: ShapeBillingEntity): React.ReactElement | null {
        if (!row.Notes) return null
        return (
          <DesignSuite2023.Tooltip title={row.Notes || ''}>
            <DesignSuite2023.CommonIcons.IconPeek />
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  ...pick(defaultColumns, [
    'TIN',
    'NPI',
    'DateStart',
    'DateEnd',
    'IsContracted',
    'IsRetired',
  ]),
})

const defaultSort = { col: 'BillingEntity.Name', dir: 'asc' }

export const Table = forwardRef(function BillingEntityTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findBillingEntities,
    DataTableProps = {},
    initPageSize = 25,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize,
      DataTableProps: {
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250],
        rowOptsApplier(row: ShapeBillingEntity) {
          const warn = { className: 'tr-warning' }
          if (row?.IsRetired) return warn
          if (!row?.IsCurrentlyActive) return warn
          return null
        },
        ...DataTableProps,
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterOrganization(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      preloadAll
      selectedOrganizationID={filter.organizationIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          setFilterImmediate({ organizationIDs: [] })
          return
        }
        setFilterImmediate({ organizationIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

export function FilterContractedStatus({ ...props }: any): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ contractedStatus: null })
    }
    setFilterImmediate({ contractedStatus: sel.props.value })
  }

  const v = filter.contractedStatus || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ width: 130, ...props?.style }}>
      <InputLabel shrink id={internalID}>
        Contracted
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Contracted"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterIsRetired({ ...props }: any): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ isRetired: null })
    }
    setFilterImmediate({ isRetired: sel.props.value })
  }

  const v = filter.isRetired || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ width: 130, ...props?.style }}>
      <InputLabel shrink id={internalID}>
        Is Retired
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Is Retired"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterCreationSource({ ...props }: any): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    {
      value: BillingEntityCreationSource.Manual,
      label:
        BillingEntityCreationSource.Manual[0].toUpperCase() +
        BillingEntityCreationSource.Manual.slice(1),
    },
    {
      value: BillingEntityCreationSource.EDI,
      label: BillingEntityCreationSource.EDI.toUpperCase(),
    },
    {
      value: BillingEntityCreationSource.VBA,
      label: BillingEntityCreationSource.VBA.toUpperCase(),
    },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ creationSource: null })
    }
    setFilterImmediate({ creationSource: sel.props.value })
  }

  const v = filter.creationSource || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ width: 130, ...props?.style }}>
      <InputLabel shrink id={internalID}>
        Source
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Source"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function OpenBillingEntity({
  BillingEntity2ID,
}: {
  BillingEntity2ID: number
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <DesignSuite2023.Tooltip title="View Billing Entity">
        <IconButton
          size="small"
          onClick={() => ref?.current?.viewBillingEntityID(BillingEntity2ID)}>
          <IconAspectRatio fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>
      <DisplayBillingEntity ref={ref} onSave={() => refresh()} />
    </>
  )
}
