import { ReferralActions } from '../actions'
const { FIND_REFERRALS } = ReferralActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_REFERRALS: {
      return {
        ...state,
        referrals: action.payload.Data,
        count: action.payload.Data.length,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
