import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  SetterArgs,
  renderNotesField as RenderNotesField,
} from '../../../components/Inputs/standard'
import {
  postDoNotInvoice,
  postDoNotInvoiceParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'

interface props {
  disabled?: boolean
  zClaimIDs: number[]
  totalAmount: string
  onComplete?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

export default function ButtonDoNotInvoiceZClaims({
  disabled,
  zClaimIDs,
  totalAmount,
  onComplete,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [notes, setNotes] = useState<string>('')
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) {
      setNotes('')
    }
  }, [isOpen])

  function handleClose() {
    setIsOpen(false)
  }

  function doPostDoNotInvoice() {
    setIsWorking(true)

    const payload: postDoNotInvoiceParams = {
      zClaimIDs,
      notes,
    }

    postDoNotInvoice(payload)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    switch (name) {
      case 'notes':
        setNotes(value)
        break
    }
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Permanently write off selected claims">
        <span>
          <Button
            disabled={isWorking || disabled}
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconDeny />}
            onClick={() => {
              setIsOpen(true)
            }}>
            {`Write off (${zClaimIDs.length}) ${formatAmount(totalAmount)}`}
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>
          {`Write off (${zClaimIDs.length}) zClaims totaling ${formatAmount(totalAmount)}`}
          <Typography variant="body2" color="textSecondary">
            This action will permanently write off these un-invoiced claims as
            not invoiceable.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            This does not affect the payable status (they still may or may not
            be payable to the providers).
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '720px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={notes}
                  setter={setter}
                  use2023Styles={true}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {`ZClaimIDs: ${zClaimIDs.join(', ')}`}
                </Typography>
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking || !zClaimIDs.length || zClaimIDs.length === 0
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doPostDoNotInvoice}>
                  {`Write Off ${zClaimIDs.length} ZClaims`}
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
