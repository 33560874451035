import React, { useState, useEffect, useContext } from 'react'
import { v1 as uuidv1 } from 'uuid'
import { Snackbar } from '@material-ui/core'
import DisplayPreState from './displayPreState'

const ADMIN_CONFIG = 'admin_config'
const CLIENT_CONFIGURATION_VERSION = 'config_client_version'
const checkInterval = 60000
const storage = window.localStorage

declare global {
  interface Window {
    _zcConfig: Object
    _zcConfig_initialized: boolean
  }
}
window._zcConfig = window._zcConfig || {}
window._zcConfig_initialized = false

interface ConfigGetter {
  (): Config | any
}

interface AwsAppConfigResponse {
  ConfigurationVersion: string
  Content: string
}

interface Config {
  baseURL: string
  mapsUrl: string
  mapsKey: string
  auth0Domain: string
  auth0ClientID: string // auth0Key: string;
  serverlessBaseURL: string
  s3BaseURL: string
  referralAppLink: string
  env: string
  teamzeroUrl: string
  status: shapeStatus // "configuration status" @todo: better name
}

interface shapeStatus {
  hasIncident: boolean
  level: string
  msg: string
}

const {
  REACT_APP_ENV,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_BASE_URL,
  REACT_APP_SERVERLESS_BASE_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_GOOGLE_MAPS_URL,
  REACT_APP_REFERRAL_APP_LINK,
  REACT_APP_TEAMZERO_URL,
  // @ts-ignore @todo: setup 'process' awareness in tsconfig
} = process.env

export const ConfigContext = React.createContext({})
export const useConfig: ConfigGetter = () => useContext(ConfigContext)

export function ConfigProvider({ children }: any) {
  const [coreConfig, setCoreConfig] = useState<Config>({
    env: REACT_APP_ENV,
  } as Config)

  const prevClientID = storage.getItem('config_client_id')
  const configClientID = prevClientID ? prevClientID : uuidv1()
  storage.setItem('config_client_id', configClientID)

  const prevConfigurationVersion = storage.getItem('config_client_version')
  const clientConfigurationVersion = prevConfigurationVersion
    ? prevConfigurationVersion
    : '0'
  storage.setItem('config_client_version', clientConfigurationVersion)

  let timer: any
  function initConfig() {
    clearTimeout(timer)
    function queueConfigCheck() {
      timer = setTimeout(initConfig, checkInterval)
    }

    fetch(
      `https://config.zero.health/config?application_id=tzc-admin&environment=${REACT_APP_ENV}&configuration=${REACT_APP_ENV}&client_id=${configClientID}&config_version=${clientConfigurationVersion}`
    )
      .then((res) => res.json())
      .then((res: AwsAppConfigResponse) => {
        storage.setItem(CLIENT_CONFIGURATION_VERSION, res.ConfigurationVersion)
        let config: any

        const decoded = atob(res.Content)
        // the Content will be empty if we are not receiving any new configuration data
        if (decoded === '') {
          const rawLocal = storage.getItem(ADMIN_CONFIG) || ''
          if (rawLocal === '') {
            //clear the local version to attempt to get the "latest" from appconfig
            storage.setItem(CLIENT_CONFIGURATION_VERSION, '0')
            throw new Error('Failed loading configuration')
          }
          // By this point, we know that the app's configuration state has been successfully
          // set at least once, and the incoming configuration is **unchanged** - so instead of setting all the state
          // values again (and causing a rerender), we can return here without making any data mutations.
          if (window._zcConfig_initialized) {
            queueConfigCheck()
            return
          }
          config = JSON.parse(rawLocal)
        } else {
          // the Content will be included if we are recieving new configuration data, in that case, update the local version of the configuration.
          config = JSON.parse(decoded)
          storage.setItem(ADMIN_CONFIG, decoded)
        }
        console.debug('we are re-rendering the config', config)

        // Status check first
        if (!config.status) {
          throw new Error(
            'Failed loading configuration, or invalid configuration status'
          )
        }
        if (config.status.hasIncident && config.status.level === 'error') {
          // setStatus(config.status)
          setCoreConfig((curr: Config) => ({ ...curr, status: config.status }))
          queueConfigCheck()
          return
        }

        window._zcConfig_initialized = true
        setCoreConfig((curr: Config) => ({
          ...curr,
          baseURL: config.BASE_URL, // setBaseURL(config.BASE_URL)
          mapsUrl: REACT_APP_GOOGLE_MAPS_URL || config.MAPS_URL, // setMapsUrl(REACT_APP_GOOGLE_MAPS_URL || config.MAPS_URL)
          mapsKey: REACT_APP_GOOGLE_MAPS_API_KEY || config.MAPS_KEY, // setMapsKey(REACT_APP_GOOGLE_MAPS_API_KEY || config.MAPS_KEY)
          auth0Domain: REACT_APP_AUTH0_DOMAIN || config.AUTH0_DOMAIN, // setAuth0Domain(REACT_APP_AUTH0_DOMAIN || config.AUTH0_DOMAIN)
          auth0ClientID: REACT_APP_AUTH0_CLIENT_ID || config.AUTH0_CLIENT_ID, // setAuth0ClientID(REACT_APP_AUTH0_CLIENT_ID || config.AUTH0_CLIENT_ID)
          serverlessBaseURL:
            REACT_APP_SERVERLESS_BASE_URL || config.SERVERLESS_BASE_URL, // setServerlessBaseURL(REACT_APP_SERVERLESS_BASE_URL || config.SERVERLESS_BASE_URL)
          s3BaseURL: REACT_APP_BASE_URL || config.S3_BASE_URL, // setS3BaseURL(REACT_APP_BASE_URL || config.S3_BASE_URL)
          referralAppLink:
            REACT_APP_REFERRAL_APP_LINK || config.REFERRAL_APP_LINK, // setReferralAppLink(REACT_APP_REFERRAL_APP_LINK || config.REFERRAL_APP_LINK)
          teamzeroUrl: REACT_APP_TEAMZERO_URL || config.TEAMZERO_URL, // setTeamzeroUrl(REACT_APP_TEAMZERO_URL || config.TEAMZERO_URL)
          status: config.status, // setStatus(config.status)
        }))

        storage.setItem('admin_base_url', REACT_APP_BASE_URL || config.BASE_URL) // hacky, but would otherwise require a massive refactor of the way we interact with the API
        storage.setItem(
          'serverless_base_url',
          REACT_APP_SERVERLESS_BASE_URL || config.SERVERLESS_BASE_URL
        )
        storage.setItem('s3_base_url', REACT_APP_BASE_URL || config.S3_BASE_URL)
        queueConfigCheck()
      })
      .catch((err) => {
        window._zcConfig_initialized = false // will cause all config values in state to be reloaded
        setCoreConfig((curr: Config) => ({
          ...curr,
          status: {
            hasIncident: true,
            level: 'error',
            msg: 'We are currently experiencing a service outage, check back here for more updates.',
          },
        }))
        console.log('Config initialization error: ', err)
        queueConfigCheck()
      })
  }

  // This is a dev helper method, so that you can call "_zcConfig.refresh()" from the console; and doesn't
  // hurt to keep this in here for future purposes if you need to provide support to someone (it can help
  // w/ tracking down potential config issues)
  Object.assign(window._zcConfig, {
    refresh: initConfig,
  })

  useEffect(() => {
    if (!window._zcConfig_initialized) {
      initConfig()
    }
    // eslint-disable-next-line
  }, [])

  if (!coreConfig.status) {
    return (
      <DisplayPreState>
        <span>Application loading...</span>
      </DisplayPreState>
    )
  }

  let warningMessage
  if (coreConfig.status?.hasIncident) {
    // If its an error level incident, set the body class then return early (ie. nuke
    // rendering the rest of the app; just show the error and effectively "disable" the
    // entire app)
    if (coreConfig.status?.level === 'error') {
      return (
        <DisplayPreState showProgress={false}>
          <h2>&#128556; oops...</h2>
          <p>ZERO Admin is currently unavailable; please check back later.</p>
          <p>{coreConfig.status?.msg}</p>
        </DisplayPreState>
      )
    }
    // If we get here, it should be a warning level status; just take the message...
    warningMessage = coreConfig.status?.msg
  }

  return (
    <ConfigContext.Provider value={coreConfig}>
      {warningMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          message={warningMessage}></Snackbar>
      ) : null}

      {children}
    </ConfigContext.Provider>
  )
}
