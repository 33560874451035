import perms from '../utils/perms'

interface NetworkModel {
  hasAccess: () => boolean
  canUpdate: () => boolean
  canCreate: () => boolean
}

const configurableNetwork: NetworkModel = {
  hasAccess: () => perms.has(perms.RoleTokens.PermReadConfigurableNetwork),
  canUpdate: () => perms.has(perms.RoleTokens.PermUpdateConfigurableNetwork),
  canCreate: () => perms.has(perms.RoleTokens.PermCreateConfigurableNetwork),
}

export default configurableNetwork
