import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'
import * as PracticeFacilityTable2 from '../../../../views/PracticeFacilities/PracticeFacilityTable2'
import DisplayKeyValueData from '../DisplayKeyValueData'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  getZClaimAssignmentProvider,
  putZClaimAssignPracticeFacility,
  scopedRoutes,
} from '../../../../actions/ZClaimActions'
import {
  renderSwitchField as RenderSwitchField,
  SetterArgs,
} from '../../../ViewHelpers'
import dateTime from '../../../../utils/dateTime'

const StyledContent = styled(DialogContent)`
  .MuiAlert-root {
    margin-bottom: 0.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .force-switch-label-font-size {
    font-size: inherit;

    .MuiFormControlLabel-label {
      font-size: inherit;
    }
  }

  .std-table .header-items {
    margin-top: 0;
  }
`

interface props {
  zClaimID: number
  buttonDisabled?: boolean
  onComplete?(): void
}

export default function AssignerPracticeFacility({
  zClaimID,
  buttonDisabled,
  onComplete,
}: props): React.ReactElement {
  const [selectedPF, setSelectedPF] = useState<any>(null)
  const [makeDefaulter, setMakeDefaulter] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [currentInfo, setCurrentInfo] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) {
      setSelectedPF(null)
      setCurrentInfo(null)
      return
    }
    loadInfo()
  }, [isOpen])

  function loadInfo() {
    if (!zClaimID) return
    getZClaimAssignmentProvider(zClaimID)
      .then(({ Data }: any) => {
        setCurrentInfo(Data)
      })
      .catch(catchAPIError({}))
  }

  function doSave() {
    putZClaimAssignPracticeFacility(zClaimID, {
      practiceFacilityID: selectedPF.ID,
      makeDefaulter,
    })
      .then((res: any) => {
        if (res?.error) throw res
        setIsOpen(false)
        showSnackbar('Practice Facility assigned OK.', 'success')
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to assign Practice Facility.',
        })
      )
  }

  function onClickRow(row: any): void {
    if (row.ID === currentInfo?.Current?.PracticeFacilityInfo?.ID) {
      showSnackbar(
        'Selected Practice Facility is already assigned to this claim.',
        'warning'
      )
      return
    }
    setSelectedPF(row)
  }

  // const hasCurrent = !!currentInfo?.Current?.PracticeFacilityInfo?.ID
  const hasDefaulter =
    !!currentInfo?.SrcPracticeFacilityDefaulter?.PracticeFacilityID
  const canHaveDefaulter = currentInfo?.SrcServiceFacility?.HasSufficientInfo

  return (
    <>
      <DesignSuite2023.Tooltip
        title={
          !buttonDisabled
            ? 'Assign Practice Facility'
            : 'Facility can only be assigned when a *contracted* billing entity is present.'
        }>
        <span>
          <IconButton
            disabled={buttonDisabled}
            size="small"
            onClick={() => setIsOpen(true)}
            style={{
              padding: '0.3rem',
              background: 'rgba(0,0,0,0.1)',
              fontSize: '110%',
            }}>
            <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
          </IconButton>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth={false} onClose={() => setIsOpen(false)}>
        <DialogTitle>Assign Practice Facility</DialogTitle>
        <StyledContent>
          {!!currentInfo && (
            <Grid container spacing={2}>
              <Grid
                item
                xs="auto"
                style={{ width: 'min-content', maxWidth: 500 }}>
                <DesignSuite2023.Section>
                  <Typography variant="subtitle1" style={{ marginTop: 0 }}>
                    Service Facility Source Record
                  </Typography>
                  <DisplayKeyValueData
                    data={currentInfo.SrcServiceFacility}
                    skipFields={['PracticeFacilityDefaulterID']}
                  />
                  <SrcAlerts
                    currentInfo={currentInfo}
                    selectedPF={selectedPF}
                  />
                  <DesignSuite2023.AlertInfo>
                    <span>
                      This source record appears on{' '}
                      {currentInfo?.SrcServiceFacility?.ZClaimOccurrences} other
                      claims.
                    </span>
                  </DesignSuite2023.AlertInfo>

                  <DesignSuite2023.Divider />

                  <Typography variant="body2" color="textSecondary">
                    Claims may not always come through with sufficient
                    information on the facility, but inspecting other
                    information (like billing or rendering provider) may help
                    provide context for choosing the correct facility.
                  </Typography>
                  <DesignSuite2023.Divider />
                  <Typography variant="subtitle1" style={{ marginTop: 0 }}>
                    Rendering Provider Source Record
                  </Typography>
                  <DisplayKeyValueData
                    data={currentInfo.SrcRenderingProvider}
                  />
                  <Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
                    Billing Provider Source Record
                  </Typography>
                  <DisplayKeyValueData
                    data={currentInfo.SrcBillingProvider}
                    skipFields={[
                      'BillingEntityDefaulterID',
                      'BillingEntityDefaulterBillingEntityID',
                    ]}
                  />
                </DesignSuite2023.Section>
              </Grid>
              <Grid
                item
                xs="auto"
                style={{
                  flex: 1,
                  width: 1100,
                  overflow: 'hidden',
                  overflowX: 'scroll',
                }}>
                {!!currentInfo.Current?.PracticeFacilityInfo?.ID && (
                  <DesignSuite2023.AlertSuccess>
                    <strong>Current Practice Facility: </strong>{' '}
                    {currentInfo.Current.PracticeFacilityInfo.Name} (ID:{' '}
                    {currentInfo.Current.PracticeFacilityInfo.ID})
                  </DesignSuite2023.AlertSuccess>
                )}
                <div style={{ display: !!selectedPF ? 'none' : 'block' }}>
                  <PracticeFacilityTable2.Table
                    noWrapWhitespace
                    apiEndpoint={scopedRoutes.findPracticeFacilities}
                    onRowClick={onClickRow}
                    passFilters={{
                      OrganizationID: currentInfo.Current?.OrganizationInfo?.ID,
                      ActiveStatus: true,
                    }}
                    LeftHeaderItems={
                      <div>
                        <Typography style={{ display: 'block' }}>
                          Choose a Practice Facility
                        </Typography>
                        <div>
                          <Typography variant="caption" color="textSecondary">
                            Filtered by Organization:{' '}
                            <strong>
                              {currentInfo?.Current.OrganizationInfo?.Name}
                            </strong>
                          </Typography>
                        </div>
                      </div>
                    }
                    RightHeaderItems={
                      <>
                        <PracticeFacilityTable2.StandardFilterSearch />
                        <PracticeFacilityTable2.FilterShowInactiveFacilities />
                      </>
                    }
                    DataTableProps={{
                      rowOptsApplier(row: any) {
                        return (
                          !!row.TerminationDate && { className: 'tr-warning' }
                        )
                      },
                    }}
                  />
                </div>

                {!!selectedPF && (
                  <div>
                    <DesignSuite2023.SubSection
                      style={{
                        margin: '0 auto 1.5rem',
                        padding: '1rem 2rem',
                        width: 'max-content',
                      }}>
                      <Typography variant="h5">{selectedPF.Name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>Launch Date:</strong>{' '}
                        {dateTime.parse(selectedPF.LaunchDate).format()} |{' '}
                        <strong>Term Date:</strong>{' '}
                        {dateTime.parse(selectedPF.TerminationDate).format() ||
                          '(none)'}
                        <br />
                        <strong>Address:</strong>{' '}
                        {selectedPF.Address?.DisplayAddress || '?'}
                        <br />
                        {/* <strong>Organization:</strong> {selectedPF.OrganizationName}<br/> */}
                        <strong>NPI:</strong> {selectedPF.Npi || '?'}
                        <br />
                        <strong>Has Active Fee Schedule:</strong>{' '}
                        {selectedPF.HasActiveFeeSchedule ? 'Yes' : 'No'}
                        <br />
                        <strong>Active Fee Schedule Name:</strong>{' '}
                        {selectedPF.ActiveFeeScheduleName}
                        <br />
                        {/* <strong>Is Default For Org:</strong> {selectedPF.IsDefaultOrganizationBillingEntity ? 'Yes' : 'No'} */}
                      </Typography>
                      {/* <pre>{JSON.stringify(selectedPF, null, '  ')}</pre> */}
                    </DesignSuite2023.SubSection>

                    <div style={{ width: '60%', margin: '0 auto' }}>
                      <Typography variant="body1" paragraph>
                        <span>
                          The Practice Facility above will be assigned to this
                          claim. If this does not look correct, or you intended
                          to select a different one, please use the{' '}
                          <i>Back To Search</i> button below.
                        </span>
                      </Typography>

                      {/* {hasCurrent && (
                      <DesignSuite2023.AlertWarning>
                        The Practice Facility you selected
                      </DesignSuite2023.AlertWarning>
                    )} */}

                      {canHaveDefaulter && !hasDefaulter ? (
                        <>
                          <div style={{ paddingTop: '0.25rem' }}>
                            <RenderSwitchField
                              name="makeDefaulter"
                              value={makeDefaulter}
                              label="Make this Practice Facility the default for all future claims with this source data?"
                              setter={(args: SetterArgs) =>
                                setMakeDefaulter(args.value)
                              }
                            />
                          </div>
                          {makeDefaulter && (
                            <small>
                              By making this the default,{' '}
                              <strong>
                                all other claims with this source record will
                                receive this Practice Facility change
                              </strong>{' '}
                              (unless the Practice Facility was manually
                              assigned on other relevant claims already).
                            </small>
                          )}
                        </>
                      ) : (
                        <Typography variant="caption">
                          This change will be applied to{' '}
                          <strong>only this claim</strong>. There are tools
                          available for managing bulk changes to claims in the{' '}
                          <code>Workflows</code> tab.
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </StyledContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              {!!selectedPF && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setSelectedPF(null)}>
                    Back To Search
                  </Button>
                  &nbsp;
                </>
              )}
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

function SrcAlerts({
  currentInfo,
  selectedPF,
}: any): React.ReactElement | null {
  if (!currentInfo) return null
  const alerts: React.ReactElement[] = []
  // const curr = currentInfo?.Current?.PracticeFacilityInfo
  const couldHaveDefaulter = currentInfo?.SrcServiceFacility?.HasSufficientInfo
  const srcDef = currentInfo?.SrcPracticeFacilityDefaulter

  if (couldHaveDefaulter) {
    if (!srcDef) {
      alerts.push(
        <DesignSuite2023.AlertWarning
          key="srcWarn1"
          style={{ marginTop: '0.5rem' }}>
          <span>
            This source record does not have a default Practice Facility
            assigned.
          </span>
        </DesignSuite2023.AlertWarning>
      )
    }
  } else {
    alerts.push(
      <DesignSuite2023.AlertWarning
        key="srcWarn2"
        style={{ marginTop: '0.5rem' }}>
        <span>
          This source record does not have sufficient information to assign a
          default Practice Facility.
        </span>
      </DesignSuite2023.AlertWarning>
    )
  }

  return <>{alerts}</>
}
