// @ts-ignore
import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findLOAs } from '../../actions/LoaActions'
import LOAStatusPicker from './LOAStatusPicker'
import { columns, LOADecoratedShape } from '../../models/Loa'
import { renderTextField as RenderTextField } from '../../components/Inputs/standard'
import {
  EmployerSearcher,
  OrganizationSearcherV2,
} from '../../components/Searchers'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = columns

const defaultSort = { col: 'loa.date_of_service', dir: 'desc' }

export const Table = forwardRef(function LOATable(
  {
    customColumns = defaultColumns,
    apiEndpoint = findLOAs,
    fnLinkOnRowClick = (row: LOADecoratedShape) => `/loa/${row.ID}`,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
    },
    ref
  )

  return <>{TableDisplay}</>
})

// relevant: https://stackoverflow.com/questions/56472438/select-outlineinput-label-with-shrink-property-not-same-as-textfield-when-empty
export function FilterLOAStatus(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <LOAStatusPicker
      selectedID={filter.statusID}
      onChange={(statusID: number | null) => {
        setFilterImmediate({ statusID })
      }}
    />
  )
}

export function FilterLOAName(): React.ReactElement {
  const { filter, setFilter } = useContext(stdTableSetup.baseContext)

  return (
    <RenderTextField
      name="MemberName"
      label="Name"
      value={filter.MemberName || ''}
      setter={({ name, value }) => {
        setFilter({ ...filter, [name]: value ?? null })
      }}
      opts={{ margin: 'none' }}
      use2023Styles
    />
  )
}

export function FilterLOAEmployer(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      selectedEmployerID={filter.EmployerID}
      onChange={(EmployerID: number | null) => {
        setFilterImmediate({ ...filter, EmployerID })
      }}
    />
  )
}

export function FilterLOAOrganization(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      selectedOrganizationID={filter.OrganizationID}
      onChange={(OrganizationID: number | null) =>
        setFilterImmediate({ ...filter, OrganizationID })
      }
    />
  )
}
