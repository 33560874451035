import { combineReducers } from 'redux'
import PracticeFacilitiesReducer from './reducer_practice_facilities'
import PricesReducer from './reducer_prices'
import AutoSuggestReducer from './reducer_autosuggest'
import AutoSuggestMultiReducer from './reducer_autosuggestmulti'
import EmployersReducer from './reducer_employers'
import EligibilityReducer from './reducer_eligibility'
import OpportunityAnalysisReducer from './reducer_opportunity_analysis'
import ContactsReducer from './reducer_contacts'
import FilesReducer from './reducer_files'
import UsersReducer from './reducer_users'
import SnackbarsReducer from './reducer_snackbars'
import ReferralsReducer from './reducer_referrals'
import GroupsReducer from './reducer_groups'
import FeeSchedulesReducer from './reducer_fee_schedule'
import ComponentFeeSchedulesReducer from './reducer_component_fee_schedule'
import BundlersReducer from './reducer_bundlers'
import PartnerReducer from './reducer_partner'

const rootReducer = combineReducers({
  partners: PartnerReducer,
  practiceFacilities: PracticeFacilitiesReducer,
  prices: PricesReducer,
  autoSuggest: AutoSuggestReducer,
  autoSuggestMulti: AutoSuggestMultiReducer,
  employers: EmployersReducer,
  elig: EligibilityReducer,
  opportunityAnalysis: OpportunityAnalysisReducer,
  contacts: ContactsReducer,
  files: FilesReducer,
  users: UsersReducer,
  snackbar: SnackbarsReducer,
  referrals: ReferralsReducer,
  groups: GroupsReducer,
  feeSchedules: FeeSchedulesReducer,
  componentFeeSchedules: ComponentFeeSchedulesReducer,
  bundlers: BundlersReducer,
})

export default rootReducer
