import React from 'react'
import { Link } from 'react-router-dom'
import { Switch, TextField } from '@material-ui/core'
import utils from '../utils'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AddIcon from '@material-ui/icons/Add'
import ManagedDateInput from '../components/Inputs/managedDateInput'
import dateTime from '../utils/dateTime'

const billingPlanFormatter = (cell, row) => {
  return (
    <BillingPlanFormatter fsPriceID={row.ID} numPlans={row.BillingPlanCount} />
  )
}

const columns = {
  ID: { name: 'ID', details: { editable: false, hidden: true } },
  CostKeyCodeID: {
    name: 'Cost Key',
    details: {
      sortName: 'CostKey',
      editable: false,
      dataFormat: costKeyLinkFormatter,
    },
  },
  CostKeyLaymanDescr: {
    name: 'Cost Key Layman Desc',
    details: { editable: false, dataFormat: laymanDescrFormatter },
  },
  Price: {
    name: 'Price',
    details: {
      width: '100px',
      dataFormat: utils.deprecatedRowTablePriceFormatter,
      editable: true,
      EditComponent: TextField,
      editComponentOpts: { style: { width: 150 } },
      sortName: 'Price',
    },
  },
  DateStart: {
    name: 'Date Start',
    details: {
      dataFormat: dateTime.cellFormatter(),
      editable: false,
      // EditComponent: DateInput,
      EditComponent: (props) => {
        return (
          <ManagedDateInput
            {...props}
            setter={({ /*name,*/ value }) => {
              // see 'handleEditCell' in DataTable.js; the result of this onChange fibs
              // to that method to make it seem like an event so it can take .target.value
              // from it.
              props.onChange &&
                props.onChange({
                  target: {
                    value: value,
                  },
                })
            }}
          />
        )
      },
      editComponentOpts: { style: { width: 150 } },
      sortName: 'DateStart',
    },
  },
  DateEnd: {
    name: 'Date End',
    details: {
      dataFormat: dateTime.cellFormatter(),
      editable: false,
      // EditComponent: DateInput,
      EditComponent: (props) => {
        return (
          <ManagedDateInput
            {...props}
            setter={({ /*name,*/ value }) => {
              // see 'handleEditCell' in DataTable.js; the result of this onChange fibs
              // to that method to make it seem like an event so it can take .target.value
              // from it.
              props.onChange &&
                props.onChange({
                  target: {
                    value: value,
                  },
                })
            }}
          />
        )
      },
      editComponentOpts: { style: { width: 150 } },
      sortName: 'DateEnd',
    },
  },
  ExcludeFromOpp: {
    name: 'Exclude From Opp Analysis',
    details: {
      ...utils.getExcludeFromOppAnalysisEditDetails(),
      editable: true,
      valProperty: 'checked',
      EditComponent: Switch,
    },
  },
  ExcludeFromSearch: {
    name: 'Exclude from Public Search',
    details: {
      dataFormat: utils.boolYesNoFormatter,
      editable: true,
      valProperty: 'checked',
      EditComponent: Switch,
    },
  },
  BillingPlan: {
    name: 'Billing Plan',
    details: { dataFormat: billingPlanFormatter },
  },
  Notes: {
    name: 'Notes',
    details: {
      editable: true,
      EditComponent: TextField,
      editComponentOpts: { style: { width: 250 }, multiline: true, rows: 4 },
    },
  },
}
const productionColumns = Object.assign({}, columns, {
  Price: {
    ...columns.Price,
    details: {
      ...columns.Price.details,
      editable: false,
    },
  },
  DateStart: {
    ...columns.DateStart,
    details: {
      ...columns.DateStart.details,
      editable: false,
    },
  },
  DateEnd: {
    ...columns.DateEnd,
    details: {
      ...columns.DateEnd.details,
      editable: false,
    },
  },
})

const prices = {
  columns: columns,
  productionColumns: productionColumns,
  editFields: {
    Price: 0,
    CostKeyCodeID: '',
    DateStart: '',
    DateEnd: '',
    ExcludeFromOpp: false,
    ExcludeFromSearch: false,
    Notes: '',
  },
  meta: {
    Price: { required: true, typeChecker: utils.isFloat },
    CostKeyCodeID: { required: true },
  },
}

const BillingPlanFormatter = ({ fsPriceID, numPlans }) => {
  const Manage = (
    <span>
      <AttachMoneyIcon size="small" /> Manage
    </span>
  )
  const AddNew = (
    <span>
      <AddIcon size="small" /> Add
    </span>
  )
  const content = numPlans === 0 ? AddNew : Manage

  return (
    <Link to={`/fee_schedule_price/${fsPriceID}/billing_plan`} name="view-link">
      {content}
    </Link>
  )
}

function costKeyLinkFormatter(cell, row) {
  if (row && row.CostKeyCodeID) {
    return (
      <Link to={`/cost_key/${row.CostKey.ID}`} target="_blank" name="view-link">
        {row.CostKey.Code}
      </Link>
    )
  }

  return <div>{row.CostKey.Code}</div>
}

function laymanDescrFormatter(cell, row) {
  return row.CostKey.LaymanDescr
}

export default prices
