import React, { forwardRef, useContext, useMemo, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimFees } from '../../../actions/ZClaimFundingActions'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewInvoiceableClaimCalcOverlay from './ViewInvoiceableClaimCalcOverlay'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

//@ts-ignore
import styled from 'styled-components'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import EditInvoiceableHold from './EditInvoiceableHold'

const TableData = styled.div`
  &.short {
    width: 100px;
    display: flex;
    align-items: center;
  }

  &.long {
    width: 400px;
  }
`

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export interface invoiceableClaimTableRecord {
  ZClaimID: number
  ProviderName: string
  ClaimPayableAmount: string
  ClaimFeeEmployerTotal: string
  ClaimInvoiceTotal: string
  IsReversal: boolean
  OnHold: boolean
  HoldReleaseOn: string
  HoldNotes: string
}

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = (onEditHold: () => void) =>
  Object.freeze({
    _ViewInvoiceableDetails: {
      name: '',
      details: {
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          return <InlineViewInvoiceableDetail data={row} />
        },
      },
    },
    ZClaimID: {
      name: 'ZClaim ID',
      details: {
        sortName: 'ZClaimID',
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          return (
            <TableData className="short">
              {row.ZClaimID}
              {row.IsReversal && (
                <span style={{ color: 'red' }}> [Reversal]</span>
              )}
            </TableData>
          )
        },
      },
    },
    ProviderName: {
      name: 'Provider',
      details: {
        sortName: 'ProviderName',
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          return <TableData className="long"> {row.ProviderName}</TableData>
        },
      },
    },
    PayableAmount: {
      name: 'Claim',
      details: {
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          const amount = parseFloat(
            row.ClaimPayableAmount.replace(/[^0-9.-]+/g, '')
          )
          const style = amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {formatAmount(row.ClaimPayableAmount)}
            </TableData>
          )
        },
        sortName: 'ClaimPayableAmount',
      },
    },
    FeeEmployerTotal: {
      name: 'Fees',
      details: {
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          const amount = parseFloat(
            row.ClaimFeeEmployerTotal.replace(/[^0-9.-]+/g, '')
          )
          const style = amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {formatAmount(row.ClaimFeeEmployerTotal)}
            </TableData>
          )
        },
        sortName: 'ClaimFeeEmployerTotal',
      },
    },
    HoldAmount: {
      name: 'On Hold',
      details: {
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          const amount = row.OnHold
            ? parseFloat(row.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, ''))
            : null
          const style = amount !== null && amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {amount !== null ? (
                <>
                  {formatAmount(row.ClaimInvoiceTotal)}
                  <InlineEditClaimHold data={row} onEditHold={onEditHold} />
                </>
              ) : null}
            </TableData>
          )
        },
      },
    },
    ClaimInvoiceTotal: {
      name: 'Current',
      sortName: 'ClaimInvoiceTotal',
      details: {
        sortName: 'Amount',
        dataFormat(_: any, row: invoiceableClaimTableRecord): any {
          const amount = !row.OnHold
            ? parseFloat(row.ClaimInvoiceTotal.replace(/[^0-9.-]+/g, ''))
            : null
          const style = amount !== null && amount < 0 ? { color: 'red' } : {}
          return (
            <TableData className="short" style={style}>
              {amount !== null ? formatAmount(row.ClaimInvoiceTotal) : null}
            </TableData>
          )
        },
      },
    },
  })

const defaultSort = { col: 'ZClaimID', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    apiEndpoint = findZClaimFees,
    dataTableProps,
    onEditHold,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    return defaultColumns(onEditHold)
  }, [onEditHold])

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        isRowCheckable: () => true,
        ...dataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function InlineViewInvoiceableDetail({
  data,
}: {
  data: invoiceableClaimTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <DesignSuite2023.Tooltip title="View claim calculations">
        <IconButton size="small" onClick={() => ref?.current?.open()}>
          <IconAspectRatio fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <ViewInvoiceableClaimCalcOverlay
        ref={ref}
        zClaimID={data.ZClaimID}
        onClose={() => refresh()}
      />
    </>
  )
}

function InlineEditClaimHold({
  data,
  onEditHold,
}: {
  data: invoiceableClaimTableRecord
  onEditHold?(): void
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DesignSuite2023.Tooltip title="Edit hold">
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <EditInvoiceableHold
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        zClaimID={data.ZClaimID}
        onComplete={() => {
          refresh()
          onEditHold?.()
        }}
      />
    </>
  )
}
