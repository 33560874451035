import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  deleteInvoiceItem,
  getInvoiceItem,
  putInvoiceItem,
  putInvoiceItemParams,
} from '../../../actions/ZClaimFundingActions'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { Autocomplete } from '@material-ui/lab'
import {
  getInvoiceItemTypeDisplay,
  InvoiceItemType,
} from './CreateInvoiceItemDialog'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

interface props {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  invoiceItemID: number
  onComplete?(): void
}

function initialPutInvoiceItemParams(
  invoiceItemID: number
): putInvoiceItemParams {
  return {
    invoiceItemID,
    date: null,
    type: null,
    amount: '',
    displayNotes: '',
    notes: '',
  }
}

export default function EditInvoiceItem({
  isOpen,
  setIsOpen,
  invoiceItemID,
  onComplete,
}: props): React.ReactElement | null {
  const [isWorking, setIsWorking] = useState(false)
  const [putInvoiceItemParams, setPutInvoiceItemParams] =
    useState<putInvoiceItemParams>(initialPutInvoiceItemParams(invoiceItemID))

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen || !invoiceItemID) {
      return
    }

    getData()
  }, [isOpen])

  const invoiceItemTypes = Object.values(InvoiceItemType).map((type) => ({
    value: type,
    label: getInvoiceItemTypeDisplay(type),
  }))

  function handleClose() {
    setPutInvoiceItemParams(initialPutInvoiceItemParams(invoiceItemID))
    setIsOpen(false)
  }

  function getData() {
    return getInvoiceItem(invoiceItemID)
      .then((res: any) => {
        let d = res.Data

        setPutInvoiceItemParams({
          invoiceItemID: d.InvoiceItemID,
          date: d.Date,
          type: d.Type,
          amount: d.Amount,
          displayNotes: d.DisplayNotes,
          notes: d.Notes,
        })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice item',
        })
      )
  }

  function doPutInvoiceItem() {
    setIsWorking(true)

    putInvoiceItem(putInvoiceItemParams)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  function doDeleteInvoiceItem() {
    setIsWorking(true)

    deleteInvoiceItem(invoiceItemID)
      .then(() => {
        showSnackbar(`Deleted invoice item`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPutInvoiceItemParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        // no-op: keep dialog open unless finished or user clicks cancel
      }}
      maxWidth={'lg'}>
      <DialogTitle> {`Edit Invoice Item`}</DialogTitle>
      <DialogContent style={{ width: '1080px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <ManagedDateInput
              name="date"
              label="Date"
              value={putInvoiceItemParams.date}
              margin="none"
              setter={setter}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={invoiceItemTypes}
              getOptionLabel={(option: { label: string }) => option.label}
              value={
                putInvoiceItemParams.type
                  ? invoiceItemTypes.find(
                      (item) => item.value === putInvoiceItemParams.type
                    )
                  : null
              }
              onChange={(
                _event: React.ChangeEvent<{}>,
                newValue: { value: InvoiceItemType } | null
              ) => {
                setter({ name: 'type', value: newValue?.value || null })
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Type"
                  variant="outlined"
                  size={'small'}
                  margin="none"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderPriceField
              name="amount"
              label="Amount"
              value={formatAmount(putInvoiceItemParams.amount)}
              setter={setter}
              opts={{ margin: 'none' }}
              use2023Styles
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <RenderNotesField
              name="displayNotes"
              label="Notes to display on invoice"
              value={putInvoiceItemParams.displayNotes}
              setter={setter}
              use2023Styles
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <RenderNotesField
              name="notes"
              label="Internal notes"
              value={putInvoiceItemParams.notes}
              setter={setter}
              use2023Styles
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <DesignSuite2023.GridLR
          left={
            <>
              <Button
                disabled={isWorking}
                color="secondary"
                variant="outlined"
                size="small"
                onClick={handleClose}>
                Cancel
              </Button>
            </>
          }
          right={
            <>
              <Button
                disabled={
                  isWorking ||
                  !putInvoiceItemParams.type ||
                  !putInvoiceItemParams.amount ||
                  !putInvoiceItemParams.date
                }
                endIcon={<DesignSuite2023.CommonIcons.IconDelete />}
                color="secondary"
                variant="outlined"
                onClick={doDeleteInvoiceItem}>
                {`Delete Invoice Item`}
                {isWorking && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner
                      size={20}
                      show={isWorking}
                    />
                  </>
                )}
              </Button>
              <Button
                disabled={
                  isWorking ||
                  !putInvoiceItemParams.type ||
                  !putInvoiceItemParams.amount ||
                  !putInvoiceItemParams.date
                }
                endIcon={<DesignSuite2023.CommonIcons.IconSave />}
                color="primary"
                variant="outlined"
                onClick={doPutInvoiceItem}>
                {`Update Invoice Item`}
                {isWorking && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner
                      size={20}
                      show={isWorking}
                    />
                  </>
                )}
              </Button>
            </>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
