import React from 'react'
import utils from '../utils'
import dateTime from '../utils/dateTime'
import { getCheckboxFormatter } from '../utils/ReactFormatters'

const OpportunityAnalysis = {
  columns: {
    KeyColumn: 'ID',
    ID: { name: 'ID', details: { editable: false, hidden: true } },
    PatientId: {
      name: 'Patient',
      details: { dataFormat: patientIDFormatter, width: '100px' },
    },
    GroupNr: 'Group',
    StartDate: {
      name: 'Service Start',
      details: {
        dataFormat: dateTime.cellFormatter(dateTime.formats.Calendar),
        width: '100px',
      },
    },
    ServiceLength: { name: 'Length', details: { width: '50px' } },
    CostKeyLayDescr: 'Cost Key Lay Descr',
    CostKey: 'Cost Key',
    GroupPosCode: { name: 'POS', details: { width: '50px' } },
    GroupUB: 'UB',
    TotalAllowed: {
      name: 'Allowed',
      details: { dataFormat: utils.deprecatedRowTablePriceFormatter },
    },
    TotalZero: {
      name: 'Zero Cost',
      details: { dataFormat: utils.deprecatedRowTablePriceFormatter },
    },
    Savings: { name: 'Savings', details: { dataFormat: savingsFormatter } },
    ShowGroup: { name: 'Status', details: { width: '50px' } },
    Notes: 'Notes',
  },
  editFields: {
    PatientId: 'Patient',
    GroupNr: 'Group',
    ClaimNr: 'Claim Number',
    StartDate: 'Service Start',
    ServiceLength: 'Length',
    CptDescr: 'CPT Description',
    CostKey: 'Cost Key',
    GroupPosCode: 'POS',
    GroupUB: 'UB',
    TotalAllowed: 'Allowed',
    TotalZero: 'Zero Cost',
    Savings: 'Savings',
    ShowGroup: 'Status',
    Rule: 'Rule',
    Notes: 'Notes',
    ShowAllForPatient: false,
    IsManuallyChanged: 'Manually Reviewed',
  },
}

const ProspectClaimLines = {
  columns: {
    KeyColumn: 'ID',
    ID: { name: 'ID', details: { hidden: true } },
    ServiceStart: {
      name: 'Service Start',
      details: {
        sortz: true,
        dataFormat: dateTime.cellFormatter(dateTime.formats.Calendar),
        width: '100px',
      },
    },
    GroupNr: { name: 'Group', details: { width: '100px' } },
    CptDescr: 'CPT Description',
    CptCode: {
      name: 'CPT Code',
      details: {
        sortz: true,
        dataFormat: cptAndModifFormatter,
        width: '100px',
      },
    },
    PosCode: {
      name: 'POS',
      details: { width: '50px', dataFormat: overlayFormatter('PosDescr') },
    },
    UbCode: {
      name: 'UB',
      details: { width: '50px', dataFormat: overlayFormatter('UbDescr') },
    },
    DrgCode: {
      name: 'DRG',
      details: { width: '50px', dataFormat: overlayFormatter('DrgDescr') },
    },
    Units: { name: 'Units', details: { width: '50px' } },
    PerformingProviderName: 'Provider',
    AllowedAmount: {
      name: 'Allowed',
      details: {
        sortz: true,
        dataFormat: utils.deprecatedRowTablePriceFormatter,
        width: '125px',
      },
    },
    ZeroPrice: {
      name: 'Zero Price',
      details: {
        dataFormat: utils.deprecatedRowTablePriceFormatter,
        width: '125px',
      },
    },
    IsIgnored: {
      name: 'Ignored',
      details: { dataFormat: getCheckboxFormatter('IsIgnored'), width: '50px' },
    },
  },
  customColumns: (onChangeHandler, onBeforeChangeHandler) => {
    return Object.assign({}, ProspectClaimLines.columns, {
      IsIgnored: {
        name: 'Ignored',
        details: {
          dataFormat: getCheckboxFormatter(
            'IsIgnored',
            onChangeHandler,
            onBeforeChangeHandler
          ),
          width: '50px',
        },
      },
    })
  },
}

function cptAndModifFormatter(cell, row) {
  const { CptModifier } = row
  if (CptModifier) {
    return (
      <span>
        {cell}
        <br />
        {CptModifier}
      </span>
    )
  }

  return <span>{cell}</span>
}

function overlayFormatter(overlayProp) {
  const formatter = (cell, row) => {
    return <span title={row[overlayProp]}>{cell}</span>
  }

  return formatter
}

function savingsFormatter(cell, row) {
  const savings = row.TotalAllowed - row.TotalZero

  return utils.deprecatedRowTablePriceFormatter(savings, null)
}

function patientIDFormatter(cell, row) {
  const patientId = cell

  if (patientId !== '' && patientId.length > 7) {
    return patientId.substr(0, 7)
  }

  return patientId
}

export { OpportunityAnalysis, ProspectClaimLines }
