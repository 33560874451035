import React from 'react'
import utils from '../utils'
import {
  ACHWeekdayOption,
  BillingConfigEditFields,
  BillingMethodOption,
  EditFields,
  Employer,
  EmployerSizeOption,
  EngagementRanking,
  IndustryOption,
  LOAProcessOption,
} from './types/Employer'
import teamZeroUsers from './TeamZeroUsers'
import dateTime from '../utils/dateTime'
import Typography from '@material-ui/core/Typography'
import perms from '../utils/perms'

const EmployerBillingConfig: BillingConfigEditFields = {
  BillingType: 1,
  BillByDivision: false,
  RatePEPM: '0',
  FractionalRateService: '0',
  FractionalRateFarley: '0',
  FractionalRateOther: '0',
  FractionalRateBroker: '0',
  FractionalRateSales: '0',
  FractionalRateFeeBasis: '0',
  OtherRateDescription: '',
  DoNotBillFarleyFeeToCustomer: false,
  ACHWeekday: '',
  BillingBreakoutFees: false,
  BillingMethod: '',
  BillingNotes: '',
  QuickbooksHandle: '',
  Salesperson: '',
  BrokerName: '',
  BrokerCompany: '',
  RateChangeReminder: '',
  RateChangeNote: '',
  BrokerPartnerID: null,
  SalesPartnerID: null,
  OtherPartnerID: null,
}

/*
 * transform function is to be used to take data from the server and make it fit a frontend format for editing.
 */
const employer: Employer = {
  columns: {
    Name: {
      name: 'Name',
      details: {
        width: '250px',
        sortName: 'Employer.Name',
        dataFormat: employerNameFormatter,
      },
    },
    Tier: { name: 'Tier' },
    TerminatedDate: { name: 'TerminatedDate', details: { hidden: true } },
    Active: { name: 'Active', details: { dataFormat: activeFormatter } },
    EmployerExperienceRep: { name: 'Rep' },
    LaunchDate: {
      name: 'Launch Date',
      details: {
        dataFormat: dateTime.cellFormatter(dateTime.formats.Calendar),
        sortName: 'Employer.LaunchDate',
      },
    },
    PlanRenewal: {
      name: 'Plan Renewal',
      details: {
        dataFormat: dayMonthFormatter('PlanRenewalDay', 'PlanRenewalMonth'),
      },
    },
    DisplayAddress: {
      name: 'Address',
      details: { width: '350px', dataFormat: utils.getDisplayAddress },
    },
    PrimaryCarrier: { name: 'PrimaryCarrier' },
  },
  orgReferrerColumns: {
    Name: {
      name: 'Name',
      details: { width: '250px', sortName: 'Employer.Name' },
    },
    Tier: { name: 'Tier' },
    TerminatedDate: { name: 'TerminatedDate', details: { hidden: true } },
    Active: { name: 'Active', details: { dataFormat: activeFormatter } },
    EmployerExperienceRep: { name: 'Rep' },
    LaunchDate: {
      name: 'Launch Date',
      details: {
        dataFormat: dateTime.cellFormatter(dateTime.formats.Calendar),
        sortName: 'Employer.LaunchDate',
      },
    },
    PlanRenewal: {
      name: 'Plan Renewal',
      details: {
        dataFormat: dayMonthFormatter('PlanRenewalDay', 'PlanRenewalMonth'),
      },
    },
    DisplayAddress: {
      name: 'Address',
      details: { width: '350px', dataFormat: utils.getDisplayAddress },
    },
    BillingType: {
      name: 'Billing Type',
      details: { dataFormat: getBillingType },
    },
  },
  canUpdateCore: () => {
    return perms.has(perms.RoleTokens.PermUpdateEmployerCore)
  },
  billingFields: EmployerBillingConfig,
  billingMethodOptions: getBillingMethods(),
  achWeekdayOptions: getACHWeekdayOptions(),
  editFields: {
    ID: 0,
    EmployerID: 0,
    Name: '',
    Descr: '',
    Address: {
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
    },
    Contacts: [],
    IsCustomer: false,
    ZeroCardId: 0,
    LaunchDate: null,
    TerminationDate: null,
    TerminationReason: '',
    Notes: '',
    EmployerExperienceRep: '',
    AllowTravel: false,
    RxSenseCode: '',
    TPA: '',
    PrimaryCarrier: '',
    VbaDivisionCode: '',
    EligibilityNotes: '',
    TIN: '',
    Industry: '',
    EmployerSize: '',
    PlanRenewalDay: '',
    PlanRenewalMonth: '',
    DeductibleStartDay: '',
    DeductibleStartMonth: '',
    DirectPrimaryCare: '',
    Website: '',
    ExclusionNotes: '',
    PhysicalLabcards: false,
    HasHSA: false,
    HasPPO: false,
    HasCobra: false,
    HasRetirees: false,
    ConciergeService: '',
    MarketingName: '',
    TelehealthProvider: '',
    EmployerRelationship: '',
    AllowLogoMemberMarketingUse: false,
    AllowLogoZeroMarketingUse: false,
    Tier: null,
    ConfigurableNetworks: [],
    PHANotes: '',
    LOAProcessType: '',
    LOAProcessTypeNotes: '',
    ClientEngagement: null,
    BrokerEngagement: null,
    LegalName: null,
    AllowEligibilityEmails: false,
    AllowHistoricalEligEmails: false,
    AllowReferralEmails: false,
    AllowEmployerEmails: false,
    AllowMyZeroEmails: false,
  },
  splitCoreDetail: (fields: EditFields) => {
    return {
      core: {
        ID: fields.ID,
        IsCustomer: fields.IsCustomer,
        ZeroCardId: fields.ZeroCardId,
        LaunchDate: fields.LaunchDate,
        TerminationDate: fields.TerminationDate,
        AllowTravel: fields.AllowTravel,
        RxSenseCode: fields.RxSenseCode,
        VbaDivisionCode: fields.VbaDivisionCode,
        Address: fields.Address,
        Name: fields.Name,
      },
      detail: {
        EmployerID: fields.ID,
        Descr: fields.Descr,
        Contacts: fields.Contacts,
        Notes: fields.Notes,
        EmployerExperienceRep: fields.EmployerExperienceRep,
        TPA: fields.TPA,
        PrimaryCarrier: fields.PrimaryCarrier,
        EligibilityNotes: fields.EligibilityNotes,
        TIN: fields.TIN,
        Industry: fields.Industry,
        EmployerSize: fields.EmployerSize,
        PlanRenewalDay: fields.PlanRenewalDay,
        PlanRenewalMonth: fields.PlanRenewalMonth,
        DeductibleStartDay: fields.DeductibleStartDay,
        DeductibleStartMonth: fields.DeductibleStartMonth,
        DirectPrimaryCare: fields.DirectPrimaryCare,
        Website: fields.Website,
        ExclusionNotes: fields.ExclusionNotes,
        PhysicalLabcards: fields.PhysicalLabcards,
        HasHSA: fields.HasHSA,
        HasPPO: fields.HasPPO,
        HasCobra: fields.HasCobra,
        HasRetirees: fields.HasRetirees,
        ConciergeService: fields.ConciergeService,
        MarketingName: fields.MarketingName,
        TelehealthProvider: fields.TelehealthProvider,
        TerminationReason: fields.TerminationReason,
        EmployerRelationship: fields.EmployerRelationship,
        AllowLogoMemberMarketingUse: fields.AllowLogoMemberMarketingUse,
        AllowLogoZeroMarketingUse: fields.AllowLogoZeroMarketingUse,
        Tier: fields.Tier,
        ConfigurableNetworks: fields.ConfigurableNetworks,
        PHANotes: fields.PHANotes,
        LOAProcessType: fields.LOAProcessType,
        LOAProcessTypeNotes: fields.LOAProcessTypeNotes,
        ClientEngagement: fields.ClientEngagement,
        BrokerEngagement: fields.BrokerEngagement,
        LegalName: fields.LegalName,
        AllowEligibilityEmails: fields.AllowEligibilityEmails,
        AllowHistoricalEligEmails: fields.AllowHistoricalEligEmails,
        AllowReferralEmails: fields.AllowReferralEmails,
        AllowEmployerEmails: fields.AllowEmployerEmails,
        AllowMyZeroEmails: fields.AllowMyZeroEmails,
      },
    }
  },
  industryOptions: getIndustryOptions(),
  employerSizeOptions: getEmployerSizeOptions(),
  loaProcessTypeOptions: getLoaProcessTypeOptions(),
  canUpdateBilling: () => {
    return perms.has(perms.RoleTokens.PermUpdateEmployerBilling)
  },
  isActive: isActive,
  legendItems: {
    Inactive: {
      color: 'yellow',
      label: 'Inactive',
    },
  },
  transform: (employer: any): any => {
    if (employer && employer.PlanRenewalDate)
      employer.PlanRenewalDate = dateTime
        .parse(employer.PlanRenewalDate)
        .format()
    return employer
  },
  transformBilling: (billingConfig: BillingConfigEditFields): any => {
    const transformedRateChangeReminder = dateTime
      .parse(billingConfig.RateChangeReminder)
      .format(dateTime.formats.ISODate)

    return Object.assign({}, billingConfig, {
      RatePEPM: parseFloat(billingConfig.RatePEPM),
      FractionalRateOther: utils.convertToRate(
        billingConfig.FractionalRateOther
      ),
      FractionalRateService: utils.convertToRate(
        billingConfig.FractionalRateService
      ),
      FractionalRateFarley: utils.convertToRate(
        billingConfig.FractionalRateFarley
      ),
      FractionalRateFeeBasis: utils.convertToRate(
        billingConfig.FractionalRateFeeBasis
      ),
      FractionalRateBroker: utils.convertToRate(
        billingConfig.FractionalRateBroker
      ),
      FractionalRateSales: utils.convertToRate(
        billingConfig.FractionalRateSales
      ),
      RateChangeReminder: transformedRateChangeReminder,
    })
  },
  validators: {
    LOAProcessTypeNotes: {
      isValid: (val: string) => {
        return val && val.length > 1
      },
      msg: 'Required',
    },
  },
  hasAccess: () => {
    return perms.has(perms.RoleTokens.PermReadEmployer)
  },
  hasTeamZeroUserAccess: () => {
    return (
      teamZeroUsers.hasAccess() && perms.has(perms.RoleTokens.PermReadEmployer)
    )
  },
  getClientEngagementLabel: (e: EngagementRanking) => {
    if (e === null) {
      return 'No ranking assigned'
    }
    const rankings = {
      3: 'Highly Engaged and Fully Support ZERO',
      2: 'Somewhat Engaged. Do not appear to push ZERO',
      1: 'Limited Engagement or High Turnover of Staff',
      0: 'None',
    }

    return rankings[e]
  },
  getBrokerEngagementLabel: (e: EngagementRanking) => {
    if (e === null) {
      return 'No ranking assigned'
    }
    const rankings = {
      3: 'Highly Engaged and Fully Support ZERO',
      2: 'Somewhat Engaged. Do not appear to push ZERO',
      1: 'Unengaged and/or Blocks CS from Engaging Directly with Client',
      0: 'No Broker Involvement',
    }

    return rankings[e]
  },
}

export function isActive(row: any): boolean {
  return dateTime.validationFns.isActive(row.LaunchDate, row.TerminationDate)
}

function employerNameFormatter(cell: any, row: any): any {
  let mktngName = row.MarketingName || '(unset)'
  if (row.MarketingName === row.Name) mktngName = '(same)'
  return (
    <div>
      <div style={{ fontWeight: 600 }}>{cell}</div>
      <Typography color="textSecondary" variant="caption">
        <strong>Marketing: </strong> {mktngName}
      </Typography>
    </div>
  )
}

function dayMonthFormatter(dayKey: string, monthKey: string) {
  return (cell: any, row: any) => {
    return `${row[monthKey]}/${row[dayKey]}`
  }
}

function activeFormatter(cell: any, row: any): string {
  return isActive(row) ? 'Active' : 'Inactive'
}

function getBillingType(cell: any, row: any): string {
  const SERVICE = 1
  const PEPM = 2

  if (row.BillingType === SERVICE) return 'Service'
  if (row.BillingType === PEPM) return 'PEPM'

  return ''
}

function getBillingMethods(): BillingMethodOption[] {
  return [
    { label: 'Debit-Approval', value: 'debit_approval' },
    { label: 'Debit-Auto', value: 'debit_auto' },
    { label: 'Wire/ACH', value: 'ach' },
    { label: 'Check', value: 'check' },
  ]
}

function getEmployerSizeOptions(): EmployerSizeOption[] {
  return [
    { label: 'None', value: '' },
    { label: 'Small Employer (<100)', value: '<100' },
    { label: 'Medium Employer (100-500)', value: '100-500' },
    { label: 'Large Employer (501-2000)', value: '501-2000' },
    { label: 'Extra Large Employer (2000+)', value: '2000+' },
  ]
}

function getIndustryOptions(): IndustryOption[] {
  return [
    { label: 'None', value: '' },
    { label: 'Aerospace', value: 'Aerospace' },
    { label: 'Agriculture', value: 'Agriculture' },
    { label: 'Arms', value: 'Arms' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Chemical', value: 'Chemical' },
    { label: 'Computer', value: 'Computer' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Defense', value: 'Defense' },
    { label: 'Education', value: 'Education' },
    { label: 'Electrical power', value: 'Electrical power' },
    { label: 'Electronics', value: 'Electronics' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Financial services', value: 'Financial services' },
    { label: 'Food', value: 'Food' },
    { label: 'Fruit production', value: 'Fruit production' },
    { label: 'Government', value: 'Government' },
    { label: 'Health care', value: 'Health care' },
    { label: 'Higher Education', value: 'Higher Education' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Information', value: 'Information' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Mass media', value: 'Mass media' },
    { label: 'Mining', value: 'Mining' },
    { label: 'Non-Profit', value: 'Non-Profit' },
    { label: 'Other', value: 'Other' },
    { label: 'Petroleum', value: 'Petroleum' },
    { label: 'Pharmaceutical', value: 'Pharmaceutical' },
    { label: 'Publishing', value: 'Publishing' },
    { label: 'Pulp and paper', value: 'Pulp and paper' },
    { label: 'Service', value: 'Service' },
    { label: 'Shipbuilding', value: 'Shipbuilding' },
    { label: 'Steel', value: 'Steel' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Transport', value: 'Transport' },
    { label: 'Water', value: 'Water' },
  ]
}

function getLoaProcessTypeOptions(): LOAProcessOption[] {
  return [
    { label: 'None', value: '' },
    { label: 'Below 30% Savings Allow', value: 'below 30% savings allow' },
    {
      label: 'Below 30% Savings Ask Client',
      value: 'below 30% savings ask client',
    },
    { label: 'Below 30% Savings Deny', value: 'below 30% savings deny' },
    { label: `No LOA's Allowed`, value: 'no loas allowed' },
  ]
}

function getACHWeekdayOptions(): ACHWeekdayOption[] {
  return [
    { label: '---', value: '' },
    { label: 'Monday', value: 'mon' },
    { label: 'Tuesday', value: 'tue' },
    { label: 'Wednesday', value: 'wed' },
    { label: 'Thursday', value: 'thu' },
    { label: 'Friday', value: 'fri' },
    { label: 'Saturday', value: 'sat' },
    { label: 'Sunday', value: 'sun' },
  ]
}

export default employer
