import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFrameHeightLock } from '../../components/AppFrame'
import {
  RenderSelectField2,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
} from '../../components/Inputs/standard'
// import TableBundlesDataGrid_WIP from './TableBundlesDataGrid_WIP'
import * as TableBundles2 from './TableBundles2'
import { useSAContext } from './saContextProvider'
import BundleDetail from './BundleDetail'
import styled from 'styled-components'
import { shapeSavingsBundle } from './types'
import ButtonGenerateBundles from './ButtonGenerateBundles'
import EmployerSearcher from '../../components/Searchers/EmployerSearcher'
import OrganizationSearcherV2 from '../../components/Searchers/OrganizationSearcherV2'
import { getReasonCodes } from '../../actions/SavingsAnalysisV2'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import { Refresh } from '@material-ui/icons'
import { IconButton, Typography } from '@material-ui/core'
import BtnChooseCostKey from './BtnChooseCostKey'
import DeletableChip from '../ZClaims/Components/DeletableChip'

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CostKeySearch = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
  padding-top: 8px;
  padding-left: 8px;
`

const StyledPageSavingsReview = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  menu {
    margin: 0;
    padding: 1rem;
    margin: 0;
    width: 100%;
    box-shadow: 0px -20px 26px -30px inset;
    background: #e1e1e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;

    button {
      white-space: nowrap;
    }
  }

  section {
    flex: 1;
    display: flex;
    // column-gap: 1rem;
    width: 100%;
    // padding: 1rem;
    height: 100%;
    overflow: hidden;
    background: #fff;

    .columnar {
      // flex: 1;
      height: 100%;
    }

    .MuiDataGrid-root {
      border-radius: 0;
    }
  }

  footer {
    margin: 0;
    padding: 1rem;
    margin: 0;
    width: 100%;
    box-shadow: 0px 20px 26px -30px inset;
    background: #e1e1e1;
  }

  .sa-bundle-detail {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    // .list-bundles {
    //   flex: 1;
    // }

    // .list-related-lines {
    //   flex: 1;
    // }
  }
`

export default function PageSavingsAnalysis(): React.ReactElement {
  // @ts-ignore
  const internalID = React.useId()
  const [selectedBundle, setSelectedBundle] =
    useState<shapeSavingsBundle | null>(null)

  /*
    Filters are separated into two categories: filters and immediateFilters.  Filters are for items that can
    be typed in by the user.  This uses debounce on the request to make sure that as a user types in the value,
    a new request is not sent for each new character
   */

  const [filters, setFilters] = useState<any>({
    BundleID: null,
    ZClaimID: null,
  })

  const [immediateFilters, setImmediateFilters] = useState<any>({
    EmployerID: null,
    OrganizationID: null,
    Status: null,
    ReasonCodeID: null,
    PaidDateStart: null,
    PaidDateEnd: null,
    CostKeyID: null,
    IsLOA: null,
    IsTravel: null,
    IsSynthetic: null,
  })

  const refListBundles = useRef<any>(null)
  const [reasonCodeOptions, setReasonCodeOptions] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()
  const { allCostKeys } = useSAContext()

  const costKeyByID = useMemo(() => {
    let costKeyMap = new Map()
    for (let key of allCostKeys) {
      costKeyMap.set(key.ID, key)
    }
    return costKeyMap
  }, [allCostKeys])

  // @todo: this causes a duplicative call to the API route for the bundles
  // table... its only on first load. No biggie
  useEffect(() => {
    refListBundles?.current?.setFilter?.({ ...filters, ...immediateFilters })
  }, [filters])

  useEffect(() => {
    refListBundles?.current?.setFilterImmediate?.({
      ...filters,
      ...immediateFilters,
    })
  }, [immediateFilters])

  useEffect(() => {
    getReasonCodes()
      .then((res: any) => {
        let reasonCodes = res.Data.map((code: any) => ({
          label: code.Handle,
          value: code.ID,
        })).sort((a: any, b: any) => a.label.localeCompare(b.label))

        setReasonCodeOptions([{ label: 'All', value: 0 }, ...reasonCodes])
      })
      .catch(
        catchAPIError({ defaultMessage: 'Unable to retrieve reason codes' })
      )
  }, [internalID])

  useFrameHeightLock(() => true, [])

  return (
    <StyledPageSavingsReview>
      <menu>
        <div style={{ display: 'inline-flex', columnGap: '1rem' }}>
          <div style={{ maxWidth: '14ex' }}>
            <IconButton size="small">
              <Refresh onClick={() => refListBundles.current?.refresh?.()} />
            </IconButton>
          </div>
          <div style={{ maxWidth: '14ex' }}>
            <RenderTextField
              name="BundleID"
              label="BundleID"
              value={filters.BundleID || ''}
              setter={({ name, value }) => {
                setFilters((curr: any) => ({
                  ...curr,
                  [name]: value ? +value : null,
                }))
              }}
              use2023Styles
              opts={{ margin: 'none', number: true }}
            />
          </div>
          <div style={{ maxWidth: '14ex' }}>
            <RenderTextField
              name="ZClaimID"
              label="ZClaimID"
              value={filters.ZClaimID || ''}
              setter={({ name, value }) => {
                setFilters((curr: any) => ({
                  ...curr,
                  [name]: value ? +value : null,
                }))
              }}
              use2023Styles
              opts={{ margin: 'none', number: true }}
            />
          </div>
          <div>
            <RenderSelectField2
              name="Status"
              label="Status"
              value={immediateFilters.Status || ''}
              setter={({ name, value }) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  [name]: value || null,
                }))
              }}
              use2023Styles
              FormControlProps={{ margin: 'none' }}
              items={[
                { value: '', label: 'All' },
                { value: 'accepted', label: 'Accepted' },
                { value: 'pended', label: 'Pended' },
                { value: 'needs_review', label: 'Needs Review' },
                { value: 'ignored', label: 'Ignored' },
              ]}
            />
          </div>
          <div>
            <RenderSelectField2
              name="ReasonCodeID"
              label="Code"
              value={immediateFilters.ReasonCodeID || 0}
              setter={({ name, value }) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  [name]: value || null,
                }))
              }}
              use2023Styles
              FormControlProps={{ margin: 'none' }}
              items={reasonCodeOptions}
            />
          </div>
          <CostKeySearch>
            <Typography
              component="span"
              variant="body2"
              style={{ color: '#646464' }}>
              CostKey
            </Typography>
            {immediateFilters.CostKeyID && (
              <DeletableChip
                key={immediateFilters.CostKeyID}
                label={costKeyByID.get(immediateFilters.CostKeyID)?.Code}
                onConfirm={() => {
                  setImmediateFilters((curr: any) => ({
                    ...curr,
                    CostKeyID: null,
                  }))
                }}
              />
            )}
            <div style={{ marginLeft: '0.5rem' }}>
              <BtnChooseCostKey
                onChange={(costKey) => {
                  setImmediateFilters((curr: any) => ({
                    ...curr,
                    CostKeyID: costKey?.ID || null,
                  }))
                }}
              />
            </div>
          </CostKeySearch>
          <div>
            <EmployerSearcher
              selectedEmployerID={immediateFilters.EmployerID}
              onChange={(x) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  EmployerID: x,
                }))
              }}
            />
          </div>
          <div>
            <OrganizationSearcherV2
              selectedOrganizationID={immediateFilters.OrganizationID}
              onChange={(x) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  OrganizationID: x,
                }))
              }}
              preloadAll
            />
          </div>
          <div style={{ width: '22ex' }}>
            <ManagedDateInput
              name="PaidDateStart"
              label="Paid Date Start"
              value={immediateFilters.PaidDateStart}
              setter={({ value }) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  PaidDateStart: value,
                }))
              }}
              margin="none"
              propagateOnlyEmptyOrValid
            />
          </div>
          <div style={{ width: '22ex' }}>
            <ManagedDateInput
              name="PaidDateEnd"
              label="Paid Date End"
              value={immediateFilters.PaidDateEnd}
              setter={({ value }) => {
                setImmediateFilters((curr: any) => ({
                  ...curr,
                  PaidDateEnd: value,
                }))
              }}
              margin="none"
              propagateOnlyEmptyOrValid
            />
          </div>
        </div>
        <div>
          <ButtonGenerateBundles
            size="small"
            onDone={() => refListBundles?.current?.refresh?.()}
          />
        </div>
      </menu>

      <section>
        <div className="columnar" style={{ width: '30%' }}>
          {/* <TableBundlesDataGrid_WIP
              ref={refListBundles}
              filters={filters}
              onSelectionChange={(bundles) => {
                if (!bundles?.length) return
                if (bundles.length > 1) {
                  console.warn('Multiple bundles selected')
                  return
                }
                setSelectedBundle(bundles[0])
              }}
            /> */}
          <TableBundles2.Table
            passFilters={filters}
            ref={refListBundles}
            onRowClick={(row: any) => {
              setSelectedBundle(row)
            }}
          />
        </div>
        <div
          className="columnar"
          style={{
            width: '70%',
            padding: '1rem',
            overflow: 'hidden',
            boxShadow: '8px 0 17px -9px rgba(0,0,0,0.25) inset',
          }}>
          <BundleDetail savingsBundleUUID={selectedBundle?.UUID} />
        </div>
      </section>

      <footer>
        <SwitchContainer>
          <RenderSwitchField
            name="IsLOA"
            label="LOA"
            value={immediateFilters.IsLOA || false}
            setter={({ name, value }) => {
              setImmediateFilters((curr: any) => ({
                ...curr,
                [name]: value || null,
              }))
            }}
          />
          <RenderSwitchField
            name="IsTravel"
            label="Travel"
            value={immediateFilters.IsTravel || false}
            setter={({ name, value }) => {
              setImmediateFilters((curr: any) => ({
                ...curr,
                [name]: value || null,
              }))
            }}
          />
          <RenderSwitchField
            name="IsSynthetic"
            label="Synthetic"
            value={immediateFilters.IsSynthetic || false}
            setter={({ name, value }) => {
              setImmediateFilters((curr: any) => ({
                ...curr,
                [name]: value || null,
              }))
            }}
          />
        </SwitchContainer>
      </footer>
    </StyledPageSavingsReview>
  )
}
