import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import * as actions from '../../actions/SavingsAnalysisV2'
import Status from './Status'
import StatusDot from '../ZClaims/Components/StatusDot'
import StringifyCostKey from '../CostKeys/Components/StringifyCostKey'
import ViewCostKeyOverlay from '../CostKeys/Components/ViewCostKeyOverlay'
import ViewClaimDetailOverlay from '../ZClaims/Components/ViewClaimDetailOverlay'
import DesignSuite2023 from '../../components/DesignSuite2023'
import styled from 'styled-components'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

const StyledBundlesTable = styled.div`
  height: 100%;

  // &.zclaims-table-wrap,
  .std-table,
  .base-table-display {
    height: 100%;
  }

  .base-table-display {
    overflow: clip;
    overflow-clip-margin: 1rem;
  }

  .datatable-common-wrapper {
    height: calc(100% - 3rem);
    .MuiTableContainer-root {
      max-height: none;
      height: 100%;
    }
  }

  .MuiTableHead-root {
    position: sticky;
    top: 0;
    background: #f1f1f1;
    z-index: 99;

    th {
      white-space: nowrap;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      white-space: nowrap;
    }
  }

  .std-table {
    &.has-before {
      display: flex;
      .lefty {
        position: sticky;
        top: 20px;
        align-self: start;
        padding-right: 1rem;
        // width: 100%;
        // max-width: 280px;
        width: 280px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
      }
      .base-table-display {
        width: calc(100% - 280px);
      }
    }
    .base-table-display {
      margin-top: 0;
    }
  }
`

const defaultColumns = Object.freeze({
  ID: {
    name: 'ID',
  },
  Status: {
    name: 'Status',
    details: {
      dataFormat(_: any, row: any): any {
        return <Status status={row?.Status} />
      },
    },
  },
  CostKey: {
    name: 'CostKey',
    details: {
      dataFormat(_: any, row: any): any {
        return row.IsRX ? (
          `RX`
        ) : (
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <StringifyCostKey
              highlighters
              bd={row?.CostKey?.BundleDefinition}
              showV2Prefix={false}
            />
            <ViewCostKeyOverlay
              readOnly
              costKeyID={row?.CostKey?.ID}
              Trigger={({ doOpen }: any) => (
                <DesignSuite2023.CommonIcons.IconPeek
                  fontSize="inherit"
                  onClick={doOpen}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              )}
            />
          </span>
        )
      },
    },
  },
  ZClaimIDs: {
    name: 'ZClaimIDs',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <div>
            {row?.ZClaimIDs?.map((id: number) => (
              <ViewClaimDetailOverlay
                readOnly
                key={id}
                zClaimID={id}
                Trigger={({ doOpen }: any) => (
                  <span
                    onClick={doOpen}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      display: 'inline-block',
                      marginRight: '0.1rem',
                    }}>
                    {id}
                  </span>
                )}
              />
            ))}
          </div>
        )
        // return row?.ZClaimIDs?.join(', ')
      },
    },
  },
  LineCount: {
    name: 'LineCount',
  },
  Savings: {
    name: 'Savings',
    details: {
      dataFormat(_: any, row: any): any {
        if (!row?.Computed.CalcdSavingsPct) return '--'

        let color = StatusDot.Colors.RED
        if (row?.Computed.CalcdSavingsPct > 15) color = StatusDot.Colors.YELLOW
        if (row?.Computed.CalcdSavingsPct > 30) color = StatusDot.Colors.GREEN

        return (
          <span>
            <StatusDot.Dot color={color} />
            <span>{row?.Computed.CalcdSavingsPct}%</span>
          </span>
        )
      },
    },
  },
  Employer: {
    name: 'Employer',
    details: {
      dataFormat(_: any, row: any): any {
        return row?.Decorated?.EmployerName || ''
      },
    },
  },
  Provider: {
    name: 'Provider(s)',
    details: {
      dataFormat(_: any, row: any): any {
        return row?.Decorated?.Organizations?.map((o: any) => o.Name).join(', ')
      },
    },
  },
})

const defaultSort = { col: 'sa_savings_bundle.id', dir: 'asc' }

export const Table = forwardRef(function (
  {
    customColumns = defaultColumns,
    apiEndpoint = actions.findSavingsAnalysisBundles,
    initPageSize: propInitPageSize = 100,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: propInitPageSize,
      DataTableProps: {
        ...(passThrough?.DataTableProps || {}),
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250],
      },
    },
    ref
  )

  return <StyledBundlesTable>{TableDisplay}</StyledBundlesTable>
})
