import React, { ReactElement, useState } from 'react'
import {
  PostSendInvoiceNotificationsParams,
  postSendInvoiceReminderNotification,
} from '../../../actions/ZClaimFundingActions'
import { Button } from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import perms from '../../../utils/perms'

interface props {
  invoicedRecords: invoicedRecord[]
  onComplete: () => void
  disabled: boolean
}

export default function ButtonSendInvoiceReminderNotification({
  invoicedRecords,
  onComplete,
  disabled = false,
}: props): ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )
  const markDisabled = disabled || isWorking || !hasPerms

  function doPostSendInvoiceReminderNotifications() {
    setIsWorking(true)
    const params: PostSendInvoiceNotificationsParams = {
      invoiceIDs: invoicedRecords.map((record) => record.InvoiceID),
    }

    postSendInvoiceReminderNotification(params)
      .then(() => {
        showSnackbar(
          'Invoice Reminder Notifications Created',
          SnackbarTypeSuccess
        )
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to create Notifications',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        fullWidth
        disabled={markDisabled}
        variant="outlined"
        color="primary"
        size="small"
        style={{ height: '50px' }}
        onClick={() => doPostSendInvoiceReminderNotifications()}>
        Send Invoice Reminder
        {isWorking && (
          <>
            &nbsp;
            <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
          </>
        )}
      </Button>
    </>
  )
}
