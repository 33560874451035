import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Chip, Popper } from '@material-ui/core'
// import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { getListAllCodesByAcronym } from '../../../actions/MasterDataActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapePOSCode } from '../types'
import styled from 'styled-components'

interface props {
  value: string[] | null | undefined
  onChange?(codes: Array<ShapePOSCode>): void
}

export default function AutoCompletePOSCodes({
  value,
  onChange: propOnChange,
}: props) {
  const [allCodes, setAllCodes] = useState<Array<ShapePOSCode>>([])
  const [selected, setSelected] = useState<Array<ShapePOSCode>>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    loadCodes()
  }, [])

  useEffect(() => {
    if (!allCodes || !value) return
    setSelected(allCodes.filter((c) => value.includes(c.Code)))
  }, [value, allCodes])

  function loadCodes(): void {
    getListAllCodesByAcronym('pos')
      .then((res: any) => {
        if (res.error) throw res
        setAllCodes(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading POS code list',
        })
      )
  }

  function handleChange(e: any, v: Array<any>) {
    propOnChange?.(v)
  }

  const renderInput = React.useCallback(
    (params: any) => (
      <TextField
        {...params}
        size="small"
        variant="outlined"
        label="POS Codes"
        placeholder="POS"
        InputLabelProps={{ shrink: true }}
      />
    ),
    []
  )

  const renderTags = React.useCallback(
    (v: any, getProps: any) =>
      v.map((opt: any, index: number) => (
        <DesignSuite2023.Tooltip key={opt.Code} title={opt.Name}>
          <Chip
            variant="outlined"
            size="small"
            label={opt.Code}
            {...getProps({ index })}
          />
        </DesignSuite2023.Tooltip>
      )),
    []
  )

  const getOptionLabel = React.useCallback(
    (o: any) => `${o.Code} | ${o.Name}`,
    []
  )

  return (
    <Autocomplete
      multiple
      // freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      options={allCodes}
      value={selected}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      renderInput={renderInput}
      renderTags={renderTags}
      PopperComponent={CustomPopper}
    />
  )
}

const CustomPopper = styled(Popper)`
  width: 480px !important;

  .MuiAutocomplete-paper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
`
