import React, { useEffect, useState } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import {
  // Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import {
  ShapeBundleContentTemplateWithContents,
  // ShapeCostKeyBundleContentTemplate,
} from '../types'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar, {
  SnackbarTypeSuccess,
  // SnackbarTypeError,
  // SnackbarTypeWarning,
} from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'

interface pickBundleContentsTemplateProps {
  onSelect?: (v: ShapeBundleContentTemplateWithContents) => void
}

const StyledDialog = styled(Dialog)`
  .template-list-item {
    cursor: pointer;

    > div {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.25rem;
    }

    &:hover {
      background: #f1f1f1;
    }
  }
`

export default function PickBundleContentsTemplate({
  onSelect,
}: pickBundleContentsTemplateProps): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)

  function doOnSelect(v: ShapeBundleContentTemplateWithContents) {
    onSelect?.(v)
    setIsOpen(false)
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Use Template</Button>

      <StyledDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          <span>Pick Bundle Content Template</span>
        </DialogTitle>
        <DialogContent className="template-list">
          {isOpen && (
            <DisplayBundleContentTemplateList
              className="template-list-item"
              onSelect={doOnSelect}
            />
          )}
        </DialogContent>
      </StyledDialog>
    </>
  )
}

interface displayBundleContentTemplateListProps {
  tag?: string
  onSelect?: (v: ShapeBundleContentTemplateWithContents) => void
  showTrashBin?: boolean
  className?: string
}

export const DisplayBundleContentTemplateList = React.forwardRef(function (
  {
    tag = 'div',
    onSelect,
    showTrashBin = false,
    className,
  }: displayBundleContentTemplateListProps,
  ref: any
): React.ReactElement | null {
  const [data, setData] = useState<ShapeBundleContentTemplateWithContents[]>([])
  const [selected, setSelected] =
    useState<ShapeBundleContentTemplateWithContents | null>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  React.useImperativeHandle(
    ref,
    () => ({
      refresh: loadTemplates,
    }),
    [loadTemplates]
  )

  useEffect(() => {
    setData([])
    setSelected(null)
    loadTemplates()
  }, [])

  useEffect(() => {
    if (!selected) return
    onSelect?.(selected)
  }, [selected])

  function loadTemplates() {
    apiGetTemplates()
      .then((res: any) => {
        if (res?.error) throw res
        const items = res?.Data || []
        setData(items)
        if (!!selected?.ID) {
          setSelected(items.find((v: any) => v.ID === selected.ID))
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to load cost key templates',
        })
      )
  }

  function doDeleteTemplate(v: any) {
    const CostKeyBundleContentTemplateID = v?.ID
    if (!CostKeyBundleContentTemplateID) return

    if (!window.confirm('Are you sure you want to delete this template?')) {
      return
    }

    apiDeleteTemplate({ CostKeyBundleContentTemplateID })
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar('Template deleted', SnackbarTypeSuccess)
        loadTemplates()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to remove template',
        })
      )
  }

  const Component = tag as keyof JSX.IntrinsicElements

  return (
    <React.Fragment>
      {data.map((row: any) => (
        <Component
          key={row.ID}
          className={[className, row.ID === selected?.ID ? 'selected' : '']
            .filter((v) => !!v)
            .join(' ')}
          onClick={() => setSelected(row)}>
          <div>
            <span>{row.Name}</span>
            <small>({row?.Contents?.length || 0} items)</small>
          </div>
          {showTrashBin && (
            <DesignSuite2023.CommonIcons.IconDelete
              fontSize="small"
              onClick={() => {
                doDeleteTemplate(row)
              }}
            />
          )}
        </Component>
      ))}
    </React.Fragment>
  )
})

function apiGetTemplates() {
  return api.get('/cost_key_bundle_content_templates')
}

function apiDeleteTemplate(params: { CostKeyBundleContentTemplateID: number }) {
  return api.del(
    `/cost_key_bundle_content_template/${params.CostKeyBundleContentTemplateID}`
  )
}
