import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  getInvoiceItemHold,
  putInvoiceableHoldsParams,
  putInvoiceableHolds,
  getZClaimHold,
} from '../../../actions/ZClaimFundingActions'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import {
  getInvoiceItemTypeDisplay,
  InvoiceItemType,
} from './CreateInvoiceItemDialog'

interface props {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  invoiceItemID?: number
  zClaimID?: number
  onComplete?(): void
}

function initialPutInvoiceableHoldsParams(): putInvoiceableHoldsParams {
  return {
    zClaimIDs: [],
    invoiceItemIDs: [],
    holdReleaseOn: null,
    notes: '',
  }
}

type holdDetail = {
  InvoiceItemID: number | null
  ZClaimID: number | null
  Type: InvoiceItemType | null
  HoldReleaseOn: string | null
  HoldNotes: string | null
  Amount: string
}

function initialHoldDetail(): holdDetail {
  return {
    InvoiceItemID: null,
    ZClaimID: null,
    Type: null,
    HoldReleaseOn: null,
    HoldNotes: null,
    Amount: '',
  }
}

export default function EditInvoiceableHold({
  isOpen,
  setIsOpen,
  invoiceItemID,
  zClaimID,
  onComplete,
}: props): React.ReactElement | null {
  const [isWorking, setIsWorking] = useState(false)
  const [putInvoiceableHoldsParams, setPutInvoiceableHoldsParams] =
    useState<putInvoiceableHoldsParams>(initialPutInvoiceableHoldsParams)
  const [holdDetail, setHoldDetail] = useState<holdDetail>(initialHoldDetail)

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen || invoiceItemID === 0) {
      return
    }

    getData()
  }, [isOpen])

  useEffect(() => {
    setPutInvoiceableHoldsParams(() => ({
      zClaimIDs: holdDetail.ZClaimID === null ? [] : [holdDetail.ZClaimID],
      invoiceItemIDs:
        holdDetail.InvoiceItemID === null ? [] : [holdDetail.InvoiceItemID],
      holdReleaseOn: holdDetail.HoldReleaseOn,
      notes: holdDetail.HoldNotes,
    }))
  }, [holdDetail])

  function handleClose() {
    setPutInvoiceableHoldsParams(initialPutInvoiceableHoldsParams)
    setIsOpen(false)
  }

  function getData() {
    if (invoiceItemID) {
      return getInvoiceItemHold(invoiceItemID)
        .then((res: any) => {
          setHoldDetail(res.Data)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed to get data',
          })
        )
    }

    if (zClaimID) {
      return getZClaimHold(zClaimID)
        .then((res: any) => {
          setHoldDetail(res.Data)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed to get data',
          })
        )
    }
  }

  function doPutInvoiceableHolds() {
    setIsWorking(true)

    putInvoiceableHolds(putInvoiceableHoldsParams)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPutInvoiceableHoldsParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  const hasZClaim = holdDetail.ZClaimID != null && holdDetail.ZClaimID !== 0
  const hasInvoiceItem =
    holdDetail.InvoiceItemID != null && holdDetail.InvoiceItemID !== 0

  const label =
    hasZClaim && !hasInvoiceItem
      ? 'zClaim'
      : hasInvoiceItem && !hasZClaim
        ? 'invoice item'
        : hasInvoiceItem && hasZClaim
          ? 'invoiceable'
          : ''

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        // no-op: keep dialog open unless finished or user clicks cancel
      }}
      maxWidth={'lg'}>
      <DialogTitle> {`Edit ${label} hold`}</DialogTitle>
      <DialogContent style={{ width: '720px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            {holdDetail.InvoiceItemID != null && (
              <Typography variant="body1" gutterBottom={true}>
                {`Invoice Item ID: ${holdDetail.InvoiceItemID}`}
              </Typography>
            )}
            {holdDetail.ZClaimID != null && (
              <Typography variant="body1" gutterBottom={true}>
                {`ZClaimID: ${holdDetail.ZClaimID}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              gutterBottom={
                true
              }>{`Amount: ${formatAmount(holdDetail.Amount)}`}</Typography>
          </Grid>
          {holdDetail.Type && (
            <Grid item xs={3}>
              <Typography
                variant="body1"
                gutterBottom={
                  true
                }>{`Type: ${holdDetail.Type && getInvoiceItemTypeDisplay(holdDetail.Type)}`}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <ManagedDateInput
              name="holdReleaseOn"
              label="Release the hold on (optional)"
              value={putInvoiceableHoldsParams.holdReleaseOn}
              setter={setter}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <RenderNotesField
              name="notes"
              label="Hold Notes"
              value={putInvoiceableHoldsParams.notes}
              setter={setter}
              use2023Styles={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DesignSuite2023.GridLR
          left={
            <>
              <Button
                disabled={isWorking}
                color="secondary"
                variant="outlined"
                size="small"
                onClick={handleClose}>
                Cancel
              </Button>
            </>
          }
          right={
            <>
              <Button
                disabled={isWorking}
                endIcon={<DesignSuite2023.CommonIcons.IconSave />}
                color="primary"
                variant="outlined"
                onClick={doPutInvoiceableHolds}>
                {`Update Hold`}
                {isWorking && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner
                      size={20}
                      show={isWorking}
                    />
                  </>
                )}
              </Button>
            </>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
