import React, { useEffect, useImperativeHandle, useState } from 'react'
import { EmployerDivision } from '../../../models/EmployerDivisions'
import {
  findEmployerDivisions,
  LinkEmployerDivisionParams,
  postLinkEmployerDivision,
} from '../../../actions/EmployerDivisionActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { RenderSelectField2 } from '../../../components/Inputs/standard'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'

interface props {
  employerID: number
  referenceID: string
  observedEmployerDivisionUUID: string
  onClose?(): void
}

export default React.forwardRef(function DialogLinkEmployerDivision(
  { employerID, referenceID, observedEmployerDivisionUUID, onClose }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [selectedEmployerDivision, setSelectedEmployerDivision] =
    useState<EmployerDivision | null>(null)

  const [employerDivisions, setEmployerDivisions] = useState<
    EmployerDivision[]
  >([])

  const { showDurationShort } = useSnackbar()

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!isOpen || !employerID) return
    findEmployerDivisions(employerID, {
      filter: { IsLatest: true, IsRetired: false, DisableLimit: true },
    })
      .then((res: any) => setEmployerDivisions(res.Data))
      .catch(catchAPIError({ defaultMessage: 'Failed fetching division' }))
  }, [isOpen])

  function handleClose() {
    onClose?.()
    setIsOpen(false)
  }

  function doLink() {
    if (!selectedEmployerDivision?.UUID || isWorking) return

    setIsWorking(true)

    let params: LinkEmployerDivisionParams = {
      ObservedEmployerDivisionUUID: observedEmployerDivisionUUID,
      EmployerDivisionUUID: selectedEmployerDivision.UUID,
    }

    postLinkEmployerDivision(employerID, params)
      .then((res: any) => {
        showDurationShort('Division linked successfully', SnackbarTypeSuccess)
        handleClose()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed linking division' }))
      .finally(() => setIsWorking(false))
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth={'xl'}
      onClose={() => {
        // Only allow close via save or cancel
      }}>
      <DialogTitle>Edit Division</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', gap: '1rem', width: '600px' }}>
          <Typography variant="body1">{`ReferenceID: ${referenceID ?? 'Default Division'}`}</Typography>
          <RenderSelectField2
            name="EmployerDivision"
            label="Employer Division"
            value={selectedEmployerDivision?.UUID || ''}
            setter={({ value }) => {
              setSelectedEmployerDivision(
                employerDivisions.find((ed) => ed.UUID === value) || null
              )
            }}
            items={employerDivisions.map((ed) => {
              return { label: ed.Name ?? `Default Division`, value: ed.UUID }
            })}
            FormControlProps={{ margin: 'none' }}
          />
        </div>
        <DesignSuite2023.GridLR
          left={
            <Button color="secondary" variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          }
          right={
            <Button color="primary" variant="contained" onClick={doLink}>
              Save
              {isWorking && (
                <>
                  &nbsp;
                  <DesignSuite2023.LoadingSpinner size={20} show />
                </>
              )}
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  )
})
