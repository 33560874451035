import React, { useEffect, useState, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import debounce from 'lodash/debounce'
import { findCostKeys, getCostKeyByID } from '../../actions/CostKeyActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'

export interface Props {
  selectedCostKeyID: number | null
  onChange(ID: number | null, costKeyObj: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
}

export default function CostKeySearcher({
  selectedCostKeyID = null,
  onChange: PropagateChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disabled = false,
  disableClear = false,
}: Props): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [query, setQuery] = useState<string>('')
  const [options, setOptions] = useState<Array<any>>([])
  const [current, setCurrent] = useState<any>(null)

  useEffect(() => {
    if (!selectedCostKeyID) return
    if (current && current.ID === selectedCostKeyID) return
    getCostKeyByID(selectedCostKeyID)
      .then((res: any) => {
        if (res.error) throw res
        setCurrent(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Cost Key search failed' }))
  }, [selectedCostKeyID, current])

  useEffect(() => {
    if ((query || '').length <= 2) return
    findCostKeys({ filter: { q: query, noLimit: true } })
      .then((res: any) => {
        if (res.error) throw res
        // this is annoying: the Autocomplete component will log a warning
        // if the search results you're currently looking up don't include
        // the current value - this fixes that
        if (current) {
          setOptions([current, ...res.Data])
          return
        }
        setOptions(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Cost Key search failed' }))
  }, [query])

  const onInputChange = useCallback(
    debounce((ev: any, q: string, reason: string) => {
      if (reason === 'reset') return
      setQuery(q)
    }, 350),
    [setQuery]
  )

  function onChange(_: any, v: any, reason: string) {
    setCurrent(v)
    PropagateChange(v ? v.ID : null, v)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      disabled={disabled}
      disableClearable={disableClear}
      options={options}
      getOptionLabel={(opt: any) => opt.Code}
      getOptionSelected={(option: any, value: any) => option.ID === value.ID}
      defaultValue={null}
      value={current}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Cost Key"
          placeholder="Starting typing to search..."
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}
