import * as api from '../services/thezerocard/api-helper'
import { EmployerDivision } from '../models/EmployerDivisions'

export function findEmployerDivisions(employerID: number, params: any) {
  return api
    .search(`/employer/${employerID}/employer_divisions`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function getEmployerDivision(
  employerID: number,
  employerDivisionUUID: string
) {
  return api
    .get(`/employer/${employerID}/employer_division/${employerDivisionUUID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function postEmployerDivision(
  employerID: number,
  division: EmployerDivision
) {
  return api
    .post(`/employer/${employerID}/employer_division`, division)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function putEmployerDivision(
  employerID: number,
  division: EmployerDivision
) {
  return api
    .put(`/employer/${employerID}/employer_division`, division)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function findObservedEmployerDivisions(employerID: number, params: any) {
  return api
    .search(`/employer/${employerID}/observed_divisions`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export interface LinkEmployerDivisionParams {
  ObservedEmployerDivisionUUID: string
  EmployerDivisionUUID: string
}

export function postLinkEmployerDivision(
  employerID: number,
  params: LinkEmployerDivisionParams
) {
  return api
    .post(`/employer/${employerID}/link_employer_division`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export interface EmployerDivisionRetireParams {
  IsRetired: boolean
  EmployerDivisionUUIDs: string[]
}

export function postEmployerDivisionRetire(
  employerID: number,
  params: EmployerDivisionRetireParams
) {
  console.log(`params`, params)
  return api
    .post(`/employer/${employerID}/retire_divisions`, params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}
