import React from 'react'
import { InputAdornment, Paper, Tooltip } from '@material-ui/core'
import { InfoOutlined, Search as IconSearch } from '@material-ui/icons'
import * as LOATable from './Table'
import styled from 'styled-components'

const FilterContainer = styled.div`
  display: flex;
  column-gap: 1rem;
`

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

const ToolTipIcon = styled(InfoOutlined)`
  margin-left: 2px;
  color: #a2a2a2;
  height: 16px;
  width: auto;
  cursor: pointer;
`

const loaInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <IconSearch />
    </InputAdornment>
  ),
  endAdornment: (
    <Tooltip title="LOAs can be searched by ID, CPT Code, and Cost Key Code">
      <ToolTipIcon />
    </Tooltip>
  ),
}

export default function LOAs(): React.ReactElement {
  return (
    <PaperWrapper elevation={2}>
      <LOATable.Table
        enableURLReflection
        passFilters={
          {
            /* none by default... */
          }
        }
        LeftHeaderItems={
          <>
            <FilterContainer>
              <div style={{ minWidth: '16rem' }}>
                <LOATable.StandardFilterSearch
                  autoFocus
                  InputProps={loaInputProps}
                />
              </div>
              <LOATable.FilterLOAStatus />
              <div style={{ minWidth: '16rem' }}>
                <LOATable.FilterLOAName />
              </div>
              <LOATable.FilterLOAEmployer />
              <LOATable.FilterLOAOrganization />
            </FilterContainer>
          </>
        }
      />
    </PaperWrapper>
  )
}
