import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'

import useErrorHandlers from '../../../hooks/useErrorHandlers'
import IconEdit from '@material-ui/icons/Edit'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import { putSABundleNotes } from '../../../actions/SavingsAnalysisV2'

type props = {
  bundleUUID: string
  currentNotes: string
  onClose?(): void
}

export default function DialogSavingsBundleNotes({
  bundleUUID,
  currentNotes,
  onClose,
}: props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const [updatedNotes, setUpdatedNotes] = useState<string>(currentNotes)

  useEffect(() => {
    setUpdatedNotes(currentNotes)
  }, [bundleUUID])

  function handleClose() {
    onClose?.()
    setIsOpen(false)
  }

  function doUpdateNotes() {
    setIsWorking(true)

    putSABundleNotes(bundleUUID, updatedNotes)
      .then((res: any) => {
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed updating notes',
        })
      )
      .finally(() => setIsWorking(false))
  }

  const noteSetter = ({ name, value }: SetterArgs) => {
    setUpdatedNotes(value)
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          setIsOpen(true)
        }}>
        <IconEdit fontSize="inherit" />
      </IconButton>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>Notes</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <RenderNotesField
            name="notes"
            label="Notes"
            value={updatedNotes}
            setter={noteSetter}
          />
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={isWorking}
                  color="primary"
                  variant="outlined"
                  onClick={doUpdateNotes}>
                  Save
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner size={20} show />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
