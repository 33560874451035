import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import useQueryParamsGen2 from './useQueryParamsGen2'
import useErrorHandlers from './useErrorHandlers'
import useRefresh from './useRefresh'
import { useHistory, useLocation } from 'react-router-dom'
import { DataTable } from '../components/DataTable'
import { debounce } from 'lodash'
import {
  Grid,
  GridItemsAlignment,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import DesignSuite2023 from '../components/DesignSuite2023'
import { Cancel as IconCancel, Search as IconSearch } from '@material-ui/icons'
//@ts-ignore
import styled from 'styled-components'

const StyledWrapper = styled.div`
  .header-items {
    margin-top: 0.75rem;
    .header-item-cell {
      flex-grow: 1;
      display: inline-flex;
      align-items: center;

      &.right {
        justify-content: right;
      }
    }
    + .base-table-display {
      margin-top: 12px;
    }
  }

  .MuiTableCell-sizeSmall {
    padding: 8px;
  }

  &.no-wrap-whitespace {
    white-space: nowrap;
  }
`

interface contextData {
  filter: any
  setFilter(v: any): void
  setFilterImmediate(v: any): void
  refresh(): void
  forceLoad(): void
}

export const baseContext = React.createContext<contextData>({} as contextData)

export interface props {
  /*
    TLDR: if you're tempted to mess with passFilters, you better test the ever
    loving hell out of anywhere its used.

    Explanation: passFilters is only honored on the *first* time the component
    gets rendered, then after that (even if you change the object/filters),
    they won't be sent through and merged into the generated request. This happens 
    because there are multiple entry points for editing the final payload ('request' 
    state) - such as APIs for setting the filters from subcomponents ('setFilterImmediate'). 
    If you were to throw a useEffect and watch 'passFilters' as they're coming in, you'd 
    very quickly end up with a) infinite loops, b) state confusion and mismatches. The
    alternative to updating the values passed via passFilters is using setFilterImmediate
    API exposed from the hook.
  */
  passFilters?: any
  customColumns?: any
  onRowClick?(row: any, cmdClicked: boolean): void
  fnLinkOnRowClick?(row: any): string | any
  onCheckHandler?(selected: any[]): void
  DataTableProps?: any
  RightHeaderItems?: React.ReactNode
  LeftHeaderItems?: React.ReactNode
  BeforeTable?: React.ReactNode
  AfterTable?: React.ReactNode
  enableURLReflection?: string | boolean
  headerItemAlignment?: GridItemsAlignment // https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/Grid/Grid.d.ts
  autoLoadData?: boolean
  // Allows overriding the endpoint: (for example, where the route decorates
  // the response with additional data)
  apiEndpoint?(payload: any): Promise<any>
  defaultSort?: { col: string; dir: string }
  initPage?: number
  initPageSize?: number
  showPagination?: boolean
  noWrapWhitespace?: boolean
  isWorkingCallback?: (isWorking: boolean) => void
}

export { useStandardTableSetup }

export default function useStandardTableSetup(
  {
    passFilters = {},
    customColumns = {},
    onRowClick,
    fnLinkOnRowClick,
    onCheckHandler,
    DataTableProps = {},
    RightHeaderItems,
    LeftHeaderItems,
    BeforeTable,
    AfterTable,
    enableURLReflection = false,
    headerItemAlignment = 'center',
    autoLoadData = true,
    apiEndpoint,
    defaultSort,
    initPageSize = 10,
    initPage = 1,
    showPagination = true,
    noWrapWhitespace = false,
    isWorkingCallback,
    ...otherProps
  }: props & Partial<any>,
  ref: any
) {
  const { queryData, setQueryData } = useQueryParamsGen2({
    disabled: enableURLReflection === false,
    scope: typeof enableURLReflection === 'string' ? enableURLReflection : null,
  })
  const { refresh, refreshToken } = useRefresh()
  const { catchAPIError } = useErrorHandlers()
  const history = useHistory()
  const location = useLocation()
  const [tickForceLoad, setTickForceLoad] = useState<number>(0)
  const [results, setResults] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [request, setRequest] = useState<any>({
    filter: {
      q: '',
      ...passFilters,
      ...(!!enableURLReflection ? queryData.filter : {}),
    },
    sort: {
      ...(defaultSort || { col: '', dir: '' }),
      ...(!!enableURLReflection ? queryData.sort : {}),
    },
    pagination: {
      page: initPage,
      pageSize: initPageSize,
      ...(!!enableURLReflection ? queryData.pagination : {}),
    },
  })
  const dataTableProps = {
    ...DataTableProps,
    ...(onCheckHandler ? { checkHandler: onCheckHandler } : {}),
  }
  const refDataTable = useRef()

  /*
    Exposes a public API for this component; parents can call .refresh() on the
    tracked ref in order to trigger an update of the table
  */
  React.useImperativeHandle(
    ref,
    () => ({ refresh, setFilter, setFilterImmediate }),
    []
  )

  // return an assembled payload, ensure all the latest state
  const assemblePayload = useCallback(() => {
    const p = {
      filter: { ...request.filter },
      page: request.pagination.page,
      pageSize: request.pagination.pageSize,
    }
    if (request.sort.col) {
      // @ts-ignore
      p.sort = [request.sort.col, request.sort.dir]
    }
    if (p.filter.q && p.filter.q.length < 3) {
      // @ts-ignore
      p.filter.q = null
    }
    return p
  }, [request])

  // loadData uses a debounce to defer a call to the API
  const loadData = useCallback(
    debounce((payload: any) => {
      setLoading(true)
      apiEndpoint?.(payload)
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setResults(res.Data || [])
          setResultCount(res.Meta?.Total || 0)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed fetching records; please contact Engineering',
          })
        )
        .finally(() => {
          setLoading(false)
        })
    }, 400),
    [setLoading, setResults, setResultCount, apiEndpoint]
  )

  useEffect(() => {
    isWorkingCallback?.(loading)
  }, [loading])

  // Fire a query to the backend if any of the tracked data changes,
  // IF autoLoadData is true (which is the default)
  useEffect(() => {
    !!enableURLReflection &&
      setQueryData(request /*{filter, pagination, sortable}*/)
    !!autoLoadData && loadData(assemblePayload())
  }, [request, loadData, assemblePayload, setQueryData])

  // Fire a query to the backend **immediately**, IF
  // autoLoadData is true (which is the default)
  useEffect(() => {
    if (!autoLoadData) return
    loadData(assemblePayload())
    loadData.flush()
  }, [autoLoadData, refreshToken])

  // On the rare occasion we want to force manual control of when the
  // table will load data (see Eligibility search), then the prop
  // autoLoadData=false... and the two useEffect hooks above will be
  // neutered (do nothing WRT calling the API). Instead of watching the
  // refresh token - this watches the 'tickForceLoad' state (which is the
  // same idea), but lets us wire up a separate hook.
  useEffect(() => {
    if (autoLoadData) return
    if (tickForceLoad === 0) return
    loadData(assemblePayload())
    loadData.flush()
  }, [autoLoadData, tickForceLoad])

  useEffect(() => {
    // @ts-ignore
    refDataTable?.current?.forceSetPagination(request.pagination)
  }, [request?.pagination])

  // If autoLoadData=false, then forceLoad acts as the refresh function.
  // It is not bound to the exported context, but instead is returned by
  // the hook only - so as to force implementing components to need to wire
  // up something like a "Send" or "Search" button that triggers this directly.
  // Again - see the EligibilityTable.
  const forceLoad = useCallback(() => {
    setTickForceLoad((v: number) => {
      return v + 1
    })
  }, [setTickForceLoad])

  const setPagination = useCallback(
    (pagination: any) => {
      setRequest((curr: any) => {
        return { ...curr, pagination }
      })
      refresh()
      forceLoad()
    },
    [setRequest, refDataTable]
  )

  const onChangeRowsPerPage = useCallback(
    (changed: any) => {
      setPagination({ page: 1, pageSize: changed.pageSize })
    },
    [setPagination]
  )

  const setSort = useCallback(
    (sort: any) => {
      setRequest((curr: any) => {
        return { ...curr, sort, pagination: { ...curr.pagination, page: 1 } }
      })
      refresh()
      forceLoad()
    },
    [setRequest, refresh, setPagination]
  )

  const setFilter = useCallback(
    (f: any) => {
      setRequest((curr: any) => {
        return {
          ...curr,
          filter: { ...curr.filter, ...f },
          pagination: { ...curr.pagination, page: 1 },
        }
      })
    },
    [setRequest, refDataTable]
  )

  const setFilterImmediate = useCallback(
    (f: any) => {
      setFilter(f)
      refresh()
    },
    [setFilter, refresh]
  )

  const clearResults = useCallback(() => {
    setResults([])
    setResultCount(0)
  }, [setResults, setResultCount])

  // Default click handler: if an 'onRowClick' prop is passed, it'll proxy to that, passing just
  // the row and cmdClicked (only two things that are relevant), then it'll fall down to trying the
  // fnLinkOnRowClick (which just returns a string to navigate to), using the default/conventional
  // behavior. IOW: there's an order of precedence: 1) onRowClick; 2) fnLinkOnRowClick, 3) nothing
  const rowClickHandler = useCallback(
    (_: any, row: any, cmdClicked: boolean): void => {
      if (onRowClick) {
        onRowClick(row, cmdClicked)
        return
      }
      if (fnLinkOnRowClick) {
        const navTo = fnLinkOnRowClick(row)
        if (cmdClicked) {
          if (typeof navTo === 'object') {
            let path = navTo['pathname']
            if (navTo['search']) {
              path = `${path}?${navTo['search']}`
            }
            window.open(path, `_blank`)
            return
          }
          window.open(navTo, `_blank`)
          return
        }
        if (typeof navTo === 'string') {
          history.push({
            pathname: navTo,
            state: {
              prevSearch: location ? location.search : null,
            },
          })
          return
        }
        history.push({
          ...navTo,
          state: {
            prevSearch: location ? location.search : null,
          },
        })
      }
    },
    [onRowClick, fnLinkOnRowClick]
  )

  const TableDisplay = (
    <baseContext.Provider
      value={{
        filter: request.filter,
        setFilter,
        setFilterImmediate,
        refresh,
        forceLoad,
      }}>
      {/* class std-table isn't used by this component, but is made available so other components can
      target it with specific styles on a case-by-case basis */}
      <StyledWrapper
        className={`std-table ${!!BeforeTable ? 'has-before' : ''} ${!!AfterTable ? 'has-after' : ''} ${!!noWrapWhitespace ? 'no-wrap-whitespace' : ''}`}>
        {(!!LeftHeaderItems || !!RightHeaderItems) && (
          <Grid
            className="header-items"
            container
            spacing={2}
            wrap="nowrap"
            justify="space-between"
            alignItems={headerItemAlignment}>
            {!!LeftHeaderItems && (
              <Grid item xs={12} md="auto" className="header-item-cell left">
                {LeftHeaderItems}
              </Grid>
            )}
            {!!RightHeaderItems && (
              <Grid item xs={12} md="auto" className="header-item-cell right">
                {RightHeaderItems}
              </Grid>
            )}
          </Grid>
        )}

        {BeforeTable}

        <DataTable
          ref={refDataTable}
          className="base-table-display"
          loading={loading}
          keyProp="ID" // this doesn't need to be passable via a prop if needs customization; use dataTableProps instead
          data={results}
          columns={customColumns}
          initPage={request.pagination.page * 1}
          initPageSize={request.pagination.pageSize * 1}
          pagination={showPagination}
          count={resultCount * 1}
          onRowClick={rowClickHandler}
          onChangePage={setPagination}
          onChangeRowsPerPage={onChangeRowsPerPage}
          sortHandler={setSort}
          sortable={request.sort}
          {...dataTableProps}
        />

        {AfterTable}
      </StyledWrapper>
    </baseContext.Provider>
  )

  return {
    TableDisplay,
    setFilterImmediate,
    refresh,
    request,
    forceLoad,
    clearResults,
    filter: request.filter,
  }
}

interface SearchProps {
  autoFocus?: boolean
  tooltip?: any
  InputProps?: any
}

export function StandardFilterSearch({
  autoFocus,
  tooltip,
  InputProps,
  ...spread
}: SearchProps & Partial<any>): React.ReactElement {
  const { filter, setFilter, setFilterImmediate } = useContext(baseContext)

  function clear() {
    setFilterImmediate({ q: '' })
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip} disableFocusListener>
      <TextField
        {...spread}
        autoFocus={!!autoFocus}
        placeholder="Start typing to search"
        size="small"
        variant="outlined"
        value={filter.q}
        onChange={(ev: any) => {
          const q = ev.target?.value
          setFilter({ q })
        }}
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filter.q?.length}
                      size="small"
                      edge="end"
                      onClick={clear}>
                      <IconCancel fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    </DesignSuite2023.Tooltip>
  )
}
