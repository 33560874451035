import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getCostKeyByID,
  postCodeKeyCode,
  putCostKeyCode,
  putEndorseCostKeyByID,
} from '../../actions/CostKeyActions'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../components/DesignSuite2023'
import styled from 'styled-components'
import { Button, Grid, InputAdornment, Typography } from '@material-ui/core'
import {
  ShapeBundleContentRecord,
  ShapeBundleDefinition,
  ShapeCostKeyData,
} from './types'
import CostKeyBuilder from './Components/CostKeyBuilder'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../components/Inputs/standard'
import CostKeySearcher from '../../components/Searchers/CostKeySearcher'
import CostKeyCategorySearcher from '../../components/Searchers/CostKeyCategorySearcher'
import NetworksAutocomplete from '../Networks/NetworksAutocomplete'
import AutoCompleteKeywords from './Components/AutoCompleteKeywords'
import { TagAutocomplete } from '../../components/Tags'
import useDiffChangedFields from '../../hooks/useDiffChangedFields'
import _pick from 'lodash/pick'
import * as FeeSchedulePriceTable from '../FeeSchedules/Prices/FeeSchedulePriceTable'
import BundleContents from './Components/BundleContents'
import { RouteComponentProps } from 'react-router'
import Models from '../../models'
import ConfirmDialog from '../../components/ConfirmDialog'

const StyledContainer = styled.div`
  padding-top: 0.5rem;

  .pad-bottom {
    padding-bottom: 1.5rem;
  }

  textarea {
    font-size: 90%;
  }
`

const StyledTableWrapper = styled.div`
  width: 100%;

  .header-items {
    margin-top: 0;
  }
`

interface props {
  id?: number
  readOnly?: boolean
}

const diffChangedFields = [
  { name: 'Code', label: 'Code' },
  { name: 'Category', label: 'Category' },
  { name: 'Descr', label: 'Descr' },
  { name: 'LaymanDescr', label: 'LaymanDescr' },
  { name: 'BundleContents', label: 'BundleContents' },
  { name: 'RequireUnits', label: 'RequireUnits' },
  { name: 'GroupByServiceDate', label: 'GroupByServiceDate' },
  { name: 'Deprecated', label: 'Deprecated' },
  { name: 'TypicalPPOOverride', label: 'TypicalPPOOverride' },
  { name: 'ManualTypicalPPOPrice', label: 'ManualTypicalPPOPrice' },
  { name: 'PPOOverrideNotes', label: 'PPOOverrideNotes' },
  { name: 'AlwaysOverrideTypicalPPO', label: 'AlwaysOverrideTypicalPPO' },
  { name: 'TagSetID', label: 'TagSetID' },
  { name: 'DRGCode', label: 'DRGCode' },
  { name: 'Notes', label: 'Notes' },
  { name: 'IsMissingPathology', label: 'IsMissingPathology' },
  { name: 'IsMissingImplant', label: 'IsMissingImplant' },
  { name: 'IsMissingXrays', label: 'IsMissingXrays' },
  { name: 'AliasID', label: 'AliasID' },
  { name: 'AllowTravel', label: 'AllowTravel' },
  { name: 'RequireMedicalReview', label: 'RequireMedicalReview' },
  { name: 'LegacyGroupDescr', label: 'LegacyGroupDescr' },
  { name: 'CostKeyCategoryID', label: 'CostKeyCategoryID' },
  { name: 'BundleDefinition', label: 'BundleDefinition' },
  { name: 'ConfigurableNetworks', label: 'ConfigurableNetworks' },
  { name: 'CostKeyKeywords', label: 'CostKeyKeywords' },
]

const priceTableColumns = Object.freeze(
  _pick(FeeSchedulePriceTable.defaultColumns, [
    FeeSchedulePriceTable.PriceTableColumns.FeeScheduleName,
    FeeSchedulePriceTable.PriceTableColumns.Price,
    FeeSchedulePriceTable.PriceTableColumns.DateStart,
    FeeSchedulePriceTable.PriceTableColumns.DateEnd,
    FeeSchedulePriceTable.PriceTableColumns.FeeScheduleDateStart,
    FeeSchedulePriceTable.PriceTableColumns.FeeScheduleDateEnd,
    FeeSchedulePriceTable.PriceTableColumns.Networks,
    FeeSchedulePriceTable.PriceTableColumns.IsValidNow,
  ])
)

export default forwardRef(function CostKeyDetail(
  { id, match, readOnly }: props & Partial<RouteComponentProps<{ id: string }>>,
  ref: any
): React.ReactElement | null {
  const [data, setData] = useState<ShapeCostKeyData>(
    () =>
      ({
        ID: null,
        UUID: null,
        CreatedAt: null,
        UpdatedAt: null,
        ModifiedByUserID: null,
        Code: null,
        Category: null,
        Descr: null,
        LaymanDescr: null,
        BundleContents: null,
        RequireUnits: null,
        GroupByServiceDate: null,
        Deprecated: null,
        TypicalPPOOverride: null,
        ManualTypicalPPOPrice: null,
        PPOOverrideNotes: null,
        AlwaysOverrideTypicalPPO: null,
        TagSetID: null,
        DRGCode: null,
        Notes: null,
        IsMissingPathology: null,
        IsMissingImplant: null,
        IsMissingXrays: null,
        AliasID: null,
        AllowTravel: null,
        RequireMedicalReview: null,
        LegacyGroupDescr: null,
        CostKeyCategoryID: null,
        BundleDefinition: {
          drg: null,
          pos: null,
          primCode: {
            cpt: null,
            mod: null,
            payableWithMods: null,
            requireUnits: false,
          },
          auxCodes: [],
          exclusions: null,
        },
        ConfigurableNetworks: null,
        CostKeyKeywords: null,
        EndorsedByUserID: null,
      }) as ShapeCostKeyData
  )
  const [showDebug, setShowDebug] = useState(false)
  const changeTracker = useDiffChangedFields(data, diffChangedFields)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const costKeyID = React.useMemo(() => {
    return id || Number(match?.params?.id) || null
  }, [id, match])
  const [validations, setValidations] = useState<any[]>([])
  const perms = useMemo(() => {
    return {
      canViewPrices: Models.BundlePrices.hasAccess(),
      canUpdateConfigNets: Models.ConfigurableNetwork.canUpdate(),
    }
  }, [])
  const history = useHistory()

  useEffect(() => {
    if (!costKeyID) return
    loadByID(costKeyID)
  }, [costKeyID])

  function loadByID(ckID: number) {
    return getCostKeyByID(ckID)
      .then((res) => {
        if (res.error) throw res
        if (!res.Data) {
          showSnackbar('Cost Key not found', SnackbarTypeWarning)
          return
        }
        const withAnnoyingConversion = {
          ...res.Data,
          ManualTypicalPPOPrice: Number.isFinite(res.Data.ManualTypicalPPOPrice)
            ? `${(parseFloat(`${res.Data.ManualTypicalPPOPrice}`) / 100).toFixed(2)}`
            : null,
        }
        setData(withAnnoyingConversion)
        changeTracker.setInitialForDiff(withAnnoyingConversion)
        return withAnnoyingConversion
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading cost key',
        })
      )
  }

  function doSave() {
    if (!checkAndSetValidations()) {
      return
    }

    // Yuck: eliminate the need for these silly last-second payload conversions
    const { ManualTypicalPPOPrice, ...rest } = data
    const payload = {
      ...rest,
      APIManualTypicalPPOPrice: ManualTypicalPPOPrice,
      ConfigurableNetworks: (data.ConfigurableNetworks || []).map(
        (n: any) => n.ID
      ),
    }

    const action = payload.ID ? putCostKeyCode : postCodeKeyCode
    action(payload)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Saved OK', SnackbarTypeSuccess)
        return loadByID(res.Data.ID)
      })
      .then((data: any) => {
        if (payload.ID) return
        history.replace(`/cost_key/${data.ID}`)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving cost key',
        })
      )
  }

  function checkAndSetValidations(): boolean {
    const vals = []
    if (!data.BundleDefinition?.primCode?.cpt) {
      vals.push(
        'At the minimum, a CPT code is required in the "Primary" component of the Bundle Definition'
      )
    }
    if (data.TypicalPPOOverride && !data.ManualTypicalPPOPrice) {
      vals.push('Typical PPO Price is required when Override is enabled')
    }
    if (!!data.ManualTypicalPPOPrice) {
      // @ts-ignore: need to convert from GoMoney -> NullDecimal on the backend :(
      const parsed = parseFloat(data.ManualTypicalPPOPrice)
      if (isNaN(parsed)) {
        vals.push('Invalid Typical PPO Price')
      }
    }
    setValidations(vals)
    return vals.length === 0
  }

  function setter({ name, value }: SetterArgs): void {
    setData((prev: ShapeCostKeyData): ShapeCostKeyData => {
      return { ...prev, [name]: value }
    })
  }

  function onDoEndorse() {
    if (!costKeyID) return

    ConfirmDialog({
      title: 'Endorse Cost Key',
      content:
        'This indicates the cost key has been reviewed, and any necessary changes have been made, after the 2024 December updates. Endorsing this cost key indicates this data can be trusted by downstream systems.',
      onConfirm: () => {
        putEndorseCostKeyByID(costKeyID)
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar('Endorsed OK', SnackbarTypeSuccess)
            return loadByID(costKeyID)
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed',
            })
          )
      },
    })
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary xl>
          {data?.Deprecated && (
            <DesignSuite2023.AlertError style={{ marginBottom: '0.5rem' }}>
              This Cost Key is marked as <strong>deprecated</strong> and should
              not be used on any new fee schedules.
            </DesignSuite2023.AlertError>
          )}
          {validations.length > 0 && (
            <div className="validations-view">
              <DesignSuite2023.AlertError style={{ marginBottom: '0.5rem' }}>
                <Typography component="span" variant="body2">
                  <strong>Validation Errors</strong>
                </Typography>
                <ul style={{ padding: '0 0 0 1rem', margin: 0 }}>
                  {validations.map((v, i) => (
                    <li key={i}>{v}</li>
                  ))}
                </ul>
              </DesignSuite2023.AlertError>
            </div>
          )}

          <StyledContainer>
            <div style={{ display: 'flex', columnGap: '1.5rem' }}>
              <div style={{ width: '65%' }}>
                <div>
                  <CostKeyBuilder
                    legacyCodeStr={data?.Code || null}
                    bundleDefinition={data.BundleDefinition || null}
                    setBundleDefinition={(bt: ShapeBundleDefinition) => {
                      setData(
                        (prev: ShapeCostKeyData | null): ShapeCostKeyData => {
                          return {
                            ...prev,
                            BundleDefinition: bt,
                          } as ShapeCostKeyData
                        }
                      )
                    }}
                  />
                </div>

                <DesignSuite2023.Divider style={{ margin: '1.5rem 0' }} />

                <div className="pad-bottom">
                  <RenderNotesField
                    name="Descr"
                    label="Description"
                    value={data.Descr || ''}
                    setter={setter}
                    use2023Styles
                    opts={{
                      margin: 'none',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  />
                </div>
                <div className="pad-bottom">
                  <RenderNotesField
                    name="LaymanDescr"
                    label="LaymanDescr"
                    value={data.LaymanDescr || ''}
                    setter={setter}
                    use2023Styles
                    opts={{
                      margin: 'none',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  />
                </div>
                <div>
                  <RenderNotesField
                    name="Notes"
                    label="Notes"
                    value={data.Notes || ''}
                    setter={setter}
                    use2023Styles
                    opts={{
                      margin: 'none',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  />
                </div>

                <DesignSuite2023.Divider style={{ margin: '1.5rem 0' }} />

                <div>
                  <div
                    style={{
                      background: '#e9e9e9',
                      padding: '1rem',
                      marginBottom: '1.25rem',
                      borderRadius: 7,
                    }}>
                    <Typography component="p" style={{ textAlign: 'center' }}>
                      Bundle Contents
                    </Typography>
                    <BundleContents
                      costKeyID={costKeyID}
                      bundleDefinition={data.BundleDefinition}
                      bundleContents={data.BundleContentsV2}
                      onChangeBundleContents={(
                        v: ShapeBundleContentRecord[]
                      ) => {
                        setter({ name: 'BundleContentsV2', value: v })
                      }}
                      // showVerbalSummary={false}
                      // editable={false}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '35%' }}>
                <div className="pad-bottom">
                  <CostKeyCategorySearcher
                    preloadAll
                    selectedCostKeyCategoryID={data.CostKeyCategoryID}
                    onChange={(v) => {
                      setter({ name: 'CostKeyCategoryID', value: v })
                    }}
                    // AutoCompleteProps={{ disabled: true }}
                    TextFieldProps={{
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    size="small"
                  />
                </div>
                <div className="pad-bottom">
                  <AutoCompleteKeywords
                    currentlySelected={data.CostKeyKeywords || []}
                    onChange={(keywords: any) => {
                      setter({ name: 'CostKeyKeywords', value: keywords })
                    }}
                    TextFieldProps={{
                      size: 'small',
                    }}
                  />
                </div>
                <div className="pad-bottom">
                  {/* @todo: SD-2458: permissions around networks */}
                  <NetworksAutocomplete
                    disabled={true}
                    currentNetworks={data.ConfigurableNetworks || []}
                    handleChange={(networks: Array<any> | null) => {
                      if (!perms.canUpdateConfigNets) return
                      setter({
                        name: 'ConfigurableNetworks',
                        value: networks,
                      })
                    }}
                    getOptionLabel={(option) => {
                      if (!option.UseSpecificCostKeys) {
                        return `${option.Name} (specific cost keys disabled)`
                      }
                      return option.Name
                    }}
                    getOptionDisabled={(option) => !option.UseSpecificCostKeys}
                    TextFieldProps={{
                      InputLabelProps: {
                        shrink: true,
                      },
                      label: 'Configurable Networks',
                      helperText:
                        'Associations to networks can only be managed on the Network page, not here. This is for display purposes only.',
                    }}
                  />
                </div>
                <div>
                  <CostKeySearcher
                    selectedCostKeyID={data.AliasID}
                    TextFieldProps={{
                      InputLabelProps: {
                        shrink: true,
                      },
                      helperText:
                        '"Aliasing allows the network evaluation to treat the current Cost Key similar to the aliased Cost Key"... whatever that means ¯\\_(ツ)_/¯',
                      label: 'Alias existing Cost Key',
                    }}
                    onChange={(_: any, rec: any) => {
                      if (!rec?.ID) {
                        setter({ name: 'AliasID', value: null })
                        return
                      }
                      if (costKeyID === rec?.ID) {
                        showSnackbar(
                          'Cannot alias a cost key to itself',
                          SnackbarTypeWarning
                        )
                        return
                      }
                      setter({ name: 'AliasID', value: rec?.ID })
                    }}
                  />
                </div>

                <DesignSuite2023.Divider />

                <div>
                  <Typography variant="subtitle1">
                    Misc Configurations
                  </Typography>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'max-content 1fr',
                      gridColumnGap: '1rem',
                    }}>
                    <div>
                      <RenderSwitchField
                        name="GroupByServiceDate"
                        label={
                          <MakeSwitchLabelWithInfo
                            label="Group By Service Date"
                            info="Claims Processor will only pay the highest line in the claim with the amount for the entire bundle"
                          />
                        }
                        value={data.GroupByServiceDate || false}
                        setter={setter}
                      />
                    </div>
                    <div>
                      <RenderSwitchField
                        name="AllowTravel"
                        label="Allow Travel"
                        value={data.AllowTravel || false}
                        setter={setter}
                      />
                    </div>
                    <div>
                      <RenderSwitchField
                        name="RequireMedicalReview"
                        label={
                          <MakeSwitchLabelWithInfo
                            label="Require Medical Review"
                            info="This bundle needs prior approval from CMO or Provider Experience as the bundle may be allowed in certain cases but not in others"
                          />
                        }
                        value={data.RequireMedicalReview || false}
                        setter={setter}
                      />
                    </div>
                    <div>
                      <RenderSwitchField
                        name="Deprecated"
                        label="Deprecated"
                        value={data.Deprecated || false}
                        setter={setter}
                      />
                    </div>
                  </div>
                </div>

                <DesignSuite2023.Divider />

                <div>
                  <Typography variant="subtitle1">Pricing Settings</Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <RenderSwitchField
                        name="TypicalPPOOverride"
                        label="Override Typical PPO Price"
                        value={data.TypicalPPOOverride || false}
                        setter={setter}
                      />
                    </Grid>
                    {!!data.TypicalPPOOverride && (
                      <Grid item xs={4}>
                        <RenderPriceField
                          name="ManualTypicalPPOPrice"
                          label="Price"
                          value={`${data.ManualTypicalPPOPrice || ''}`}
                          setter={setter}
                          use2023Styles
                          opts={{
                            margin: 'none',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!!data.TypicalPPOOverride && (
                    <div
                      style={{
                        padding: '0 0 0 1rem',
                        marginLeft: '1rem',
                        borderLeft: '3px solid #eee',
                      }}>
                      <div style={{ paddingBottom: '0.5rem' }}>
                        <RenderSwitchField
                          name="AlwaysOverrideTypicalPPO"
                          label={
                            <MakeSwitchLabelWithInfo
                              label="Always Override Typical PPO Price"
                              info="Always override the typical ppo, or only override when there are less than 10 entries"
                            />
                          }
                          value={data.AlwaysOverrideTypicalPPO || false}
                          setter={setter}
                        />
                      </div>
                      <RenderNotesField
                        name="PPOOverrideNotes"
                        label="PPO Override Notes"
                        value={data.PPOOverrideNotes || ''}
                        setter={setter}
                        use2023Styles
                        opts={{
                          margin: 'none',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      />
                    </div>
                  )}
                </div>

                <DesignSuite2023.Divider />

                <div>
                  <Typography variant="h6">Legacy</Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ marginBottom: '1rem' }}>
                    These are legacy options that had previously been available,
                    but that are no longer relevant or applicable. These will be
                    displayed here until Feb 1st 2025; at which point all cost
                    keys MUST have been updated. They cannot be changed - they
                    are only here for reference.
                  </Typography>
                  <div className="pad-bottom">
                    <TagAutocomplete
                      disabled
                      tagFamily="costkeycode"
                      tagSetID={data.TagSetID}
                      onChangeTagSet={(newTagsetID) => {
                        setter({ name: 'TagSetID', value: newTagsetID })
                      }}
                    />
                  </div>
                  <div className="pad-bottom">
                    <RenderTextField
                      name="LegacyGroupDescr"
                      label="Grouping Term (Legacy)"
                      value={data.LegacyGroupDescr || ''}
                      setter={setter}
                      disabled={true}
                      opts={{
                        margin: 'none',
                        size: 'small',
                        InputLabelProps: {
                          shrink: true,
                        },
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <DesignSuite2023.Tooltip title="This is a legacy concept ZERO had used to group costkeys together with a common tag-like term. Ensure the Category is set instead. This field is displayed here for information purposes only (if the costkey had been previously assigned to a group).">
                                <DesignSuite2023.CommonIcons.IconInfo fontSize="inherit" />
                              </DesignSuite2023.Tooltip>
                            </InputAdornment>
                          ),
                        },
                      }}
                      use2023Styles
                    />
                  </div>
                  <div
                    style={{
                      margin: '-1rem 0 0.5rem',
                      display: 'flex',
                      columnGap: '1rem',
                      alignItems: 'center',
                    }}>
                    <div>
                      <RenderTextField
                        use2023Styles
                        disabled
                        name="DRGCode"
                        label="DRG Code"
                        setter={() => {}}
                        value={data.DRGCode}
                      />
                    </div>
                    <div>
                      <RenderSwitchField
                        name="RequireUnits"
                        label={
                          <MakeSwitchLabelWithInfo
                            label="Require Units"
                            info="Claims processor will respect units when this Cost Key is encountered"
                          />
                        }
                        value={data.RequireUnits || false}
                        setter={setter}
                        opts={{ disabled: true }}
                      />
                    </div>
                  </div>
                  <div style={{ margin: '-0.5rem 0 0.5rem' }}>
                    <RenderTextField
                      use2023Styles
                      disabled
                      name="Category"
                      label="Category"
                      setter={() => {}}
                      value={data.Category}
                    />
                  </div>
                  <div>
                    <Typography>Exclusions</Typography>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '0.5rem',
                      }}>
                      <div>
                        <RenderSwitchField
                          name="IsMissingPathology"
                          label="Pathology"
                          value={data.IsMissingPathology || false}
                          setter={setter}
                          opts={{ disabled: true, margin: 'none' }}
                        />
                      </div>
                      <div>
                        <RenderSwitchField
                          name="IsMissingImplant"
                          label="Implant"
                          value={data.IsMissingImplant || false}
                          setter={setter}
                          opts={{ disabled: true }}
                        />
                      </div>
                      <div>
                        <RenderSwitchField
                          name="IsMissingXrays"
                          label="Xrays"
                          value={data.IsMissingXrays || false}
                          setter={setter}
                          opts={{ disabled: true }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <RenderNotesField
                      name="BundleContents"
                      label="LEGACY BUNDLE CONTENTS"
                      value={data.BundleContents || ''}
                      setter={setter}
                      use2023Styles
                      opts={{
                        disabled: true,
                        margin: 'none',
                        InputLabelProps: {
                          shrink: true,
                        },
                        rowsMax: 100,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </StyledContainer>

          {!!data.UUID && (
            <Typography variant="caption">
              <strong>UUID:</strong> {data.UUID}
              <DesignSuite2023.Tooltip title="A unique identifier that will be used to pinpoint this bundle during fee schedule contracting with providers.">
                <code>What is this?</code>
              </DesignSuite2023.Tooltip>
            </Typography>
          )}

          {showDebug && (
            <DesignSuite2023.StyledPre>
              {JSON.stringify(data, null, '  ')}
            </DesignSuite2023.StyledPre>
          )}
        </DesignSuite2023.LayoutPrimary>

        <DesignSuite2023.LayoutSidebar>
          <Button
            fullWidth
            disabled={readOnly}
            size="small"
            variant="contained"
            color="primary"
            onClick={doSave}>
            Save
          </Button>
          {!!data?.EndorsedByUserID ? (
            <div
              style={{ marginTop: '0.5rem', fontSize: '90%', color: '#777' }}>
              <span>Endorsed by {data.EndorsedByUsername}</span>
            </div>
          ) : (
            <Button
              fullWidth
              disabled={readOnly}
              size="small"
              variant="outlined"
              onClick={onDoEndorse}>
              Endorse
            </Button>
          )}
          <DesignSuite2023.Divider />
          <DesignSuite2023.DisplayRecordMeta record={data} />
          <changeTracker.DisplayChangedFields>
            <DesignSuite2023.Divider />
          </changeTracker.DisplayChangedFields>
          <div>
            <DesignSuite2023.Divider />
            <span
              style={{ fontSize: '85%', opacity: 0.5, cursor: 'pointer' }}
              onClick={() => setShowDebug((v) => !v)}>
              Debug
            </span>
          </div>
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>

      {perms.canViewPrices && !!costKeyID && (
        <DesignSuite2023.LayoutContainer>
          <DesignSuite2023.LayoutPrimary xl style={{ width: '100%' }}>
            <StyledTableWrapper>
              <FeeSchedulePriceTable.Table
                passFilters={{ costKeyId: costKeyID, onlyActive: true }}
                customColumns={priceTableColumns}
                LeftHeaderItems={<Typography variant="h6">Prices</Typography>}
                RightHeaderItems={
                  <>
                    <FeeSchedulePriceTable.FilterToggleInactives />
                    <FeeSchedulePriceTable.StandardFilterSearch />
                  </>
                }
              />
            </StyledTableWrapper>
          </DesignSuite2023.LayoutPrimary>
        </DesignSuite2023.LayoutContainer>
      )}
    </>
  )
})

function MakeSwitchLabelWithInfo({
  label,
  info,
}: {
  label: string
  info: string
}) {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <span style={{ display: 'inline-block', marginRight: '0.25rem' }}>
        {label}
      </span>
      <DesignSuite2023.Tooltip title={info}>
        <DesignSuite2023.CommonIcons.IconInfo fontSize="small" />
      </DesignSuite2023.Tooltip>
    </div>
  )
}
