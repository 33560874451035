/*
@todo: is this file still in use at all? There is a peer model file 'Org.tsx' with
lots of the same stuff still copied over.
*/
import React from 'react'
import { ActiveFormatter } from '../utils/ReactFormatters'
import goZeroUsers from './GoZeroUsers'
import perms from '../utils/perms'

const organization = {
  columns: {
    Name: { name: 'Name', details: {} },
    IsActive: {
      name: 'Active Provider',
      details: { width: '100px', dataFormat: getActive },
    },
    Ranking: { name: 'Ranking' },
    Notes: { name: 'Notes' },
  },
  editFields: {
    ID: 0,
    Name: '',
    LoaOnly: false,
    Notes: '',
    Contacts: [],
    BillingNotes: '',
    PHANotes: '',
    Ranking: 3,
    ZeroRanking: 3,
    RankingNotes: '',
    Descr: '',
    AcceptingReferrals: false,
    LaunchDate: '',
    GoZeroBilling: false,
    EdiBilling: false,
    PaperBilling: false,
    Website: '',
    PaymentMethod: '',
    BillingCompanyName: '',
    Specializations: [],
    ImplementationStatus: '',
    EMRSoftware: null,
    IsReferenceProvider: null,
    ContractingType: null,
    ConfigurableNetworks: [],
  },
  legendItems: {
    Inactive: {
      color: 'yellow',
      label: 'Inactive',
    },
  },
  paymentMethodOptions: [
    { label: 'EFT', value: 'eft' },
    { label: 'Paper Check', value: 'paper_check' },
  ],
  implementationStatusOptions: [
    { label: 'Pricing Review', value: 'Pricing Review' },
    { label: 'Contract Signed', value: 'Contract Signed' },
    { label: 'goZERO Trained', value: 'goZERO Trained' },
    { label: 'Implemented', value: 'Implemented' },
  ],
  rankingDescriptions: {
    1: 'Our relationship is in trouble',
    2: 'Our relationship needs some attention',
    3: 'Our relationship is fine (default)',
    4: 'We have a pretty good relationship with this provider',
    5: 'We have a glowing relationship with this provider',
  },
  hasGoZeroUserAccess: () => {
    return (
      goZeroUsers.hasAccess() && perms.has(perms.RoleTokens.PermReadProvider)
    )
  },
}

function getActive(_, row) {
  return <ActiveFormatter active={row.IsActive} />
}

export default organization
