import React, { useState, useEffect, useRef } from 'react'
import * as ClaimTable from '../../Components/ClaimTable'
import { canExecEngineering } from '../../../../utils/perms'
import { Button } from '@material-ui/core'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import { putRemoveFromTrash } from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../../hooks/useSnackbar'

export default function WorkflowProblematics(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<any>>([])
  const [isWorking, setIsWorking] = useState(false)
  const [canEngeeringOnly] = useState<boolean>(canExecEngineering())
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refTbl = useRef<any>(null)

  useEffect(() => {
    setCheckedItems([])
  }, [])

  const spreaders = {} as any
  if (canEngeeringOnly) {
    spreaders.onCheckHandler = setCheckedItems
  }

  function onDoRemove() {
    if (!(checkedItems?.length > 0)) return
    const zClaimIDs = checkedItems.map((v: any) => v.ID)
    setIsWorking(true)
    putRemoveFromTrash({ zClaimIDs })
      .then(() => {
        showSnackbar('Claims sent to trash', SnackbarTypeSuccess)
        refTbl.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed removing claims from trash',
        })
      )
      .finally(() => setIsWorking(false))
  }

  const btnDisabled = isWorking || !(checkedItems?.length > 0)

  return (
    <ClaimTable.Table
      ref={refTbl}
      {...spreaders}
      passFilters={{ OnlyClaimsInTrash: true }}
      LeftHeaderItems={<ClaimTable.FilterZClaimIDs />}
      RightHeaderItems={
        <div>
          <DesignSuite2023.Tooltip title="Removes selected items from Trash. This action is restricted to Engineering audiences only for the time being (if you do not see Checkboxes on the claim rows, you do not have permission).">
            <span>
              <Button onClick={onDoRemove} disabled={btnDisabled}>
                Remove From Trash
              </Button>
            </span>
          </DesignSuite2023.Tooltip>
        </div>
      }
    />
  )
}
