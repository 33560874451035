import React, { useState, useEffect, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Chip, Popper } from '@material-ui/core'
// import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { getListAllCodesByAcronym } from '../../../actions/MasterDataActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapeCPTCode } from '../types'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

export type CPTCodeAndDescr = Pick<ShapeCPTCode, 'Code' | 'Descr'>

interface props {
  value: string[] | null | undefined
  onChange?(codes: Array<CPTCodeAndDescr>): void
  TextFieldProps?: any
  multiple?: boolean
}

export default function AutoCompleteCPTCodes({
  value,
  onChange: propOnChange,
  TextFieldProps,
  multiple = true,
}: props) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [allCodes, setAllCodes] = useState<Array<CPTCodeAndDescr>>([])
  const [selected, setSelected] = useState<Array<CPTCodeAndDescr>>([])
  const [searchText, setSearchText] = useState<string>('')
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setSelected(value?.map((v) => ({ Code: v, Descr: '' })) || [])
  }, [value])

  const dbncOnSearchTextChange = useCallback(
    debounce((q: string) => {
      loadCodes(q)
    }, 500),
    []
  )

  useEffect(() => {
    if (!searchText) {
      setAllCodes([])
      return
    }
    dbncOnSearchTextChange(searchText)
  }, [searchText, selected])

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...TextFieldProps}
        {...params}
        size={TextFieldProps?.size || 'small'}
        variant={TextFieldProps?.variant || 'outlined'}
        label={TextFieldProps?.label || 'CPT Codes'}
        placeholder={TextFieldProps?.placeholder || 'CPT'}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading && <DesignSuite2023.LoadingSpinner />}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
        helperText={isLoading ? 'Type at least 3 characters to search' : ''}
      />
    ),
    [TextFieldProps, isLoading]
  )

  const renderTags = useCallback(
    (v: any, getProps: any) =>
      v.map((opt: any, index: number) => (
        <Chip
          key={opt.Code}
          variant="outlined"
          size="small"
          label={opt.Code}
          {...getProps({ index })}
        />
      )),
    []
  )

  const getOptionLabel = useCallback((o: any) => {
    if (o?.Descr) {
      return `${o.Code} | ${o.Descr}`
    }
    return o?.Code
  }, [])

  const onInputChange = useCallback((ev: any, q: string, r: string) => {
    setIsLoading(q.length === 0 ? false : true)
    setSearchText(q || '')
  }, [])

  function loadCodes(q: string): void {
    if (q?.length < 3) return
    getListAllCodesByAcronym('cpt', { q })
      .then((res: any) => {
        if (res.error) throw res
        setAllCodes(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading CPT code list',
        })
      )
  }

  function handleChange(
    e: any,
    v: CPTCodeAndDescr | Array<CPTCodeAndDescr> | any // 'any' to shut TS up
  ) {
    propOnChange?.(v)
  }

  return (
    <Autocomplete
      multiple={multiple}
      freeSolo // https://stackoverflow.com/a/72732562
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      filterSelectedOptions
      options={allCodes}
      value={selected}
      getOptionLabel={getOptionLabel}
      inputValue={searchText}
      onInputChange={onInputChange}
      onChange={handleChange}
      renderInput={renderInput}
      renderTags={renderTags}
      PopperComponent={CustomPopper}
      getOptionSelected={(option, value) => {
        // need to use 'freeSolo' prop to get the 'getOptionSelected' warning
        // from material-ui to go away
        return option.Code === value.Code
      }}
    />
  )
}

const CustomPopper = styled(Popper)`
  width: 380px !important;

  .MuiAutocomplete-paper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
`
