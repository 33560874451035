import utils from '../utils'
import perms from '../utils/perms'

interface GzUsers {
  columns: cols
  permissionColumns: permCols
  editFields: any
  hasAccess: any
}

interface cols {
  FirstName: col
  LastName: col
  Email: col
  CanManageOtherUsers: col
}

interface col {
  name: string
  details?: any
}

interface permCols {
  ViewX: col // @todo: SD-816
  ViewY: col // @todo: SD-816
}

const goZeroUsers: GzUsers = {
  columns: {
    FirstName: {
      name: 'First Name',
      details: { dataFormat: getFromUserInfo('FirstName') },
    },
    LastName: {
      name: 'Last Name',
      details: { dataFormat: getFromUserInfo('LastName') },
    },
    Email: { name: 'Email', details: { dataFormat: getFromUserInfo('Email') } },
    CanManageOtherUsers: {
      name: 'Is Admin',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
  },
  permissionColumns: {
    ViewX: { name: 'ViewX', details: { dataFormat: utils.boolYesNoFormatter } },
    ViewY: { name: 'ViewY', details: { dataFormat: utils.boolYesNoFormatter } },
  },
  editFields: {},
  hasAccess: () => perms.has(perms.RoleTokens.PermReadGoZeroUser),
}

function getFromUserInfo(colName: string): any {
  return (cell: any, row: any) => {
    return row.UserInfo[colName]
  }
}

export default goZeroUsers
