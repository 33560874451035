import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { SetterArgs } from '../../../components/Inputs/standard'
import {
  postInvoice,
  postInvoiceParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import perms from '../../../utils/perms'

interface props {
  zClaimIDs: number[]
  invoiceItemIDs: number[]
  invoiceCount: number
  invoiceCurrent: string
  onComplete?(): void
  disabled?: boolean
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

function initialPostInvoiceParams(): postInvoiceParams {
  return {
    invoiceDate: '',
    zClaimIDs: [],
    invoiceItemIDs: [],
  }
}

export default function IssueInvoices({
  zClaimIDs,
  invoiceItemIDs,
  invoiceCount,
  invoiceCurrent,
  onComplete,
  disabled = false,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postInvoiceParams, setPostInvoiceParams] = useState<postInvoiceParams>(
    initialPostInvoiceParams()
  )
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )
  const markDisabled = isWorking || disabled || !hasPerms

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      zClaimIDs: zClaimIDs,
      invoiceItemIDs: invoiceItemIDs,
    }))
  }, [zClaimIDs, invoiceItemIDs])

  useEffect(() => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      invoiceDate: '',
    }))
  }, [isOpen])

  function doInvoice() {
    if (!hasPerms) {
      showSnackbar('Insufficient permission', SnackbarTypeWarning)
      return
    }

    setIsWorking(true)

    postInvoice(postInvoiceParams)
      .then(() => {
        showSnackbar(`Invoices issued OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed issuing invoices',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPostInvoiceParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Invoice selected items">
        <span>
          <Button
            disabled={markDisabled}
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconFile />}
            onClick={() => {
              setIsOpen(true)
            }}>
            {'Invoice' +
              (invoiceCount > 0
                ? ` (${invoiceCount}) ${formatAmount(invoiceCurrent)}`
                : '')}
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>Issue Invoices</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="invoiceDate"
                  label="Invoice Date"
                  value={postInvoiceParams.invoiceDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  Invoice count: {invoiceCount}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  Total: {formatAmount(invoiceCurrent)}
                </Typography>
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking ||
                    !postInvoiceParams.invoiceDate ||
                    (!zClaimIDs.length && !invoiceItemIDs.length) ||
                    (zClaimIDs.length === 0 && invoiceItemIDs.length === 0)
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doInvoice}>
                  Issue Invoices
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
