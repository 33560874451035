import React from 'react'
import styled from 'styled-components'
import {
  Exclusions,
  ShapeBundleDefinition,
  ShapeBundleDefinitionCell,
} from '../types'
import DesignSuite2023 from '../../../components/DesignSuite2023'

const StyledCostKey = styled.em`
  display: block;
  font-style: normal;
  line-height: 1;
  user-select: all;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: 400;
  letter-spacing: 0.01em;

  span {
    display: inline-block;
    padding: 3px;
    border-radius: 6px;

    span {
      padding: 3px 0;
    }

    &.sep {
      padding: 3px;
    }

    &.primary {
      text-decoration: underline;
    }

    &:hover {
      background: rgba(116, 71, 170, 0.45);
    }

    &.v2-pre {
      font-size: 70%;
      opacity: 0.5;

      &:hover {
        background: none;
      }
    }
  }
`

interface props {
  highlighters?: boolean
  bd: ShapeBundleDefinition | null
  showV2Prefix?: boolean
  invalidText?: string
}

export default function StringifyCostKey({
  highlighters = false,
  bd,
  showV2Prefix = true,
  invalidText = 'invalid_cost_key',
}: props): any {
  const invalid = (
    <DesignSuite2023.Tooltip title='This means the cost key bundle definition is invalid or does not exist (likely cause: the cost key was missing a "billing translation")'>
      <StyledCostKey className="styled-cost-key invalid">
        {invalidText}
      </StyledCostKey>
    </DesignSuite2023.Tooltip>
  )
  if (!bd) return invalid

  // @ts-ignore
  bd.primCode._isPrim = true

  let ordered = (
    [bd.primCode, ...(bd.auxCodes || [])] as ShapeBundleDefinitionCell[]
  )
    .sort((a: any, b: any) => {
      if (a.cpt < b.cpt) return -1
      if (a.cpt > b.cpt) return 1
      return 0
    })
    .filter((c: ShapeBundleDefinitionCell) => !!c.cpt)

  const sortedPOSCodes = (bd.pos || []).sort()

  if (!highlighters) {
    let asStr = ordered
      .map((c: ShapeBundleDefinitionCell): string | null => {
        let s = c.cpt
        if (c.mod) s += `-${c.mod}`
        if (c.payableWithMods && c.payableWithMods?.length > 0) {
          s += `?${[...c.payableWithMods].sort().join('|')}`
        }
        if (c.requireUnits) s += '#RU'
        return s
      })
      .join('~')

    if (!asStr) return invalid

    if (sortedPOSCodes.length > 0) {
      asStr += `~POS:${sortedPOSCodes.join('|')}`
    }

    if (bd.drg) {
      asStr += `~DRG:${bd.drg}`
    }

    if (bd.exclusions?.path || bd.exclusions?.impl) {
      const keys = Object.keys(bd.exclusions).filter(
        (k: string) => !!(bd.exclusions as any)[k]
      )
      const shorts = keys.map((k: string) => k.substring(0, 2).toUpperCase())
      asStr += `~!${shorts.join('|')}`
    }

    return <StyledCostKey className="styled-cost-key">{asStr}</StyledCostKey>
  }

  let fancied = ordered
    .map((c: ShapeBundleDefinitionCell): any => {
      const k = `${c.cpt}.${Math.random()}`
      return (
        <React.Fragment key={k}>
          <DesignSuite2023.Tooltip
            key={k}
            title={`Component${c._isPrim ? ' (PRIMARY)' : ''}`}>
            <span key={k} className={`component`}>
              <DesignSuite2023.Tooltip
                title={`CPT: ${c.cpt}`}
                placement="bottom">
                <span className={`cpt ${c._isPrim && 'primary'}`}>{c.cpt}</span>
              </DesignSuite2023.Tooltip>
              {c.mod && (
                <DesignSuite2023.Tooltip
                  title={`Modifier (required)`}
                  placement="bottom">
                  <span>-{c.mod}</span>
                </DesignSuite2023.Tooltip>
              )}
              {c.payableWithMods && (c.payableWithMods || []).length > 0 && (
                <DesignSuite2023.Tooltip
                  title={`"Payable with" any of modifiers: ${[...c.payableWithMods].sort().join(', ')}`}
                  placement="bottom">
                  <span>?{[...c.payableWithMods].sort().join('|')}</span>
                </DesignSuite2023.Tooltip>
              )}
              {c.requireUnits && (
                <DesignSuite2023.Tooltip
                  title="Require Units"
                  placement="bottom">
                  <span>#RU</span>
                </DesignSuite2023.Tooltip>
              )}
            </span>
          </DesignSuite2023.Tooltip>
        </React.Fragment>
      )
    })
    .filter((v) => !!v)

  if (!fancied.length) return invalid

  if (sortedPOSCodes.length > 0) {
    fancied.push(
      <DesignSuite2023.Tooltip
        title={`Place of Service (required); any of ${sortedPOSCodes.join(', ')}`}
        placement="bottom"
        key={`pos-${bd.pos}`}>
        <span key="pos">POS:{sortedPOSCodes.join('|')}</span>
      </DesignSuite2023.Tooltip>
    )
  }

  if (bd.drg) {
    fancied.push(
      <DesignSuite2023.Tooltip
        title={`DRG (required)`}
        placement="bottom"
        key={`drg-${bd.drg}`}>
        <span key="drg">DRG:{bd.drg}</span>
      </DesignSuite2023.Tooltip>
    )
  }

  if (bd.exclusions?.path || bd.exclusions?.impl) {
    const keys = Object.keys(bd.exclusions).filter(
      (k: string) => !!(bd.exclusions as any)[k]
    )
    const labels = keys.map((k: string) => Exclusions[k])
    const shorts = keys.map((k: string) => k.substring(0, 2).toUpperCase())
    fancied.push(
      <DesignSuite2023.Tooltip
        title={`Excludes: ${labels.join(', ')}`}
        placement="bottom"
        key={`excl-${keys.join('|')}`}>
        <span key="excl">!{shorts.join('|')}</span>
      </DesignSuite2023.Tooltip>
    )
  }

  let echo = []
  if (showV2Prefix) {
    echo.push(
      <span className="v2-pre" key="v2-pre">
        [v2]
      </span>
    )
  }
  for (let i = 0; i < fancied.length; i++) {
    echo.push(fancied[i])
    if (i < fancied.length - 1)
      echo.push(
        <span className="sep" key={`sep${i}`}>
          ~
        </span>
      )
  }

  return <StyledCostKey className="styled-cost-key">{echo}</StyledCostKey>
}
