import React, { useState, useEffect } from 'react'
import * as api from '../../services/thezerocard/api-helper'
// import {
//   findCostKeyCategories,
//   getCostKeyCategoryByID,
// } from '../../actions/CostKeyActions'

type ShapeCategoryThreshold = {
  CostKeyCategoryID: number
  Label: string
  High: number
  Low: number
}

export default function PageSettings(): React.ReactElement {
  const [ckCategories, setCKCategories] = useState<any[]>([])

  useEffect(() => {
    loadCategories()
  }, [])

  function loadCategories() {
    apiLoadCategoryThresholds().then((res: any) => {
      if (res.error) throw res
      setCKCategories(res.Data)
    })
  }

  function onSave(ev: any) {
    ev.preventDefault()
    const fd = new FormData(ev.target)
    const data = Object.fromEntries(fd)
    const payload = {
      costKeyCategoryID: +data.costKeyCategoryID,
      high: +data.high / 100,
      low: +data.low / 100,
    }
    api.put('/sa/category_threshold', payload).then((res: any) => {
      if (res.error) throw res
      return loadCategories()
    })
  }

  return (
    <div>
      <h5>Bundle Category Thresholds</h5>
      <p>
        Thresholds are used to flag bundles where the Paid Amount is out of
        bounds against High And Low percentages of typical PPO prices. @todo:
        explain this better
      </p>

      <div
        style={{
          display: 'grid',
          alignItems: 'center',
          columnGap: '1rem',
          rowGap: '0.5rem',
          gridTemplateColumns:
            'min-content min-content min-content min-content',
          whiteSpace: 'nowrap',
        }}>
        <strong>Category</strong>
        <strong>High</strong>
        <strong>Low</strong>
        <span></span>
        {ckCategories.map((v: any) => (
          <form
            key={v.CostKeyCategoryID}
            onSubmit={onSave}
            style={{
              display: 'contents',
            }}>
            <input
              type="hidden"
              name="costKeyCategoryID"
              value={v.CostKeyCategoryID}
            />
            <span>{v.Label}</span>
            <div>
              <input
                defaultValue={v.High * 100 || ''}
                name="high"
                type="number"
                size={4}
                min="0"
                max="100"
              />
              %
            </div>
            <div>
              <input
                defaultValue={v.Low * 100 || ''}
                name="low"
                type="number"
                size={4}
                min="0"
                max="100"
              />
              %
            </div>
            <div>
              <button type="submit">Save</button>
            </div>
          </form>
        ))}
      </div>
    </div>
  )
}

function apiLoadCategoryThresholds(): Promise<{
  error?: boolean
  Data?: ShapeCategoryThreshold[]
}> {
  return api.get('/sa/category_threshold')
}
