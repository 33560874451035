import {
  getEmployerReceiptDetail,
  PostApplyEmployerReceiptRecord,
  postApplyEmployerReceipts,
  PostApplyEmployerReceiptsParams,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { RenderPriceField, SetterArgs } from '../../ViewHelpers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import dateTime from '../../../utils/dateTime'
import VoidReceipt from './VoidReceipt'
import DesignSuite2023 from '../../../components/DesignSuite2023'

import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

interface appliedEmployerReceipt {
  ReceiptInvoiceID: string
  ReceiptID: number
  ReceiptDate: string | null
  ReceiptType: receiptType
  CheckNumber: string
  ACHInfo: string
  ReceiptAmount: string
  AppliedAmount: string
  Notes: string
}

interface relatedInvoiceInfo {
  InvoiceID: number
  InvoiceNumber: number
  InvoiceDate: string | null
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  AppliedReceipts: appliedEmployerReceipt[]
}

interface employerReceiptInfo {
  ReceiptID: number
  ReceiptDate: string | null
  EmployerID: number
  PayerName: string
  EmployerName: string
  EmployerLegalName: string
  EmployerQuickbooksHandle: string
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
  UnappliedAmount: string
  IsReversed: boolean
  ReversalDate: string | null
  RelatedInvoiceInfo: relatedInvoiceInfo[]
}

const initialEmployerReceiptInfoRecord = (): employerReceiptInfo => ({
  ReceiptID: 0,
  ReceiptDate: dateTime.now().format(dateTime.formats.ISODate),
  EmployerID: 0,
  EmployerName: '',
  EmployerLegalName: '',
  EmployerQuickbooksHandle: '',
  PayerName: '',
  ReceiptType: receiptType.CHECK,
  CheckNumber: '',
  Notes: '',
  ReceiptAmount: '',
  AppliedAmount: '',
  UnappliedAmount: '',
  IsReversed: false,
  ReversalDate: null,
  RelatedInvoiceInfo: [],
})

interface receiptDetailProps {
  receiptID: number
  doClose?(): void
}

const ReceiptDetailDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`

export default function ReceiptDetail({
  receiptID,
  doClose,
}: receiptDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null
  const [isWorking, setIsWorking] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [data, setData] = useState<employerReceiptInfo>(
    initialEmployerReceiptInfoRecord
  )

  useEffect(() => {
    getData()
  }, [receiptID])

  function getData() {
    if (receiptID === 0) {
      setData(initialEmployerReceiptInfoRecord)
      return
    }
    return getEmployerReceiptDetail(receiptID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  // map by invoiceID
  const [postApplyEmployerReceiptRecords, setPostApplyEmployerReceiptRecords] =
    useState<Map<number, PostApplyEmployerReceiptRecord>>(new Map())

  function doPostApplyReceipt(invoiceID: number) {
    // Get the record for the given invoiceID
    const record = postApplyEmployerReceiptRecords.get(invoiceID)

    // If there's no record for the given invoiceID, we can't proceed
    if (!record) {
      console.error(`No record found for invoiceID: ${invoiceID}`)
      return
    }

    setIsWorking(true)

    // Create an array with the single record
    const params: PostApplyEmployerReceiptsParams = [record]

    postApplyEmployerReceipts(params)
      .then(() => {
        setAmountByInvoiceID({ value: '', invoiceID })
        getData()
        showSnackbar(`Receipt posted OK`, SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  function getReceiptInfo() {
    if (data.ReceiptType === receiptType.CHECK) {
      return `Check Number: ${data.CheckNumber}`
    } else if (data.ReceiptType === receiptType.ACH) {
      return 'ACH'
    }
  }

  interface setByInvoiceIDArgs {
    value: string
    invoiceID: number
  }

  const setAmountByInvoiceID = ({ value, invoiceID }: setByInvoiceIDArgs) => {
    setPostApplyEmployerReceiptRecords((prevRecords) => {
      let record = prevRecords.get(invoiceID)
      if (record) {
        // Update the existing record
        record = {
          ...record,
          amount: value,
        }
      } else {
        // Create a new record
        record = {
          receiptID: data.ReceiptID,
          zClaimInvoiceID: invoiceID,
          amount: value,
          date: data.ReceiptDate,
        }
      }
      prevRecords.set(invoiceID, record)

      // Create a new Map for the updated records
      const updatedRecords = new Map(prevRecords)
      updatedRecords.set(invoiceID, record)

      return updatedRecords
    })
  }

  return (
    <>
      <Container>
        <Typography component="div" variant={'h5'}>
          <div>
            <div>{data.EmployerLegalName}</div>
            {data.EmployerLegalName !== data.EmployerQuickbooksHandle && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {data.EmployerQuickbooksHandle}
              </div>
            )}
            {data.EmployerLegalName !== data.EmployerName && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {data.EmployerName}
              </div>
            )}
          </div>
        </Typography>
        <ReceiptDetailDiv>
          <ChipInformationDisplay label="Receipt ID" value={data.ReceiptID} />
          <ChipInformationDisplay label="Method" value={getReceiptInfo()} />
          <ChipInformationDisplay
            label="Receipt Amount"
            value={formatAmount(data.ReceiptAmount)}
          />
          {!data.IsReversed && (
            <>
              <ChipInformationDisplay
                label="Applied Amount"
                value={formatAmount(data.AppliedAmount)}
              />
              <ChipInformationDisplay
                label="Unapplied Balance"
                value={formatAmount(data.UnappliedAmount)}
              />
            </>
          )}
          {!data.IsReversed && <VoidReceipt data={data} onClose={getData} />}
        </ReceiptDetailDiv>
        <Typography component="div" variant={'body1'}>
          {data.Notes && `Notes: ${data.Notes}`}
        </Typography>
        <Divider />
        {data.IsReversed && (
          <Typography variant="body1" style={{ color: 'red' }}>
            {`Voided on ${data.ReversalDate ? dateTime.parse(data.ReversalDate).format() : ''}`}
          </Typography>
        )}
        {!data.IsReversed && (
          <>
            <Typography variant={'body1'}>
              Open and previously applied invoices:
            </Typography>
            {data.RelatedInvoiceInfo.length === 0 && (
              <Typography
                variant="h6"
                color={'textSecondary'}
                style={{ marginTop: '100px', marginBottom: '100px' }}>
                There are no open invoices for {data.EmployerName}
              </Typography>
            )}
            {data.RelatedInvoiceInfo.map((invoice) => {
              const setter = ({ value }: SetterArgs) => {
                setAmountByInvoiceID({ value, invoiceID: invoice.InvoiceID })
              }

              const getAmountValue = () => {
                const record = postApplyEmployerReceiptRecords.get(
                  invoice.InvoiceID
                )
                return record ? record.amount : ''
              }

              return (
                <>
                  <Card variant={'outlined'} style={{ marginTop: '20px' }}>
                    <CardHeader
                      title={`Invoice Number: ${invoice.InvoiceNumber}`}
                      subheader={
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Invoice Date:{' '}
                                {dateTime.parse(invoice.InvoiceDate).format()}
                              </Typography>
                              <Typography variant="body1">
                                Invoice Total:{' '}
                                {formatAmount(invoice.InvoiceAmount)}
                              </Typography>
                              <Typography variant="body1">
                                Applied Amount:{' '}
                                {formatAmount(invoice.AppliedAmount)}
                              </Typography>
                              <Typography variant="body1">
                                Open Balance:{' '}
                                {formatAmount(invoice.OpenBalance)}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              {invoice.OpenBalance !== '0' &&
                                data.UnappliedAmount !== '0' && (
                                  <>
                                    <RenderPriceField
                                      name="amount"
                                      label="Amount"
                                      opts={{
                                        placeholder: 'Enter amount to apply',
                                      }}
                                      value={getAmountValue()}
                                      setter={setter}
                                      use2023Styles
                                    />
                                    <Button
                                      disabled={isWorking}
                                      color="primary"
                                      variant="outlined"
                                      fullWidth
                                      onClick={() =>
                                        doPostApplyReceipt(invoice.InvoiceID)
                                      }>
                                      Apply Receipt
                                      {isWorking && (
                                        <>
                                          &nbsp;
                                          <DesignSuite2023.LoadingSpinner
                                            size={20}
                                            show
                                          />
                                        </>
                                      )}
                                    </Button>
                                  </>
                                )}
                            </Grid>
                          </Grid>
                        </>
                      }></CardHeader>
                    <CardContent>
                      {invoice.AppliedReceipts &&
                        invoice.AppliedReceipts.length > 0 && (
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>ID</TableCell>
                                  <TableCell>Receipt ID</TableCell>
                                  <TableCell>Receipt Date</TableCell>
                                  <TableCell>Receipt Type</TableCell>
                                  <TableCell>Notes</TableCell>
                                  <TableCell>Receipt Amount</TableCell>
                                  <TableCell>Applied Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {invoice.AppliedReceipts.map((receipt) => (
                                  <TableRow
                                    key={receipt.ReceiptInvoiceID}
                                    style={
                                      receipt.ReceiptID === receiptID
                                        ? { backgroundColor: 'yellow' }
                                        : {}
                                    }>
                                    <TableCell>
                                      {receipt.ReceiptInvoiceID}
                                    </TableCell>
                                    <TableCell>{receipt.ReceiptID}</TableCell>
                                    <TableCell>
                                      {dateTime
                                        .parse(receipt.ReceiptDate)
                                        .format()}
                                    </TableCell>
                                    <TableCell>
                                      {receipt.ReceiptType === receiptType.CHECK
                                        ? `Check: ${receipt.CheckNumber}`
                                        : receipt.ReceiptType ===
                                            receiptType.ACH
                                          ? `ACH: ${receipt.ACHInfo}`
                                          : receipt.ReceiptType}
                                    </TableCell>
                                    <TableCell>{receipt.Notes}</TableCell>
                                    <TableCell>
                                      {formatAmount(receipt.ReceiptAmount)}
                                    </TableCell>
                                    <TableCell>
                                      {formatAmount(receipt.AppliedAmount)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      <Divider />
                    </CardContent>
                  </Card>
                </>
              )
            })}
          </>
        )}
      </Container>
    </>
  )
}
