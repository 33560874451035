import React, { useState, useEffect } from 'react'
import { Button, Chip, Typography } from '@material-ui/core'
import * as CostKeyTable2 from './CostKeyTable2'
import styled from 'styled-components'
import { useSetFrameHeaderMid } from '../../components/AppFrame'
import { useHistory } from 'react-router-dom'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import DataQualityBulkContents from './DataQualityBulkContents'
import ContentTemplates from './ContentTemplates'

const StyledTableWrap = styled.div`
  .std-table .header-items {
    margin: 0;
  }
`

const TAB_LIST = 'tab_list'
const TAB_BUNDLE_CONTENTS = 'tab_bundle_contents'
const TAB_MISCONFIGURED = 'tab_misconfigured'
const TAB_BC_TEMPLATES = 'tab_bc_templates'

export default function CostKeys(): React.ReactElement | null {
  const history = useHistory()
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'costkeys.tabs',
  })
  const [tabVal, setTabVal] = useState(queryData?.tabVal || TAB_LIST)

  useEffect(() => {
    setQueryData({ tabVal })
  }, [tabVal])

  useSetFrameHeaderMid(
    () => (
      <div>
        <Chip
          variant={tabVal === TAB_LIST ? 'default' : 'outlined'}
          label="Cost Keys"
          onClick={() => setTabVal(TAB_LIST)}
        />
        &nbsp;
        <Chip
          variant={tabVal === TAB_BUNDLE_CONTENTS ? 'default' : 'outlined'}
          label="Data Quality: Bundle Contents Manager"
          onClick={() => setTabVal(TAB_BUNDLE_CONTENTS)}
        />
        &nbsp;
        <Chip
          variant={tabVal === TAB_MISCONFIGURED ? 'default' : 'outlined'}
          label="Data Quality: Misconfigured"
          onClick={() => setTabVal(TAB_MISCONFIGURED)}
        />
        &nbsp;
        <Chip
          variant={tabVal === TAB_BC_TEMPLATES ? 'default' : 'outlined'}
          label="Bundle Contents Templates"
          onClick={() => setTabVal(TAB_BC_TEMPLATES)}
        />
      </div>
    ),
    [tabVal, setTabVal]
  )

  const handleAddNew = () => {
    history?.push(`/cost_key`)
  }

  if (tabVal === TAB_LIST) {
    return (
      <StyledTableWrap>
        <CostKeyTable2.Table
          enableURLReflection
          LeftHeaderItems={
            <>
              <CostKeyTable2.StandardFilterSearch autoFocus />
              &nbsp;&nbsp;
              <div style={{ width: '100%', maxWidth: 280 }}>
                <CostKeyTable2.FilterCategory />
              </div>
              &nbsp;&nbsp;
              <div style={{ minWidth: 240 }}>
                <CostKeyTable2.FilterCPTs />
              </div>
              &nbsp;&nbsp;
              <div style={{ minWidth: 180 }}>
                <CostKeyTable2.FilterCPTModifiers />
              </div>
              &nbsp;&nbsp;
              <div style={{ width: 130 }}>
                <CostKeyTable2.FilterDRG />
              </div>
              &nbsp;&nbsp;
              <div style={{ minWidth: 180 }}>
                <CostKeyTable2.FilterKeywords />
              </div>
              &nbsp;&nbsp;
              <div style={{ minWidth: 180 }}>
                <CostKeyTable2.FilterExclusions />
              </div>
            </>
          }
          RightHeaderItems={
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNew}>
                Add New
              </Button>
            </>
          }
        />
      </StyledTableWrap>
    )
  }

  if (tabVal === TAB_BUNDLE_CONTENTS) {
    return <DataQualityBulkContents />
  }

  if (tabVal === TAB_MISCONFIGURED) {
    return (
      <StyledTableWrap>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1" color="error">
            These cost keys require immediate attention!
          </Typography>
          <Typography variant="body2" color="error">
            Likely cause: they{' '}
            <strong>
              did not have "Billing Translations" configured properly prior to
              update.
            </strong>{' '}
            If these cost keys are encountered in other products (GoZERO,
            ZClaims, Opp Analysis, Savings Analysis etc.), they will cause
            errors.
          </Typography>
        </div>

        <CostKeyTable2.Table
          passFilters={{ HasValidBundleDefinition: false }}
        />
      </StyledTableWrap>
    )
  }

  if (tabVal === TAB_BC_TEMPLATES) {
    return <ContentTemplates />
  }

  return null
}
