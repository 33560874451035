import React, { useEffect, useState } from 'react'
import {
  getZelisBatchDetail,
  reprintZelisBatch,
  reprintZelisBatchPayments,
} from '../../../actions/ZelisActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
} from '@material-ui/core'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import useSnackbar, { SnackbarTypeError } from '../../../hooks/useSnackbar'
import ChipInformationDisplay from './ChipInformationDisplay'
import dateTime from '../../../utils/dateTime'
import * as ZelisBatchPaymentsTable from './ZelisBatchPaymentsTable'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

interface zelisBatchDetail {
  ID: number
  BatchUUID: string
  Date: string | null
  CheckAmount: number
  EOPAmount: number
  FileName: string
  FileID: number
  S3Key: string
  IsReprint: boolean
}

const makeDefaultZelisBatch = () => ({
  ID: 0,
  BatchUUID: '',
  Date: dateTime.now().format(dateTime.formats.ISODate),
  CheckAmount: 0,
  EOPAmount: 0,
  FileName: '',
  FileID: 0,
  S3Key: '',
  IsReprint: false,
})

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

type props = {
  batchID: number
  doClose?(): void
}

export default function ZelisBatchDetail({
  batchID,
  doClose,
}: props): React.ReactElement {
  onclose = doClose ? doClose : null

  const [batchInfo, setBatchInfo] = React.useState<zelisBatchDetail>({
    ...makeDefaultZelisBatch(),
  })
  const [isWorking, setIsWorking] = useState(false)
  const [selected, setSelected] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()
  const { showDurationShort } = useSnackbar()

  useEffect(() => {
    getZelisBatchDetail(batchID)
      .then((res: any) => {
        setBatchInfo(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed to get ZelisBatch Details' })
      )
  }, [batchID])

  function reprintBatch() {
    if (isWorking) {
      return
    }
    setIsWorking(true)
    reprintZelisBatch(batchID)
      .then(() => {
        setSelected([])
        showDurationShort('Reprint Batch Created', 'success')
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to reprint batch',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  function reprintPayments() {
    if (isWorking || selected.length === 0) {
      return
    }
    setIsWorking(true)
    reprintZelisBatchPayments(selected.map((payment) => payment.ID))
      .then(() => {
        setSelected([])
        showDurationShort(
          `Zelis Reprint Batch created for ${selected.length} payment(s)`,
          'success'
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to reprint payments',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  if (!batchInfo.ID) {
    return <Container>No data found</Container>
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader
            title={
              <div>
                Zelis Batch {batchInfo.ID}{' '}
                {batchInfo.IsReprint && (
                  <span style={{ color: 'red' }}>[Reprint]</span>
                )}
              </div>
            }
          />
          <CardContent>
            <DetailDiv>
              <DesignSuite2023.GridLR
                left={
                  <>
                    <ChipInformationDisplay
                      label="Batch Date"
                      value={dateTime.parse(batchInfo.Date).format()}
                    />
                    <ChipInformationDisplay
                      label="Batch Check Amount"
                      value={formatAmount(batchInfo.CheckAmount.toString())}
                    />
                    <ChipInformationDisplay
                      label="Batch EOP Amount"
                      value={formatAmount(batchInfo.EOPAmount.toString())}
                    />
                    <Chip label="File" size="small" />
                    &nbsp;&nbsp;&nbsp;
                    <FileLinkBtn
                      filename={batchInfo.FileName}
                      handleGetDownloadURLFail={() => {
                        showDurationShort(
                          'Unable to download file',
                          SnackbarTypeError
                        )
                      }}
                      fileId={batchInfo.FileID}
                    />
                  </>
                }
                right={
                  batchInfo.IsReprint ? null : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={isWorking || selected.length > 0}
                      onClick={reprintBatch}>
                      Reprint Batch EOPs
                      {isWorking && (
                        <>
                          &nbsp;
                          <DesignSuite2023.LoadingSpinner size={20} show />
                        </>
                      )}
                    </Button>
                  )
                }
              />
            </DetailDiv>
            <ZelisBatchPaymentsTable.Table
              onCheckHandler={batchInfo.IsReprint ? undefined : setSelected}
              LeftHeaderItems={<ZelisBatchPaymentsTable.StandardFilterSearch />}
              RightHeaderItems={
                selected.length > 0 ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={isWorking}
                    onClick={reprintPayments}>
                    Reprint Payment EOP(s)
                    {isWorking && (
                      <>
                        &nbsp;
                        <DesignSuite2023.LoadingSpinner size={20} show />
                      </>
                    )}
                  </Button>
                ) : null
              }
              passFilters={{ batchUUIDs: [batchInfo.BatchUUID] }}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
