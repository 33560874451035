import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  SetterArgs,
  renderNotesField as RenderNotesField,
} from '../../../components/Inputs/standard'
import {
  postCreateInvoiceableHolds,
  postCreateInvoiceableHoldsParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'

interface props {
  disabled?: boolean
  zClaimIDs: number[]
  invoiceItemIDs: number[]
  totalAmount: string
  onComplete?(): void
  style?: React.CSSProperties
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

export default function ButtonCreateInvoiceableHolds({
  disabled,
  zClaimIDs,
  invoiceItemIDs,
  totalAmount,
  onComplete,
  style,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [holdReleaseOn, setHoldReleaseOn] = useState<string | null>(null)
  const [notes, setNotes] = useState<string>('')
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) {
      setHoldReleaseOn(null)
      setNotes('')
    }
  }, [isOpen])

  function handleClose() {
    setIsOpen(false)
  }

  function doPostHoldClaims() {
    setIsWorking(true)

    const payload: postCreateInvoiceableHoldsParams = {
      zClaimIDs,
      invoiceItemIDs,
      holdReleaseOn,
      notes,
    }

    postCreateInvoiceableHolds(payload)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const hasInvoiceItem = invoiceItemIDs.length > 0
  const hasZClaim = zClaimIDs.length > 0
  const label =
    hasZClaim && !hasInvoiceItem
      ? 'zClaims'
      : hasInvoiceItem && !hasZClaim
        ? 'invoice items'
        : hasInvoiceItem && hasZClaim
          ? 'invoiceables'
          : ''

  const setter = ({ name, value }: SetterArgs) => {
    switch (name) {
      case 'holdReleaseOn':
        setHoldReleaseOn(value)
        break
      case 'notes':
        setNotes(value)
        break
    }
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Place selected items on hold">
        <span style={style}>
          <Button
            disabled={isWorking || disabled}
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconLock />}
            onClick={() => {
              setIsOpen(true)
            }}>
            {`Place Holds (${zClaimIDs.length + invoiceItemIDs.length}) ${formatAmount(totalAmount)}`}
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>
          {`Place On Hold (${zClaimIDs.length + invoiceItemIDs.length}) ${label} totaling ${formatAmount(totalAmount)}`}
          <Typography variant="body2" color="textSecondary">
            {`This action will place holds on these ${label}, preventing
                  them from being invoiced until the holds have been lifted.`}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '720px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5}>
                <ManagedDateInput
                  name="holdReleaseOn"
                  label="Release the hold on (optional)"
                  value={holdReleaseOn}
                  setter={setter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Hold Notes"
                  value={notes}
                  setter={setter}
                  use2023Styles={true}
                />
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  {invoiceItemIDs.length > 0 && (
                    <Typography variant="body2" color="textSecondary">
                      {`InvoiceItemIDs: ${invoiceItemIDs.join(', ')}`}
                    </Typography>
                  )}
                  {zClaimIDs.length > 0 && (
                    <Typography variant="body2" color="textSecondary">
                      {`ZClaimIDs: ${zClaimIDs.join(', ')}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={
                    isWorking || (!zClaimIDs?.length && !invoiceItemIDs?.length)
                  }
                  color="primary"
                  variant="outlined"
                  onClick={doPostHoldClaims}>
                  {`Place On Hold [${zClaimIDs.length + invoiceItemIDs.length}] ${label}`}
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
