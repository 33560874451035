import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getAllCostKeyBundleContentOptions } from '../../../actions/CostKeyActions'
import { ShapeBundleContentRecord } from '../types'
import styled from 'styled-components'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

const StyledOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  small {
    opacity: 0.75;
  }
`

export default function AutoCompleteBundleContentOptions({
  onChange,
  AutoCompleteProps,
  TextFieldProps,
  disabled = false,
  disableClear = false,
  filterOccurrencesZero = true,
}: {
  onChange?(rec: ShapeBundleContentRecord): void
  AutoCompleteProps?: any
  TextFieldProps?: any
  disabled?: boolean
  disableClear?: boolean
  filterOccurrencesZero?: boolean
}): React.ReactElement {
  const [allOptions, setAllOptions] = useState<ShapeBundleContentRecord[]>([])
  const { catchAPIError } = useErrorHandlers()
  // const [current, setCurrent] = useState<any>(null)

  useEffect(() => {
    getAllCostKeyBundleContentOptions()
      .then((res) => {
        if (res?.error) throw res
        if (filterOccurrencesZero) {
          setAllOptions(
            (res.Data || []).filter((opt: any) => opt.Occurrences > 0)
          )
          return
        }
        setAllOptions(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading bundle content options',
        })
      )
  }, [])

  function handleChange(_: any, v: any, reason: string) {
    // setCurrent(v)
    onChange?.(v as ShapeBundleContentRecord)
  }

  return (
    <Autocomplete
      disabled={disabled}
      disableClearable={disableClear}
      options={allOptions}
      getOptionLabel={(opt: any) => opt.Content}
      getOptionSelected={(opt: any, val: any) => opt.ID === val.ID}
      defaultValue={null}
      value={null}
      onChange={handleChange}
      // onInputChange={onInputChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Bundle Content Options"
          placeholder="Search bundle content options"
          {...TextFieldProps}
        />
      )}
      renderOption={(option: any) => (
        <StyledOption>
          <span>{option?.Content}</span>
          <small>({option.Occurrences})</small>
        </StyledOption>
      )}
      {...AutoCompleteProps}
    />
  )
}
