import util from './utils'
import { CLEAR_SEARCH_RESULTS, OpportunityAnalysisActions } from '../actions'

const {
  GET_GROUPS_TO_REVIEW,
  GET_SHOW_GROUP_OPTIONS,
  GET_LIMIT_OPTIONS,
  GET_SUMMARY,
  GET_SUMMARY_CLAIM_LINES,
  GET_EMPLOYER_FOR_FILE_CLAIMS,
  SAVE_GROUP_LINES,
  SAVE_GROUP_SUMMARY,
  CLEAR_SAVE_RESULT,
  CLEAR_SELECTED_SUMMARY,
} = OpportunityAnalysisActions

export default function (state = null, action) {
  switch (action.type) {
    case GET_GROUPS_TO_REVIEW:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        getError: null,
        results: action.payload.Data,
      }
    case GET_SUMMARY:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        getError: null,
        summary: action.payload.Data,
      }
    case GET_EMPLOYER_FOR_FILE_CLAIMS:
      if (action.payload.error) {
        return {
          ...state,
          getEmployerError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        getEmployerError: null,
        employer: action.payload.Data,
      }
    case GET_SUMMARY_CLAIM_LINES:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        getError: null,
        summaryClaimLines: action.payload.Data,
      }
    case GET_LIMIT_OPTIONS:
      return {
        ...state,
        limitOptions: getLimitOptions(),
      }
    case GET_SHOW_GROUP_OPTIONS:
      return {
        ...state,
        showGroupOptions: getShowGroupOptions(),
      }
    case SAVE_GROUP_LINES:
    case SAVE_GROUP_SUMMARY:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: { ...action.payload.Data, saved: true, type: action.type },
      }
    case CLEAR_SELECTED_SUMMARY:
      return {
        ...state,
        summary: {},
      }
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: {},
      }
    default:
      return {
        ...state,
      }
  }
}

function getLimitOptions() {
  return [
    {
      value: 50,
      label: '50',
    },
    {
      value: 500,
      label: '500',
    },
    {
      value: 1000,
      label: 'All (Max 1k)',
    },
  ]
}

function getShowGroupOptions() {
  return [
    {
      value: -1,
      label: '(-1) Show all',
    },
    {
      value: 0,
      label: '(0) Rejected',
    },
    {
      value: 1,
      label: '(1) Accepted',
    },
    {
      value: 2,
      label: '(2) Review visibility based on Cost',
    },
    {
      value: 3,
      label: '(3) Review visibility based on other critiera',
    },
    {
      value: 4,
      label: '(4) Technical Component Only',
    },
    {
      value: 5,
      label: '(5) Professional Component Only',
    },
    {
      value: 6,
      label: '(6) Manual override',
    },
    {
      value: 7,
      label:
        '(7) Part of multiple procedures - could not extract a clean bundle',
    },
    {
      value: 8,
      label: '(8) Not analyzable for other reasons',
    },
    {
      value: 9,
      label: '(9) Marked for analysis as last step',
    },
    {
      value: 10,
      label: '(10) Durable Medical Equipment',
    },
    {
      value: 11,
      label: '(11) Emergency Service not properly identified by POS',
    },
    {
      value: 12,
      label: '(12) Inpatient exclusion',
    },
    {
      value: 13,
      label: '(13) Implants not covered under Zerocard',
    },
    {
      value: 14,
      label: '(14) Incomplete Billing',
    },
    {
      value: 15,
      label:
        '(15) Ask Provider Experience -- there should be none left at the end',
    },
    {
      value: 16,
      label: '(16) Parking',
    },
    {
      value: 20,
      label: '(20) SQL Bulk Move (do not use)',
    },
    {
      value: 22,
      label: '(22) Temporary storage',
    },
  ]
}

/*
var reviewNumberText = `0 - Don't Show
1 - Show
2 - Review Show based on cost
3 - Review Show based on other criteria
4 - Technical Component Only
5 - Professional Component Only
6 - Manual override
7 - Part of multiple procedures - could not extract a clean bundle
8 - Not analyzable for other reasons
9 - marked for analysis as last step
10 - Durable Medical Equipment
11 - Emergency Service not properly identified by POS
12 - Inpatient exclusion
13 - implants not covered under Zero
14 - Incomplete billing - originally was "not primary procedure"
15 - Discuss with wim - There should be none left at the end
16 - Parking`
*/
