import * as api from '../services/thezerocard/api-helper'
export const FIND_REFERRALS = 'FIND_REFERRALS'

export const findReferrals = (params) => {
  return {
    type: FIND_REFERRALS,
    payload: api.search('/referral', params),
  }
}

export const getReferralStatuses = () => {
  return api.get('/referral_statuses')
}

export const findReferralsV2 = (params) => {
  return api.search('/referral', params)
}
