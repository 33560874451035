import React, { useEffect, useState, useMemo } from 'react'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import PageClaims from './pageClaims'
import PageFundingReview from './pageFundingReview'
import PageFunding from './pageFunding'
import PageSavingsReview from './pageSavingsReview'
import { useSetFrameHeaderLeft } from '../../components/AppFrame'
import { Chip } from '@material-ui/core'
import perms from '../../utils/perms'
//@ts-ignore
import styled from 'styled-components'

const StyledComponent = styled.div`
  height: 100%;
`

const StyledHeaderTabs = styled.div`
  .MuiChip-root {
    margin-right: 0.3rem;
  }
`

const TAB_CLAIMS = 'tab_claims'
const TAB_FUNDING_REVIEW = 'tab_pre_funding'
const TAB_FUNDING = 'tab_funding'
const TAB_SAVINGS = 'tab_savings'

export default function ZClaims(/*props : any*/): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.tabs',
  })
  const [tabVal, setTabVal] = useState(queryData?.tabVal || TAB_CLAIMS)
  const calcPerms = useMemo(
    () => ({
      canViewClaims: perms.hasAny(
        perms.RoleTokens.PermZClaimViewer,
        perms.RoleTokens.PermEngineering
      ),
      canViewPreFunding: perms.hasAny(
        perms.RoleTokens.PermZClaimViewer,
        perms.RoleTokens.PermZClaimAdjudicator,
        perms.RoleTokens.PermZClaimSupervisor,
        perms.RoleTokens.PermEngineering
      ),
      canViewFunding: perms.hasAny(
        perms.RoleTokens.PermZClaimAcctViewer,
        perms.RoleTokens.PermEngineering
      ),
      canViewSavings: perms.hasAny(
        perms.RoleTokens.PermZClaimAnalyticsViewer,
        perms.RoleTokens.PermEngineering
      ),
    }),
    []
  )

  useEffect(() => {
    setQueryData({ tabVal })
  }, [tabVal])

  useSetFrameHeaderLeft(
    () => (
      <StyledHeaderTabs>
        <perms.Checker boolIf={calcPerms.canViewClaims}>
          <Chip
            variant={tabVal === TAB_CLAIMS ? 'default' : 'outlined'}
            color="primary"
            label="Claims"
            onClick={() => setTabVal(TAB_CLAIMS)}
          />
        </perms.Checker>

        <perms.Checker boolIf={calcPerms.canViewPreFunding}>
          <Chip
            variant={tabVal === TAB_FUNDING_REVIEW ? 'default' : 'outlined'}
            color="primary"
            label="Pre-Funding"
            onClick={() => setTabVal(TAB_FUNDING_REVIEW)}
          />
        </perms.Checker>

        <perms.Checker boolIf={calcPerms.canViewFunding}>
          <Chip
            variant={tabVal === TAB_FUNDING ? 'default' : 'outlined'}
            color="primary"
            label="Funding"
            onClick={() => setTabVal(TAB_FUNDING)}
          />
        </perms.Checker>

        <perms.Checker boolIf={calcPerms.canViewSavings}>
          <Chip
            variant={tabVal === TAB_SAVINGS ? 'default' : 'outlined'}
            color="primary"
            label="Savings"
            onClick={() => setTabVal(TAB_SAVINGS)}
          />
        </perms.Checker>
      </StyledHeaderTabs>
    ),
    [tabVal, setTabVal, calcPerms]
  )

  switch (tabVal) {
    case TAB_CLAIMS:
      if (calcPerms.canViewClaims) {
        return (
          <StyledComponent>
            <PageClaims />
          </StyledComponent>
        )
      }
      break

    case TAB_FUNDING_REVIEW:
      if (calcPerms.canViewPreFunding) {
        return (
          <StyledComponent>
            <PageFundingReview />
          </StyledComponent>
        )
      }
      break

    case TAB_FUNDING:
      if (calcPerms.canViewFunding) {
        return (
          <StyledComponent>
            <PageFunding />
          </StyledComponent>
        )
      }
      break

    case TAB_SAVINGS:
      if (calcPerms.canViewSavings) {
        return (
          <StyledComponent>
            <PageSavingsReview />
          </StyledComponent>
        )
      }
  }

  return <span>Insufficient Permission</span>
}
