import React, { useEffect, useMemo, useState } from 'react'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import { ShapeExCodeOpt, ShapeZClaimLine } from '../../../types'
import {
  deleteZClaimLine,
  getZClaimLinesByZClaimID,
  postAddLineExCode,
  postClearLineExCode,
  putZClaimLines,
} from '../../../../../actions/ZClaimActions'
import helpers, { ClaimTypes, SourceTypes } from '../../../helpers'
// import * as customInput from './customInput'
import { Button, IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
// import ExCodesDisplay from '../../Advanced/ExCodesDisplay'
import styled from 'styled-components'
import Table837P, { props as TypedLineDisplayProps } from './837p'
import Table837I from './837i'
import StatusDot from '../../StatusDot'
import { AdjStatuses } from '../../Status'
import Rxngo from './Rxngo'

const StyledContainer = styled.div`
  .table-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    overflow-x: scroll;

    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 13px;
      // table-layout: fixed;

      th {
        padding: 0.15rem 0.35rem;
        background: #eee;
      }
      th.center {
        text-align: center;
      }

      th,
      td {
        border-left: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
      }
      tr th:first-of-type,
      tr td:first-of-type {
        border-left: 0;
      }
      // tbody tr:last-of-type td {border-bottom:0;}

      tbody {
        font-family: monospace;

        tr {
          &:hover {
            background: #f1f1f1;
          }
          &:focus-within {
            background: #e9f2ef;
          }
          &.targeted {
            background: #bfe8bf;
          }
        }
      }

      // reset childTable.scss bs
      td {
        cursor: initial;
        [contenteditable] {
          display: block;
          cursor: initial;
        }
      }

      tr td {
        line-height: 1;
        padding: 0.15rem;
      }
      input[type='checkbox'] {
        margin: 0;
      }

      .stable-identifier {
        font-size: 85%;
        color: #999;
        font-family: monospace;
        display: inline-block;
        padding: 0 0.15rem;
      }

      tfoot {
        td {
          padding: 0.35rem;
          border-bottom: 0;
          &.sumd {
            background: #dcedf2;
            font-family: monospace;
            font-weight: bold;
            font-size: 90%;

            &.reversal {
              background: #f8d7da;
            }
          }
        }
      }
    }
  }

  .changed {
    box-shadow: inset 0px 0px 8px rgb(50 34 190 / 41%);
  }
  .invalid {
    box-shadow: inset 0px 0px 8px rgb(216 20 48 / 41%);
  }
`

const StyledActions = styled.div`
  .MuiButton-root {
    line-height: 1;
    margin-right: 0.5rem;
  }
`

export interface ExtShapeLine extends ShapeZClaimLine {
  _memID: string
}

export interface trackLines {
  [k: string]: ExtShapeLine
}

export interface trackValidations {
  [k: string]: {
    // memID:{object}
    [k: string]: boolean // fieldname:boolean
  }
}

interface props {
  zClaimID?: number
  claimType?: ClaimTypes | null
  diagnosisCodes?: string[]
  onLinesUpdate?(ll: ShapeZClaimLine[]): void
  readOnly?: boolean
  isReversal?: boolean
  markDirty?: (v: boolean) => void
  claimStatus?: AdjStatuses | null
  summary?: {
    SumPayable: string | null
  } | null
  sourceType?: SourceTypes | null
  prePopulateSingleEmptyLine?: boolean
  reloadEntireClaim?: () => void
}

export default React.forwardRef(function Table(
  {
    zClaimID,
    claimType,
    diagnosisCodes = [],
    onLinesUpdate,
    readOnly = false,
    isReversal = false,
    markDirty,
    claimStatus,
    summary,
    sourceType,
    prePopulateSingleEmptyLine = false,
    reloadEntireClaim,
  }: props,
  ref: any
): React.ReactElement | null {
  const [origLines, setOrigLines] = useState<trackLines>({})
  const [lines, setLines] = useState<trackLines>({})
  const [checkedRows, setCheckedRows] = useState<any>({})
  const [isDirty, setIsDirty] = useState(false)
  const [validations, setValidations] = useState<trackValidations>({})
  const [targetedZClaimLineID, setTargetedZClaimLineID] = useState<
    number | null
  >(null)
  const [showDebug, setShowDebug] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const newClaim: boolean = !zClaimID

  const calculations = useMemo(() => {
    const totals = {
      sumBilled: safeSumCol('AmntBilled', lines),
      sumAllowed: safeSumCol('AmntAllowed', lines),
      sumPPODiscount: safeSumCol('AmntPPODiscount', lines),
      sumPayable: safeSumCol('AmntPayable', lines),
    }
    if (isReversal) {
      totals.sumBilled = `-${totals.sumBilled}`
      totals.sumAllowed = `-${totals.sumAllowed}`
      totals.sumPayable = `-${totals.sumPayable}`
    }
    return totals
  }, [lines, isReversal])

  React.useImperativeHandle(
    ref,
    () => ({
      isDirty,
      doSave,
      refresh,
      autoSetDefaultsWhereNull,
      doToggleLineTargeted,
    }),
    [isDirty, lines]
  )

  useEffect(() => {
    markDirty?.(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (!zClaimID) {
      setOrigLines({})
      setLines({})
      setCheckedRows({})
      setIsDirty(false)
      setTargetedZClaimLineID(null)
      setShowDebug(false)
      return
    }
    loadLines(zClaimID)
  }, [zClaimID])

  useEffect(() => {
    onLinesUpdate?.(Object.values(lines))
    setIsDirty(checkForChanges())
  }, [lines, origLines])

  useEffect(() => {
    if (!prePopulateSingleEmptyLine) return
    onAddLine()
  }, [prePopulateSingleEmptyLine])

  function doToggleLineTargeted(ZClaimLineID: number | null): void {
    setTargetedZClaimLineID(ZClaimLineID)
  }

  function checkForChanges(): boolean {
    let chs = false
    for (let memID in lines) {
      if (!origLines[memID]) {
        chs = true
        break
      }
      for (let field in lines[memID]) {
        // If its an array (eg. of excode objects), skip "diffing". We only
        // want to compare simple types like strings, numbers, nulls
        if (Array.isArray(lines[memID][field])) continue
        // If its a decorative field, skip it
        if (field.startsWith('Linked') || field.startsWith('Computed')) continue

        if (lines[memID][field] !== origLines[memID]?.[field]) {
          chs = true
          break
        }
      }
    }
    return chs
  }

  function refresh() {
    if (!zClaimID) return
    loadLines(zClaimID)
  }

  function loadLines(zClaimID: number | null) {
    if (!zClaimID || newClaim) return
    return getZClaimLinesByZClaimID(zClaimID)
      .then(({ Data: ll }: { Data: ShapeZClaimLine[] }) => {
        const _sorted = ll.sort((a, b) => {
          const aln = a.ServiceLineNumber || 0
          const bln = b.ServiceLineNumber || 0
          if (aln < bln) return -1
          if (aln > bln) return 1
          return 0
        })
        const rdcd = _sorted.reduce((coll: any, l: ShapeZClaimLine) => {
          const _memID = helpers.randomID()
          coll[_memID] = { _memID, ...l }
          return coll
        }, {}) as trackLines
        setCheckedRows({})
        setOrigLines(JSON.parse(JSON.stringify(rdcd)) as trackLines)
        setLines(JSON.parse(JSON.stringify(rdcd)) as trackLines)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading zclaim lines',
        })
      )
  }

  function fnTrackChange(memID: string, field: string, v: any) {
    setLines((curr: trackLines) => {
      curr[memID] = Object.assign({ ...curr[memID] } as ExtShapeLine, {
        [field]: v,
      })
      return { ...curr }
    })
  }

  function fnGetCellClass(memID: string, field: string): string {
    if (validations?.[memID]?.[field]) return 'invalid'
    if (origLines[memID]?.[field] === lines[memID]?.[field]) return ''
    return 'changed'
  }

  function onAddLine() {
    setLines((curr: trackLines) => {
      const _memID = helpers.randomID()
      const currLineCount = Object.keys(curr).length + 1

      curr[_memID] = {
        _memID,
        ID: 0,
        ServiceLineNumber: currLineCount,
      } as ExtShapeLine
      return { ...curr }
    })
  }

  function doSave(): Promise<any> {
    if (newClaim) {
      return Promise.reject({
        Error: { Message: 'Cannot save lines for new claim' },
      })
    }
    if (!zClaimID) {
      return Promise.reject({
        Error: { Message: 'ZClaimID required to save claim lines' },
      })
    }
    if (!isDirty) {
      return Promise.reject({
        Error: { Message: 'No changes to lines' },
      })
    }
    // note: could check validations here, but with validations on backend right now might be better
    // to just treat UI ones as warnings and let the backend do the heavy lifting

    return new Promise((rslv, rej) => {
      putZClaimLines(zClaimID, Object.values(lines))
        .then(() => {
          showSnackbar(`Lines saved successfully`, SnackbarTypeSuccess)
          return loadLines(zClaimID)
        })
        .then(() => rslv(null))
        .catch(
          catchAPIError({
            defaultMessage: 'Failed saving claim lines',
            withError: rej,
          })
        )
    })
  }

  function onDelete() {
    if (!zClaimID || newClaim) return

    // @ts-ignore
    const ids = Object.values(lines)
      .map((l: ExtShapeLine) => {
        if (checkedRows[l._memID] && l.ID > 0) {
          return l.ID
        }
        return null
      })
      .filter((v) => !!v)

    ConfirmDialog({
      content:
        'Are you sure you want to delete the selected claim lines? This will occur immediately and cannot easily be undone.',
      onConfirm() {
        deleteZClaimLine(zClaimID, { ids })
          .then(() => {
            showSnackbar(`${ids.length} lines deleted OK`, SnackbarTypeSuccess)
            return loadLines(zClaimID)
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed deleting claim lines',
            })
          )
          .finally(() => {
            setCheckedRows({})
          })
      },
    })
  }

  function onAssignLineExCode(eco: ShapeExCodeOpt, memID: string) {
    // when lines are being edited for a claim all in-memory (eg. manual claim),
    // where no claim record exists yet, we can just assign the excode directly
    if (!zClaimID || newClaim) {
      let v = [eco]
      if (lines?.[memID]?.LineExCodes) {
        v = [...lines[memID].LineExCodes, eco]
      }
      fnTrackChange(memID, 'LineExCodes', v)
      return
    }

    // if ANY line has an unpersisted change, don't allow setting an excode since the first
    // thing we do upon saving the excode is refresh all the line data
    if (isDirty) {
      showSnackbar(
        `Cannot assign excodes while edited lines have not been saved`,
        SnackbarTypeWarning
      )
      return
    }
    const lineID = lines?.[memID]?.ID
    if (!lineID) {
      showSnackbar(
        `Cannot assign excode to an un-saved line (save it first)`,
        SnackbarTypeWarning
      )
      return
    }
    postAddLineExCode(lineID, { ExCodeOptID: eco.ID })
      // .then(refresh)
      .then(() => {
        reloadEntireClaim?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed assigning line ex code',
        })
      )
  }

  function handleClearExCode(eco: ShapeExCodeOpt, memID: string) {
    if (!zClaimID || newClaim) {
      let v = lines[memID]?.LineExCodes || []
      v = v.filter((x: ShapeExCodeOpt) => x.ID !== eco.ID)
      fnTrackChange(memID, 'LineExCodes', v)
      return
    }

    if (isDirty) {
      showSnackbar(
        `Cannot assign excodes while claim edits have not been saved`,
        SnackbarTypeWarning
      )
      return
    }

    postClearLineExCode(eco.ID)
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar(`Line ExCode cleared OK`, SnackbarTypeSuccess)
      })
      // .then(refresh)
      .then(() => {
        reloadEntireClaim?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed clearing line excode',
        })
      )
  }

  function fnMakeRowCheckbox(row: ExtShapeLine): React.ReactElement {
    if (row.ID >= 1) {
      return (
        // @ts-ignore
        <input
          type="checkbox"
          disabled={readOnly}
          checked={checkedRows[row._memID] === true}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            const checked = ev.target.checked
            setCheckedRows((curr: any) => {
              if (checked) {
                curr[row._memID] = true
              } else {
                delete curr[row._memID]
              }
              return { ...curr }
            })
          }}
        />
      )
    }

    // this is the case of an unpersisted line: can just nuke it immediately
    return (
      <IconButton
        size="small"
        onClick={() => {
          setLines((curr: trackLines) => {
            delete curr[row._memID]
            return { ...curr }
          })
        }}>
        <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
      </IconButton>
    )
  }

  // exposed API method on the component
  function autoSetDefaultsWhereNull(opts: {
    useLineDateStart?: string | null
    useLineDateEnd?: string | null
  }) {
    setLines((curr: trackLines) => {
      const x = JSON.parse(JSON.stringify(curr))
      for (let k in x) {
        if (!x[k].FeeSchedulePriceID && x[k].AmntAllowed === null) {
          x[k].AmntAllowed = '0.00'
          x[k].AmntPayable = '0.00'
          x[k].AmntPPODiscount = x[k].AmntBilled
          if (x[k].AmntBilled === null) {
            x[k].AmntBilled = '0.00'
          }
        }
        if (x[k].ServiceDateStart === null) {
          x[k].ServiceDateStart = opts.useLineDateStart || null
        }
        if (x[k].ServiceDateEnd === null) {
          x[k].ServiceDateEnd =
            opts.useLineDateEnd || opts.useLineDateStart || null
        }
      }
      return x
    })
  }

  function onKeyDown(ev: any) {
    if (!ev?.target?.isContentEditable) return

    const els = {
      td: ev.target.closest('td'),
      tr: ev.target.closest('tr'),
      tbody: ev.target.closest('tbody'),
    }
    if (!els.td || !els.tr || !els.tbody) return

    let capture = false
    switch (ev.code) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'NumpadEnter':
      case 'Enter':
        capture = true
    }
    if (!capture) return
    ev.preventDefault()
    ev.stopPropagation()

    switch (ev.code) {
      case 'ArrowUp': // up
        if (!els.tr.previousSibling) return
        els.tr.previousSibling.cells[els.td.cellIndex]
          ?.querySelector('[contenteditable]')
          ?.focus()
        return

      case 'ArrowDown':
      case 'NumpadEnter': // down
        if (!els.tr.nextSibling) return
        els.tr.nextSibling.cells[els.td.cellIndex]
          ?.querySelector('[contenteditable]')
          ?.focus()
        return

      case 'Enter': // enter
        if (!els.td.nextSibling) return
        els.td.nextSibling?.querySelector('[contenteditable]')?.focus()
        return
    }
  }

  const elmntCheckAll: React.ReactElement = (
    // @ts-ignore
    <input
      type="checkbox"
      disabled={readOnly}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.checked) {
          setCheckedRows({})
          return
        }
        setCheckedRows(
          Object.values(lines).reduce((accum: any, row: any) => {
            if (row.ID) {
              accum[row._memID] = true
            }
            return accum
          }, {})
        )
      }}
    />
  )

  const Actions = React.useMemo(
    () => (
      <StyledActions>
        <Button
          disabled={isReversal || readOnly}
          size="small"
          variant="outlined"
          onClick={onAddLine}
          startIcon={<DesignSuite2023.CommonIcons.IconAdd />}>
          <span>Add Line</span>
        </Button>
        {newClaim === false && (
          <>
            <Button
              disabled={!Object.keys(checkedRows).length}
              size="small"
              variant="outlined"
              onClick={onDelete}
              startIcon={<DesignSuite2023.CommonIcons.IconCancel />}>
              <span>Delete Line(s)</span>
            </Button>
          </>
        )}
        {isDirty && <StatusDot.Dot color={StatusDot.Colors.YELLOW} />}
      </StyledActions>
    ),
    [isDirty, checkedRows]
  )

  const tableProps: TypedLineDisplayProps = {
    newClaim,
    readOnly,
    isReversal,
    elmntCheckAll,
    fnMakeRowCheckbox,
    origLines,
    lines,
    fnGetCellClass,
    fnTrackChange,
    diagnosisCodes,
    refresh,
    onAssignLineExCode,
    handleClearExCode,
    setValidations,
    Actions,
    targetedZClaimLineID,
    claimStatus,
    summary,
    sourceType,
    calculations,
  }

  return (
    <StyledContainer onKeyDown={onKeyDown}>
      <div className="table-container">
        {claimType === ClaimTypes.Professional && <Table837P {...tableProps} />}
        {claimType === ClaimTypes.Institutional && (
          <Table837I {...tableProps} />
        )}
        {claimType === ClaimTypes.RX && <Rxngo {...tableProps} />}
      </div>

      <div style={{ textAlign: 'right' }}>
        <small
          onClick={() => {
            setShowDebug((curr: boolean) => !curr)
          }}
          style={{ display: 'inline-block', color: '#aaa', cursor: 'pointer' }}>
          Debug
        </small>
      </div>
      {!!showDebug && (
        <DisplayDebug
          lines={lines}
          origLines={origLines}
          checkedRows={checkedRows}
          isDirty={isDirty}
          validations={validations}
          calculations={calculations}
        />
      )}
    </StyledContainer>
  )
})

function DisplayDebug({
  lines,
  origLines,
  checkedRows,
  isDirty,
  validations,
  calculations,
}: any) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '33.33%', padding: '0 0.5rem' }}>
        <h6>Original Lines</h6>
        <pre>{JSON.stringify(origLines, null, '  ')}</pre>
      </div>
      <div style={{ width: '33.33%', padding: '0 0.5rem' }}>
        <h6>Mod'd Lines</h6>
        <pre>{JSON.stringify(lines, null, '  ')}</pre>
      </div>
      <div style={{ width: '33.33%', padding: '0 0.5rem' }}>
        <h6>Is Dirty (has changes)? {isDirty ? 'true' : 'false'}</h6>
        <hr />
        <h6>Validations</h6>
        <pre>{JSON.stringify(validations, null, '  ')}</pre>
        <hr />
        <h6>Checked Rows</h6>
        <pre>{JSON.stringify(checkedRows, null, '  ')}</pre>
        <hr />
        <h6>Calculations</h6>
        <pre>{JSON.stringify(calculations, null, '  ')}</pre>
      </div>
    </div>
  )
}

export function safeSumCol(col: string, lines: trackLines): string {
  let sum = 0
  for (let key in lines) {
    let row = lines[key]
    if (
      col === 'AmntPayable' &&
      (row?.ComputedLineSummary?.HasDeniableLineExCodes ||
        row?.ComputedLineSummary?.ClaimIsFullyDenied)
    ) {
      continue
    }

    let v = row[col]
    const x = Number.parseFloat(v)
    if (Number.isFinite(x)) {
      sum += x
    }
  }
  return sum.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
