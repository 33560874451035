import React, {
  useState,
  useEffect,
  forwardRef,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Chip,
} from '@material-ui/core'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import * as api from '../../services/thezerocard/api-helper'
import utils from '../../utils'
import styled from 'styled-components'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { renderTextField as RenderTextField, SetterArgs } from '../ViewHelpers'

const StyledDialog = styled(Dialog)`
  [role='dialog'],
  .MuiDialogContent-root,
  .std-table {
    height: 100%;
  }

  .MuiDialogContent-root {
    padding: 0 !important;

    .std-table {
      overflow: hidden;
      overflow-y: auto;

      .header-items {
        padding: 0 1rem;
        white-space: nowrap;
        margin-top: 0;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 5;

        .header-item-cell {
          column-gap: 0.5rem;
        }
      }

      .base-table-display {
        margin-top: 0 !important;

        .data-table-footer {
          position: sticky;
          bottom: 0;
          background: #fff;
        }
      }
    }
  }
`

const enum CodeTypes {
  CPT = 'cpt',
  CPT_MOD = 'cpt_mod',
  DRG = 'drg',
  POS = 'pos',
  ICD10 = 'icd10',
  UB = 'ub',
}

const codeTypeLabels = {
  [CodeTypes.CPT]: 'CPT',
  [CodeTypes.CPT_MOD]: 'CPT Modifier',
  [CodeTypes.DRG]: 'DRG',
  [CodeTypes.POS]: 'Place Of Service',
  [CodeTypes.ICD10]: 'ICD-10-CM',
  [CodeTypes.UB]: 'UB',
}

const codeTypeColumns = {
  [CodeTypes.CPT]: {
    Code: { name: 'Code' },
    Category: { name: 'Category' },
    Descr: { name: 'Descr' },
    LaymanDescr: { name: 'LaymanDescr' },
    Source: { name: 'Source' },
    IsValidCpt: {
      name: 'IsValidCpt',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
  },
  [CodeTypes.CPT_MOD]: {
    Code: { name: 'Code' },
    Descr: { name: 'Descr' },
  },
  [CodeTypes.DRG]: {
    Code: { name: 'Code' },
    Descr: { name: 'Descr' },
    Type: { name: 'Type' },
  },
  [CodeTypes.POS]: {
    Code: { name: 'Code' },
    Name: { name: 'Name' },
    Descr: { name: 'Descr' },
  },
  [CodeTypes.ICD10]: {
    Code: { name: 'Code' },
    Descr: { name: 'Descr' },
    Formatted: { name: 'Formatted' },
  },
  [CodeTypes.UB]: {
    Code: { name: 'Code' },
    Group: { name: 'Group' },
    Descr: { name: 'Descr' },
  },
}

export default function CodesManagement(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [chosenType, setChosenType] = useState<CodeTypes>(CodeTypes.CPT)

  useEffect(() => {
    setChosenType(CodeTypes.CPT)
  }, [isOpen])

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Launch address management dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Manage Codes
        </Button>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        open={isOpen}
        fullWidth
        maxWidth="lg"
        onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Table
            chosenType={chosenType}
            LeftHeaderItems={
              <>
                <Typography variant="subtitle1">Codes</Typography>
                <stdTableSetup.StandardFilterSearch />
                <DialogEditCode chosenType={chosenType} record={null} />
              </>
            }
            RightHeaderItems={
              <>
                {Object.entries(codeTypeLabels).map(([key, label]) => (
                  <Chip
                    key={key}
                    label={label}
                    onClick={() => setChosenType(key as CodeTypes)}
                    variant={chosenType === key ? 'default' : 'outlined'}
                    color={chosenType === key ? 'primary' : 'default'}
                  />
                ))}
              </>
            }
          />
        </DialogContent>
      </StyledDialog>
    </>
  )
}

const Table = forwardRef(function (
  {
    chosenType,
    DataTableProps: passDataTableProps = {},
    initPageSize = 50,
    ...passThrough
  }: stdTableSetup.props & Partial<{ chosenType: CodeTypes }>,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    const def = {
      _View: {
        name: '',
        details: {
          dataFormat(_: any, row: any) {
            return <DialogEditCode record={row} chosenType={chosenType!} />
          },
        },
      },
    }
    return {
      ...def,
      ...codeTypeColumns[chosenType!],
    }
  }, [chosenType])

  const apiEndpoint = useCallback(
    (payload: any): Promise<any> => {
      return api.search(`/engineering/healthcare_codes/${chosenType}`, payload)
    },
    [chosenType]
  )

  const { TableDisplay, forceLoad, request } =
    stdTableSetup.useStandardTableSetup(
      {
        ...passThrough,
        customColumns,
        apiEndpoint,
        initPageSize,
        autoLoadData: false,
        DataTableProps: {
          ...passDataTableProps,
          keyProp: 'Code',
          density: 'small',
          rowsPerPage: [25, 50, 100],
        },
      },
      ref
    )

  useEffect(() => {
    if (!chosenType) return
    forceLoad()
  }, [chosenType])

  useEffect(() => {
    forceLoad()
  }, [request])

  return <>{TableDisplay}</>
})

/*
 */
function DialogEditCode({
  record,
  chosenType,
}: {
  record: any
  chosenType: CodeTypes
}): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const { forceLoad: refreshTable } = useContext(stdTableSetup.baseContext)
  const isExisting = !!record?.Code

  const editFields = useMemo(() => {
    switch (chosenType) {
      case CodeTypes.CPT:
        return ['Category', 'Descr', 'LaymanDescr', 'Source']
      case CodeTypes.CPT_MOD:
        return ['Descr']
      case CodeTypes.DRG:
        return ['Descr', 'Type']
      case CodeTypes.POS:
        return ['Name', 'Descr']
      case CodeTypes.ICD10:
        return ['Descr', 'Formatted']
      case CodeTypes.UB:
        return ['Group', 'Descr']
    }
    return []
  }, [chosenType])

  useEffect(() => {
    if (!isOpen) return
    setData({ ...record })
  }, [isOpen, record])

  function doSave() {
    let action: Promise<any>
    if (isExisting) {
      action = api.put(`/engineering/healthcare_codes/${chosenType}`, data)
    } else {
      action = api.post(`/engineering/healthcare_codes/${chosenType}`, data)
    }
    action
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Code saved OK', SnackbarTypeSuccess)
        setIsOpen(false)
        refreshTable?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving code',
        })
      )
  }

  function setter({ name, value }: SetterArgs) {
    setData((prev: any) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <IconButton size="small" onClick={() => setIsOpen(true)}>
        {isExisting ? (
          <DesignSuite2023.CommonIcons.IconAspectRatio />
        ) : (
          <DesignSuite2023.CommonIcons.IconAdd />
        )}
      </IconButton>

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          maxWidth="sm"
          fullWidth>
          <DialogTitle
            disableTypography
            style={{
              background: '#f1f1f1',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant="h6">
              {isExisting ? 'Edit' : 'Add'} {codeTypeLabels[chosenType]} Code
            </Typography>
            <div>
              <IconButton
                size="medium"
                onClick={() => setIsOpen(false)}
                style={{ padding: 5 }}>
                <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent>
            {!!record?.Code ? (
              <div style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                <span
                  style={{
                    fontSize: '200%',
                    padding: '0.5rem 0.75rem',
                    background: '#dadada',
                    borderRadius: '8px',
                    display: 'inline-block',
                    textAlign: 'center',
                    margin: '0 auto',
                  }}>
                  {record?.Code}
                </span>
              </div>
            ) : (
              <RenderTextField
                name="Code"
                label="Code"
                value={data?.Code}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            )}

            <div>
              {editFields.map((f) => (
                <div key={f} style={{ paddingBottom: '0.5rem' }}>
                  <RenderTextField
                    name={f}
                    label={f}
                    value={data?.[f]}
                    setter={setter}
                    use2023Styles
                    opts={{ margin: 'dense' }}
                  />
                </div>
              ))}
            </div>
          </DialogContent>

          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <Button variant="outlined" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
              }
              right={
                <Button variant="contained" color="primary" onClick={doSave}>
                  Save
                </Button>
              }
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
