import React, { useEffect, useState } from 'react'
import * as api from '../../services/thezerocard/api-helper'
import { getAllCostKeyBundleContentOptions } from '../../actions/CostKeyActions'
import { ShapeCostKeyData, ShapeBundleContentRecord } from './types'
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridSelectionModel,
  GridSortModel,
  GridRenderCellParams,
  GridCellEditCommitParams,
  GridColDef,
  GridRowParams,
  GridFilterModel,
} from '@mui/x-data-grid'
import { TextField, IconButton, Button, Switch } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import styled from 'styled-components'
import { useFrameHeightLock } from '../../components/AppFrame'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeError,
  SnackbarTypeWarning,
} from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../components/DesignSuite2023'
import StringifyCostKey from './Components/StringifyCostKey'

const StyledDataQuality = styled.div`
  height: 100%;
  position: relative;
  padding: 1rem;

  .flex-vert {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .dq-container {
    flex: 1;
    height: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    column-gap: 1rem;
    padding-bottom: 1rem;

    .table-grid-wrap {
      height: 100%;
      width: 50%;
      overflow: hidden;
      overflow-y: scroll;

      .MuiDataGrid-columnsContainer,
      .MuiDataGrid-footerContainer {
        background: #e9e9e9;
      }
    }
  }

  .peekers {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 1rem;
    overflow: hidden;
    overflow-y: scroll;

    .headerer {
      position: sticky;
      top: 0;
      background: #e1e1e1;
      text-align: center;
      margin-bottom: 1px;
      z-index: 2;
    }

    .peeky {
      margin-bottom: 2rem;
      .attr {
        display: block;
        font-size: 90%;
      }
    }
  }

  .actions {
    // height: calc(8% - 1rem);
  }
`

const StyledContent = styled.span`
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledFloatingTool = styled.div`
  display: flex;
  column-gap: 1rem;
  padding: 1rem;
  background: #f1f1f1;
  border: 2px solid #ccc;
  border-radius: 7px;
  justify-content: center;
  white-space: nowrap;
`

export default function DataQualityBulkContents() {
  const [isLoading, setIsLoading] = useState(false)
  const [allOptions, setAllOptions] = useState<ShapeBundleContentRecord[]>([])
  const [leftSelectedIDs, setLeftSelectedIDs] = useState<number[]>([])
  const [rightSelectedIDs, setRightSelectedIDs] = useState<number[]>([])
  const rightSelectedID = React.useMemo(() => {
    if (rightSelectedIDs.length === 1) return rightSelectedIDs[0]
    return null
  }, [rightSelectedIDs])
  const [leftSearchText, setLeftSearchText] = useState('')
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  const [peekRow, setPeekRow] = useState<GridRenderCellParams | null>(null)
  const [peekCostKeys, setPeekCostKeys] = useState<ShapeCostKeyData[]>([])

  useFrameHeightLock(() => true, [])

  useEffect(() => {
    loadAllOptions()
  }, [])

  useEffect(() => {
    setRightSelectedIDs([])
  }, [leftSelectedIDs])

  useEffect(() => {
    if (!peekRow) {
      if (!!peekCostKeys?.length) {
        setPeekCostKeys([])
      }
      return
    }
    // if (leftSelectedIDs?.[0] === peekRow?.row?.ID) return
    if (leftSelectedIDs.length !== 1) {
      setPeekRow(null)
      setPeekCostKeys([])
      return
    }

    if (leftSelectedIDs?.[0] === peekRow?.row?.ID && !peekCostKeys?.length) {
      apiGetDataQualityCostKeys({
        WhereCostKeyContentOptionIDs: [peekRow.row.ID],
      })
        .then((res: any) => {
          if (res.error) throw res
          setPeekCostKeys(res.Data || [])
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed to load cost keys for line',
          })
        )
    }
  }, [peekRow, peekCostKeys, leftSelectedIDs])

  function loadAllOptions() {
    setIsLoading(true)
    return (
      getAllCostKeyBundleContentOptions()
        .then((res) => {
          if (res.error) throw res
          setAllOptions(res.Data || [])
        })
        // .catch() // @todo
        .finally(() => setIsLoading(false))
    )
  }

  const onLeftSideSelection = React.useCallback(
    (newSelection: GridSelectionModel) => {
      setLeftSelectedIDs(newSelection as number[])
    },
    []
  )

  const onRightSideSelection = React.useCallback(
    (newSelection: GridSelectionModel) => {
      setRightSelectedIDs(newSelection as number[])
    },
    []
  )

  function onClickDelete() {
    const c = window.confirm(
      `You have ${leftSelectedIDs.length} items selected for deletion. Continue?`
    )
    if (!c) return

    apiPost({
      contentOptionIDs: leftSelectedIDs,
      action: 'delete',
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar(
          `Successfully deleted ${leftSelectedIDs.length} rows`,
          SnackbarTypeSuccess
        )
      })
      .then(loadAllOptions)
      .finally(() => {
        setLeftSelectedIDs([])
      })
  }

  function onClickReplace() {
    // check to make sure none of the leftSelectedIDs have 0 occurrences
    const leftSelected = allOptions.filter((x) =>
      leftSelectedIDs.includes(x.ID)
    )
    for (let i = 0; i < leftSelected.length; i++) {
      if (leftSelected[i].Occurrences === 0) {
        showSnackbar(
          'One or more of the left-selected rows have 0 occurrences and cannot be replaced',
          SnackbarTypeError
        )
        return
      }
    }

    apiPost({
      contentOptionIDs: leftSelectedIDs,
      action: 'replace',
      replaceToContentOptionID: rightSelectedID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Replaced successfully', SnackbarTypeSuccess)
      })
      .then(loadAllOptions)
      .catch(catchAPIError({ defaultMessage: 'Operation failed' }))
      .finally(() => {
        setLeftSelectedIDs([])
      })
  }

  function onCellEdit(params: GridCellEditCommitParams) {
    const opt = allOptions.find((x) => x.ID === params.id)
    const prevText = opt?.Content
    const newText = params.value
    const c = window.confirm(
      `Changing the text from:\n"${prevText}"\n\nto:\n"${newText}".\n\nThis text appears in the bundle contents on ${opt?.Occurrences} cost key records.\n\nAre you sure you want to continue?`
    )
    if (!c) return

    apiPost({
      contentOptionIDs: [params.id as number],
      action: 'update_in_place',
      newContent: params.value,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Updated OK', SnackbarTypeSuccess)
      })
      .then(loadAllOptions)
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to update content',
          withError: (err) => {
            // always reload the list to wipe out the in-place edit
            loadAllOptions()
          },
        })
      )
  }

  function onClickClearRight() {
    setRightSelectedIDs([])
  }

  function onClickPeekAllCostKeys(r: GridRenderCellParams) {
    if (!r.row?.ID) {
      return console.warn('No record ID found')
    }
    setPeekCostKeys([])
    setLeftSelectedIDs([r.row.ID])
    setPeekRow(r)
  }

  function renderRight() {
    if (!!(peekCostKeys || []).length) {
      return (
        <div className="peekers">
          <div className="headerer">
            Cost Keys containing line selected from left side:
            <IconButton
              onClick={() => {
                setPeekRow(null)
                setPeekCostKeys([])
              }}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
          {peekCostKeys.length < 150 ? (
            peekCostKeys.map((ck) => (
              <div className="peeky" key={ck.ID}>
                <div style={{ display: 'inline-block' }}>
                  <StringifyCostKey highlighters bd={ck.BundleDefinition!} />
                </div>
                <br />
                <div className="attr">
                  Legacy Code: <strong>{ck.Code}</strong>
                </div>
                <div className="attr">
                  Deprecated?:{' '}
                  {ck.Deprecated ? (
                    <i style={{ color: 'red' }}>YES</i>
                  ) : (
                    <i style={{ color: 'green' }}>NO</i>
                  )}
                </div>
                <div className="attr">
                  Legacy "Bundle Contents": <pre>{ck.BundleContents}</pre>
                </div>
              </div>
            ))
          ) : (
            <div className="peeky">
              <div>
                <strong>Too many cost keys to display (150 is the max)</strong>
              </div>
            </div>
          )}
        </div>
      )
    }

    if (!!(leftSelectedIDs || []).length) {
      return (
        <DataGridDisplay
          isLoading={isLoading}
          allOptions={allOptions}
          selections={rightSelectedIDs}
          handleSelectionChange={onRightSideSelection}
          initialSearchText={leftSearchText}
          fnIsRowSelectable={(params) => true}
        />
      )
    }

    return null
  }

  if (!allOptions.length) return null

  return (
    <StyledDataQuality>
      <div className="flex-vert">
        <div className="dq-container">
          <div className="table-grid-wrap">
            <DataGridDisplay
              isLoading={isLoading}
              allOptions={allOptions}
              selections={leftSelectedIDs}
              handleSelectionChange={onLeftSideSelection}
              checkboxSelection
              onCellEditCommit={onCellEdit}
              onClickPeekAllCostKeys={onClickPeekAllCostKeys}
              onSearchTextChange={setLeftSearchText}
            />
          </div>
          <div className="table-grid-wrap">{renderRight()}</div>
        </div>

        <StyledFloatingTool className="actions">
          <div>
            <Button
              disableRipple // https://github.com/mui/material-ui/issues/30953
              variant="outlined"
              color="secondary"
              disabled={!!rightSelectedID || leftSelectedIDs.length === 0}
              onClick={onClickDelete}>
              Delete Selected
            </Button>
          </div>
          <div>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              disabled={!rightSelectedID}
              onClick={onClickReplace}>
              Replace All Left Values With Right
            </Button>
          </div>
          <div>
            <Button
              disableRipple
              variant="outlined"
              color="primary"
              disabled={!rightSelectedID}
              onClick={onClickClearRight}>
              Clear Right Selection
            </Button>
          </div>
        </StyledFloatingTool>
      </div>
    </StyledDataQuality>
  )
}

function DataGridDisplay({
  isLoading = false,
  allOptions,
  selections,
  handleSelectionChange,
  checkboxSelection = false,
  onCellEditCommit,
  onClickPeekAllCostKeys,
  initialSearchText = '',
  onSearchTextChange,
  fnIsRowSelectable = (params: GridRowParams, details: any) => {
    return params?.row?.Occurrences >= 1
  },
}: {
  isLoading?: boolean
  allOptions: ShapeBundleContentRecord[]
  selections: number[]
  handleSelectionChange: (newSelection: GridSelectionModel) => void
  checkboxSelection?: boolean
  onCellEditCommit?(params: GridCellEditCommitParams): void
  onClickPeekAllCostKeys?(params: GridRenderCellParams): void
  initialSearchText?: string
  onSearchTextChange?(searchText: string): void
  fnIsRowSelectable?(params: GridRowParams, details?: any): boolean
}) {
  const [trackedPage, setTrackedPage] = useState(0)
  const [trackedPageSize, setTrackedPageSize] = useState(50)
  const [searchText, setSearchText] = useState(initialSearchText || '')
  const [sortModel, setSortModel] = useState<GridSortModel>(() => [
    { field: 'Occurrences', sort: 'desc' },
  ])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  })
  const [exclude0s, setExclude0s] = useState(true)
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    handleSelectionChange([])
  }, [trackedPage])

  useEffect(() => {
    onSearchTextChange?.(searchText)
    if (filterModel.items?.length === 0 && !searchText) return
    if (!searchText) return setFilterModel({ items: [] })
    setFilterModel({
      items: [
        {
          columnField: 'Content',
          operatorValue: 'contains',
          value: searchText,
        },
      ],
    })
  }, [searchText])

  const tableCols = React.useMemo(() => {
    const cols: Array<GridColDef> = [
      {
        field: 'Occurrences',
        headerName: 'Count',
        minWidth: 70,
        type: 'number',
        filterable: false,
      },
      {
        field: 'Content',
        headerName: 'Content',
        flex: 1,
        editable: true,
        type: 'string',
        renderCell: (params: GridRenderCellParams) => (
          <DesignSuite2023.Tooltip title={params.value}>
            <StyledContent>{params.value}</StyledContent>
          </DesignSuite2023.Tooltip>
        ),
      },
      {
        field: 'ID',
        headerName: 'ID',
        width: 75,
        sortable: false,
        hide: true,
        type: 'number',
      },
    ]

    if (onClickPeekAllCostKeys) {
      cols.push({
        field: '',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        width: 50,
        renderHeader: () => <span></span>,
        renderCell: (params: GridRenderCellParams) => (
          <DesignSuite2023.Tooltip
            title={`View all cost keys where this line appears (contentOptionID: ${params.id})`}>
            <IconButton
              size="small"
              onClick={() => onClickPeekAllCostKeys?.(params)}>
              <DesignSuite2023.CommonIcons.IconPeek fontSize="inherit" />
            </IconButton>
          </DesignSuite2023.Tooltip>
        ),
      })
    }

    return cols
  }, [onClickPeekAllCostKeys])

  const rows = React.useMemo(() => {
    if (!allOptions || !allOptions.length) return []
    if (exclude0s) {
      return allOptions.filter((x) => x.Occurrences! > 0)
    }
    return allOptions
  }, [allOptions, exclude0s])

  const onSortModelChanged = React.useCallback(
    (sm: GridSortModel) => {
      if (JSON.stringify(sm) === JSON.stringify(sortModel)) return
      setTrackedPage(0)
      setSortModel(sm)
    },
    [trackedPage, sortModel]
  )

  const isRowSelectable = React.useCallback(
    (params: GridRowParams, details?: any) => {
      return fnIsRowSelectable?.(params, details) || false
    },
    [rows]
  )

  const onSelectionModelChange = React.useCallback(
    (newSelection: GridSelectionModel) => {
      if (newSelection.length >= trackedPageSize) {
        showSnackbar(
          'Select-all is not supported if the list of results is > than the "Rows Per Page" attribute in the table footer',
          SnackbarTypeWarning
        )
        return
      }
      const validIDs = rows.map((x) => x.ID)
      const selections = newSelection.filter((x) =>
        validIDs.includes(x as number)
      )
      handleSelectionChange(selections)
    },
    [rows, trackedPage, trackedPageSize]
  )

  const onFilterModelChange = React.useCallback((fm: GridFilterModel) => {
    setFilterModel(fm)
    if (fm.items?.length === 0) {
      setSearchText('')
    }
  }, [])

  const components = React.useMemo(
    () => ({
      Toolbar: QuickSearchToolbar,
      LoadingOverlay: (props: any) => (
        <div
          {...props}
          id="custom-loader-overlay"
          style={{
            background: 'rgba(0,0,0,0.35)',
            zIndex: 999,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            position: 'absolute',
          }}>
          <div>
            <DesignSuite2023.LoadingSpinner />
          </div>
        </div>
      ),
    }),
    []
  )

  const componentsProps = React.useMemo(
    () => ({
      toolbar: {
        searchText,
        onChangeSearchText: (ev: any) => setSearchText(ev.target.value),
        exclude0s,
        onChangeExclude0s: (ev: any) => setExclude0s(ev.target.checked),
        clearSearch: () => setSearchText(''),
      },
    }),
    [searchText, exclude0s]
  )

  return (
    <DataGrid
      getRowId={(row) => row.ID}
      loading={rows.length === 0 || isLoading}
      disableColumnMenu
      checkboxSelection={checkboxSelection}
      disableSelectionOnClick={checkboxSelection}
      density="compact"
      rows={rows}
      columns={tableCols}
      selectionModel={selections}
      onSelectionModelChange={onSelectionModelChange}
      page={trackedPage}
      onPageChange={setTrackedPage}
      pageSize={trackedPageSize}
      onPageSizeChange={setTrackedPageSize}
      sortModel={sortModel}
      onSortModelChange={onSortModelChanged}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      isRowSelectable={isRowSelectable}
      components={components}
      componentsProps={componentsProps}
      onCellEditCommit={onCellEditCommit}
      isCellEditable={(params) => {
        return !!onCellEditCommit
      }}
    />
  )
}

/*
https://v4.mui.com/components/data-grid/filtering/
*/
function QuickSearchToolbar(props: any) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
      }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </div>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '1rem',
        }}>
        <label>
          <Switch
            value={props.exclude0s}
            checked={props.exclude0s}
            onChange={props.onChangeExclude0s}
            name="exclude0s"
            color="primary"
            size="small"
          />
          <span>Exclude Where Count=0</span>
        </label>
        <TextField
          autoFocus={true}
          variant="outlined"
          size="small"
          margin="none"
          value={props.searchText}
          onChange={props.onChangeSearchText}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.searchText ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  )
}

// function escapeRegExp(value: string) {
//   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
// }

function apiPost(data: any) {
  return api.post('/cost_key_data_quality', data)
}

function apiGetDataQualityCostKeys(params: any) {
  return api.get('/cost_key_data_quality/costkeys', null, params)
}
