import React, {
  useState,
  useEffect,
  forwardRef,
  useMemo,
  useContext,
} from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import * as api from '../../services/thezerocard/api-helper'
import utils from '../../utils'
import styled from 'styled-components'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { renderTextField as RenderTextField, SetterArgs } from '../ViewHelpers'

const StyledDialog = styled(Dialog)`
  [role='dialog'],
  .MuiDialogContent-root,
  .std-table {
    height: 100%;
  }

  .MuiDialogContent-root {
    padding: 0 !important;

    .std-table {
      overflow: hidden;
      overflow-y: auto;

      .header-items {
        padding: 0 1rem;
        white-space: nowrap;
        margin-top: 0;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 5;

        .header-item-cell {
          column-gap: 0.5rem;
        }
      }

      .base-table-display {
        margin-top: 0 !important;

        .data-table-footer {
          position: sticky;
          bottom: 0;
          background: #fff;
        }
      }
    }
  }
`

export default function AddressManagement(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Launch address management dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Addresses
        </Button>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        open={isOpen}
        fullWidth
        maxWidth="lg"
        onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Table
            LeftHeaderItems={
              <>
                <Typography variant="subtitle1">Addresses</Typography>
                <stdTableSetup.StandardFilterSearch />
              </>
            }
          />
        </DialogContent>
      </StyledDialog>
    </>
  )
}

const Table = forwardRef(function TableAddress(
  {
    customColumns: propCustomColumns,
    apiEndpoint = getAddresses,
    DataTableProps: passDataTableProps = {},
    initPageSize = 50,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const customColumns = useMemo(() => {
    if (!!propCustomColumns) return propCustomColumns
    return {
      _View: {
        name: '',
        details: {
          dataFormat(_: any, row: any) {
            return <DialogEditAddress addrObj={row} />
          },
        },
      },
      ID: { name: 'ID' },
      Address1: { name: 'Address1' },
      Address2: { name: 'Address2' },
      City: { name: 'City' },
      State: { name: 'State' },
      Zip: { name: 'Zip' },
      Lat: { name: 'Lat' },
      Long: { name: 'Long' },
      Geocoded: {
        name: 'Geocoded',
        details: { dataFormat: utils.boolYesNoFormatter },
      },
    }
  }, [propCustomColumns])

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      initPageSize,
      DataTableProps: {
        ...passDataTableProps,
        density: 'small',
        rowsPerPage: [25, 50, 100],
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

function getAddresses(params: any) {
  return api.search('/engineering/address', params)
}

type shapeAddress = {
  ID: number
  Address1: string
  Address2: string
  City: string
  State: string
  Zip: string
  // make full typing less restrictive; there are other properties we don't care about
  [k: string]: any
}

function DialogEditAddress({
  addrObj,
}: {
  addrObj: shapeAddress
}): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<shapeAddress | null>(null)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const { refresh } = useContext(stdTableSetup.baseContext)

  useEffect(() => {
    if (!isOpen) return
    setData({ ...addrObj })
  }, [isOpen, addrObj])

  function doSave() {
    api
      .put(`/engineering/address/${data?.ID}`, data)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Address saved OK', SnackbarTypeSuccess)
        setIsOpen(false)
        refresh?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving address',
        })
      )
  }

  function setter({ name, value }: SetterArgs) {
    setData((prev: any) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <IconButton size="small" onClick={() => setIsOpen(true)}>
        <DesignSuite2023.CommonIcons.IconAspectRatio />
      </IconButton>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="sm"
        fullWidth>
        <DialogTitle
          disableTypography
          style={{
            background: '#f1f1f1',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="h6">Edit Address</Typography>
          <div>
            <IconButton
              size="medium"
              onClick={() => setIsOpen(false)}
              style={{ padding: 5 }}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <RenderTextField
                name="Address1"
                label="Address1"
                value={data?.Address1}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
            <Grid item xs={5}>
              <RenderTextField
                name="Address2"
                label="Address2"
                value={data?.Address2}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <RenderTextField
                name="City"
                label="City"
                value={data?.City}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
            <Grid item xs={2}>
              <RenderTextField
                name="State"
                label="State"
                value={data?.State}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
            <Grid item xs={2}>
              <RenderTextField
                name="Zip"
                label="Zip"
                value={data?.Zip}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button variant="outlined" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button variant="contained" color="primary" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
