import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimInvoiced } from '../../../actions/ZClaimFundingActions'
import { scopedRoutes } from '../../../actions/ZClaimActions'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import { EmployerSearcher } from '../../../components/Searchers'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import {
  Alarm,
  AspectRatio as IconAspectRatio,
  Notifications,
} from '@material-ui/icons'
import ViewInvoicedDetailOverlay from './ViewInvoicedDetailOverlay'
//@ts-ignore
import styled from 'styled-components'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import dateTime from '../../../utils/dateTime'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export interface invoicedRecord {
  InvoiceID: number
  InvoiceNumber: string
  InvoiceDate: string
  EmployerID: number
  EmployerName: string
  EmployerLegalName: string
  EmployerQuickbooksHandle: string
  ClaimCount: number
  InvoiceTotal: string
  Applied: string
  InvoiceBalance: string
  BillingMethod: BillingMethod
  IsRefund: boolean
  IsVoid: boolean
  VoidInvoiceNotes: string
  LastNotificationDate: string | null
  LastNotificationType: string
}

export enum BillingMethod {
  All = 'all',
  DebitApproval = 'debit_approval',
  DebitAuto = 'debit_auto',
  ACH = 'ach',
  Check = 'check',
}

function getBillingMethodDisplay(value: BillingMethod): string {
  switch (value) {
    case BillingMethod.All:
      return 'All'
    case BillingMethod.DebitAuto:
      return 'Debit Auto'
    case BillingMethod.ACH:
      return 'ACH'
    case BillingMethod.Check:
      return 'Check'
    default:
      return ''
  }
}

export const defaultColumns = Object.freeze({
  _ViewInvoiceDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: invoicedRecord): any {
        return <InlineViewInvoiceDetail data={row} />
      },
    },
  },
  InvoiceNumber: {
    name: 'Number',
    details: {
      sortName: 'InvoiceNumber',
      dataFormat(_: any, row: invoicedRecord): React.ReactElement {
        return (
          <>
            {row.InvoiceNumber}
            {row.IsRefund && (
              <span style={{ color: 'red', marginLeft: '5px' }}>[refund]</span>
            )}
            {row.IsVoid && (
              <span style={{ color: 'red', marginLeft: '5px' }}>[void]</span>
            )}
          </>
        )
      },
    },
  },
  InvoiceDate: {
    name: 'Date',
    details: { sortName: 'InvoiceDate', dataFormat: dateTime.cellFormatter() },
  },
  EmployerName: {
    name: 'Employer',
    details: {
      sortName: 'EmployerLegalName',
      dataFormat(_: any, row: invoicedRecord): React.ReactElement {
        return (
          <div>
            <div>{row.EmployerLegalName}</div>
            {row.EmployerLegalName !== row.EmployerQuickbooksHandle && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {row.EmployerQuickbooksHandle}
              </div>
            )}
            {row.EmployerLegalName !== row.EmployerName && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {row.EmployerName}
              </div>
            )}
          </div>
        )
      },
    },
  },
  BillingMethod: {
    name: 'Method',
    details: {
      dataFormat(_: BillingMethod, row: invoicedRecord): string {
        return getBillingMethodDisplay(row.BillingMethod)
      },
    },
  },
  InvoiceTotal: {
    name: 'Invoiced',
    details: {
      dataFormat(_: any, row: invoicedRecord): JSX.Element {
        let style = {}
        if (row.IsRefund) {
          style = { color: 'red' }
        }

        return <span style={style}>{formatAmount(row.InvoiceTotal)}</span>
      },
      sortName: 'InvoiceAmount',
    },
  },
  Applied: {
    name: 'Applied',
    details: {
      dataFormat(_: any, row: invoicedRecord): JSX.Element {
        return <>{row.Applied !== '0' && <>{formatAmount(row.Applied)}</>}</>
      },
    },
  },
  InvoiceBalance: {
    name: 'Balance',
    details: {
      dataFormat(_: any, row: invoicedRecord): string {
        return formatAmount(row.InvoiceBalance)
      },
    },
  },
  LastNotified: {
    name: 'Last Notified',
    details: {
      sortName: 'NotificationDate',
      dataFormat(_: any, row: invoicedRecord): JSX.Element {
        if (!row.LastNotificationDate) {
          return <></>
        }

        return (
          <Typography component="div" variant="body2">
            {row.LastNotificationType === `zclaim_invoice_created` ? (
              <Notifications />
            ) : (
              <Alarm />
            )}
            &nbsp;&nbsp;&nbsp;{' '}
            {dateTime.parse(row.LastNotificationDate).format()}
          </Typography>
        )
      },
    },
  },
})

const defaultSort = { col: 'InvoiceNumber', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimInvoiced,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      passFilters: { hasBalance: true },
      initPageSize: 250,
      DataTableProps: {
        keyProp: 'InvoiceID',
        isRowCheckable: (row: invoicedRecord) => !row.IsVoid,
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function InlineViewInvoiceDetail({
  data,
}: {
  data: invoicedRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewInvoicedDetailOverlay
        ref={ref}
        data={data}
        invoiceID={data.InvoiceID}
        onClose={() => refresh()}
      />
    </>
  )
}

export function FilterInvoiceDate(props: any): React.ReactElement {
  const { setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <ManagedDateInput
      {...props}
      label="Invoice Date"
      setter={({ value }) => {
        if (!value || value === '_invalid_') {
          setFilterImmediate({ invoiceDate: null })
          return
        }

        setFilterImmediate({ invoiceDate: value })
      }}
    />
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
      apiEndpointGetByID={scopedRoutes.getEmployerByID}
      apiEndpointSearch={scopedRoutes.findEmployers}
    />
  )
}

export function FilterHasBalance(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Has Balance"
      control={
        <Switch
          color="primary"
          checked={filter.hasBalance}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, hasBalance: true })
              return
            }
            setFilterImmediate({ ...filter, hasBalance: null })
          }}
        />
      }
    />
  )
}

export function FilterBillingMethod(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(BillingMethod).map((value: BillingMethod) => ({
      value,
      label: getBillingMethodDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ billingMethods: null })
    }
    setFilterImmediate({ billingMethods: [sel.props.value] })
  }

  const v = filter.billingMethods || BillingMethod.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Biling Method
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Biling Method"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterHasInvoiceNotification(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const items = ['Any', 'Yes', 'No']

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'Any') {
      return setFilterImmediate({ hasInvoiceNotification: null })
    }
    setFilterImmediate({ hasInvoiceNotification: sel.props.value === 'Yes' })
  }

  const v =
    !filter.hasOwnProperty('hasInvoiceNotification') ||
    filter.hasInvoiceNotification === null
      ? 'Any'
      : filter.hasInvoiceNotification
        ? 'Yes'
        : 'No'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Has Invoice Notification
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Invoice Notification"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterHasInvoiceReminder(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const items = ['Any', 'Yes', 'No']

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'Any') {
      return setFilterImmediate({ hasInvoiceReminder: null })
    }
    setFilterImmediate({ hasInvoiceReminder: sel.props.value === 'Yes' })
  }

  const v =
    !filter.hasOwnProperty('hasInvoiceReminder') ||
    filter.hasInvoiceReminder === null
      ? 'Any'
      : filter.hasInvoiceReminder
        ? 'Yes'
        : 'No'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Has Invoice Reminder
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Invoice Reminder"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
