import axios from 'axios'

const SERVERLESS_BASE_URL = localStorage.getItem('serverless_base_url')
const axiosClient = axios.create({
  baseURL: SERVERLESS_BASE_URL,
})

export function get(url, params) {
  let requestUrl = SERVERLESS_BASE_URL + url

  if (params) {
    requestUrl += '?' + queryParams(params)
  }

  return axiosClient.get(requestUrl)
}

function queryParams(params) {
  return Object.keys(params)
    .map((k) => {
      let value = params[k]
      if (value && typeof value === 'string') {
        value = value.trim()
      }
      return encodeURIComponent(k) + '=' + encodeURIComponent(value)
    })
    .join('&')
}
