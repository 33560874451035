import React, { forwardRef } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'

export const RenderBool = forwardRef(function RenderBool(
  { value, ...restProps }: { value: boolean },
  ref: any
): React.ReactElement {
  if (value === true) {
    return (
      <DesignSuite2023.CommonIcons.IconCheck
        {...restProps}
        ref={ref}
        style={{ color: 'green' }}
        fontSize="inherit"
      />
    )
  }
  return (
    <DesignSuite2023.CommonIcons.IconDeny
      {...restProps}
      ref={ref}
      style={{ color: '#ad4242' }}
      fontSize="inherit"
    />
  )
})
