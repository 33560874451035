import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Chip, Popper } from '@material-ui/core'
// import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { getListAllCodesByAcronym } from '../../../actions/MasterDataActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapeDRGCode } from '../types'
import styled from 'styled-components'

interface props {
  value: string | string[] | null | undefined
  onChange?(codes: Array<ShapeDRGCode> | null): void
  multiple?: boolean
}

export default function AutoCompleteDRGCodes({
  value,
  onChange: propOnChange,
  multiple = false,
}: props) {
  const [allCodes, setAllCodes] = useState<Array<ShapeDRGCode>>([])
  const { catchAPIError } = useErrorHandlers()

  const acValue = React.useMemo(():
    | ShapeDRGCode[]
    | ShapeDRGCode
    | string
    | null
    | undefined => {
    if (multiple) {
      if (!value?.length) return []
      return allCodes.filter((c) => value?.includes(c.Code))
    }

    if (!value) return null
    const matched = allCodes.find((c) => c.Code === value)
    if (matched) return matched
    return null
  }, [value, multiple, allCodes])

  useEffect(() => {
    loadDRGs()
  }, [])

  function loadDRGs(): void {
    getListAllCodesByAcronym('drg')
      .then((res: any) => {
        if (res.error) throw res
        setAllCodes(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading DRG code list',
        })
      )
  }

  function handleChange(
    e: any,
    v: ShapeDRGCode | Array<ShapeDRGCode> | any // 'any' to shut TS up
  ) {
    if (Array.isArray(v)) {
      propOnChange?.(v)
      return
    }
    if (!!v) {
      propOnChange?.([v])
      return
    }
    propOnChange?.(null)
  }

  const renderInput = React.useCallback(
    (params: any) => (
      <TextField
        {...params}
        size="small"
        variant="outlined"
        label="DRG Code"
        placeholder="DRG"
        InputLabelProps={{ shrink: true }}
      />
    ),
    []
  )

  const renderTags = React.useCallback(
    (v: any, getProps: any) =>
      v.map((opt: any, index: number) => (
        <DesignSuite2023.Tooltip key={opt.Code} title={opt.Name}>
          <Chip
            variant="outlined"
            size="small"
            label={opt.Code}
            {...getProps({ index })}
          />
        </DesignSuite2023.Tooltip>
      )),
    []
  )

  const getOptionLabel = React.useCallback((o: any) => o?.Code || '', [])

  const getOptionSelected = React.useCallback(
    (opt: any, val: any) => opt?.Code === val?.Code,
    []
  )

  return (
    <Autocomplete
      multiple={multiple}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      options={allCodes}
      value={acValue}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      renderInput={renderInput}
      renderTags={renderTags}
      renderOption={(o: any) => <RenderOption {...o} />}
      PopperComponent={CustomPopper}
    />
  )
}

function RenderOption(o: any) {
  return (
    <div
      style={{
        display: 'flex',
        columnGap: '1rem',
        alignItems: 'center',
        width: '100%',
      }}>
      <span>{o.Code}</span>
      <div style={{ fontSize: '85%' }}>
        <span>{o.Descr}</span>
        <small style={{ display: 'block' }}>(type: {o.TypeHandle})</small>
      </div>
    </div>
  )
}

const CustomPopper = styled(Popper)`
  width: 480px !important;

  .MuiAutocomplete-paper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
`
