/*
@todo: This would be a fantastic case to use Redux or some other centralized
state store... see how it's used on the employer plan limits page for why
(follow the network requests tab :|).
*/
import React, { useEffect, useState, useCallback } from 'react'
import { TextField, Popper } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  findCostKeyCategories,
  getCostKeyCategoryByID,
} from '../../actions/CostKeyActions'
import debounce from 'lodash/debounce'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import './styles.scss'
import styled from 'styled-components'

const StyledOption = styled.span`
  display: inline-flex;
  align-items: center;
  b {
    color: #aaa;
    font-size: 75%;
  }
`

interface DataItem {
  ID: number
  Name: string
  Path: string
  [k: string]: any
}

interface props {
  selectedCostKeyCategoryID: number | null
  onChange(costKeyCategoryID: number | null, obj?: any): void
  AutoCompleteProps?: object
  TextFieldProps?: any
  disableClear?: boolean
  preloadAll?: boolean
  size?: 'small' | 'medium'
}

export default React.forwardRef(function CostKeyCategorySearcher(
  {
    selectedCostKeyCategoryID,
    onChange,
    AutoCompleteProps = {},
    TextFieldProps = {},
    disableClear = false,
    preloadAll = false,
    size = 'small',
  }: props,
  ref: any
): React.ReactElement {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!selectedCostKeyCategoryID) return
    if (active && active.ID === selectedCostKeyCategoryID) return
    getCostKeyCategoryByID(selectedCostKeyCategoryID)
      .then((res: any) => {
        if (res?.err) throw res
        const { ID, Name, Path } = res?.Data
        setActive({ ID, Name, Path })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading selected costkey category',
        })
      )
  }, [selectedCostKeyCategoryID, active])

  useEffect(() => {
    if (preloadAll) return
    if (query.length < 3) return
    if (active?.Name === query) return
    load({ filter: { q: query } })
  }, [preloadAll, query])

  useEffect(() => {
    if (!preloadAll) return
    load({ filter: { noLimit: true }, sort: ['handle', 'asc'] })
  }, [preloadAll])

  const onInputChange = useCallback(
    debounce((ev: any, q: string, r: string) => {
      setQuery(q)
    }, 350),
    [setQuery]
  )

  function load(params: any) {
    findCostKeyCategories(params)
      .then((r: any) => {
        setData(
          r.Data.map((v: any) => ({
            ID: v.ID,
            Name: v.Name,
            Path: v.Path,
          }))
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading categories',
        })
      )
  }

  function onSelectionChange(_: any, b: DataItem | null) {
    setActive(b)
    onChange?.(b ? b.ID : null, b || null)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Path}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      disableClearable={disableClear}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      renderOption={(opt: DataItem) => (
        <StyledOption>
          {opt.Path}&nbsp;<b>(ID: {opt.ID})</b>
        </StyledOption>
      )}
      PopperComponent={CustomPopper}
      renderInput={(params: any) => {
        // We have to do some fancy destructuring here in order to allow passing TextFieldProps that
        // may contain InputProps and things like a start/end adornment, while **being sure we don't
        // override the 'params' sent by the Autocomplete component
        const { InputProps: tfInputProps, ...tfProps } = TextFieldProps
        return (
          <TextField
            {...params}
            variant="outlined"
            size={size}
            label="CostKey Category"
            placeholder="Start typing..."
            style={{ minWidth: 220 }}
            InputProps={{
              ...params.InputProps,
              ...tfInputProps,
            }}
            {...tfProps}
          />
        )
      }}
      {...AutoCompleteProps}
    />
  )
})

const CustomPopper = styled(Popper)`
  width: 380px !important;

  .MuiAutocomplete-paper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
`
