import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Step as MUIStep,
  StepLabel as MUIStepLabel,
  Stepper as MUIStepper,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import * as FileUpload from '../../../components/FileUpload'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../ViewHelpers'
import {
  PostProviderReceiptRecord,
  postProviderReceipts,
  PostProviderReceiptsParams,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import ProviderReceiptDetail from './ProviderReceiptDetail'
import DialogBillingEntitySearch from './DialogBillingEntitySearch'
import { getFileTypes } from '../../../actions/FileActions'
import dateTime from '../../../utils/dateTime'
import perms from '../../../utils/perms'

interface props {
  onCancel?: () => void
}

enum Steps {
  Enter,
  Apply,
}

const stepLabels = {
  [Steps.Enter]: 'Enter Funds',
  [Steps.Apply]: 'Apply Funds',
} as any

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
  }
`

interface BillingEntityData {
  ID: number
  Name: string
  TIN: string
  NPI: string
  OrganizationID: number
  OrganizationName: string
  RemittanceAddress: {
    Address1: string
    Address2: string
    City: string
    State: string
    Zip: string
  }
}

function initialBillingEntityData(): BillingEntityData {
  return {
    ID: 0,
    Name: '',
    TIN: '',
    NPI: '',
    OrganizationID: 0,
    OrganizationName: '',
    RemittanceAddress: {
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
    },
  }
}

export default function ReceiveProviderFunds({
  onCancel,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const [fileTypeID, setFileTypeID] = useState<number | null>(null)
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const [uploadInFlight, setUploadInFlight] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Enter)
  const [receiptID, setReceiptID] = useState<number>(0)
  const [billingEntityData, setBillingEntityData] = useState<BillingEntityData>(
    initialBillingEntityData
  )
  const { showForDuration: showSnackbar } = useSnackbar()
  const refBillingEntitySearch = React.useRef<any>(null)
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )

  function initialPostProviderReceiptRecord(): PostProviderReceiptRecord {
    return {
      billingEntityID: 0,
      receiptDate: dateTime.now().format(dateTime.formats.ISODate),
      receiptType: receiptType.CHECK,
      checkNumber: '',
      achInfo: '',
      fileIDs: [],
      amount: '0',
      notes: '',
    }
  }

  const [postProviderReceiptRecord, setPostProviderReceiptRecord] =
    useState<PostProviderReceiptRecord>(initialPostProviderReceiptRecord())

  const setter = ({ name, value }: SetterArgs) =>
    setPostProviderReceiptRecord((curr: PostProviderReceiptRecord) => ({
      ...curr,
      [name]: value,
    }))

  useEffect(() => {
    setReceiptID(0)
    setPostProviderReceiptRecord(initialPostProviderReceiptRecord)
    setBillingEntityData(initialBillingEntityData)
    setActiveStep(Steps.Enter)
    if (isOpen) {
      loadFileTypeID()
    }
  }, [isOpen])

  function loadFileTypeID() {
    if (fileTypeID) return // ghetto cache; if component already has this in memory great, otherwise reload it
    getFileTypes({ filter: { Hashes: ['provider_receipt'] } })
      .then((res: any) => {
        if (res.error) throw res
        setFileTypeID(res?.[0]?.ID)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading activity type info' })
      )
  }

  function doPostProviderReceipt() {
    setIsWorking(true)
    let params: PostProviderReceiptsParams = [postProviderReceiptRecord]

    interface res {
      Data: number[]
    }

    postProviderReceipts(params)
      .then((res: res) => {
        if (res && Array.isArray(res.Data) && res.Data.length === 1) {
          setReceiptID(res.Data[0])
        }

        setActiveStep(Steps.Apply)

        showSnackbar(`Receipt posted OK`, SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt',
        })
      )
      .finally(() => setIsWorking(false))
  }

  function displayStep(st: Steps) {
    return { style: { display: activeStep === st ? 'block' : 'none' } }
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Receive Provider Funds">
        <span>
          <Button
            fullWidth
            disabled={!hasPerms}
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<DesignSuite2023.CommonIcons.IconAttachMoney />}
            onClick={() => {
              setIsOpen(true)
            }}>
            Receive Provider Funds
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}>
        <DialogTitle>Receive Provider Funds</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <MUIStepper
              alternativeLabel
              activeStep={activeStep}
              style={{ paddingTop: 0 }}>
              {Object.entries(stepLabels).map((tuple: any[]) => {
                return (
                  <MUIStep key={tuple[0]}>
                    <MUIStepLabel>{tuple[1]}</MUIStepLabel>
                  </MUIStep>
                )
              })}
            </MUIStepper>

            <div {...displayStep(Steps.Enter)}>
              <Grid container spacing={2} alignItems="center">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DialogBillingEntitySearch
                      ref={refBillingEntitySearch}
                      onPickedBillingEntity={(data: BillingEntityData) => {
                        setBillingEntityData(data)
                        setter({ name: 'billingEntityID', value: data.ID })
                      }}
                      Trigger={(props: any) => (
                        <Button {...props} variant="outlined">
                          Select Billing Entity
                        </Button>
                      )}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    {billingEntityData.ID !== 0 && (
                      <div>
                        <Chip label="Billing Entity" size="small" />
                        &nbsp;&nbsp;&nbsp;
                        <Typography component="span" variant="body2">
                          {billingEntityData.Name}
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                        <Chip label="TIN" size="small" />
                        &nbsp;&nbsp;&nbsp;
                        <Typography component="span" variant="body2">
                          {billingEntityData.TIN}
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                        <Chip label="NPI" size="small" />
                        &nbsp;&nbsp;&nbsp;
                        <Typography component="span" variant="body2">
                          {billingEntityData.NPI}
                        </Typography>
                        &nbsp;&nbsp;&nbsp;
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <ManagedDateInput
                              name="receiptDate"
                              label="Date"
                              value={postProviderReceiptRecord.receiptDate}
                              setter={setter}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <RenderPriceField
                              opts={{ margin: 'dense' }}
                              name="amount"
                              label="Amount"
                              value={postProviderReceiptRecord.amount}
                              setter={setter}
                              use2023Styles
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControl>
                              <FormLabel>Type</FormLabel>
                              <RadioGroup
                                row
                                name="receiptType"
                                value={postProviderReceiptRecord.receiptType}
                                onChange={(
                                  ev: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setter({
                                    name: ev.target.name,
                                    value: ev.target.value,
                                  })
                                  if (ev.target.value !== receiptType.CHECK) {
                                    setter({ name: 'checkNumber', value: '' })
                                  }
                                  if (ev.target.value !== receiptType.ACH) {
                                    setter({ name: 'achInfo', value: '' })
                                  }
                                }}>
                                <FormControlLabel
                                  value={receiptType.ACH}
                                  control={<Radio />}
                                  label="ACH"
                                />
                                <FormControlLabel
                                  value={receiptType.CHECK}
                                  control={<Radio />}
                                  label="Check"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <RenderTextField
                              name="checkNumber"
                              label="Check Number"
                              value={postProviderReceiptRecord.checkNumber}
                              opts={{
                                hidden:
                                  postProviderReceiptRecord.receiptType !==
                                  receiptType.CHECK,
                                margin: 'dense',
                              }}
                              setter={setter}
                              use2023Styles
                            />
                            <RenderTextField
                              name="achInfo"
                              label="ACH Info"
                              value={postProviderReceiptRecord.achInfo}
                              opts={{
                                hidden:
                                  postProviderReceiptRecord.receiptType !==
                                  receiptType.ACH,
                                margin: 'dense',
                              }}
                              setter={setter}
                              use2023Styles
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <RenderNotesField
                            name="notes"
                            label="Notes"
                            value={postProviderReceiptRecord.notes}
                            setter={setter}
                            opts={{ rows: 8, margin: 'dense' }}
                            use2023Styles
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {!!fileTypeID && billingEntityData.ID !== 0 && (
                          <>
                            <FileUpload.Uploader
                              multiple
                              autoSign
                              autoUniqueName
                              defaultFileData={{
                                FileTypeID: fileTypeID,
                                OrganizationID:
                                  billingEntityData.OrganizationID,
                              }}
                              CustomFileDisplay={(props: any) => (
                                <FileUpload.DefaultSingleFileInput
                                  {...props}
                                  showInputs={false}
                                />
                              )}
                              subscribeAllProgress={(
                                fu: FileUpload.coordinator
                              ) => {
                                if (fu.status === FileUpload.statuses.START) {
                                  setUploadInFlight(true)
                                  return
                                }
                                if (fu.status === FileUpload.statuses.DONE) {
                                  setUploadInFlight(false)
                                }
                              }}
                              onSuccess={(
                                res: FileUpload.postFileResponse[]
                              ) => {
                                setter({
                                  name: 'fileIDs',
                                  value: res.map((item) => item.ID),
                                })
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <div {...displayStep(Steps.Apply)}>
              {receiptID !== 0 && (
                <ProviderReceiptDetail
                  receiptID={receiptID}
                  doClose={() => setReceiptID(0)}
                />
              )}
            </div>
          </StyledContent>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsOpen(false)
                    onCancel && onCancel()
                  }}>
                  {activeStep === Steps.Enter ? 'Cancel' : 'Close'}
                </Button>
                {activeStep === Steps.Apply && (
                  <Button
                    disabled={isWorking}
                    style={{ marginLeft: '10px' }}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(Steps.Enter)
                      setReceiptID(0)
                      setPostProviderReceiptRecord(
                        initialPostProviderReceiptRecord()
                      )
                      setBillingEntityData(initialBillingEntityData)
                    }}>
                    Enter Another Receipt
                  </Button>
                )}
              </>
            }
            right={
              <>
                {activeStep === Steps.Enter && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={doPostProviderReceipt}
                      disabled={
                        !billingEntityData.ID ||
                        uploadInFlight ||
                        isWorking ||
                        postProviderReceiptRecord.amount === '0' ||
                        postProviderReceiptRecord.amount === '' ||
                        postProviderReceiptRecord.amount.startsWith('-') ||
                        !postProviderReceiptRecord.receiptDate ||
                        (postProviderReceiptRecord.receiptType ===
                          receiptType.CHECK &&
                          !postProviderReceiptRecord.checkNumber) ||
                        (postProviderReceiptRecord.receiptType ===
                          receiptType.ACH &&
                          !postProviderReceiptRecord.achInfo)
                      }>
                      Save Receipt
                      {isWorking && (
                        <>
                          &nbsp;
                          <DesignSuite2023.LoadingSpinner
                            size={20}
                            show={isWorking}
                          />
                        </>
                      )}
                    </Button>
                  </>
                )}
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
