import React, { useEffect, useState } from 'react'
import { Chip } from '@material-ui/core'
import Models from '../../../models'
import DesignSuite2023 from '../../../components/DesignSuite2023'

import * as LoaTable from '../../Loas/Table'
import DisplayMemberFiles from './DisplayMemberFiles'
import * as ReferralTable from '../../Referrals/ReferralTableV2'
import * as EligSrcTable2 from '../../Eligibility/EligibilitySrcTable2'
import TableSESNotifications from './TableSESNotifications'

interface props {
  useEmployerMemberID: number | null
  emailForNotificationsLookup?: string | null
}

export default function TabbedItems({
  useEmployerMemberID,
  emailForNotificationsLookup = null,
}: props): React.ReactElement | null {
  const [currTab, setCurrTab] = useState(0)
  const [forceRefresh, setForceRefresh] = useState<number>(0)
  const idsToInclude = [useEmployerMemberID]

  useEffect(() => {
    setForceRefresh((curr: number) => curr + 1)
  }, [useEmployerMemberID, emailForNotificationsLookup])

  const tabList = [
    {
      label: 'LOAs',
      value: 0,
      hasAccess: Models.Loa.hasAccess,
      display() {
        return (
          <LoaTable.Table
            key={forceRefresh}
            passFilters={{ employerMemberIds: idsToInclude }}
          />
        )
      },
    },
    {
      label: 'Files',
      value: 1,
      hasAccess: Models.File.hasMemberFileAccess,
      display() {
        if (!useEmployerMemberID) return null
        return (
          <div key={forceRefresh}>
            <DisplayMemberFiles EmployerMemberID={useEmployerMemberID} />
          </div>
        )
      },
    },
    {
      label: 'Referrals',
      value: 2,
      hasAccess: Models.Referral.hasAccess,
      display() {
        if (!useEmployerMemberID) {
          return null
        }
        return (
          <ReferralTable.Table
            LeftHeaderItems={
              <>
                <ReferralTable.FilterReferralStatus />
              </>
            }
            passFilters={{
              employerMemberIDs: [useEmployerMemberID],
              ReferralsOnly: true,
            }}
          />
        )
      },
    },
    {
      label: 'Eligibility Src',
      value: 3,
      hasAccess: Models.Eligibility.hasAccessSrc,
      display() {
        return (
          <EligSrcTable2.Table
            key={forceRefresh}
            passFilters={{ employerMemberID: useEmployerMemberID }}
            LeftHeaderItems={
              <>
                <Chip label={`EM-ID: ${useEmployerMemberID}`} />
              </>
            }
          />
        )
      },
    },
    {
      label: 'Notifications',
      value: 4,
      hasAccess: () => !!emailForNotificationsLookup,
      display() {
        return (
          <TableSESNotifications
            key={forceRefresh}
            email={emailForNotificationsLookup}
          />
        )
      },
    },
  ]

  if (!useEmployerMemberID) return null

  const curr = tabList.find((tabList) => tabList.value === currTab)

  return (
    <DesignSuite2023.Section padTop0>
      <DesignSuite2023.StyledTabs value={currTab}>
        {tabList
          .filter((t) => t.hasAccess())
          .map((tab) => (
            <DesignSuite2023.StyledTab
              value={tab.value}
              key={tab.value}
              label={tab.label}
              onClick={() => setCurrTab(tab.value)}
            />
          ))}
      </DesignSuite2023.StyledTabs>
      {!!curr && curr.hasAccess() && curr.display()}
    </DesignSuite2023.Section>
  )
}
