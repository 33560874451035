import React, { forwardRef, useContext, useEffect } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import Models from '../../models'
import { ReferralActions } from '../../actions'
import { useConfig } from '../../providers/Config'
import { Launch } from '@material-ui/icons'
import { RenderSelectField2 } from '../../components/Inputs/standard'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

// default table columns; exported so can be reused and customized if necessary
export const defaultColumns = Object.freeze(Models.Referral.columns)
// default sort; freeze for re-rendering stability
const defaultSort = Object.freeze({ col: 'created_at', dir: 'desc' })

export const Table = forwardRef(function ReferralTable2(
  {
    customColumns,
    apiEndpoint = ReferralActions.findReferralsV2,
    onRowClick,
    onCheckHandler,
    DataTableProps,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { referralAppLink } = useConfig()

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns: {
        ...defaultColumns,
        ...customColumns,
        View: {
          name: 'View',
          details: {
            dataFormat: (_: any, row: any) => {
              return (
                <a
                  href={`${referralAppLink}/referral/${row.ID}`}
                  target="_blank"
                  rel="noreferrer">
                  <Launch />
                </a>
              )
            },
          },
        },
      },
      apiEndpoint,
      defaultSort,
      onRowClick,
      onCheckHandler,
      DataTableProps: {
        ...DataTableProps,
      },
    },
    ref
  )

  return <> {TableDisplay} </>
})

export function FilterReferralStatus({
  apiEndpoint = ReferralActions.getReferralStatuses,
}: {
  apiEndpoint?(): Promise<any>
}): React.ReactElement | null {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [statusOptions, setStatusOptions] = React.useState<any[]>([])

  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()

  useEffect(() => {
    apiEndpoint().then((res: any) => {
      let options = res.Data.map((s: any) => ({ value: s.ID, label: s.Descr }))
      setStatusOptions([{ value: 'Any', label: 'Any' }, ...options])
    })
  }, [internalID])

  function setStatus(option: any) {
    if (option.value !== 'Any') {
      return setFilterImmediate({ StatusID: [option.value] })
    }
    setFilterImmediate({ StatusID: [] })
  }

  if (!statusOptions.length) return null

  return (
    <RenderSelectField2
      fullWidth
      name={'status'}
      label={'Status'}
      value={filter.StatusID?.[0] || 'Any'}
      items={statusOptions}
      setter={setStatus}
    />
  )
}
