import React, { forwardRef, useCallback, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import {
  ObservedEmployerDivision,
  ObservedEmployerDivisionColumns,
} from '../../../models/EmployerDivisions'
import { findObservedEmployerDivisions } from '../../../actions/EmployerDivisionActions'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import DialogLinkEmployerDivision from './DialogLinkEmployerDivision'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = ObservedEmployerDivisionColumns

export const Table = forwardRef(function ObservedEmployerDivisionTable(
  {
    employerID,
    customColumns,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const apiEndpoint = useCallback(
    (params: any) => findObservedEmployerDivisions(employerID, params),
    [employerID]
  )

  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns: { ...customColumns, ...defaultColumns },
      initPage: 1,
      initPageSize: 10,
      apiEndpoint,
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function InlineLinkEmployerDivision({
  data,
}: {
  data: ObservedEmployerDivision
}): React.ReactElement {
  const ref = React.useRef<any>(null)
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <DialogLinkEmployerDivision
        employerID={data.EmployerID}
        referenceID={data.ReferenceID}
        observedEmployerDivisionUUID={data.UUID}
        ref={ref}
        onClose={refresh}
      />
    </>
  )
}
