import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { IconButton } from '@material-ui/core'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Subject as IconSubject } from '@material-ui/icons'

interface props {
  uuid?: string
  btnSize?: 'small' | 'medium'
}

export default function LogViewer({
  uuid,
  btnSize = 'medium',
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [logs, setLogs] = useState<any[]>([])
  const [viewLog, setViewLog] = useState<any | null>(null)
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()

  useEffect(() => {
    if (!isOpen) {
      setLogs([])
      return
    }
    loadLogsByUUID()
  }, [isOpen])

  function loadLogsByUUID() {
    api
      .get(`/zclaims/tools/logs/${uuid}`)
      .then((res: any) => {
        if (res.error) throw res
        setLogs(res.Data)
        if ((res.Data || []).length) {
          setViewLog(res.Data[0])
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading logs',
          withError(err: any) {
            console.log(err) /* @todo */
          },
        })
      )
  }

  function onChangeSelectToView(e: any, sel: any) {
    const x = logs.find((v: any) => `${v.ID}` === sel.props?.value)
    setViewLog(x)
  }

  if (!uuid) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="View logs">
        <IconButton
          size={btnSize}
          onClick={(ev: any) => {
            ev.stopPropagation()
            setIsOpen(true)
          }}>
          <IconSubject fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <React.Fragment>
        <DesignSuite2023.Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          width="70vw">
          <div style={{ padding: '1rem' }}>
            {!logs.length && 'No logs to display'}
            {!!logs.length && (
              <>
                <DesignSuite2023.AlertInfo>
                  <span>
                    Note: logs are not (necessarily) unique to the record you
                    may be looking for, however, the record is guaranteed to
                    have been associated with any of logs listed below.
                  </span>
                </DesignSuite2023.AlertInfo>

                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="none"
                  size="small"
                  style={{ minWidth: 120, margin: '1rem 0' }}>
                  <InputLabel shrink id={internalID}>
                    Choose Log To Display
                  </InputLabel>
                  <Select
                    labelId={internalID}
                    value={viewLog?.ID || ''}
                    onChange={onChangeSelectToView}
                    label="Choose Log To Display"
                    autoWidth>
                    {logs.map((item: any) => (
                      <MenuItem key={item.ID} value={`${item.ID}`}>
                        {item.CreatedAt} ({item.Kind})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!!viewLog && (
                  <DesignSuite2023.StyledPre>
                    {viewLog.Logs}
                  </DesignSuite2023.StyledPre>
                )}
              </>
            )}
          </div>
        </DesignSuite2023.Drawer>
      </React.Fragment>
    </>
  )
}
