import React, { useState, useEffect, useCallback, useRef } from 'react'
import InvoiceTable, { InvoiceTableColumnsDefault } from './InvoiceTable'
import InvoiceStatusFilter from './statusFilter'
import BillingMethodFilter from './billingMethodFilter'
import InvoiceTypeFilter from './invoiceTypeFilter'
import DateOfIssueFilter from './dateOfIssueFilter'
import Models from '../../models'
import DialogEditInvoice from './dialogEditInvoice'
import DialogBulkStatusTransition from './dialogBulkStatusTransition'
import { Edit as IconEdit } from '@material-ui/icons'
import { InvoiceBatchNotifier } from './InvoiceBatchNotifier'
import BillingConfig from '../Employers/BillingConfig'
import { Link } from 'react-router-dom'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import * as api from '../../services/thezerocard/api-helper'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import get from 'lodash/get'
import {
  Switch,
  FormControlLabel,
  IconButton,
  Button,
  Paper,
  Tabs,
  Tab,
  Link as MUILink,
  Drawer,
  Chip,
} from '@material-ui/core'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import perms from '../../utils/perms'
require('./invoices.scss')

const tabItems = {
  list: 0,
  notifications: 1,
}

interface RemindersPayload {
  InvoiceIDs: Array<number>
}

export default function Invoice({ ...props }: any): React.ReactElement {
  const { queryData } = useQueryParamsGen2()
  const refInvoiceTable = useRef()
  const [editInvoiceID, setEditInvoiceID] = useState<number | null>(null)
  const [dialogEditOpen, setDialogEditOpen] = useState(false)
  const [dialogBulkStatTransOpen, setDialogBulkStatTransOpen] = useState(false)
  const [tabVal, setTabVal] = useState(tabItems.list)
  const [filters, setFilters] = useState<any>({
    isOutstanding: get(queryData, 'filter.isOutstanding', true), // true,
    withFiles: get(queryData, 'filter.withFiles', true), // true,
    statusID: get(queryData, 'filter.statusID', null), // filters.statusID
    billingMethod: get(queryData, 'filter.billingMethod', null), // filters.billingMethod
    invoiceTypeID: get(queryData, 'filter.invoiceTypeID', null), // filters.invoiceTypeID
    dateOfIssue: get(queryData, 'filter.dateOfIssue', null), // filters.dateOfIssue
  })
  const [forceFilters, setForceFilters] = useState<any>(null)
  const [quickInfoDrawer, setQuickInfoDrawer] = useState<any>(null)
  const [bulkSelected, setBulkSelected] = useState<any[]>([])
  const [lastBulkOperationIDs, setLastBulkOperationIDs] = useState<
    number[] | null
  >(null)
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )

  useEffect(() => {
    // ensure when switching between tabs that we reset this
    setBulkSelected([])
    setLastBulkOperationIDs(null)
  }, [tabVal, setBulkSelected])

  useEffect(() => {
    if ((lastBulkOperationIDs || []).length === 0) {
      setForceFilters(null)
      return
    }
    setForceFilters({ InvoiceIDs: lastBulkOperationIDs })
  }, [lastBulkOperationIDs, setForceFilters])

  // order matters w/ javascript objects, so this will *prepend* the column to the list of standard ones
  const customTableCols = {
    _: {
      name: '',
      details: {
        dataFormat(_: any, row: any) {
          return (
            <IconButton
              onClick={(ev: any) => {
                ev.stopPropagation()
                openEditInvoice(row.ID)
              }}>
              <IconEdit />
            </IconButton>
          )
        },
      },
    },
    EmployerName: {
      name: 'Employer',
      details: {
        sortName: 'EmployerQuickbooksHandle',
        dataFormat(_: any, row: any) {
          return (
            <MUILink
              href="#"
              underline="always"
              onClick={setQuickInfoDrawer.bind(null, row)}>
              {row.EmployerQuickbooksHandle}
            </MUILink>
          )
        },
      },
    },
    ...InvoiceTableColumnsDefault,
  }

  const onChangeStatusFilter = useCallback(
    (v: any) => {
      setFilters((f: any) => ({ ...f, statusID: !!v ? v.ID : null }))
    },
    [setFilters]
  )

  const onChangeBillingMethodFilter = useCallback(
    (v: any) => {
      setFilters((f: any) => ({ ...f, billingMethod: !!v ? v : null }))
    },
    [setFilters]
  )

  const onChangeInvoiceTypeFilter = useCallback(
    (v: any) => {
      setFilters((f: any) => ({ ...f, invoiceTypeID: !!v ? v.ID : null }))
    },
    [setFilters]
  )

  const onChangeDateOfIssueFilter = useCallback(
    (v: any) => {
      setFilters((f: any) => ({ ...f, dateOfIssue: !!v ? v : null }))
    },
    [setFilters]
  )

  const onChangeIsOutstanding = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const to = ev.target.checked
      setFilters((f: any) => ({ ...f, isOutstanding: to }))
    },
    [setFilters]
  )

  const onCheckHandler = (selected: any[]) => {
    setBulkSelected(selected || [])
  }

  function openEditInvoice(invoiceID: number) {
    setEditInvoiceID(invoiceID)
    setDialogEditOpen(true)
  }

  function onDialogEditClose() {
    setEditInvoiceID(null)
    setDialogEditOpen(false)
  }

  function onDialogBulkStatusTransitionClose() {
    setDialogBulkStatTransOpen(false)
  }

  function onDialogEditAfterSave() {
    // @ts-ignore
    refInvoiceTable.current?.refresh()
  }

  function onDialogBulkStatusChangeAfterSave(invoiceIDs: number[]): void {
    setLastBulkOperationIDs(invoiceIDs)
    // @ts-ignore
    setTimeout(refInvoiceTable.current?.refresh, 50)
  }

  function clearOnlyInvoiceIDs(): void {
    setLastBulkOperationIDs(null)
  }

  function sendInvoiceReminders(): void {
    const payload: RemindersPayload = { InvoiceIDs: [] }
    bulkSelected.forEach((invoice) => payload.InvoiceIDs.push(invoice.ID))
    api
      .post(`/invoice_reminders`, payload)
      .then(() => {
        // @ts-ignore
        refInvoiceTable.current?.refresh()
        snackbar.show(
          'Invoice Reminders successfully sent',
          SnackbarTypeSuccess
        )
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to send bulk invoice reminders; please contact Engineering',
        })
      )
  }

  return (
    <>
      <Paper className="invoices-landing" elevation={2}>
        <div className="tab-wrap">
          <Tabs
            value={tabVal}
            onChange={(_: any, v: number) => {
              setTabVal(v)
            }}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="inherit">
            <Tab value={tabItems.list} label="Invoice List" />
            {Models.Invoice.canCreate() && (
              <Tab value={tabItems.notifications} label="ACH Notifications" />
            )}
          </Tabs>
        </div>

        {tabVal === tabItems.list && (
          <>
            <InvoiceTable
              enableURLReflection
              ref={refInvoiceTable}
              customColumns={customTableCols}
              passFilters={filters}
              forceFilters={forceFilters}
              DataTableProps={{
                checkHandler: onCheckHandler,
              }}
              LeftHeaderItems={
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        color="primary"
                        checked={filters.isOutstanding}
                        onChange={onChangeIsOutstanding}
                      />
                    }
                    label="Show Outstanding Only"
                    style={{ marginBottom: 0 }}
                  />
                </>
              }
              RightHeaderItems={
                <>
                  <div className="filter-option">
                    <InvoiceStatusFilter
                      selectedID={filters.statusID}
                      onChange={onChangeStatusFilter}
                    />
                  </div>
                  <div className="filter-option">
                    <BillingMethodFilter
                      selectedVal={filters.billingMethod}
                      onChange={onChangeBillingMethodFilter}
                    />
                  </div>
                  <div className="filter-option">
                    <InvoiceTypeFilter
                      selectedID={filters.invoiceTypeID}
                      onChange={onChangeInvoiceTypeFilter}
                    />
                  </div>
                  <div className="filter-option">
                    <DateOfIssueFilter
                      selectedVal={filters.dateOfIssue}
                      onChange={onChangeDateOfIssueFilter}
                    />
                  </div>
                </>
              }
              SubHeaderItems={
                <>
                  {!!bulkSelected.length && (
                    <div className="invoice-bulk-actions">
                      <span className="action-label">
                        <strong>Bulk Actions</strong> ({bulkSelected.length}{' '}
                        invoices selected):{' '}
                      </span>
                      <Button
                        disabled={!hasPerms}
                        color="primary"
                        variant="outlined"
                        onClick={setDialogBulkStatTransOpen.bind(null, true)}>
                        Transition Statuses
                      </Button>
                      <Button
                        disabled={!hasPerms}
                        className="reminders-button"
                        color="secondary"
                        variant="outlined"
                        onClick={sendInvoiceReminders}>
                        Send Invoice Reminders
                      </Button>
                    </div>
                  )}
                  {!!lastBulkOperationIDs && (
                    <div className="last-bulk-operation-ids">
                      <p>
                        Since you just performed a bulk operation and any search
                        filters applied may not show the results anymore, we're
                        auto-filtering to the items you just edited (InvoiceIDs:{' '}
                        {JSON.stringify(lastBulkOperationIDs)}).
                      </p>
                      <Chip
                        label="Click here to clear this filter"
                        color="primary"
                        onClick={() => {
                          clearOnlyInvoiceIDs()
                        }}
                      />
                    </div>
                  )}
                </>
              }
            />
          </>
        )}

        {tabVal === tabItems.notifications && Models.Invoice.canCreate() && (
          <>
            <div className="wrap-batch-notifier">
              <InvoiceBatchNotifier />
            </div>
          </>
        )}
      </Paper>

      {/* These are dialogs or other elements that are only visible with certain states on the page; they
    don't need to be nested beneath active tab outputs */}
      <DialogEditInvoice
        isOpen={dialogEditOpen}
        invoiceID={editInvoiceID}
        onDialogClose={onDialogEditClose}
        doAfterSave={onDialogEditAfterSave}
      />

      <DialogBulkStatusTransition
        isOpen={dialogBulkStatTransOpen}
        invoices={bulkSelected}
        onDialogClose={onDialogBulkStatusTransitionClose}
        doAfterSave={onDialogBulkStatusChangeAfterSave}
      />

      <React.Fragment>
        <Drawer
          anchor="right"
          open={!!quickInfoDrawer}
          onClose={setQuickInfoDrawer.bind(null, null)}>
          {!!quickInfoDrawer && Number(quickInfoDrawer.EmployerId) > 0 && (
            <div className="overlay-emp-billing-config">
              <h4>{quickInfoDrawer.EmployerName}</h4>
              <p>
                <strong>
                  Billing Configuration is read only within the sidebar. For
                  more info,{' '}
                  <Link
                    to={`/employer/${quickInfoDrawer.EmployerId}`}
                    target="_blank">
                    view this employer's page
                  </Link>
                  .
                </strong>
              </p>
              <BillingConfig
                employerID={Number(quickInfoDrawer.EmployerId)}
                employerName={quickInfoDrawer.EmployerName}
                gettingConfig={false}
                forceDisableEditing
              />
            </div>
          )}
        </Drawer>
      </React.Fragment>
    </>
  )
}
