import React, { useMemo, useState } from 'react'
import ClaimDetail from './ClaimDetail'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'

type props = DrawerProps & {
  zClaimID?: number | null
  Trigger?: React.FC<any> | null
}

export default React.forwardRef<props, any>(function (
  {
    zClaimID,
    disableEscapeKeyDown = true,
    onClose: propOnClose,
    Trigger,
    ...spread
  }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)
  const internalRef = React.useRef<any>(null)
  const whichRef = useMemo(() => ref || internalRef, [ref, internalRef])

  function onOpen() {
    setComponent(
      <ClaimDetail
        zClaimID={zClaimID}
        doClose={() => whichRef?.current?.close()}
      />
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <>
      <DesignSuite2023.Drawer
        {...spread}
        ref={whichRef}
        width="80vw"
        disableEscapeKeyDown={disableEscapeKeyDown}
        onOpen={onOpen}
        onClose={onClose}>
        {component}
      </DesignSuite2023.Drawer>
      {Trigger && (
        <Trigger
          doOpen={() => {
            whichRef?.current?.open()
          }}
        />
      )}
    </>
  )
})
