import * as api from '../services/thezerocard/api-helper'
import dateTime from '../utils/dateTime'

export function getZClaimByID(id: number) {
  return api.get(`/zclaims/claim/${id}`)
}

export function getZClaimHistoryByID(id: number) {
  return api.get(`/zclaims/claim/${id}/history`)
}

export function findZClaims(params: any) {
  return api.search('/zclaims/claim', params)
}

export function findZClaimSourceFiles(params: any) {
  return api.search('/zclaims/source_file', params)
}

export function findUnprocessedZClaimSrcGoZero(params: any) {
  params.filter = { ...params.filter, processed: false }
  return api.search('/zclaims/src_go_zero', { ...params }).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function postZClaimSourceFileIngest(params: any) {
  return api.post('/zclaims/source_file/ingest', params)
}

export function postZClaimSourceFileScanNew() {
  return api.post('/zclaims/source_file/scan')
}

export function postZClaimProcessSources(params: any) {
  return api.post('/zclaims/process_srcs', params)
}

export function postZClaimProcessGoZero(params: any) {
  return api.post('/zclaims/process_go_zero', params).then((res: any) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function getViewableSource(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/viewable_source`)
}

export function postZClaimCollection(data: any) {
  return api.post(`/zclaims/collection`, data)
}

export function postZClaimCollectionClaims(data: any) {
  return api.post(
    `/zclaims/collection/${data?.zClaimCollectionID}/zclaims`,
    data
  )
}

export function findZClaimCollections(params: any) {
  return api.search('/zclaims/collection', params)
}

export function deleteZClaimCollection(collectionID: number) {
  return api.del(`/zclaims/collection/${collectionID}`)
}

export function deleteZClaimsFromCollection(
  collectionID: number,
  zClaimIDs: number[]
) {
  return api.del(`/zclaims/collection/${collectionID}/claims`, { zClaimIDs })
}

export function getExcodeList() {
  return api.get(`/zclaims/excode_list`)
}

export function postAddClaimExCode(
  zClaimID: number,
  data: { ExCodeOptID: number }
) {
  return api.post(`/zclaims/claim/${zClaimID}/claim_ex_code`, data)
}

export function postAddLineExCode(
  zClaimLineID: number,
  data: { ExCodeOptID: number }
) {
  return api.post(`/zclaims/line/${zClaimLineID}/line_ex_code`, data)
}

// id here is zclaim_ex_code_claim.id, NOT the "ex_code_opt".id
export function postClearClaimExCode(id: number) {
  return api.post(`/zclaims/claim_ex_code/${id}/clear`)
}

// id here is zclaim_ex_code_line.id, NOT the "ex_code_opt".id
export function postClearLineExCode(id: number) {
  return api.post(`/zclaims/line_ex_code/${id}/clear`)
}

export function postAdjudicate(data: any) {
  return api.post(`/zclaims/adjudicate`, data)
}

export function postMoveClaimsToFundingInProcess(data: any) {
  return api.post(`/zclaims/move_to_funding_in_process`, data)
}

export function getZClaimLinesByZClaimID(id: number) {
  return api.get(`/zclaims/claim/${id}/lines`)
}

export function putZClaimLines(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/lines`, data)
}

export function putZClaimLineFeeSchedulePrice(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/line_fee_schedule_price`, data)
}

export function putZClaimFeeSchedule(zClaimID: number, fsID: number) {
  return api.put(`/zclaims/claim/${zClaimID}/fee_schedule`, {
    FeeScheduleID: fsID,
  })
}

export function deleteZClaimLine(zClaimID: number, data: any) {
  return api.del(`/zclaims/claim/${zClaimID}/lines`, data)
}

export function postNewManualZClaim(data: any) {
  return api.post('/zclaims/claim', data)
}

export function putZClaimDiagnosisCodes(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/diagnosis_codes`, data)
}

export function putZClaimProcedureCodes(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/procedure_codes`, data)
}

export function putZClaimDetail(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/detail`, data)
}

export function getZClaimAssignmentPatient(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/patient_assignment`)
}

export function putZClaimPatientOrSubscriber(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/patient`, data)
}

export function putZClaimClearSuspense(zClaimID: number, suspendedID: number) {
  return api.put(`/zclaims/claim/${zClaimID}/clear_suspense/${suspendedID}`)
}

export function postZClaimClone(zClaimID: number, data: any) {
  return api.post(`/zclaims/claim/${zClaimID}/clone`, data)
}

//////
export function getZClaimAssignmentProvider(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/provider_assignment`)
}

export function putZClaimAssignBillingEntity(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/billing_entity`, data)
}

export function putZClaimAssignPracticeFacility(zClaimID: number, data: any) {
  return api.put(`/zclaims/claim/${zClaimID}/practice_facility`, data)
}

////////////// Src records and defaulters management
export function getZClaimInspectSrcRecords(params: any) {
  return api.search(`/zclaims/inspect_src_records`, params)
}

export function getZClaimSrcRecord(params: any) {
  return api.get(`/zclaims/src_record`, null, params)
}

export function putZClaimSrcRecordDefaulterEmployerMember(params: any) {
  return api.put(`/zclaims/src_record_defaulter_employer_member`, params)
}

export function putZClaimSrcRecordDefaulterBillingEntity(params: any) {
  return api.put(`/zclaims/src_record_defaulter_billing_entity`, params)
}

export function putZClaimSrcRecordDefaulterPracticeFacility(params: any) {
  return api.put(`/zclaims/src_record_defaulter_practice_facility`, params)
}

export function putZClaimAssignUncontractedProvider(params: any) {
  return api.put(`/zclaims/assign_uncontracted_provider`, params)
}
////////////// Src records and defaulters management

export function getZClaimLimitsInfo(zClaimID: number): Promise<any> {
  return api.get(`/zclaims/claim/${zClaimID}/limits_info`)
}

export function getRelatedClaimsByZClaimID(zClaimID: number) {
  return api.get(`/zclaims/claim/${zClaimID}/related_claims`)
}

export function getMembersInfo(params: any) {
  return api.get('/zclaims/members_info', null, params)
}

export function getLinkedReferrals(zClaimID: number) {
  return api.get(`/zclaims/linked_referrals/${zClaimID}`)
}

export function linkReferralsToZClaim(zClaimID: number, referralIDs: number[]) {
  return api
    .post(`/zclaims/link_referrals/${zClaimID}`, {
      ReferralIDs: referralIDs,
    })
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function unlinkReferral(linkedReferralID: number) {
  return api
    .del(`/zclaims/linked_referrals/${linkedReferralID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export function putSendToTrash(params: { zClaimIDs: number[]; note: string }) {
  return api.put(`/zclaims/send_to_trash`, params)
}

export function putRemoveFromTrash(params: { zClaimIDs: number[] }) {
  return api.put(`/zclaims/remove_from_trash`, params)
}

export const scopedRoutes = {
  findReferrals(params: any): Promise<any> {
    return api.search('/zclaims/scoped/referral', params)
  },
  getReferralStatuses(): Promise<any> {
    return api.get('/zclaims/scoped/referral_statuses')
  },
  getEmployerByID(employerID: number) {
    return api.get(`/zclaims/scoped/employer/${employerID}`)
  },
  findEmployers(params: any) {
    return api.search('/zclaims/scoped/employer', params)
  },
  getMemberByID(memberID: number) {
    return api.get(`/zclaims/scoped/member/${memberID}`)
  },
  getBillingEntityByID(billingEntityID: number) {
    return api.get(`/zclaims/scoped/billing_entity/${billingEntityID}`)
  },
  findBillingEntities(params: any) {
    return api.search(`/zclaims/scoped/billing_entity`, params)
  },
  findLOAs(params: { memberID: number }) {
    return api.get(`/zclaims/scoped/loa`, null, params)
  },
  getPrices(params: { feeScheduleID: number; [k: string]: any }) {
    return api.get(`/zclaims/scoped/prices`, null, params)
  },
  findOrganizations(params: any) {
    return api.search(`/zclaims/scoped/organization`, params)
  },
  getOrganizationByID(organizationID: number) {
    return api.get(`/zclaims/scoped/organization/${organizationID}`)
  },
  findPracticeFacilities(params: any) {
    return api.search('/zclaims/scoped/practice_facility', params)
  },
  getPracticeFacilityByID(practiceFacilityID: number) {
    return api.get(`/zclaims/scoped/practice_facility/${practiceFacilityID}`)
  },
  findFeeSchedules(params: any) {
    return api.search('/zclaims/scoped/fee_schedule', params)
  },
  getEligibilitySearch(payload: any) {
    if (payload.filter?.dob?.length >= 1) {
      const m = dateTime.parse(payload.filter.dob)
      if (!m.isValid()) {
        return Promise.reject({ Error: { Message: 'Invalid date of birth' } })
      }
      payload.filter.dob = m.format(dateTime.formats.ISODate)

      // insurance; just make sure this is a numeric type and not a string
      const emID = parseInt(payload.filter.employerMemberID)
      payload.filter.employerMemberID = emID > 0 ? emID : null
    }
    return api.search('/zclaims/scoped/eligibility', payload)
  },
}
