import React from 'react'
import Chip from '@material-ui/core/Chip'
import perms from '../utils/perms'

export interface PartnerRecordShape {
  ID: number | null
  CreatedAt: string | null
  UpdatedAt: string | null
  Name: string | null
  Notes: string | null
  LastReviewDate: string | null
  ReviewNotes: string | null
  Tags: string[]
  TagSetID: number | null
  DeletedAt: string | null
  CreatedByUserID: number | null
  ModifiedByUserID: number | null
  Active: boolean | null
  Website: string | null
  Owner: string | null
}

export const diffChangedFields = Object.freeze([
  { name: 'Name', label: 'Name' },
  { name: 'Owner', label: 'Owner' },
  { name: 'Notes', label: 'Notes' },
  { name: 'LastReviewDate', label: 'Last Review Date' },
  { name: 'ReviewNotes', label: 'Review Notes' },
  { name: 'Tags', label: 'Tags' },
  { name: 'Active', label: 'Active' },
  { name: 'Website', label: 'Website' },
])

export const columns = Object.freeze({
  Name: {
    name: 'Name',
    details: { width: '250px', sortName: 'Partner.Name' },
  },
  Owner: {
    name: 'Owner',
    details: { width: '250px', sortName: 'Partner.Owner' },
  },
  Website: {
    name: 'Website',
    details: { width: '250px', sortName: 'Partner.Website' },
  },
  Active: { name: 'Active', details: { dataFormat: activeFormatter } },
  Tags: { name: 'Tags', details: { dataFormat: tagsFormatter } },
})

export const isActive = (row: any): boolean => !!row.Active
export const hasAccess = () => perms.has(perms.RoleTokens.PermReadEmployer)

function activeFormatter(_cell: any, row: any): string {
  return isActive(row) ? 'Active' : 'Inactive'
}

function tagsFormatter(_cell: any, row: any) {
  return (
    <>
      {row.Tags.map((t: string) => (
        <Chip key={t} label={t} />
      ))}
    </>
  )
}

export default {
  columns,
  hasAccess,
  isActive,
}
