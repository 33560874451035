import * as api from '../services/thezerocard/api-helper'
import {
  ShapeCostKeyData,
  ShapeBundleContentRecord,
} from '../views/CostKeys/types'

export function findCostKeys(params: any) {
  return api.search('/cost_keys', params)
}

export function getCostKeyByID(
  id: number
): Promise<{ Data?: ShapeCostKeyData; error: boolean }> {
  return api.get(`/cost_key/${id}`)
}

export function postCodeKeyCode(data: any) {
  return api.post('/cost_key', data)
}

export function putCostKeyCode(data: any) {
  if (!data.ID) return Promise.reject('ID is required')
  return api.put(`/cost_key/${data.ID}`, data)
}

export function findCostKeyCategories(params: any) {
  return api.search('/cost_key_category', params)
}

export function getCostKeyCategoryByID(id: number) {
  return api.get(`/cost_key_category/${id}`)
}

export function getAllCostKeyKeywords() {
  return api.get('/cost_key_keyword')
}

export function getCostKeyBundleContentsByCostKeyID(
  id: number
): Promise<{ Data?: ShapeBundleContentRecord[]; error: boolean }> {
  return api.get(`/cost_key/${id}/bundle_contents`)
}

export function getAllCostKeyBundleContentOptions(): Promise<{
  Data?: ShapeBundleContentRecord[]
  error: boolean
}> {
  return api.get(`/cost_key_bundle_content_options`)
}

export function putEndorseCostKeyByID(id: number) {
  return api.put(`/cost_key/${id}/endorse`)
}
