import React, { forwardRef, useContext, useEffect, useState } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { searchEligibility2 } from '../../actions/EligibilityActions'
import Models from '../../models'
import { EmployerSearcher } from '../../components/Searchers'
import { Button, Grid, TextField } from '@material-ui/core'
import styled from 'styled-components'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

const ValidationText = styled.small`
  color: #666;
  font-weight: bold;
`

// default table columns; exported so can be reused and customized if necessary
export const defaultColumns = Object.freeze(Models.Eligibility.columns)

// create a custom context for the table; remember the base table has one too, this
// is additional to support customized validation
export const EligTableContext = React.createContext<{
  showValidation: boolean
  filtersValid: boolean
  doSearch(): void
  doClear(): void
}>({} as any)

const linkOnRowClick = (row: any) => ({
  pathname: `/member/${row.MemberID}`,
  search: `employerMemberID=${row.EmployerMemberID}`,
})

export const Table = forwardRef(function EligibilityTable2(
  {
    customColumns = defaultColumns,
    apiEndpoint = customAPIEndpoint,
    fnLinkOnRowClick = linkOnRowClick,
    autoLoadData = false,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay, setFilterImmediate, request, forceLoad, clearResults } =
    stdTableSetup.useStandardTableSetup(
      {
        ...passThrough,
        autoLoadData, // @todo: pagination/state bugs exist using this setting; needs fixing prior to merge!
        headerItemAlignment: 'flex-start',
        customColumns,
        apiEndpoint,
        fnLinkOnRowClick,
        DataTableProps: {
          rowOptsApplier,
          keyProp: 'EmployerMemberID',
          ...DataTableProps,
        },
      },
      ref
    )
  const [filtersValid, setFiltersValid] = useState(_filtersAreValid())
  const [showValidation, setShowValidation] = useState(false)

  useEffect(() => {
    setFiltersValid(_filtersAreValid())
  }, [request.filter, setFiltersValid])

  useEffect(() => {
    if (!filtersValid) {
      setShowValidation(true)
      return
    }
    setShowValidation(false)
  }, [request.filter, filtersValid, setShowValidation])

  // Run one time, on first load: if the filters are already set and valid,
  // assume we want to have the results auto-load. From then on out, it requires
  // manual interaction (hitting the search button)
  useEffect(() => {
    if (!filtersValid) return
    forceLoad()
  }, [])

  function _filtersAreValid(): boolean {
    const firstName = (request.filter.firstName || '').length >= 1
    const lastName = (request.filter.lastName || '').length >= 1
    const integrationID = (request.filter.integrationID || '').length >= 1
    const ssnLast4 =
      (request.filter.ssnLast4 || '').length >= 1 &&
      parseInt(request.filter.ssnLast4 || '') > 0
    const dob = dateTime.parse(request.filter.dob).isValid()
    const emp = (request.filter.employerIDs || []).length >= 1
    const empMemID = parseInt(request.filter.employerMemberID)
    return (
      (firstName && lastName) ||
      integrationID ||
      dob ||
      ssnLast4 ||
      emp ||
      empMemID > 0
    )
  }

  function doSearch() {
    if (!filtersValid) return
    forceLoad()
  }

  function doClear() {
    setFilterImmediate({
      firstName: null,
      lastName: null,
      integrationID: null,
      ssnLast4: null,
      dob: null,
      employerIDs: [],
    })
    clearResults()
  }

  return (
    <EligTableContext.Provider
      value={{ showValidation, filtersValid, doSearch, doClear }}>
      {TableDisplay}
    </EligTableContext.Provider>
  )
})

// customize the API endpoint; not for changing where it goes, but for intercepting
// the payload to change the date format before sending
function customAPIEndpoint(payload: any): Promise<any> {
  if (payload.filter?.dob?.length >= 1) {
    const m = dateTime.parse(payload.filter.dob)
    if (!m.isValid()) {
      return Promise.reject({ Error: { Message: 'Invalid date of birth' } })
    }
    payload.filter.dob = m.format(dateTime.formats.ISODate)

    // insurance; just make sure this is a numeric type and not a string
    const emID = parseInt(payload.filter.employerMemberID)
    payload.filter.employerMemberID = emID > 0 ? emID : null
  }
  return searchEligibility2(payload)
}

// passed to DataTableProps default
function rowOptsApplier(row: any): any {
  if (row.Problematic) {
    return { className: 'tr-error' }
  }
  if (!row.IsCurrentlyActive) {
    return { className: 'tr-warning' }
  }
  return null
}

/*
Button to trigger search
*/
export function FilterButtonDoSearch({
  variant = 'contained',
  color = 'primary',
  ...props
}: any): React.ReactElement {
  const { doSearch, filtersValid } = useContext(EligTableContext)

  function onClick() {
    if (props.onClick) {
      props.onClick()
      return
    }
    doSearch()
  }

  return (
    <Button
      {...props}
      disabled={!filtersValid}
      type="submit"
      variant={variant}
      color={color}
      onClick={onClick}>
      Search
    </Button>
  )
}

/*
Button to clear search params
*/
export function FilterButtonClear({
  variant = 'outlined',
  ...props
}: any): React.ReactElement {
  const { doClear } = useContext(EligTableContext)
  return (
    <Button {...props} variant={variant} type="button" onClick={doClear}>
      Clear
    </Button>
  )
}

/*
Input for first name
*/
export function FilterFirstName({
  size = 'small',
  variant = 'outlined',
  style = { maxWidth: 130 },
  ...textFieldProps
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <TextField
      {...textFieldProps}
      style={style}
      size={size}
      variant={variant}
      label="First Name"
      value={filter.firstName || ''}
      onChange={(ev: any) => {
        const firstName = ev.target?.value
        setFilterImmediate({ firstName })
      }}
    />
  )
}

/*
Input for last name
*/
export function FilterLastName({
  size = 'small',
  variant = 'outlined',
  style = { maxWidth: 130 },
  ...textFieldProps
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <TextField
      {...textFieldProps}
      style={style}
      size={size}
      variant={variant}
      label="Last Name"
      value={filter.lastName || ''}
      onChange={(ev: any) => {
        const lastName = ev.target?.value
        setFilterImmediate({ lastName })
      }}
    />
  )
}

/*
Input for integration ID
*/
export function FilterIntegrationID({
  size = 'small',
  variant = 'outlined',
  style = { maxWidth: 130 },
  ...textFieldProps
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <TextField
      {...textFieldProps}
      style={style}
      size={size}
      variant={variant}
      label="Integration ID"
      value={filter.integrationID || ''}
      onChange={(ev: any) => {
        const integrationID = ev.target?.value
        setFilterImmediate({ integrationID })
      }}
    />
  )
}

/*
Input for SSN last 4
*/
export function FilterSSNLast4({
  size = 'small',
  variant = 'outlined',
  style = { maxWidth: 130 },
  ...textFieldProps
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <TextField
      {...textFieldProps}
      style={style}
      size={size}
      variant={variant}
      label="SSN (Last 4)"
      value={filter.ssnLast4 || ''}
      inputProps={{ maxLength: 4 }}
      onChange={(ev: any) => {
        const ssnLast4 = ev.target?.value
        setFilterImmediate({ ssnLast4 })
      }}
    />
  )
}

/*
Input for date of birth
*/
export function FilterDateOfBirth(passThrough: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <ManagedDateInput
      {...passThrough}
      name="dob"
      label="Date Of Birth"
      value={filter.dob}
      setter={({ name, value }) => {
        setFilterImmediate({ dob: value })
      }}
      fullWidth={false}
      margin="none"
    />
  )
}

export function FilterEmployerMemberID({
  size = 'small',
  variant = 'outlined',
  style = { maxWidth: 110 },
  ...textFieldProps
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <TextField
      {...textFieldProps}
      type="number"
      style={style}
      size={size}
      variant={variant}
      label="EmpMemID"
      value={filter.employerMemberID || ''}
      inputProps={{ maxLength: 4 }}
      onChange={(ev: any) => {
        const employerMemberID = ev.target?.valueAsNumber
        setFilterImmediate({ employerMemberID })
      }}
    />
  )
}

/*
Input for employer filtering
*/
export function FilterEmployer({
  EmployerSearcherProps,
}: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  return (
    <EmployerSearcher
      {...EmployerSearcherProps}
      selectedEmployerID={filter.employerIDs?.[0] || null}
      onChange={(empID: number | null) => {
        setFilterImmediate({ employerIDs: !!empID ? [empID] : [] })
      }}
      onlyCustomers={true}
    />
  )
}

/*
Convenience component for rendering a form containing all filter/button inputs
*/
export function DefaultFullyComposedForm(): React.ReactElement {
  const { doSearch, showValidation } = useContext(EligTableContext)

  function onFormSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    ev.stopPropagation()
    doSearch()
  }

  // @ts-ignore
  return (
    <form onSubmit={onFormSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md="auto">
          <FilterFirstName />
        </Grid>
        <Grid item xs={12} md="auto">
          <FilterLastName />
        </Grid>
        <Grid item xs={12} md="auto">
          <FilterIntegrationID />
        </Grid>
        <Grid item xs={12} md="auto">
          <FilterDateOfBirth />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md="auto"
          spacing={2}
          alignItems="center"
          style={{ width: 'auto' }}>
          <Grid item xs={12} md="auto">
            <FilterSSNLast4 />
          </Grid>
          <Grid item xs={12} md="auto">
            <FilterEmployerMemberID />
          </Grid>
          <Grid item xs={12} md="auto">
            <FilterEmployer />
          </Grid>
          <Grid item xs={12} md="auto">
            <FilterButtonDoSearch onClick={() => {}} />
            &nbsp;
            <FilterButtonClear />
          </Grid>
        </Grid>
      </Grid>
      {!!showValidation && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ValidationText>
              Must enter either: First &amp; Last name(s); IntegrationID, valid
              Date of Birth, last 4 of SSN, or select an Employer to search.
            </ValidationText>
          </Grid>
        </Grid>
      )}
      {/* @ts-ignore */}
    </form>
  )
}
