import styled from 'styled-components'
import { Dialog, InputAdornment, Tooltip } from '@material-ui/core'
import React, { useImperativeHandle } from 'react'
import * as LoaTable from '../../../Loas/Table'
import { Button, Typography } from '@material-ui/core'
import { InfoOutlined, Search as IconSearch } from '@material-ui/icons'

const FilterContainer = styled.div`
  display: flex;
  column-gap: 1rem;
`

const ToolTipIcon = styled(InfoOutlined)`
  margin-left: 2px;
  color: #a2a2a2;
  height: 16px;
  width: auto;
  cursor: pointer;
`

const loaInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <IconSearch />
    </InputAdornment>
  ),
  endAdornment: (
    <Tooltip title="LOAs can be searched by ID, CPT Code, and Cost Key Code">
      <ToolTipIcon />
    </Tooltip>
  ),
}

const StyledDialog = styled(Dialog)`
  .std-table {
    .header-items {
      margin-top: 0;
      margin: 0;
      width: auto;
      padding: 0.5rem 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;
      align-items: center;
    }
    .base-table-display {
      margin: 0;
    }
  }
`

interface props {
  passFilters?: any
  onPickedLOA: Function
  onClose?: Function
  Trigger?: React.FC<any> | null
}

export default React.forwardRef(function DialogLoaSearch(
  { passFilters, onPickedLOA, onClose, Trigger }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function doClose() {
    onClose?.()
    setIsOpen(false)
  }

  function onSelectLOA(loa: any) {
    onPickedLOA?.(loa)
    doClose()
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          setIsOpen(true)
        }}
      />
    )
  }

  const filters = { ...passFilters, contractedStatus: 'yes' }

  return (
    <>
      {btnTrigger}
      <StyledDialog open={isOpen} onClose={doClose} maxWidth="xl">
        <LoaTable.Table
          DataTableProps={{ density: 'small' }}
          passFilters={filters}
          onRowClick={onSelectLOA}
          RightHeaderItems={
            <>
              <Typography variant="h6">LOA Search</Typography>
              &nbsp;&nbsp;
              <Button onClick={doClose} variant="outlined" size="small">
                Close
              </Button>
            </>
          }
          LeftHeaderItems={
            <>
              <FilterContainer>
                <div style={{ minWidth: '16rem' }}>
                  <LoaTable.StandardFilterSearch
                    autoFocus
                    InputProps={loaInputProps}
                  />
                </div>
                <LoaTable.FilterLOAStatus />
                <div style={{ minWidth: '16rem' }}>
                  <LoaTable.FilterLOAName />
                </div>
                <LoaTable.FilterLOAEmployer />
                <LoaTable.FilterLOAOrganization />
              </FilterContainer>
            </>
          }
        />
      </StyledDialog>
    </>
  )
})
