import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ViewInbound from './ViewInbound'
import ViewStaged from './ViewStaged'
import ViewMatched from './ViewMatched'
import ViewReadyToPreprocess from './ViewReadyToPreprocess'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'

export default function InboundEligFiles(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)

  useEffect(() => {
    setCurrentTab(1)
  }, [isOpen])

  const handleTabChange = (e: any, value: number) => {
    setCurrentTab(value)
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="left"
        title="Launch inbound eligibility workflow dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Inbound Eligibility
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="xl"
        onClose={() => setIsOpen(false)}>
        <DesignSuite2023.StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          marginless>
          <DesignSuite2023.StyledTab value={1} label="Inbound" />
          <DesignSuite2023.StyledTab value={2} label="Staged" />
          <DesignSuite2023.StyledTab value={3} label="Matched" />
          <DesignSuite2023.StyledTab value={4} label="Ready to Preprocess" />
        </DesignSuite2023.StyledTabs>

        <DialogContent>
          {currentTab === 1 && <ViewInbound setCurrentTab={setCurrentTab} />}

          {currentTab === 2 && <ViewStaged />}

          {currentTab === 3 && <ViewMatched />}

          {currentTab === 4 && <ViewReadyToPreprocess />}
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Close
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
