import React, { useEffect, useState, useMemo } from 'react'
import { Button } from '@material-ui/core'
import PageInvoiceableView from './viewInvoiceable'
import PageInvoicedView from './viewInvoiced'
import PageReceipts from './viewReceipts'
import PagePayableView from './viewPayable'
import PagePaidView from './viewPaid'
import PageZelisView from './viewZelis'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import styled from 'styled-components'
import perms from '../../../utils/perms'

const StyledMenuButtons = styled.div`
  .MuiButton-root {
    margin-left: 1.25rem;

    &::after {
      content: '|';
      position: absolute;
      left: 100%;
      margin-left: 0.5rem;
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type::after {
      content: '';
    }
  }
`

const SUB_VIEW_INVOICEABLE = 'invoiceable'
const SUB_VIEW_INVOICED = 'invoiced'
const SUB_VIEW_RECEIPTS = 'receipts'
const SUB_VIEW_PAYABLE = 'payable'
const SUB_VIEW_PAID = 'paid'
const SUB_VIEW_ZELIS = 'zelis'

export default function PageFunding(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.page_funding.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_INVOICEABLE
  )
  const calcPerms = useMemo(
    () => ({
      viewer: perms.hasAny(
        perms.RoleTokens.PermZClaimAcctViewer,
        perms.RoleTokens.PermEngineering
      ),
      processor: perms.hasAny(
        perms.RoleTokens.PermZClaimAcctProcessor,
        perms.RoleTokens.PermEngineering
      ),
      supervisor: perms.hasAny(
        perms.RoleTokens.PermZClaimAcctSupervisor,
        perms.RoleTokens.PermEngineering
      ),
    }),
    []
  )

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <StyledMenuButtons>
        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICEABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICEABLE)}>
          Invoiceable
        </Button>

        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICED ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICED)}>
          Invoiced
        </Button>

        <Button
          size="small"
          variant={subView === SUB_VIEW_RECEIPTS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_RECEIPTS)}>
          Receipts
        </Button>

        <Button
          size="small"
          variant={subView === SUB_VIEW_PAYABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAYABLE)}>
          Payable
        </Button>

        <Button
          size="small"
          variant={subView === SUB_VIEW_PAID ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAID)}>
          Paid
        </Button>

        <Button
          size="small"
          variant={subView === SUB_VIEW_ZELIS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_ZELIS)}>
          Zelis
        </Button>
      </StyledMenuButtons>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_INVOICEABLE:
      if (calcPerms.viewer) {
        return <PageInvoiceableView />
      }
      break

    case SUB_VIEW_INVOICED:
      if (calcPerms.viewer) {
        return <PageInvoicedView />
      }
      break

    case SUB_VIEW_RECEIPTS:
      if (calcPerms.viewer) {
        return <PageReceipts />
      }
      break

    case SUB_VIEW_PAYABLE:
      if (calcPerms.viewer) {
        return <PagePayableView />
      }
      break

    case SUB_VIEW_PAID:
      if (calcPerms.viewer) {
        return <PagePaidView />
      }
      break

    case SUB_VIEW_ZELIS:
      if (calcPerms.supervisor) {
        return <PageZelisView />
      }
  }

  return <span>Insufficient Permission</span>
}
