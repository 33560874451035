import { TextField } from '@material-ui/core'
import ManagedDateInput from '../components/Inputs/managedDateInput'
import dateTime from '../utils/dateTime'
import utils from '../utils'

const columns = {
  ID: { name: 'ID', details: { editable: false, hidden: true } },
  CPTCode: {
    name: 'CPT Code',
    details: { sortName: 'CPTCode', editable: false },
  },
  DRGCode: { name: 'DRG Code', details: { editable: false } },
  Price: {
    name: 'Price',
    details: {
      width: '100px',
      editable: true,
      dataFormat: utils.deprecatedRowTablePriceFormatter,
      EditComponent: TextField,
      editComponentOpts: { style: { width: 150 } },
      sortName: 'Price',
    },
  },
  Units: {
    name: 'Units',
    details: {
      width: '100px',
      editable: true,
      EditComponent: TextField,
      editComponentOpts: { style: { width: 150 } },
      sortName: 'Units',
    },
  },
  StartDate: {
    name: 'Start Date',
    details: {
      dataFormat: dateTime.cellFormatter(),
      editable: true,
      EditComponent: (props) => {
        return (
          <ManagedDateInput
            {...props}
            setter={({ /*name,*/ value }) => {
              // see 'handleEditCell' in DataTable.js; the result of this onChange fibs
              // to that method to make it seem like an event so it can take .target.value
              // from it.
              props.onChange &&
                props.onChange({
                  target: {
                    value: value,
                  },
                })
            }}
          />
        )
      },
      editComponentOpts: { style: { width: 150 } },
    },
  },
  EndDate: {
    name: 'End Date',
    details: {
      dataFormat: dateTime.cellFormatter(),
      editable: true,
      EditComponent: (props) => {
        return (
          <ManagedDateInput
            {...props}
            setter={({ /*name,*/ value }) => {
              // see 'handleEditCell' in DataTable.js; the result of this onChange fibs
              // to that method to make it seem like an event so it can take .target.value
              // from it.
              props.onChange &&
                props.onChange({
                  target: {
                    value: value,
                  },
                })
            }}
          />
        )
      },
      editComponentOpts: { style: { width: 150 } },
    },
  },
  Notes: {
    name: 'Notes',
    details: {
      editable: true,
      EditComponent: (props) => (
        <TextField {...props} multiline minRows={4} variant="outlined" />
      ),
      editComponentOpts: { style: { width: 400 } },
    },
  },
}
const productionColumns = Object.assign({}, columns, {
  Price: {
    ...columns.Price,
    details: {
      ...columns.Price.details,
      editable: false,
    },
  },
  Units: {
    ...columns.Units,
    details: {
      ...columns.Units.details,
      editable: false,
    },
  },
  StartDate: {
    ...columns.StartDate,
    details: {
      ...columns.StartDate.details,
      editable: false,
    },
  },
  EndDate: {
    ...columns.EndDate,
    details: {
      ...columns.EndDate.details,
      editable: false,
    },
  },
})

const componentPrices = {
  columns: columns,
  productionColumns: productionColumns,
  editFields: {
    Price: 0,
    Units: 0,
    CPTCode: '',
    StartDate: '',
    EndDate: '',
  },
  meta: {
    Price: { required: true, typeChecker: utils.isFloat },
    CPTCode: { required: true },
  },
}

export default componentPrices
