import React from 'react'
import { Button, Grid, List, ListItem } from '@material-ui/core'
import {
  ExportEligibilityModal,
  IngestEligibilityModal,
} from './EligibilityModals'
import FindDuplicateMembers from './FindDuplicateMembers'
import NotifySavingsAnalysis from './NotifySavingsAnalysis'
import SendProviderClaims from './SendProviderClaims'
import SendQuikTripReports from './SendQuikTripReports'
import InboundEligFiles from './InboundEligFiles'
import DesignSuite2023 from '../../components/DesignSuite2023'
import DeactivateMembers from './DeactivateMembers'
import RemoveOppAnalysis from './RemoveOppAnalysis'
import QueueOppAnalysisTask from './QueueOppAnalysisTask'
import InboxUploadManagement from './InboxUploadManagement'
import AddressManagement from './AddressManagement'
import CodesManagement from './CodesManagement'
import FlaggedEmailsManagement from './FlaggedEmailsManagement'

interface HistoryObj {
  push: (route: string) => void
}

interface ETLProps {
  history: HistoryObj
}

export default function ETL({ history }: ETLProps) {
  return (
    <>
      <Grid container spacing={4} justify="center">
        <Grid item>
          <h4>Eligibility-Related Flows</h4>
          <List disablePadding>
            <ListItem disableGutters>
              <InboundEligFiles />
            </ListItem>
            <ListItem disableGutters>
              <IngestEligibilityModal />
            </ListItem>
            <ListItem disableGutters>
              <ExportEligibilityModal />
            </ListItem>
            <ListItem disableGutters>
              <FindDuplicateMembers />
            </ListItem>
            <ListItem disableGutters>
              <DeactivateMembers />
            </ListItem>
            <h4>Opportunity Analysis Flows</h4>
            <List disablePadding>
              <ListItem disableGutters>
                <QueueOppAnalysisTask />
              </ListItem>
              <ListItem disableGutters>
                <RemoveOppAnalysis />
              </ListItem>
            </List>
          </List>
        </Grid>
        <Grid item>
          <h4>Other Flows</h4>
          <List disablePadding>
            <ListItem disableGutters>
              <NotifySavingsAnalysis />
            </ListItem>
            <ListItem disableGutters>
              <DesignSuite2023.Tooltip
                placement="right"
                title="Navigate to sftpaas page (configure sending files to recipients)">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/engineering/sftpaas')}>
                  SFTPaas
                </Button>
              </DesignSuite2023.Tooltip>
            </ListItem>
            <ListItem disableGutters>
              <DesignSuite2023.Tooltip
                placement="right"
                title="Navigate to inbound_sftp page">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/etl/inbound_sftp')}>
                  Inbound SFTP Configs
                </Button>
              </DesignSuite2023.Tooltip>
            </ListItem>
            <ListItem disableGutters>
              <SendProviderClaims />
            </ListItem>
            <ListItem disableGutters>
              <SendQuikTripReports />
            </ListItem>
            <h4>Maintenance</h4>
            <List disablePadding>
              <ListItem disableGutters>
                <InboxUploadManagement />
              </ListItem>
              <ListItem disableGutters>
                <AddressManagement />
              </ListItem>
              <ListItem disableGutters>
                <CodesManagement />
              </ListItem>
              <ListItem disableGutters>
                <FlaggedEmailsManagement />
              </ListItem>
            </List>
          </List>
        </Grid>
      </Grid>
    </>
  )
}
