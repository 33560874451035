import React, { useImperativeHandle } from 'react'
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core'
import * as ReferralTable from './ReferralTableV2'

interface props {
  fnOnRowClick?: Function
  fnOnCheckHandler?: Function
  fnOnTrigger?: Function
  Trigger?: React.FC<any> | null
  apiEndpointLoadReferralStatuses?(): Promise<any>
}

export default React.forwardRef(function DialogReferralSearch(
  {
    LeftHeaderItems,
    RightHeaderItems,
    passFilters,
    customColumns,
    fnOnRowClick,
    fnOnCheckHandler,
    fnOnTrigger,
    Trigger,
    apiEndpointLoadReferralStatuses,
    ...passThrough
  }: props & Partial<ReferralTable.props>,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function doClose() {
    setIsOpen(false)
  }

  function onRowClick(referral: any) {
    if (!fnOnRowClick) return
    fnOnRowClick(referral)
    doClose()
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          fnOnTrigger?.()
          setIsOpen(true)
        }}
      />
    )
  }

  return (
    <>
      {btnTrigger}
      <Dialog open={isOpen} onClose={doClose} maxWidth="xl">
        <DialogContent style={{ width: '1600px' }}>
          <Typography variant="h6">Search Referrals</Typography>
          <ReferralTable.Table
            LeftHeaderItems={
              <>
                {LeftHeaderItems}
                <ReferralTable.FilterReferralStatus
                  apiEndpoint={apiEndpointLoadReferralStatuses}
                />
              </>
            }
            RightHeaderItems={RightHeaderItems}
            AfterTable={
              <div style={{ marginTop: '5px' }}>
                <Button variant="contained" color="secondary" onClick={doClose}>
                  Close
                </Button>
              </div>
            }
            passFilters={{ ...passFilters, ReferralsOnly: true }}
            customColumns={customColumns}
            onRowClick={onRowClick}
            onCheckHandler={fnOnCheckHandler}
            {...passThrough}
          />
        </DialogContent>
      </Dialog>
    </>
  )
})
