import * as api from '../services/thezerocard/api-helper'
export const GET_NEW_FEE_SCHEDULE = 'GET_NEW_FEE_SCHEDULE'
export const RESET_FEE_SCHEDULE_STATE = 'RESET_FEE_SCHEDULE_STATE'
export const SAVE_FEE_SCHEDULE_ENTITIES = 'SAVE_FEE_SCHEDULE_ENTITIES'
export const FIND_FEE_SCHEDULES_FOR_FACILITY = 'FIND_FEE_SCHEDULES_FOR_FACILITY'
export const FIND_PRICES = 'FIND_PRICES'
export const SAVE_PRICE_ROW = 'SAVE_PRICE_ROW'
// export const SAVE_NEW_PRICE = 'SAVE_NEW_PRICE'
export const MOVE_TO_PRODUCTION = 'MOVE_TO_PRODUCTION'
export const APPLY_TO_ORGANIZATION = 'APPLY_TO_ORGANIZATION'

export const findFeeSchedules = (params) => {
  return api.search('/fee_schedule', params)
}

export const getNewFeeSchedule = (params) => {
  return {
    type: GET_NEW_FEE_SCHEDULE,
    payload: {},
  }
}

export const applyToOrganization = ({ id, orgId }) => {
  return {
    type: APPLY_TO_ORGANIZATION,
    payload: api.put(`/fee_schedule/${id}/apply_to_organization/${orgId}`),
  }
}

export const activateImportedFeeSchedules = (importID) => {
  return api.put(`/fee_schedule_import/${importID}/activate`)
}

export const resetFeeScheduleState = () => {
  return { type: RESET_FEE_SCHEDULE_STATE }
}

export const findFeeScheduleFacilities = (id, params) => {
  return api.search(`/fee_schedule/${id}/practice_facilities`, params)
}

export const saveFeeScheduleEntities = (params) => {
  const { feeScheduleId, added, removed } = params
  return {
    type: SAVE_FEE_SCHEDULE_ENTITIES,
    payload: api.put(`/fee_schedule/${feeScheduleId}/entities`, {
      added,
      removed,
    }),
  }
}

export const deleteFeeSchedules = (params) => {
  const { ids } = params
  return api.put(`/fee_schedules/bulk_delete`, { ids })
}

export const bulkTerminateFeeSchedules = (params) => {
  return api.put('/fee_schedules/bulk_terminate', params)
}

export const bulkEditFeeScheduleStarts = (params) => {
  return api.put('/fee_schedules/bulk_edit_start', params)
}

export const deletePracticeFacilitiesFromSchedule = (
  scheduleId,
  PracticeFacilityIDs
) => {
  return api.post(`/fee_schedule/${scheduleId}/terminate_practice_facilities`, {
    PracticeFacilityIDs,
  })
}

export const deleteFeeSchedulesFromPracticeFacility = (
  practiceFacilityID,
  FeeScheduleIDs
) => {
  return api.post(
    `/practice_facility/${practiceFacilityID}/terminate_fee_schedules`,
    { FeeScheduleIDs }
  )
}

export const getFeeSchedule = (id) => {
  return api.get(`/fee_schedule/${id}`).then((res) => res.Data)
}

export const saveFeeSchedule = (id, body) => {
  const url = id ? `/fee_schedule/${id}` : '/fee_schedule'
  return api.put(url, body)
}

export const saveNewFeeSchedule = (body) => {
  return api.post('/fee_schedule', body).then((res) => res.Data)
}

export const moveToProduction = (id, body) => {
  return api
    .put(`/fee_schedule/${id}/move_to_production`, body)
    .then((res) => res.Data)
}

export const cloneFeeSchedule = (id) => {
  return api.post(`/fee_schedule/${id}/clone`).then((res) => res.Data)
}
