import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../services/thezerocard/api-helper'
import styled from 'styled-components'
import { useFrameHeightLock } from '../../components/AppFrame'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { Typography } from '@material-ui/core'
import DesignSuite2023 from '../../components/DesignSuite2023'
import NewContentTemplate from './Components/NewContentTemplate'
import {
  ShapeBundleContentRecord,
  ShapeBundleContentTemplateWithContents,
} from './types'
import AutoCompleteBundleContentOptions from './Components/AutoCompleteBundleContentOptions'
import { DisplayBundleContentTemplateList } from './Components/PickBundleContentsTemplate'

const StyledContentTemplates = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  column-gap: 1rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 100%;
    background: #fff;

    li {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover,
      &.selected {
        background: #f1f1f1;
        cursor: pointer;
      }

      small {
        font-weight: 500;
        font-size: 90%;
        color: #888;
        display: inline-block;
        margin-left: 0.25rem;
      }

      &.heading,
      &.footing {
        &:hover {
          background: none;
        }
      }

      &.footing {
        border-bottom: none;
        padding: 1rem;
      }
    }
  }
`

export default function ContentTemplates(): React.ReactElement | null {
  const [selected, setSelected] =
    useState<ShapeBundleContentTemplateWithContents | null>(null)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refTemplateList = useRef<any>(null)

  useFrameHeightLock(() => true, [])

  useEffect(() => {
    setSelected(null)
  }, [])

  function onAddBundleContentLine(v: ShapeBundleContentRecord) {
    if (!selected || !v) return
    apiPostTemplateOption({
      CostKeyBundleContentTemplateID: selected?.ID,
      CostKeyBundleContentOptionID: v.ID,
    })
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar('Content line added', SnackbarTypeSuccess)
        refTemplateList?.current?.refresh?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to add content line',
        })
      )
  }

  function doDeleteTemplateOption(row: any) {
    const { CostKeyBundleContentTemplateOptionID } = row
    if (!CostKeyBundleContentTemplateOptionID) return

    if (!window.confirm('Are you sure you want to delete this line?')) {
      return
    }

    apiDeleteTemplateOption({ CostKeyBundleContentTemplateOptionID })
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar('Content line deleted', SnackbarTypeSuccess)
        refTemplateList?.current?.refresh?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to remove content line',
        })
      )
  }

  return (
    <StyledContentTemplates>
      <div style={{ width: '25%' }}>
        <ul>
          <li className="heading">
            <Typography variant="h6">Templates</Typography>
          </li>
          <DisplayBundleContentTemplateList
            ref={refTemplateList}
            tag="li"
            onSelect={(v) => setSelected(v)}
            showTrashBin
          />
          <li className="footing">
            <NewContentTemplate
              onCreated={() => {
                refTemplateList?.current?.refresh?.()
              }}
            />
          </li>
        </ul>
      </div>
      <div style={{ width: '75%' }}>
        {selected && (
          <ul>
            <li className="heading">
              <Typography variant="h6">Bundle Content Lines</Typography>
            </li>
            {selected.Contents?.map((row: any) => (
              <li key={row.CostKeyBundleContentTemplateOptionID}>
                <span>{row.Content}</span>
                <DesignSuite2023.CommonIcons.IconDelete
                  fontSize="small"
                  onClick={() => {
                    doDeleteTemplateOption(row)
                  }}
                />
              </li>
            ))}
            <li className="footing">
              <div style={{ flex: 1 }}>
                <AutoCompleteBundleContentOptions
                  onChange={onAddBundleContentLine}
                />
              </div>
            </li>
          </ul>
        )}
      </div>
    </StyledContentTemplates>
  )
}

function apiPostTemplateOption(params: {
  CostKeyBundleContentTemplateID: number
  CostKeyBundleContentOptionID: number
}) {
  return api.post(
    `/cost_key_bundle_content_template/${params.CostKeyBundleContentTemplateID}/option`,
    { optionID: params.CostKeyBundleContentOptionID }
  )
}

function apiDeleteTemplateOption(params: {
  CostKeyBundleContentTemplateOptionID: number
}) {
  return api.del(
    `/cost_key_bundle_content_template_option/${params.CostKeyBundleContentTemplateOptionID}`
  )
}
