import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Chip } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import {
  createNewTagSet,
  getTagsByFamily,
  getTagsByTagSetId,
  Tag,
  TagSet,
  TagSetRoute,
  TagFamily,
} from '../../actions/TagActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface TagAutoProps {
  tagFamily: TagFamily
  tagSetID: number | null
  onChangeTagSet: (tagSetID: number | null) => void
  disabled?: boolean
  TextFieldProps?: any
}

export const TagAutocomplete = ({
  tagFamily,
  tagSetID,
  onChangeTagSet,
  disabled = false,
  TextFieldProps,
}: TagAutoProps) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [availableTags, setAvailableTags] = useState<Tag[]>([])
  // todo: this needs to be abstracted if we add more tag families
  const tagSetRoute =
    tagFamily === 'partner'
      ? 'partner_tagset'
      : ('cost_key_tagset' as TagSetRoute)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getTagsByFamily(tagFamily)
      .then((tags: Tag[]) => {
        setAvailableTags(tags)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch Tags; please contact Engineering',
        })
      )

    if (!tagSetID) {
      setSelectedTags([])
      return
    }

    getTagsByTagSetId(tagSetID)
      .then((tagset: TagSet) => {
        setSelectedTags(tagset.Tags)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch Tagset; please contact Engineering',
        })
      )
  }, [tagSetID])

  const updateTagSet = (tags: Tag[]) => {
    if (!tags || tags.length === 0) return onChangeTagSet(null)
    createNewTagSet(tagSetRoute, tags)
      .then((tagSet: TagSet) => {
        onChangeTagSet(tagSet.ID)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to create new Tagset; please contact Engineering',
        })
      )
  }

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      options={availableTags}
      getOptionLabel={(opt: Tag & any) => {
        return opt
          ? opt.inputValue
            ? `Add ${opt.inputValue}`
            : opt.Tag || ''
          : ''
      }}
      value={selectedTags || []}
      renderInput={(params: any) => (
        <TextField
          {...TextFieldProps}
          {...params}
          variant={TextFieldProps?.variant || 'outlined'}
          size={TextFieldProps?.size || 'small'}
          label="Tags"
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderTags={(value: any, getProps: any) =>
        value.map((option: any, index: any) => (
          <Chip
            variant="outlined"
            label={option.Tag}
            size={TextFieldProps?.size || 'small'}
            {...getProps({ index })}
          />
        ))
      }
      filterOptions={(options: any, params: any) => {
        const f = createFilterOptions()
        const filtered = f(options, params)
        const { inputValue } = params
        const exists = options.some((option: any) => {
          return inputValue === option.Tag
        })
        if (!exists && inputValue !== '') {
          filtered.push({ inputValue, Tag: inputValue })
        }
        return filtered
      }}
      onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
        let words = [] as Tag[]

        if (Array.isArray(newValue)) {
          for (let i = 0; i < newValue.length; i++) {
            const nv = newValue[i]
            if (typeof nv === 'string') {
              words.push({ ID: null, Tag: nv })
              continue
            }
            if (nv.inputValue) {
              words.push({ ID: null, Tag: nv.inputValue })
              continue
            }
            // disallow duplicates
            if (words.find((w) => w.Tag === nv.Tag)) {
              continue
            }
            words.push(nv)
          }
        }

        setAvailableTags(
          availableTags.concat(
            words.filter((w: Tag) => {
              return !availableTags.find((a: Tag) => a.Tag === w.Tag)
            })
          )
        )
        setSelectedTags(words)
        updateTagSet(words)
      }}
    />
  )
}
