import React, { ReactElement, useState, CSSProperties } from 'react'

import useErrorHandlers from '../../../hooks/useErrorHandlers'
import IconRefresh from '@material-ui/icons/Refresh'
import { postSABundleRecalc } from '../../../actions/SavingsAnalysisV2'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'

type props = {
  bundleUUID: string
  onFinish?(): void
  style?: CSSProperties
}

export default function ButtonDoSavingsRecalc({
  bundleUUID,
  onFinish,
  style,
}: props): ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { catchAPIError } = useErrorHandlers()

  function handleClose() {
    onFinish?.()
  }

  function doRecalc() {
    setIsWorking(true)

    postSABundleRecalc(bundleUUID)
      .then((res: any) => {
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed recalc',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <DesignSuite2023.Tooltip title={'Recalculate'}>
        <IconButton disabled={isWorking} onClick={doRecalc} style={style}>
          <IconRefresh />
        </IconButton>
      </DesignSuite2023.Tooltip>
    </>
  )
}
