import React, { useState, useEffect, forwardRef } from 'react'
import * as CostKeyTable2 from '../CostKeys/CostKeyTable2'
import { STATUS_PRODUCTION } from '../../models/FeeSchedule'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import {
  renderNotesField as RenderNotesField,
  renderSwitchField as RenderSwitchField,
  RenderPriceField,
  SetterArgs,
} from '../../components/Inputs/standard'
import StringifyCostKey from '../CostKeys/Components/StringifyCostKey'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import { saveNewPrice } from '../../actions/PriceActions'
import { ShapeFeeSchedulePrice } from './types'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e1e1e1;
  }

  .MuiDialogContent-root {
    width: 100%;
  }

  .std-table .header-items {
    margin-top: 0;
  }

  .form-inputs {
    width: 680px;

    .pad-bottom {
      padding-bottom: 1rem;
    }
  }

  .costkey-info {
    text-align: center;
    padding-top: 0.5rem;

    .styled-cost-key {
      margin: 0 auto;
      display: inline-block;
    }
    .descr {
      display: block;
      text-align: left;
      padding: 0.5rem 1rem 0;
    }
  }
`

interface props {
  feeScheduleID: number
  feeScheduleStatus?: number | null
  onSave?(): void
}

function emptyDefault(): ShapeFeeSchedulePrice {
  return {
    FeeScheduleID: null,
    CostKeyCodeID: null,
    // CostKeyPH: null,
    DateStart: null,
    DateEnd: null,
    ExcludeFromOpp: false,
    ExcludeFromSearch: false,
    Notes: null,
    Price: null,
  }
}

export default forwardRef(function DialogAddPrice(
  { feeScheduleID, feeScheduleStatus, onSave }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCK, setSelectedCK] = useState<any>(null)
  const [data, setData] = useState<ShapeFeeSchedulePrice>(emptyDefault)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const showTable = selectedCK === null
  const isProd = feeScheduleStatus === STATUS_PRODUCTION

  useEffect(() => {
    if (!isOpen) return
    setSelectedCK(null)
    setData(emptyDefault())
  }, [isOpen])

  function setter({ name, value }: SetterArgs): void {
    setData((prev: ShapeFeeSchedulePrice): ShapeFeeSchedulePrice => {
      return { ...prev, [name]: value }
    })
  }

  function doSave() {
    if (!selectedCK) return
    const payload: ShapeFeeSchedulePrice = {
      ...data,
      FeeScheduleID: feeScheduleID,
      CostKeyCodeID: selectedCK.ID,
    }
    if (!payload.Price) {
      alert('Price is required')
      return
    }
    saveNewPrice(payload)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Price added OK', SnackbarTypeSuccess)
        setIsOpen(false)
        onSave?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to save price',
        })
      )
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add Price</Button>

      <StyledDialog maxWidth={'lg'} open={isOpen} style={{ width: '100%' }}>
        <DialogTitle disableTypography>
          <Typography variant="h6">Add Fee Schedule Price</Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: showTable ? 'block' : 'none' }}>
            <CostKeyTable2.Table
              onRowClick={(v: any) => {
                console.log('onRowClick', v)
                setSelectedCK(v || null)
              }}
              passFilters={{ ExcludeDeprecated: true }}
              LeftHeaderItems={<h3 style={{ margin: 0 }}>Select Cost Key</h3>}
              RightHeaderItems={
                <CostKeyTable2.StandardFilterSearch autoFocus />
              }
              DataTableProps={{
                LeftFooterItems: (
                  <small>
                    <strong>Deprecated cost keys are not displayed.</strong>
                  </small>
                ),
              }}
            />
          </div>

          <div
            className="form-inputs"
            style={{ display: showTable ? 'none' : 'block' }}>
            <div className="costkey-info">
              <StringifyCostKey
                bd={selectedCK?.BundleDefinition}
                highlighters
              />
              <span className="descr">
                <strong>Description:</strong> {selectedCK?.Descr}
              </span>
            </div>

            <DesignSuite2023.Divider />

            <div
              style={{
                display: 'flex',
                columnGap: '1rem',
                alignItems: 'start',
                paddingBottom: '0.5rem',
              }}>
              <div style={{ width: 120 }}>
                <RenderPriceField
                  name="Price"
                  label="Price"
                  value={data.Price || ''}
                  setter={setter}
                  use2023Styles
                  opts={{
                    margin: 'none',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <RenderNotesField
                  name="Notes"
                  label="Notes"
                  value={data.Notes || ''}
                  setter={setter}
                  use2023Styles
                  opts={{
                    margin: 'none',
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                />
              </div>
            </div>

            {isProd && (
              <div
                style={{
                  display: 'flex',
                  columnGap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <div style={{ width: 180 }}>
                  <ManagedDateInput
                    name="DateStart"
                    label="Start Date"
                    value={data.DateStart}
                    setter={setter}
                    fullWidth
                  />
                </div>
                <div style={{ width: 180 }}>
                  <ManagedDateInput
                    name="DateEnd"
                    label="End Date"
                    value={data.DateEnd}
                    setter={setter}
                    fullWidth
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                columnGap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div>
                <RenderSwitchField
                  name="ExcludeFromOpp"
                  label="Exclude from OA"
                  value={data.ExcludeFromOpp || false}
                  setter={setter}
                  opts={{ margin: 'none' }}
                />
              </div>
              <div>
                <RenderSwitchField
                  name="ExcludeFromSearch"
                  label="Exclude from Search"
                  value={data.ExcludeFromSearch || false}
                  setter={setter}
                  opts={{ margin: 'none' }}
                />
              </div>
            </div>

            <DesignSuite2023.Divider />

            <DesignSuite2023.GridLR
              left={
                <Button variant="outlined" onClick={() => setSelectedCK(null)}>
                  Back
                </Button>
              }
              right={
                <Button variant="contained" color="primary" onClick={doSave}>
                  Add Price
                </Button>
              }
            />

            {/* <pre>
              {JSON.stringify(
                {
                  selectedCK,
                  data,
                },
                null,
                '  '
              )}
            </pre> */}
          </div>
        </DialogContent>
      </StyledDialog>
    </>
  )
})
