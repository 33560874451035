import React from 'react'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import {
  PercentageInput,
  PhoneExtInput,
  PhoneInput,
  PriceInput,
  SSNInput,
} from './index'
import utils from '../../utils'
// @ts-ignore
import styled from 'styled-components'

interface RenderTextProps {
  name: string
  label: string
  opts?: any
  value: any
  setter: Setter
  disabled?: boolean
  use2023Styles?: boolean
}

interface RenderNumericProps {
  name: string
  label: string
  opts?: any
  value: any
  setter: any
  disabled?: boolean
}

interface RenderSwitchProps {
  name: string
  label: string | React.ReactElement
  opts?: any
  value: boolean | null
  setter: Setter
}

export interface SelectItem {
  value: any
  label: string
}

interface RenderSelectProps {
  name: string
  label: string
  opts?: any
  value: any
  setter: Setter
  items: SelectItem[]
}

interface RenderPercentageProps {
  name: string
  label: string
  opts?: any
  value: string
  setter: any
  use2023Styles?: boolean
}

interface RenderPhoneProps {
  name: string
  label: string
  opts?: any
  value: string
  setter: any
  use2023Styles?: boolean
}

interface RenderPhoneExtProps {
  name: string
  label: string
  opts?: any
  value: string
  setter: any
  use2023Styles?: boolean
}

interface RenderSSNProps {
  name: string
  label: string
  opts?: any
  value: string
  setter: any
}

export interface SetterArgs {
  name: string
  value: any
}

export type Setter = ({ name, value }: SetterArgs) => void

const defaultFormatter = (val: any) => val

const handleChange = (
  setter: Setter,
  name: string,
  formatter = defaultFormatter
) => {
  return (e: any) => {
    const value = formatter(e.target.value)
    setter({ name, value })
  }
}

const handleSwitchChange = (setter: Setter, name: string) => {
  return (e: any) => {
    setter({ name, value: e.target.checked })
  }
}

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledGridContainer = styled(Grid)`
  width: 100%;
`
const AdjustedTooltipIcon = styled(Tooltip)`
  margin-top: -20px;
`

export const renderSelectFieldWithTooltip = (
  props: RenderSelectProps,
  tooltip: string
) => {
  return (
    <StyledGridContainer container spacing={1} alignItems="flex-end">
      <Grid item xs={11}>
        {renderSelectField(props)}
      </Grid>
      <Grid item xs={1}>
        <AdjustedTooltipIcon title={tooltip}>
          <InfoOutlined />
        </AdjustedTooltipIcon>
      </Grid>
    </StyledGridContainer>
  )
}

export const renderTextFieldWithTooltip = (
  props: RenderTextProps,
  tooltip: string
) => {
  const { opts, ...propsNoOpts } = props
  const newOpts: Object = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip title={tooltip}>
            <InfoOutlined />
          </Tooltip>
        </InputAdornment>
      ),
    },
    ...opts,
  }
  return renderTextField({ opts: newOpts, ...propsNoOpts })
}

export const renderTextField = ({
  name,
  label,
  value,
  setter,
  opts = {},
  disabled,
  use2023Styles = true,
}: RenderTextProps) => {
  opts.margin = opts.margin || 'normal'
  if (use2023Styles) {
    opts.variant = opts.variant || 'outlined'
    opts.size = opts.size || 'small'
  }
  return (
    <StyledTextField
      {...opts}
      label={label}
      value={value || ''}
      onChange={handleChange(setter, name)}
      name={name}
      key={name}
      disabled={disabled}
    />
  )
}

export const renderNotesField = ({
  name,
  label,
  value,
  setter,
  opts = {},
  use2023Styles = false,
}: RenderTextProps) => {
  opts.margin = opts.margin || 'normal'
  opts.rows = opts.rows || 4
  opts.rowsMax = opts.rowsMax || 8
  if (use2023Styles) {
    opts.variant = opts.variant || 'outlined'
    opts.size = opts.size || 'small'
    opts.style = {
      fieldSizing: 'content',
      ...(opts.style || []),
    }
    opts.rows = null
    // leave rowsMax as is
  }
  return (
    <StyledTextField
      {...opts}
      multiline
      label={label}
      value={value || ''}
      onChange={handleChange(setter, name)}
      name={name}
      key={name}
    />
  )
}

// note: material-ui's switch component is (sometimes, after you click it) misaligned
// on the vertical with the text by about 2px and its super annoying... this fixes
const StyledSwitch = styled(Switch)`
  .MuiSwitch-input[type='checkbox'] {
    margin-top: 0 !important;
  }
`

export const renderSwitchField = ({
  name,
  label,
  value,
  setter,
  opts = {},
}: RenderSwitchProps) => {
  const { FormControlLabelProps, ...restProps } = opts

  return (
    <FormControlLabel
      control={
        <StyledSwitch
          {...restProps}
          color="primary"
          checked={value || false}
          key={name}
          onChange={handleSwitchChange(setter, name)}
        />
      }
      label={label}
      {...FormControlLabelProps}
    />
  )
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

export const renderSelectField = ({
  name,
  label,
  setter,
  items,
  value,
  opts = {},
}: RenderSelectProps) => {
  const styleObj = opts.wide ? { width: 400 } : {}
  return (
    <StyledFormControl style={styleObj} margin={opts.margin || 'normal'}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...opts}
        value={value || ''} // @todo - this is force a controlled component, but could have issues based on value types
        key={name}
        onChange={handleChange(setter, name)}
        // @ts-ignore
        input={<Input name={name} id={name} />}>
        {items.map((item) => {
          // @ts-ignore
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </StyledFormControl>
  )
}

// todo: whats the difference here between select field 1 and 2
// It defaults to the 2023 styles (outlined, small, margin, etc), honors targeted
// spreading of props to nested components, and uses autoWidth as the default. This
// is a move-forward-but-don't-break-backwards strategy; will make refactoring uses
// of the original RenderSelectField easier to track where we still need to update.
export const RenderSelectField2 = ({
  name,
  label,
  setter,
  items,
  value,
  FormControlProps = {},
  InputLabelProps = {},
  SelectProps = {},
}: RenderSelectProps & Partial<any>) => {
  // @ts-ignore: ignored due to typescript version mismatch; .useId does exist
  const compID = React.useId()
  const id = `field-${name}-${compID}`
  const fcp = {
    variant: 'outlined',
    margin: 'normal',
    size: 'small',
    ...FormControlProps,
  }
  const ilp = { ...InputLabelProps, shrink: true, id }
  const sp = {
    ...SelectProps,
    labelId: id,
    label,
    value: value || '',
    onChange: handleChange(setter, name),
    autoWidth: true,
    notched: true,
  }

  return (
    <FormControl {...fcp}>
      <InputLabel {...ilp}>{label}</InputLabel>
      <Select {...sp}>
        {items.map((item: SelectItem) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const StyledPercentageInput = styled(PercentageInput)`
  width: 100%;
`

export const renderPercentageField = ({
  name,
  label,
  setter,
  value,
  opts = {},
}: RenderPercentageProps) => {
  return (
    <div>
      <StyledPercentageInput
        {...opts}
        label={label}
        onChange={handleChange(setter, name)}
        name={name}
        key={name}
        value={value}
      />
    </div>
  )
}

export const renderNumericField = ({
  name,
  label,
  setter,
  value,
  opts = {},
}: RenderNumericProps) => {
  return (
    <div>
      <StyledTextField
        {...opts}
        label={label}
        onChange={handleChange(setter, name, utils.stripNonNumeric)}
        name={name}
        key={name}
        value={value}
      />
    </div>
  )
}

export const RenderPriceField = ({
  name,
  label,
  setter,
  value,
  opts = {},
  use2023Styles = false,
}: RenderPercentageProps) => {
  opts.margin = opts.margin || 'normal'
  if (use2023Styles) {
    opts.variant = opts.variant || 'outlined'
    opts.size = opts.size || 'small'
  }
  return (
    <div>
      <StyledTextField
        {...opts}
        InputProps={{ inputComponent: PriceInput }}
        label={label}
        onChange={handleChange(setter, name)}
        name={name}
        key={name}
        value={value}
      />
    </div>
  )
}

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
`

export const renderPhoneField = ({
  name,
  label,
  setter,
  value,
  opts = {},
  use2023Styles = false,
}: RenderPhoneProps) => {
  opts.margin = opts.margin || 'normal'
  if (use2023Styles) {
    opts.variant = opts.variant || 'outlined'
    opts.size = opts.size || 'small'
  }
  return (
    <StyledPhoneInput
      {...opts}
      label={label}
      onChange={handleChange(setter, name, utils.stripNonNumeric)}
      name={name}
      value={value}
    />
  )
}

const StyledPhoneExtInput = styled(PhoneExtInput)`
  width: 100%;
`

export const renderPhoneExtField = ({
  name,
  label,
  setter,
  value,
  opts = {},
  use2023Styles = false,
}: RenderPhoneExtProps) => {
  opts.margin = opts.margin || 'normal'
  if (use2023Styles) {
    opts.variant = opts.variant || 'outlined'
    opts.size = opts.size || 'small'
  }
  return (
    <StyledPhoneExtInput
      {...opts}
      label={label}
      onChange={handleChange(setter, name, utils.stripNonNumeric)}
      name={name}
      value={value}
    />
  )
}

// NOTICE: do not use this for future things until we update the underlying Mask
// library
export const renderEmailField = renderTextField

const StyledSSNInput = styled(SSNInput)`
  width: 100%;
`

export const renderSSNField = ({
  name,
  label,
  setter,
  value,
  opts = {},
}: RenderSSNProps) => {
  return (
    <div>
      <StyledSSNInput
        {...opts}
        label={label}
        onChange={handleChange(setter, name, utils.stripNonNumeric)}
        name={name}
        value={value}
      />
    </div>
  )
}
