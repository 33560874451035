import React, { useEffect, useState } from 'react'
import { useSetFrameHeaderMid } from '../../components/AppFrame'
import { Button } from '@material-ui/core'
import PageSavingsAnalysis from './PageSA'
import PageSettings from './PageSettings'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import SAContextProvider from './saContextProvider'

const SUB_VIEW_SA = 'analysis'
const SUB_VIEW_THRESHOLDS = 'thresholds'

export default function PageSavingsReview(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.page_savings.subview',
  })
  const [subView, setSubView] = useState(queryData['subView'] || SUB_VIEW_SA)

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_SA ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_SA)}>
          Analysis
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_THRESHOLDS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_THRESHOLDS)}>
          Settings
        </Button>
      </>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_SA:
      return (
        <SAContextProvider>
          <PageSavingsAnalysis />
        </SAContextProvider>
      )
    case SUB_VIEW_THRESHOLDS:
      return <PageSettings />
  }

  return <span>invalid state</span>
}
