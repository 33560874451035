import React, { useEffect, useState } from 'react'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { TextField, Chip, Popper } from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { getAllCostKeyKeywords } from '../../../actions/CostKeyActions'
import styled from 'styled-components'

type keyword = {
  ID: number
  Keyword: string
}

interface props {
  currentlySelected: Array<any>
  onChange: (words: any) => void
  TextFieldProps?: any
}

export default function AutoCompleteKeywords({
  currentlySelected,
  onChange,
  TextFieldProps,
}: props): React.ReactElement | null {
  const [availableKeywords, setAvailableKeywords] = useState<Array<keyword>>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    loadKeywords()
  }, [])

  function loadKeywords(): void {
    getAllCostKeyKeywords()
      .then(({ Data }: any) => {
        setAvailableKeywords(
          Data.map((v: keyword, idx: number) => {
            return { ID: idx, Keyword: v.Keyword }
          })
        )
        // this.setState({
        //   // Note: this is probably confusing AF: keywords are not unique in the database; every cost key just saves
        //   // a list of keywords applicable to itself. When we load keywords, they come back WITH ALL IDs SET TO 0, because
        //   // they're deduplicated... thus there is no canonical record to point to. The Autocomplete cares about
        //   // IDs for keeping track of render lists though, so that's why we're using the index value from the map function
        //   // here to set the ID. IOW - if you see IDs assigned to keywords, THAT IS A LIE.
        //   availableKeywords: Data.map((v, idx) => {
        //     return { ID: idx, Keyword: v.Keyword }
        //   }),
        // })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading cost key keywords',
        })
      )
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      options={availableKeywords.map((v) => ({
        Keyword: v.Keyword,
      }))}
      PopperComponent={CustomPopper}
      getOptionLabel={(opt) => {
        return opt
          ? opt.inputValue
            ? `Add ${opt.inputValue}`
            : opt.Keyword || ''
          : ''
      }}
      value={currentlySelected || []}
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          size={TextFieldProps?.size || 'small'}
          variant={TextFieldProps?.variant || 'outlined'}
          label={TextFieldProps?.label || 'Keywords'}
          placeholder={TextFieldProps?.placeholder || 'Search...'}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderTags={(value, getProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.Keyword}
            size={TextFieldProps?.size || 'small'}
            {...getProps({ index })}
          />
        ))
      }
      filterOptions={(options, params) => {
        const f = createFilterOptions()
        const filtered = f(options, params)
        const { inputValue } = params
        const exists = options.some((option) => {
          return inputValue === option.Keyword
        })
        if (!exists && inputValue !== '') {
          filtered.push({ inputValue, Keyword: inputValue })
        }
        return filtered
      }}
      onChange={(event, newValue) => {
        let words: Array<any> = []

        if (Array.isArray(newValue)) {
          for (let i = 0; i < newValue.length; i++) {
            const nv = newValue[i]
            if (typeof nv === 'string') {
              words.push(nv)
              continue
            }
            if (nv.inputValue) {
              words.push(nv.inputValue)
              continue
            }
            if (nv.Keyword) {
              words.push(nv.Keyword)
            }
          }
        }

        const values = words.map((w) => ({ Keyword: w }))

        availableKeywords.forEach((r) => {
          let i = words.indexOf(r.Keyword)
          if (i >= 0) {
            words.splice(i, 1)
          }
        })

        setAvailableKeywords((prev: any) => {
          return prev.concat(
            words.map((w) => {
              return { Keyword: w }
            })
          )
        })

        onChange(values)
      }}
    />
  )
}

const CustomPopper = styled(Popper)`
  width: 380px !important;

  .MuiAutocomplete-paper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  }
`
