import {
  getInvoiceFiles,
  getInvoiceNotifications,
  getInvoiceReceiptDetail,
  postSendInvoiceCreatedNotification,
  postSendInvoiceReminderNotification,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import dateTime from '../../../utils/dateTime'
import VoidInvoice from './VoidInvoice'
import perms, { canExecEngineering } from '../../../utils/perms'

import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
interface appliedReceipt {
  ReceiptID: number
  ReceiptDate: string | null
  ReceiptType: receiptType
  CheckNumber: string
  ACHInfo: string
  Notes: string
  IsVoid: boolean
  ReceiptAmount: string
  AppliedAmount: string
}

interface notification {
  EventType: string
  EventDescription: string
  EventDate: string | null
  ConsumedDate: string | null
}

interface invoiceFile {
  FileID: number
  S3Key: string
  FileTypeHash: string
  FileTypeDescription: string
}

interface invoiceInfo {
  InvoiceID: number
  EmployerID: number
  EmployerName: string
  EmployerLegalName: string
  EmployerQuickbooksHandle: string
  InvoiceNumber: number
  InvoiceDate: string | null
  ClaimCount: number
  ClaimTotal: string
  FeeTotal: string
  InvoiceItemCount: number
  InvoiceItemTotal: string
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  IsVoid: boolean
  VoidInvoiceNotes: string
  AppliedReceipts: appliedReceipt[]
}

interface invoiceReceiptDetailProps {
  invoiceID: number
  doClose?(): void
}

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

export default function InvoiceReceiptDetail({
  invoiceID,
  doClose,
}: invoiceReceiptDetailProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<invoiceInfo>({
    InvoiceID: 0,
    EmployerID: 0,
    EmployerName: '',
    EmployerLegalName: '',
    EmployerQuickbooksHandle: '',
    InvoiceNumber: 0,
    InvoiceDate: null,
    ClaimCount: 0,
    ClaimTotal: '0',
    FeeTotal: '0',
    InvoiceItemCount: 0,
    InvoiceItemTotal: '0.00',
    InvoiceAmount: '0',
    AppliedAmount: '0',
    OpenBalance: '0',
    IsVoid: false,
    VoidInvoiceNotes: '',
    AppliedReceipts: [],
  })
  const [notifications, setNotifications] = useState<notification[]>([])
  const [files, setFiles] = useState<invoiceFile[]>([])
  const [canEngineeringOnly] = useState<boolean>(canExecEngineering())
  const { showDurationShort } = useSnackbar()
  const [hasPerms] = useState<boolean>(() =>
    perms.hasAny(
      perms.RoleTokens.PermZClaimAcctProcessor,
      perms.RoleTokens.PermZClaimAcctSupervisor,
      perms.RoleTokens.PermEngineering
    )
  )
  onclose = doClose ? doClose : null
  const amount = data.InvoiceAmount
    ? parseFloat(data.InvoiceAmount.replace(/[^0-9.-]+/g, ''))
    : 0

  const style = amount < 0 ? { color: 'red' } : {}

  useEffect(() => {
    getData()
    getNotificationData()
    getFiles()
  }, [invoiceID])

  function getData() {
    return getInvoiceReceiptDetail(invoiceID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  function getNotificationData() {
    return getInvoiceNotifications(invoiceID)
      .then((res: any) => {
        setNotifications(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get notification receipt data',
        })
      )
  }

  function getFiles() {
    return getInvoiceFiles(invoiceID)
      .then((res: any) => {
        setFiles(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to get files' }))
  }

  function sendInvoiceReminder() {
    if (notifications.length === 0) {
      return
    }

    postSendInvoiceReminderNotification({ invoiceIDs: [invoiceID] })
      .then((res: any) => {
        showDurationShort(
          `Invoice Reminder Notification Queued`,
          SnackbarTypeSuccess
        )
        getNotificationData()
      })
      .catch(catchAPIError({ defaultMessage: 'Unable to queue notification' }))
  }

  function sendInvoiceNotification() {
    if (notifications.length > 0) {
      return
    }

    postSendInvoiceCreatedNotification({ invoiceIDs: [invoiceID] })
      .then((res: any) => {
        showDurationShort(
          `Invoice Created Notification Queued`,
          SnackbarTypeSuccess
        )
        getNotificationData()
      })
      .catch(catchAPIError({ defaultMessage: 'Unable to queue notification' }))
  }

  if (!data) {
    return <></>
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader
            title={
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={9}>
                  <div>
                    <div>{data.EmployerLegalName}</div>
                    {data.EmployerLegalName !==
                      data.EmployerQuickbooksHandle && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerQuickbooksHandle}
                      </div>
                    )}
                    {data.EmployerLegalName !== data.EmployerName && (
                      <div
                        style={{
                          fontSize: '0.875em',
                          fontStyle: 'italic',
                          color: 'secondary',
                        }}>
                        {data.EmployerName}
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item xs={'auto'}>
                  {data.InvoiceItemCount > 0 && (
                    <Typography>
                      {`Invoice Items[${data.InvoiceItemCount}]:`}{' '}
                    </Typography>
                  )}
                  {data.ClaimCount > 0 && (
                    <>
                      <Typography>{`Claims[${data.ClaimCount}]:`} </Typography>
                      <Typography>{`Fees:`}</Typography>
                    </>
                  )}
                  <Typography>{`Total:`}</Typography>
                </Grid>
                <Grid item xs={'auto'} style={{ textAlign: 'right', ...style }}>
                  {data.InvoiceItemCount > 0 && (
                    <Typography>{`${formatAmount(data.InvoiceItemTotal)}`}</Typography>
                  )}
                  {data.ClaimCount > 0 && (
                    <>
                      <Typography>{`${formatAmount(data.ClaimTotal)}`}</Typography>
                      <Typography>{`${formatAmount(data.FeeTotal)}`}</Typography>
                    </>
                  )}
                  <Typography>
                    {`${formatAmount(data.InvoiceAmount)}`}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <DetailDiv>
              <ChipInformationDisplay
                label="Invoice Number"
                value={data.InvoiceNumber.toString()}
              />
              <ChipInformationDisplay
                label="Invoice Date"
                value={dateTime.parse(data.InvoiceDate).format()}
              />
              <ChipInformationDisplay
                label="Invoice Amount"
                value={formatAmount(data.InvoiceAmount)}
              />
              <ChipInformationDisplay
                label="Applied Amount"
                value={formatAmount(data.AppliedAmount)}
              />
              <ChipInformationDisplay
                label="Open Balance"
                value={formatAmount(data.OpenBalance)}
              />
              <div style={{ marginTop: '16px' }}>
                <DesignSuite2023.GridLR
                  left={
                    files.length > 0 ? (
                      <>
                        {files.map((file) => (
                          <FileLinkBtn
                            key={file.FileID}
                            filename={file.FileTypeDescription}
                            handleGetDownloadURLFail={() =>
                              showDurationShort(
                                `Unable to download file`,
                                SnackbarTypeError
                              )
                            }
                            fileId={file.FileID}
                          />
                        ))}
                      </>
                    ) : null
                  }
                  right={
                    <div style={{ paddingRight: '16px' }}>
                      {data.IsVoid ? (
                        <>
                          <Typography variant="h4" style={{ color: 'red' }}>
                            This invoice has been voided
                          </Typography>
                          {data.VoidInvoiceNotes && (
                            <Typography variant="h5">
                              {data.VoidInvoiceNotes}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <VoidInvoice
                            data={data}
                            disabled={
                              data.OpenBalance !== data.InvoiceAmount ||
                              !canEngineeringOnly
                            }
                            hidden={false}
                            onClose={getData}
                          />
                          <div style={{ paddingTop: '10px' }}>
                            Please see an engineer to assist in voiding the
                            invoice
                          </div>
                        </>
                      )}
                      {data.OpenBalance !== data.InvoiceAmount &&
                      !data.IsVoid ? (
                        <div style={{ paddingTop: '10px' }}>
                          To void invoice, first void receipts
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </div>
            </DetailDiv>
            {data.AppliedReceipts && data.AppliedReceipts.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Receipt ID</TableCell>
                      <TableCell>Receipt Date</TableCell>
                      <TableCell>Receipt Type</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Receipt Amount</TableCell>
                      <TableCell>Applied Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.AppliedReceipts.map((receipt) => (
                      <TableRow key={receipt.ReceiptID}>
                        <TableCell>
                          <span>
                            {receipt.ReceiptID}
                            {receipt.IsVoid && (
                              <span style={{ color: 'red' }}> [Void]</span>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          {dateTime.parse(receipt.ReceiptDate).format()}
                        </TableCell>
                        <TableCell>
                          {receipt.ReceiptType === receiptType.CHECK
                            ? `Check: ${receipt.CheckNumber}`
                            : receipt.ReceiptType === receiptType.ACH
                              ? `ACH: ${receipt.ACHInfo}`
                              : receipt.ReceiptType}
                        </TableCell>
                        <TableCell>{receipt.Notes}</TableCell>
                        <TableCell>
                          {formatAmount(receipt.ReceiptAmount)}
                        </TableCell>
                        <TableCell>
                          {formatAmount(receipt.AppliedAmount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardContent>
            <DesignSuite2023.GridLR
              left={
                <Typography component="div" variant="h5">
                  Notification History
                </Typography>
              }
              right={
                data.IsVoid ? null : notifications.length === 0 ? (
                  <Button
                    disabled={!hasPerms}
                    variant="outlined"
                    color="secondary"
                    onClick={sendInvoiceNotification}>
                    Send Creation Notification
                  </Button>
                ) : (
                  <Button
                    disabled={!hasPerms}
                    variant="outlined"
                    color="secondary"
                    onClick={sendInvoiceReminder}>
                    {' '}
                    Send Invoice Reminder{' '}
                  </Button>
                )
              }
            />
            {notifications.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Notification Type</TableCell>
                      <TableCell>Notification Created</TableCell>
                      <TableCell>Email Sent</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications.map((notif, index) => (
                      <TableRow key={`notif${index}`}>
                        <TableCell>{notif.EventDescription}</TableCell>
                        <TableCell>
                          {dateTime.parse(notif.EventDate).format()}
                        </TableCell>
                        <TableCell>
                          {dateTime
                            .parse(notif.ConsumedDate)
                            .format(dateTime.formats.AmericanDate, '')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
