import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  RenderPriceField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  postInvoiceItem,
  postInvoiceItemParams,
} from '../../../actions/ZClaimFundingActions'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import { Autocomplete } from '@material-ui/lab'
import { getEmployerByIDV2 } from '../../../actions/EmployerActions'
import { EmployerSearcher } from '../../../components/Searchers'
import { scopedRoutes } from '../../../actions/ZClaimActions'

interface props {
  employerID?: number
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  useEmployerSearcher?: boolean
  disabled?: boolean
  onComplete?(): void
}

function initialPostInvoiceItemParams(
  employerID: number
): postInvoiceItemParams {
  return {
    date: null,
    employerID,
    type: null,
    amount: '',
    displayNotes: '',
    notes: '',
  }
}

export default function CreateInvoiceItemDialog({
  employerID,
  isOpen,
  setIsOpen,
  useEmployerSearcher,
  onComplete,
}: props): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const [postInvoiceItemParams, setPostInvoiceItemParams] =
    useState<postInvoiceItemParams>(
      initialPostInvoiceItemParams(employerID || 0)
    )
  const [employerName, setEmployerName] = useState('')
  const [employerLegalName, setEmployerLegalName] = useState('')
  const [selectedEmployerID, setSelectedEmployerID] = useState<number>(
    employerID || 0
  )
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setPostInvoiceItemParams((prevParams) => ({
      ...prevParams,
      employerID: selectedEmployerID || 0,
    }))

    updateEmployerName()
  }, [selectedEmployerID])

  function handleClose() {
    setSelectedEmployerID(employerID || 0)

    setPostInvoiceItemParams(initialPostInvoiceItemParams(employerID || 0))

    setIsOpen(false)
  }

  function updateEmployerName() {
    if (!selectedEmployerID) {
      setEmployerName('')
      setEmployerLegalName('')
      return
    }

    return getEmployerByIDV2(selectedEmployerID.toString())
      .then((res: any) => {
        setEmployerName(res.Data?.Name || '')
        setEmployerLegalName(res.Data?.LegalName || '')
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get employer data',
        })
      )
  }

  function doPostInvoiceItem() {
    setIsWorking(true)

    postInvoiceItem(postInvoiceItemParams)
      .then(() => {
        showSnackbar(`OK`, SnackbarTypeSuccess)
        onComplete?.()
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  const setter = ({ name, value }: SetterArgs) => {
    setPostInvoiceItemParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        // no-op: keep dialog open unless finished or user clicks cancel
      }}
      maxWidth={'lg'}>
      <DialogTitle>
        Create Invoice Item
        {!useEmployerSearcher && (
          <>
            <Typography variant="subtitle1">{employerLegalName}</Typography>
            {employerLegalName !== employerName && (
              <Typography
                variant="subtitle2"
                color={'textSecondary'}
                style={{ fontStyle: 'italic' }}>
                {employerName}
              </Typography>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent style={{ width: '720px' }}>
        {useEmployerSearcher && (
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginBottom: '1rem' }}>
            <Grid item xs={12}>
              <EmployerSearcher
                selectedEmployerID={selectedEmployerID || 0}
                onChange={(empID: number) => {
                  setSelectedEmployerID(empID)
                }}
                TextFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                  InputLabelProps: { shrink: true },
                }}
                AutoCompleteProps={{
                  style: { display: 'inline-block' },
                }}
                apiEndpointGetByID={scopedRoutes.getEmployerByID}
                apiEndpointSearch={scopedRoutes.findEmployers}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <ManagedDateInput
              name="date"
              label="Date"
              value={postInvoiceItemParams.date}
              margin="none"
              setter={setter}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={invoiceItemTypes}
              getOptionLabel={(option) => option.label}
              value={
                postInvoiceItemParams.type
                  ? invoiceItemTypes.find(
                      (item) => item.value === postInvoiceItemParams.type
                    )
                  : null
              }
              onChange={(event, newValue) => {
                setter({ name: 'type', value: newValue?.value || null })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  variant="outlined"
                  size={'small'}
                  margin="none"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderPriceField
              name="amount"
              label="Amount"
              value={postInvoiceItemParams.amount}
              setter={setter}
              opts={{ margin: 'none' }}
              use2023Styles
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <RenderNotesField
              name="displayNotes"
              label="Notes to display on invoice"
              value={postInvoiceItemParams.displayNotes}
              setter={setter}
              use2023Styles
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <RenderNotesField
              name="notes"
              label="Internal notes"
              value={postInvoiceItemParams.notes}
              setter={setter}
              use2023Styles
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <DesignSuite2023.GridLR
          left={
            <>
              <Button
                disabled={isWorking}
                color="secondary"
                variant="outlined"
                size="small"
                onClick={handleClose}>
                Cancel
              </Button>
            </>
          }
          right={
            <>
              <Button
                disabled={
                  isWorking ||
                  !selectedEmployerID ||
                  selectedEmployerID === 0 ||
                  !postInvoiceItemParams.type ||
                  !postInvoiceItemParams.date ||
                  !postInvoiceItemParams.amount
                }
                color="primary"
                variant="outlined"
                onClick={doPostInvoiceItem}>
                {`Create Invoice Item`}
                {isWorking && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner
                      size={20}
                      show={isWorking}
                    />
                  </>
                )}
              </Button>
            </>
          }
        />
      </DialogActions>
    </Dialog>
  )
}

export enum InvoiceItemType {
  Credit = 'credit',
  LateFee = 'late_fee',
  PEPM = 'pepm',
  WireTransferFee = 'wire_transfer_fee',
  Other = 'other',
}

const invoiceItemTypes = Object.values(InvoiceItemType).map((type) => ({
  value: type,
  label: getInvoiceItemTypeDisplay(type),
}))

export function getInvoiceItemTypeDisplay(type: InvoiceItemType): string {
  switch (type) {
    case InvoiceItemType.Credit:
      return 'Credit'
    case InvoiceItemType.LateFee:
      return 'Late Fee'
    case InvoiceItemType.PEPM:
      return 'PEPM'
    case InvoiceItemType.WireTransferFee:
      return 'Wire Transfer Fee'
    case InvoiceItemType.Other:
      return 'Other'
    default:
      return 'Unknown'
  }
}
