import React, { forwardRef, useMemo, useRef, useState } from 'react'
import { Button, Popover, TextField } from '@material-ui/core'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { useSAContext } from './saContextProvider'
import styled from 'styled-components'
import StringifyCostKey from '../CostKeys/Components/StringifyCostKey'

const StyledPopoverContent = styled.div`
  padding: 0.75rem;
  font-size: 100%;
  color: #fff;
  background: #222;
  width: 30vw;
  height: 35vh;
  overflow: hidden;
  overflow-y: scroll;
`

const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    color: #fff !important;
    opacity: 0.65;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(3px);

    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
    .MuiInputBase-input {
      color: inherit;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .Mui-focused {
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
  }
`

interface props {
  onChange?(costKey: any): void
}

export default forwardRef(function BtnChooseCostKey(
  { onChange }: props,
  ref: any
): React.ReactElement {
  // @ts-ignore
  const constID = React.useId()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [searchText, setSearchText] = useState<string>('')
  const refBtn = useRef<any>(null)
  const { allCostKeys } = useSAContext()

  const filteredCostKeys = useMemo(() => {
    if (searchText?.length < 3) return []
    if (!allCostKeys?.length) return []
    return allCostKeys.filter((ck) => {
      return (
        ck.Code.toLowerCase().includes(searchText.toLowerCase()) ||
        ck.V2Category.toLowerCase().includes(searchText.toLowerCase()) // ||
        // ck.Descr.toLowerCase().includes(searchText.toLowerCase())
      )
    })
  }, [allCostKeys, searchText])

  function onPopoverClose() {
    setAnchorEl(null)
    setSearchText('')
  }

  function onBtnClick() {
    setAnchorEl(refBtn?.current)
  }

  return (
    <>
      {/* <Button
        ref={refBtn}
        onClick={onBtnClick}
        size="small"
        color="inherit"
        variant="outlined">
        Choose Cost Key
      </Button> */}
      <DesignSuite2023.CommonIcons.IconSearch
        fontSize="small"
        ref={refBtn}
        onClick={onBtnClick}
      />

      <Popover
        id={constID}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: -15 /* 'top' */, horizontal: 'center' }}
        transitionDuration={0}>
        <StyledPopoverContent>
          <div
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              paddingTop: '0.25rem',
            }}>
            <StyledTextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
              margin="none"
              onChange={(ev: any) => setSearchText(ev.target.value)}
              value={searchText}
              InputLabelProps={{ shrink: true }}
              style={{ marginBottom: '0.5rem' }}
            />
          </div>

          <div
            style={{
              padding: '1rem 2rem',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'auto auto auto auto',
              gap: '1rem',
            }}>
            {filteredCostKeys.map((ck) => (
              <React.Fragment key={ck.ID}>
                <div key={`${ck.ID}.1`}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      onChange?.(ck)
                      onPopoverClose()
                    }}>
                    Select
                  </Button>
                </div>
                <span key={`${ck.ID}.2`}>
                  {ck.BundleDefinition?.primCode?.cpt ? (
                    <StringifyCostKey bd={ck.BundleDefinition} />
                  ) : (
                    <>
                      {ck.Code} (legacy){'  '}
                      <span style={{ color: 'red' }}>INVALID_COST_KEY </span>
                    </>
                  )}
                </span>
                <span key={`${ck.ID}.3`}>
                  {ck.TypicalPPOPrice ? `$${ck.TypicalPPOPrice}` : '?'}
                </span>
                <span key={`${ck.ID}.4`}> {ck.V2Category}</span>
                <span key={`${ck.ID}.5`} style={{ gridColumn: '1/-1' }}>
                  {ck.Descr}
                </span>
                <hr
                  key={`${ck.ID}.hr`}
                  style={{
                    width: '100%',
                    gridColumn: '1/-1',
                    borderTop: '1px dashed #aaa',
                    margin: '0.15rem 0',
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </StyledPopoverContent>
      </Popover>
    </>
  )
})
