import React from 'react'
import { Button, IconButton } from '@material-ui/core'
import DialogReferralSearch from '../Referrals/DialogReferralSearch'
import { Check, Delete, InsertLink } from '@material-ui/icons'
import DesignSuite2023 from '../../components/DesignSuite2023'

interface props {
  selectedID: number | null
  employerMemberIDs: number[]
  onChange(statusID: number | null): void
}

export default function LOAReferralPicker({
  selectedID,
  employerMemberIDs,
  onChange,
}: props): React.ReactElement {
  const dialogRef = React.useRef<any>(null)

  const customColumns = Object.freeze({
    Link: {
      name: 'Linked',
      details: {
        dataFormat(_: any, row: any): any {
          if (row.ID !== selectedID) return null
          return (
            <DesignSuite2023.Tooltip title="Referral is linked to LOA">
              <Check style={{ color: 'green' }} />
            </DesignSuite2023.Tooltip>
          )
        },
      },
    },
    Action: {
      details: {
        dataFormat(_: any, row: any): any {
          if (row.ID !== selectedID)
            return (
              <DesignSuite2023.Tooltip title="Link Referral to LOA">
                <IconButton onClick={() => onChange(row.ID)}>
                  <InsertLink />
                </IconButton>
              </DesignSuite2023.Tooltip>
            )
          return (
            <>
              <DesignSuite2023.Tooltip title="Unlink Referral from LOA">
                <IconButton onClick={() => onChange(null)}>
                  <Delete />
                </IconButton>
              </DesignSuite2023.Tooltip>
            </>
          )
        },
      },
    },
  })

  return (
    <DialogReferralSearch
      ref={dialogRef}
      customColumns={customColumns}
      passFilters={{ EmployerMemberIDs: employerMemberIDs }}
      Trigger={(props: any) => (
        <Button {...props} variant="outlined">
          {selectedID ? 'Change Referral' : 'Select Referral'}
        </Button>
      )}
    />
  )
}
