import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { EmployerActions } from '../../actions'
import debounce from 'lodash/debounce'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import './styles.scss'

interface DataItem {
  ID: number
  Name: string
  QuickbooksHandle?: string
  LegalName?: string
}

interface Props {
  selectedEmployerID: number | null
  onChange(employerID: number | null, employer?: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
  onlyCustomers?: boolean
  apiEndpointSearch?(params: any): Promise<any>
  apiEndpointGetByID?(id: number): Promise<any>
}

export default React.forwardRef(function (
  {
    selectedEmployerID,
    onChange,
    AutoCompleteProps = {},
    TextFieldProps = {},
    disabled = false,
    disableClear = false,
    onlyCustomers = false,
    apiEndpointSearch = EmployerActions.getEmployers,
    apiEndpointGetByID = EmployerActions.getEmployerByIDV2,
  }: Props,
  ref: any
) {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!selectedEmployerID) {
      return
    }
    if (active && active.ID === selectedEmployerID) {
      return
    }
    apiEndpointGetByID(selectedEmployerID)
      .then((r: any) => {
        if (r.error) throw r
        setActive({ ID: r.Data.ID, Name: r.Data.Name, ...r })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading employer',
        })
      )
  }, [selectedEmployerID, active])

  // if selectedEmployerID prop passed in and its falsy, setActive to null
  useEffect(() => {
    !selectedEmployerID && setActive(null)
  }, [selectedEmployerID])

  useEffect(() => {
    if (query.length < 3) {
      return
    }
    const filter = { q: query } as any
    if (onlyCustomers) {
      filter.isCustomer = true
    }
    apiEndpointSearch({
      filter,
      sort: ['Employer.Name', 'asc'],
    })
      .then((r: any) => {
        if (r.error) throw r
        setData(
          r.Data.map((p: any) => {
            return { ID: p.ID, Name: p.Name, ...p }
          })
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading employer',
        })
      )
  }, [query])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 350)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.ID : null, b)
  }

  return (
    <Autocomplete
      disabled={disabled}
      classes={{ root: 'autocomplete-root' }}
      options={data}
      getOptionLabel={(opt: DataItem) => {
        if (opt.Name.toLowerCase().includes(query.toLowerCase())) {
          return opt.Name
        }
        if (
          opt.QuickbooksHandle &&
          opt.QuickbooksHandle.toLowerCase().includes(query.toLowerCase())
        ) {
          return opt.QuickbooksHandle
        }
        if (
          opt.LegalName &&
          opt.LegalName.toLowerCase().includes(query.toLowerCase())
        ) {
          return opt.LegalName
        }
        return opt.Name
      }}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (opt) =>
            opt.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
            (opt.QuickbooksHandle &&
              opt.QuickbooksHandle.toLowerCase().includes(
                inputValue.toLowerCase()
              )) ||
            (opt.LegalName &&
              opt.LegalName.toLowerCase().includes(inputValue.toLowerCase()))
        )
      }}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      disableClearable={disableClear}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Employer"
          placeholder="Start typing to search..."
          style={{ minWidth: 250 }}
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
})
