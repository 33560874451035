import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findInvoiceZClaimFees } from '../../../actions/ZClaimFundingActions'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'

//@ts-ignore
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import ViewInvoicedClaimCalcOverlay from './ViewInvoicedClaimCalcOverlay'

const TableData = styled.div`
  &.short {
    width: 100px;
  }

  &.long {
    width: 400px;
  }
`

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

interface dataItem {
  ZClaimID: number
  ProviderName: string
  ClaimPayableAmount: string
  ClaimFeeEmployerTotal: string
  ClaimInvoiceTotal: string
  IsReversal: boolean
}

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  _ViewInvoicedDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: dataItem): any {
        return <InlineViewInvoicedDetail data={row} />
      },
    },
  },
  ZClaimID: {
    name: 'ZClaim ID',
    details: {
      sortName: 'ZClaimID',
      dataFormat(_: any, row: dataItem): any {
        return (
          <TableData className="short">
            {row.ZClaimID}
            {row.IsReversal && (
              <span style={{ color: 'red', marginLeft: '5px' }}>
                [Reversal]
              </span>
            )}
          </TableData>
        )
      },
    },
  },
  ProviderName: {
    name: 'Provider',
    details: {
      sortName: 'ProviderName',
      dataFormat(_: any, row: dataItem): any {
        return <TableData className="long">{row.ProviderName}</TableData>
      },
    },
  },
  PayableAmount: {
    name: 'Claim',
    details: {
      dataFormat(_: any, row: dataItem): any {
        let style = {}
        if (row.IsReversal) {
          style = { color: 'red' }
        }
        return (
          <TableData style={style} className="short">
            {formatAmount(row.ClaimPayableAmount)}
          </TableData>
        )
      },
      sortName: 'ClaimPayableAmount',
    },
  },
  FeeEmployerTotal: {
    name: 'Fees',
    details: {
      dataFormat(_: any, row: dataItem): any {
        let style = {}
        if (row.IsReversal) {
          style = { color: 'red' }
        }
        return (
          <TableData style={style} className="short">
            {formatAmount(row.ClaimFeeEmployerTotal)}
          </TableData>
        )
      },
      sortName: 'ClaimFeeEmployerTotal',
    },
  },
  ClaimInvoiceTotal: {
    name: 'Invoice',
    details: {
      dataFormat(_: any, row: dataItem): any {
        let style = {}
        if (row.IsReversal) {
          style = { color: 'red' }
        }
        return (
          <TableData style={style} className="short">
            {formatAmount(row.ClaimInvoiceTotal)}
          </TableData>
        )
      },
      sortName: 'ClaimInvoiceTotal',
    },
  },
})

const defaultSort = { col: 'ZClaimID', dir: 'desc' }

export const InvoicedClaimTable = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findInvoiceZClaimFees,
    dataTableProps,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        ...dataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function InlineViewInvoicedDetail({
  data,
}: {
  data: dataItem
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewInvoicedClaimCalcOverlay
        ref={ref}
        zClaimID={data.ZClaimID}
        onClose={() => refresh()}
      />
    </>
  )
}
