import React, { useState } from 'react'
import useSnackbar from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import * as ZelisBatchPaymentsTable from './ZelisBatchPaymentsTable'
import { reprintZelisBatchPayments } from '../../../actions/ZelisActions'

interface props {
  disabled?: boolean
  isWorkingCallback?: (isWorking: boolean) => void
}

export default React.forwardRef(function ButtonDoZelisReprint(
  { disabled = false }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedPayments, setSelectedPayments] = useState<any[]>([])
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  const tableRef = React.useRef<any>(null)

  function openReprintDialog() {
    setIsOpen(true)
    setSelectedPayments([])
  }

  function reprintZelisPayments() {
    const paymentIDs = selectedPayments.map((payment) => payment.ID)
    if (paymentIDs.length === 0) {
      return
    }

    setIsWorking(true)
    reprintZelisBatchPayments(paymentIDs)
      .then(() => {
        showSnackbar('Reprint completed OK', 'success')
        setIsOpen(false)
      })
      .catch(catchAPIError({ defaultMessage: `Failed to reprint payments` }))
      .finally(() => {
        setIsWorking(false)
      })
  }

  return (
    <>
      <Button
        ref={ref}
        disabled={isWorking || disabled}
        variant="contained"
        color="primary"
        onClick={openReprintDialog}>
        Reprint Zelis EOPs
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {}} // no-op: only close on user close
        maxWidth="lg">
        <DialogTitle>Reprint Zelis EOPs</DialogTitle>
        <DialogContent>
          <ZelisBatchPaymentsTable.Table
            ref={tableRef}
            onCheckHandler={setSelectedPayments}
            LeftHeaderItems={
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ZelisBatchPaymentsTable.FilterBatchDate />
                </Grid>
                <Grid item xs={3}>
                  <ZelisBatchPaymentsTable.FilterZelisBatch />
                </Grid>
              </Grid>
            }
            RightHeaderItems={
              selectedPayments.length > 0 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isWorking}
                  onClick={reprintZelisPayments}>
                  Reprint
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner size={20} show />
                    </>
                  )}
                </Button>
              )
            }
            passFilters={{ IsReprint: false }}
          />
          <div style={{ marginTop: '8px' }}>
            <DesignSuite2023.GridLR
              left={
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isWorking || disabled}
                  onClick={() => setIsOpen(false)}>
                  Close
                </Button>
              }
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
})
