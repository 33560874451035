import React, {
  ReactElement,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { payableTableRecord } from './PayableTable'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import DialogBillingEntitySearch from './DialogBillingEntitySearch'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { postTransferPayablesBillingEntity } from '../../../actions/ZClaimFundingActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import { formatStringDollarAmount as formatAmount } from '../../../utils/formatStringDollarAmount'

const CurrentHeader = styled(Typography)`
  border: 1px solid black;
  padding: 5px;
  border-radius: 2px;
  text-align: center;
`
const InformationDiv = styled.div`
  margin: 16px 0;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 1rem;
  align-items: center;
  max-width: 400px;
  font-size: 96%;
`

const StyledHR = styled.hr`
  grid-column: 1/-1;
  margin: 0;
  height: 1px;
`

const StyledStrong = styled.strong`
  white-space: nowrap;
`

interface transferPayableBillingEntityProps {
  ref: React.Ref<any>
  record: payableTableRecord
  onClose: () => void
}

export default React.forwardRef(function DialogTransferPayableBillingEntity(
  { record, onClose }: transferPayableBillingEntityProps,
  ref: any
): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [targetBillingEntity, setTargetBillingEntity] = useState<any>(null)

  const { showDurationShort } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const beRef = useRef<any>(null)

  const zClaimIDs = useMemo(() => {
    return record.Details.map((d) => d.ZClaimID)
  }, [record])

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function handleClose() {
    onClose?.()
    setIsOpen(false)
  }

  function doTransfer() {
    if (!targetBillingEntity || isWorking) {
      return
    }
    setIsWorking(true)
    postTransferPayablesBillingEntity({
      TargetBillingEntityID: targetBillingEntity.ID,
      ZClaimIDs: zClaimIDs,
    })
      .then(() => {
        showDurationShort(
          'Payables transferred successfully',
          SnackbarTypeSuccess
        )
        handleClose()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to transfer payables' }))
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>Transfer Payables</DialogTitle>
        <DialogContent style={{ minWidth: '800px' }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{ borderRight: '3px solid #cccc', padding: '0px 8px' }}>
              <CurrentHeader>Current Payable</CurrentHeader>
              <InformationDiv>
                <StyledStrong>Pay Entity</StyledStrong>
                <span>{record.PayEntityName}</span>
                <StyledHR />

                <StyledStrong>Pay Entity Address</StyledStrong>
                <span>{record.PayEntityAddress}</span>
                <StyledHR />

                <StyledStrong>Amount</StyledStrong>
                <span>{formatAmount(record.Amount)}</span>
                <StyledHR />

                <StyledStrong>Claim Count</StyledStrong>
                <span>{record.ClaimCount}</span>
                <StyledHR />
              </InformationDiv>
            </Grid>

            <Grid item xs={6} style={{ padding: '0px 8px' }}>
              <DialogBillingEntitySearch
                ref={beRef}
                onPickedBillingEntity={(be: any) => {
                  setTargetBillingEntity(be)
                }}
                Trigger={(props: any) => (
                  <Button
                    {...props}
                    variant="outlined"
                    color="secondary"
                    fullWidth>
                    Target Billing Entity
                  </Button>
                )}
              />
              {targetBillingEntity && (
                <InformationDiv>
                  <StyledStrong>Billing Entity Name</StyledStrong>
                  <span>{targetBillingEntity.Name}</span>
                  <StyledHR />

                  <StyledStrong>Address</StyledStrong>
                  <span>
                    {targetBillingEntity.RemittanceAddress?.DisplayAddress}
                  </span>
                  <StyledHR />

                  <StyledStrong>Organization</StyledStrong>
                  <span>{targetBillingEntity.OrganizationName}</span>
                  <StyledHR />
                </InformationDiv>
              )}
            </Grid>
          </Grid>
          <DesignSuite2023.GridLR
            left={
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}>
                Close
              </Button>
            }
            right={
              <Button
                variant="contained"
                color="primary"
                disabled={isWorking || !targetBillingEntity}
                onClick={doTransfer}>
                Transfer
                {isWorking && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner size={20} show />
                  </>
                )}
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    </>
  )
})
