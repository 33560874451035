import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { renderTextField, SetterArgs } from '../../views/ViewHelpers'

interface componentProps {
  open: boolean
  onCancel(): void
  onCreateNewUser(u: any): void
}

interface editFields {
  FirstName: string
  LastName: string
  Email: string
}

export default ({ open, onCancel, onCreateNewUser }: componentProps) => {
  const [fields, setFields]: [editFields, Function] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
  })

  useEffect(() => {
    setFields({
      FirstName: '',
      LastName: '',
      Email: '',
    })
  }, [open])

  const setter = ({ name, value }: SetterArgs) => {
    setFields(Object.assign({}, fields, { [name]: value }))
  }

  const doCreate = () => {
    onCreateNewUser(fields)
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Create New GoZero User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will setup a new user account, configured with access to only
          this organization. The user will need to use the password reset
          mechanism in order to finalize their account setup.{' '}
          <strong>
            As a favor to your favorite engineering team, please{' '}
            <i>double and triple check the email address before completing!</i>
          </strong>
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {renderTextField({
              name: 'FirstName',
              label: 'First Name',
              setter,
              value: fields.FirstName,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
          <Grid item xs={6}>
            {renderTextField({
              name: 'LastName',
              label: 'Last Name',
              setter,
              value: fields.LastName,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
          <Grid item xs={12}>
            {renderTextField({
              name: 'Email',
              label: 'Email',
              setter,
              value: fields.Email,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" onClick={doCreate}>
              Create User
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
