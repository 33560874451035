import React, { useState } from 'react'
import { postZClaimCollection } from '../../../../actions/ZClaimActions'
import { ShapeCollection } from '../../types'
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../.././../hooks/useErrorHandlers'

const StyledDialog = styled(Dialog)`
  width: 100vw;

  .custom-title {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 0.5rem 1rem 1rem;
    min-width: 500px;
  }
`

interface props {
  onDone?: (v: ShapeCollection) => void
}

export default function BtnCreateNewCollection({
  onDone,
}: props): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const [dialogCreateIsOpen, setDialogCreateIsOpen] = React.useState(false)
  const [name, setName] = useState('')
  const { catchAPIError } = useErrorHandlers()

  function doCreateCollection() {
    if (isWorking) return

    setIsWorking(true)

    postZClaimCollection({
      name,
    })
      .then((res: any) => {
        if (res.error) throw res
        setName('')
        setDialogCreateIsOpen(false)
        onDone?.(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating collection',
        })
      )
      .finally(() => setIsWorking(false))
  }

  function doClose() {
    setDialogCreateIsOpen(false)
  }

  return (
    <>
      <Button onClick={() => setDialogCreateIsOpen(true)}>
        Create New Collection
      </Button>

      <StyledDialog open={dialogCreateIsOpen} onClose={doClose} maxWidth="lg">
        <DialogTitle disableTypography className="custom-title">
          <Typography variant="h5">Create New Collections</Typography>
          <div>
            <IconButton size="small" onClick={doClose}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>

        <div className="content">
          <DesignSuite2023.Tooltip title="Create a new collection">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
              }}>
              <div style={{ flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={name}
                  placeholder="Collection Name"
                  onChange={(ev: any) => {
                    setName(ev.target?.value || '')
                  }}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  disabled={isWorking}
                  onClick={doCreateCollection}>
                  Create
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner size={20} show />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </DesignSuite2023.Tooltip>
        </div>
      </StyledDialog>
    </>
  )
}
