import { SelectOption } from '../components/Inputs/types'
import { EligibilityFileConfigFields } from './types/EligibilityFileConfig'
import utils from '../utils'
import dateTime from '../utils/dateTime'
import _ from 'lodash'
import perms from '../utils/perms'

interface EligilbilityFileConfigModel {
  columns: any
  editFields: EligibilityFileConfigFields
  hasAccess: () => boolean
  benefitStartEndOpts: SelectOption[]
  benefitEndDateDefinitionOpts: SelectOption[]
  submissionFrequencyOpts: SelectOption[]
  benefitExpirationDefinitionOpts: SelectOption[]
  subsetHandleOpts: SelectOption[]
  fileSubmissionOpts: SelectOption[]
  fileTypeOpts: SelectOption[]
  benefitEndDateCalculationForOmissionOpts: SelectOption[]
}

const eligibilityFileConfig: EligilbilityFileConfigModel = {
  columns: {
    ID: { name: 'ID', details: { hidden: true } },
    SubsetHandle: {
      name: 'Subset Handle',
      details: { dataFormat: selectOptionsFormatter(getSubsetHandleOptions()) },
    },
    SourceSystem: { name: 'Source System', details: {} },
    FileType: {
      name: 'File Type',
      details: { dataFormat: selectOptionsFormatter(getFileTypeOptions()) },
    },
    BenefitStartDateSource: {
      name: 'Benefit Start Date Source',
      details: {
        dataFormat: selectOptionsFormatter(getBenefitStartEndOptions()),
      },
    },
    BenefitEndDateSource: {
      name: 'Benefit End Date Source',
      details: {
        dataFormat: selectOptionsFormatter(getBenefitStartEndOptions()),
      },
    },
    BenefitEndDateDefinition: {
      name: 'Benefit End Date Definition',
      details: {
        dataFormat: selectOptionsFormatter(
          getBenefitEndDateDefinitionOptions()
        ),
      },
    },
    BenefitEndDateCalculationForOmission: {
      name: 'Benefit End Date for Omission',
      details: {
        dataFormat: selectOptionsFormatter(
          getBenefitEndDateCalculationForOmissionOptions()
        ),
      },
    },
    BenefitExpirationDefinition: {
      name: 'Benefit Expiration Definition',
      details: {
        dataFormat: selectOptionsFormatter(
          getBenefitExpirationDefinitionOptions()
        ),
      },
    },
    SubmissionFrequency: {
      name: 'Submission Frequency',
      details: {
        dataFormat: selectOptionsFormatter(getSubmissionFrequencyOptions()),
      },
    },
    FrequencyDetail: { name: 'Frequency Detail', details: {} },
    SubmissionType: {
      name: 'Submission Type',
      details: {
        dataFormat: selectOptionsFormatter(getFileSubmissionOptions()),
      },
    },
    IncludeCobra: {
      name: 'Include Cobra',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    IncludePPO: {
      name: 'Include PPO',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    IncludeHSA: {
      name: 'Include HSA',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    IncludeRetirees: {
      name: 'Include Retirees',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    EmailsIncluded: {
      name: 'Emails Included',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    IsActive: {
      name: 'Active',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    UpdatedAt: {
      name: 'Last Updated',
      details: {
        dataFormat(cell: any, row: any) {
          return dateTime.parse(cell).local().format()
        },
      },
    },
    OtherNotes: {
      name: 'Other Notes',
      details: { style: { minWidth: '300px' } },
    },
  },
  editFields: {
    ID: 0,
    EmployerID: 0,
    SubsetHandle: '',
    SourceSystem: '',
    FileType: '',
    BenefitStartDateSource: '',
    BenefitEndDateSource: '',
    BenefitEndDateDefinition: '',
    BenefitEndDateCalculationForOmission: '',
    BenefitExpirationDefinition: '',
    SubmissionFrequency: '',
    FrequencyDetail: '',
    SubmissionType: '',
    IncludeCobra: false,
    IncludePPO: false,
    IncludeHSA: false,
    IncludeRetirees: false,
    EmailsIncluded: false,
    OtherNotes: '',
    IsActive: true,
    ParserVersion: '',
    Regex: '',
    ExampleFileName: '',
    SeparateAccumulatorFile: false,
  },
  benefitStartEndOpts: getBenefitStartEndOptions(),
  benefitEndDateDefinitionOpts: getBenefitEndDateDefinitionOptions(),
  benefitExpirationDefinitionOpts: getBenefitExpirationDefinitionOptions(),
  submissionFrequencyOpts: getSubmissionFrequencyOptions(),
  fileSubmissionOpts: getFileSubmissionOptions(),
  subsetHandleOpts: getSubsetHandleOptions(),
  fileTypeOpts: getFileTypeOptions(),
  benefitEndDateCalculationForOmissionOpts:
    getBenefitEndDateCalculationForOmissionOptions(),
  hasAccess: () => {
    return perms.has(perms.RoleTokens.PermReadEligibilityFileConfig)
  },
}

function getBenefitStartEndOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'Date in File', value: 'date in file' },
    { label: 'Use Effective Date', value: 'use effective date' },
  ]
}

function getBenefitEndDateDefinitionOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'First Day of Non-Coverage', value: 'first day of non-coverage' },
    { label: 'Last Day of Coverage', value: 'last day of coverage' },
  ]
}

function getBenefitExpirationDefinitionOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'End of month', value: 'end of month' },
    { label: 'End of week', value: 'end of week' },
    { label: 'Immediate', value: 'immediate' },
  ]
}

function getSubmissionFrequencyOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Other', value: 'other' },
  ]
}

function getFileSubmissionOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'SFTP', value: 'sftp' },
    { label: 'Secure Email', value: 'email' },
    { label: 'Inbox', value: 'inbox' },
    { label: 'teamZERO-manual', value: 'teamZERO-manual' },
    { label: 'teamZERO-upload', value: 'teamZERO-upload' },
  ]
}

function getFileTypeOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'CSV', value: 'csv' },
    { label: 'CSV (tab)', value: 'csv (tab)' },
    { label: 'Fixed length', value: 'fixed length' },
    { label: 'TXT', value: 'txt' },
    { label: 'XLSX', value: 'xlsx' },
    { label: '834', value: '834' },
    { label: 'Other', value: 'other' },
  ]
}

function getSubsetHandleOptions(): SelectOption[] {
  return [
    { label: 'Default', value: 'default' },
    { label: 'HSA', value: 'hsa' },
    { label: 'Deps', value: 'deps' },
    { label: 'Subs', value: 'subs' },
    { label: 'Manual', value: 'manual' },
    { label: 'Cobra', value: 'cobra' },
    { label: 'PPO', value: 'ppo' },
    { label: 'Retirees', value: 'retirees' },
  ]
}

function getBenefitEndDateCalculationForOmissionOptions(): SelectOption[] {
  return [
    { label: 'Not specified', value: '' },
    { label: 'End of week', value: 'end of week' },
    { label: '1st of same month', value: '1st of same month' },
    { label: '1st of next month', value: '1st of next month' },
    { label: '1st of previous month', value: '1st of previous month' },
    { label: 'Other', value: 'other' },
  ]
}

function selectOptionsFormatter(
  opts: SelectOption[]
): (cell: any, row: any) => string {
  return (cell, _row) => {
    if (!cell) {
      return ''
    }
    const opt = _.find(opts, { value: cell })

    return opt ? opt.label : 'Unknown'
  }
}

export default eligibilityFileConfig
